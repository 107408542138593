import { Button } from 'react-bootstrap';
import styles from './UtilButtons.module.scss';

type UtilButtonsProps = {
  imageList: string[];
  tooltip?: string[];
  onClick: ((param: any) => void)[];
  onlyIcon?: boolean;
};

export const UtilButtons = ({ imageList, tooltip, onClick, onlyIcon }: UtilButtonsProps) => {
  const clickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, clickbtn: (param?: any) => void) => {
    e.stopPropagation();
    clickbtn();
  };

  return (
    <div className={styles.container}>
      {imageList.map((val, i) => {
        const tooltopText = tooltip?.[i];
        return (
          <div className={styles.btnWrap} key={`utilbtn-${i}`}>
            <Button
              className={onlyIcon ? styles.icon_button_only_icon : styles.icon_button}
              onClick={(e) => clickButton(e, onClick[i] ?? onClick[0])}>
              <img alt={`image-${i}`} src={val} />
            </Button>
            {tooltopText && (
              <>
                <span className={styles.tooltip}>{tooltopText}</span>
                <span className={styles.arrow} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
