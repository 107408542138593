import * as React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { Modal, ModalTypes } from 'components/modal';
import { SvgIcon, SvgIconSource, Typography } from 'components';
import { useAppDispatch } from 'hooks';
import { closeModal, openModal } from 'modules/modal/modalReducer';
import {
  scriptFileAnalysisAndTaggingAll,
  scriptFileGPTAnalysisAndTaggingAll,
  uploadScriptFile,
} from 'modules/scripts/ScriptService';
import classNames from 'classnames';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';


type CompletedState = 'progress' | 'elapsed' | 'finished';

export type ScriptUploadProcessingModalProps = {
  projectNo: number;
  fileName?: string;
  duration: number;
  file: File;
  episode: string;
  model: string;
  onComplete?: (scriptNo: number) => void;
};

export default function ScriptUploadProcessingModal({
  projectNo,
  file,
  episode,
  model,
  onComplete,
  duration,
}: ScriptUploadProcessingModalProps) {
  const dispatch = useAppDispatch();
  const [scriptNo, setScriptNo] = React.useState<number | undefined>(undefined);
  const [completeState, setCompleteState] = React.useState<CompletedState>('progress');
  const [hasError, setHasError] = React.useState(false);
  const [progress, setProgress] = React.useState<number>(0);
  const handleClose = () => {
    dispatch(closeModal(ModalTypes.ScriptProcessing));
  };

  const handleError = (msg: string) => {
    setHasError(true);
    dispatch(closeModal(ModalTypes.ScriptProcessing));
    dispatch(
      openModal({
        type: ModalTypes.Confirm,
        props: { text: msg, onConfirm: handleClose, autoClose: true },
      })
    );
  };

  const handleUploadScriptAndAnalysis = async () => {
    if (!projectNo || !file) return;

    try {
      const uploadResult = await uploadScriptFile(projectNo, { file, episode });
      if (model === '1') {
        await scriptFileAnalysisAndTaggingAll(projectNo, uploadResult.scriptNo);
      } else {
        scriptFileGPTAnalysisAndTaggingAll(projectNo, uploadResult.scriptNo);
      }

      setScriptNo(uploadResult.scriptNo);
      setCompleteState('finished');
    } catch (e: AxiosError | any) {
      console.log(e);
      if (e?.response?.data?.detail == 'Script File already exists.') {
        handleError('이미 존재하는 대본 입니다.');
      } else if (e?.response?.data?.detail == 'Episode already exists.') {
        handleError('이미 존재하는 에피소드 입니다.');
      } else {
        handleError('대본 업로드가 실패하였습니다. 다시 시도해주세요.');
      }

    }
  };

  React.useEffect(() => {
    if (!file || !episode || !model) return;
    // Script Upload 및 분석에 대해 임의로 progress 바를 진행
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;

        if (newProgress >= 100 || hasError) {
          clearInterval(interval); // Stop the interval when progress reaches 100 or there is an error
        }

        return newProgress;
      });
    }, 50);

    handleUploadScriptAndAnalysis();

    return () => {
      clearInterval(interval);
    };
  }, [file, episode, model]);

  React.useEffect(() => {
    if (completeState !== 'finished') {
      return;
    }

    console.log('completeState', completeState, scriptNo);

    if (!scriptNo) {
      handleError('대본 업로드가 실패하였습니다. 다시 시도해주세요.');
      return;
    }

    onComplete?.(scriptNo);
    dispatch(closeModal(ModalTypes.ScriptProcessing));
  }, [completeState]);

  return (
    <Modal.Container size="md" style={{ borderRadius: `var(--f4)`, padding: 0 }}>
      <div className={classNames('px-9 pt-5 pb-9', hasError && 'd-none')}>
        <div className="d-flex justify-content-end">
          <Button variant="white" className="p-1" onClick={handleClose}>
            <SvgIcon source={SvgIconSource.Close} />
          </Button>
        </div>
        <div className="py-2">
          <Typography variant="f18-normal--72" className="fw-bold">
            대본을 업로드 중입니다.
          </Typography>
        </div>
        <Typography variant="f14-normal--56">{file.name}</Typography>

        <div className="pt-6 pb-3">
          <ProgressBar now={progress} />
        </div>
        <Typography variant="f14-normal--56" className="text-blue-gray-300">
          {Math.round(duration - progress)}초 남았습니다.
        </Typography>
      </div>
    </Modal.Container>
  );
}
