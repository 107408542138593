import React from 'react';
import { ItemProps, MenuProps, TitleProps } from './type';
import { DropdownContext } from './DropDownContainer';
import styles from './DropDown.module.scss';

function Container({ children, style }: MenuProps) {
  return (
    <div className={styles.dropdown_container} style={style}>
      {Array.isArray(children) ? (
        <>
          {children.map((child, idx) => {
            if (!child) return;
            return <div key={idx}>{React.cloneElement(child)}</div>;
          }
          )}
        </>
      ) : (
        <div>{React.cloneElement(children as React.ReactElement)}</div>
      )}
    </div>
  );
}

function Menu({ children, style }: MenuProps) {
  return (
    <div className={styles.dropdown_menu} style={style}>
      {Array.isArray(children) ? (
        <>
          {children.map((child, idx) => {
            if (!child) return;
            return <div key={idx}>{React.cloneElement(child)}</div>;
          }
          )}
        </>
      ) : (
        <>{React.cloneElement(children as React.ReactElement)}</>
      )}
    </div>
  );
}

function Item({ children, style, onClick }: ItemProps) {
  const context = React.useContext(DropdownContext);

  const onClickMenuItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    e.stopPropagation();
    context?.onToggle();
    if (onClick)onClick();
  };

  return (
    <div key="dropdown_menu_item" className={styles.dropdown_menu_item} onClick={onClickMenuItem} style={style}>
      {children}
    </div>
  );
}

function Title({ text, style }: TitleProps) {
  return (
    <span className={styles.dropdown_menu_title} style={style}>{text}</span>
  );
}
function SubTitle({ text }: TitleProps) {
  return (
    <span className={styles.dropdown_menu_sub_title}>{text}</span>
  );
}

function SearchBar(props: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input className={styles.dropdown_search_input} {...props}/>
  );
}

export  { Container, Menu, Item, Title, SubTitle, SearchBar };
