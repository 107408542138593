import style from './Footer.module.scss';
import Logo from './assets/logo.svg';
import Mail from './assets/envelope.svg';

export default function Footer() {
  return (
    <div className={style.footer}>
      <div className={style.section1}>
        <img src={Logo} />
        <span>Strips</span>
      </div>
      <div className={style.section2}>
        <span>
          <img src={Mail} />
          <span>finch.strips@gmail.com</span>
        </span>
        <span>Copyright ©  Strips {new Date().getFullYear()}.  All rights reserved.</span>
      </div>
      <div className={style.section3}>
        <a href="https://boom-viburnum-8a0.notion.site/b5f501216d564831903dc910e87c1d4f">Terms of Service</a>
        <a href="https://boom-viburnum-8a0.notion.site/526f3e4e95694b83bc0ea3d21d60d459">Privacy Policy</a>
      </div>
    </div>
  );
}
