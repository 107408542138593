import { serverUrl } from '../../util/SvAPI';
import { get } from '../common/HttpCaller';
import Project from './Project';


export default class ProjectGetService {

  getProjects = async () => {
    return (get<undefined, Project[]>(`${serverUrl}/projects`, { }));
  };

  getProject = (id: number | string) => {
    return (get<undefined, Project>(`${serverUrl}/project/${id}`, { }));
  };
}
