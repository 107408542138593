import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import style from './Modal.module.scss';
import { Modal } from './ModalComponents';

import checkCircle from 'public/common/check_circle.svg';

export type AsyncModalParams = { loading: string; success: string; error: string };

export type StatusModalProps = {
  type?: 'loading' | 'success' | 'fail';
  text: string;
  asyncObject?: AsyncModalParams;
  onSuccess?: () => {};
};
export const AsyncStatusModal = ({ type, text }: StatusModalProps) => {
  return (
    <Modal.Container size="md" className="alert_container">
      <div>
        {type === 'loading' ? (
          <LoadingSpinner />
        ) : type === 'fail' ? (
          <div>ERROR!!</div>
        ) : (
          <img src={checkCircle} alt="success" />
        )}
      </div>
      <span className={style.status_modal__text}>{text}</span>
    </Modal.Container>
  );
};
