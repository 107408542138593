import { useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './MultiTagInput.module.scss';
import { MultiTagInputProps } from './MultiTagInput.types';
import { Typography } from 'components/Typography';
import { SvgIcon, SvgIconSource } from 'components/SvgIcon';

export const MutiTagInput = ({
  value = [],
  placeholder = '',
  height = 388,
  className,
  onChange,
  onDelete,
}: MultiTagInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [localValue, setLocalValue] = useState<string>();

  const handleFocus = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const handleRemove = (idx: number) => {
    const del = value?.[idx];
    if (del) {
      onDelete?.(del);
    }
  };

  return (
    <div className={classNames(styles.root, className || '')} style={{ height: `${height}px` }} onClick={handleFocus}>
      <div className={classNames('items-center flex-wrap gap-4 mb-4', !value?.length && 'd-none')}>
        {value?.map((val, index) => (
          <div key={index} className={styles.tag}>
            <Typography variant="f20-140--40">{val}</Typography>
            <a role="button" onClick={() => handleRemove(index)}>
              <SvgIcon source={SvgIconSource.Close} size={24} />
            </a>
          </div>
        ))}
      </div>
      <form
        className="w-100"
        onSubmit={(e) => {
          e.preventDefault();
          if (localValue) {
            onChange?.(localValue);
            setLocalValue('');
          }
        }}>
        <input
          ref={inputRef}
          className={styles.input}
          placeholder={!value?.length ? placeholder : ''}
          value={localValue || ''}
          onChange={(e) => setLocalValue(e.target.value)}
        />
      </form>
    </div>
  );
};
