import { useEffect, useState } from 'react';
import styles from './SceneFilterModal.module.scss';
import { useAppSelector } from '../../../hooks';
import useQueryString from '../../../hooks/scene/useQueryString';
import { SearchListModal, FormModal, SearchListValue } from 'components/modal';
import { StripsTable } from 'components';
import BadgeButtonCell from '../cell/BadgeButtonCell';
import { FilterSetType, ProceedState, SceneFilterType, SceneResource } from '../Scene';
import { PROCEED } from '../../constants';
import { proceedState } from '../../constants/sceneConfig';
import { getFilteredInfos, filtersSetToArray, getPlacesAndCharacters, getEpisodes } from '../../../util/sceneHelper';
import _ from 'lodash';

type FilterMenuType = {
  [key in SceneFilterType]: SearchListValue[];
};

export interface SceneFilterModalProps {
  onConfirm: (filters?: { [key: string]: string }) => void;
}

const SceneFilterModal = ({ onConfirm }: SceneFilterModalProps) => {
  const filterMenuStyle: React.CSSProperties = { width: '19%', height: `var(--f290)` };
  const { scenes } = useAppSelector((state) => state.scenes);

  const { getAllParams, setParams, deleteParams } = useQueryString();

  const [filteredScenes, setFilteredScenes] = useState<SceneResource[]>();
  const [filterMenu, setFilterMenu] = useState<FilterMenuType>({
    episode: [],
    bigPlayPlaces: [],
    playPlaces: [],
    characters: [],
    site: [{ text: 'S' }, { text: 'L' }, { text: '미정' }],
    timeslot: [{ text: 'D' }, { text: 'N' }],
    proceed: [{ text: '예정' }, { text: '미촬영' }, { text: '재촬영' }, { text: '완료' }, { text: '오밋' }],
  });

  const [selectedFilters] = useState<FilterSetType>({
    episode: new Set<string>(),
    bigPlayPlaces: new Set<string>(),
    playPlaces: new Set<string>(),
    characters: new Set<string>(),
    site: new Set<string>(),
    timeslot: new Set<string>(),
    proceed: new Set<string>(),
  });

  useEffect(() => {
    if (!scenes.data?.body) return;

    for (let key in selectedFilters) {
      const filterKey = key as SceneFilterType;
      const params = getAllParams(filterKey);
      if (params) params.forEach((v) => selectedFilters[filterKey].add(v));
    }

    const sceneList = scenes.data.body;
    setFilterMenu({ ...filterMenu, ...getPlacesAndCharacters(sceneList), ...getEpisodes(sceneList) });
    setFilteredScenes(getFilteredInfos(sceneList, filtersSetToArray(selectedFilters)).filteredScenes);
  }, [scenes.data?.body]);

  const checkItem = (type: SceneFilterType, isChecked: boolean, value: SearchListValue) => {
    if (isChecked) {
      selectedFilters[type].add(value.id ? `${value.id}` : value.text);
    } else {
      selectedFilters[type].delete(value.id ? `${value.id}` : value.text);
    }

    const filtered = getFilteredInfos(scenes.data?.body!, filtersSetToArray(selectedFilters)).filteredScenes;
    setFilteredScenes(filtered);
  };

  const applyFilter = () => {
    for (let key in selectedFilters) {
      const filterKey = key as SceneFilterType;
      const params = selectedFilters[filterKey];
      deleteParams(filterKey);
      if (params.size === 0) continue;
      setParams(filterKey, Array.from(params));
    }
    
    const displayFilters: { [key: string]: string } = {
      episode: filterMenu.episode?.filter(e => selectedFilters.episode.has(e.text)).map(e => e.text).join(', '),
      bigPlayPlaces: filterMenu.bigPlayPlaces?.filter(b => selectedFilters.bigPlayPlaces.has(String(b.id))).map(b => b.text).join(', '),
      playPlaces: filterMenu.playPlaces?.filter(b => selectedFilters.playPlaces.has(String(b.id))).map(b => b.text).join(', '),
      site: filterMenu.site?.filter(e => selectedFilters.site.has(e.text)).map(e => e.text).join(', '),
      timeslot: filterMenu.timeslot?.filter(e => selectedFilters.timeslot.has(e.text)).map(e => e.text).join(', '),
      characters: filterMenu.characters?.filter(b => selectedFilters.characters.has(String(b.id))).map(b => b.text).join(', '),
      proceed: filterMenu.proceed?.filter(e => selectedFilters.proceed.has(proceedState[e.text as ProceedState])).map(e => e.text).join(', '),
    };
    
    const fCount = Object.keys(displayFilters).reduce((r, d) => r + displayFilters[d].length, 0);
    console.log(fCount, displayFilters);
    onConfirm(fCount ? displayFilters : undefined);
  };

  const getProceed = (proceed: string) => {
    const proc = PROCEED.filter((item) => item.value === proceed);
    if (!_.isEmpty(proc)) {
      return proc[0].text;
    } else {
      return 'No data';
    }
  };

  return (
    <FormModal
      title="씬 필터링"
      subTitle="조건에 맞는 씬을 검색합니다."
      size="sw"
      style={{ padding: `var(--f45)` }}
      onConfirm={applyFilter}>
      <div className={styles.search_lists}>
        {filterMenu && (
          <div className={styles.modal_list}>
            <SearchListModal
              size="medium"
              title="EP선택"
              items={filterMenu.episode}
              hideShadow={true}
              checkbox={true}
              setChecked={(item: SearchListValue) => selectedFilters.episode.has(item.text)}
              getCheckedItem={(isChecked, chItem) => checkItem('episode', isChecked, chItem)}
              disabled={true}
              style={filterMenuStyle}
            />
            <div style={filterMenuStyle}>
              <SearchListModal
                size="medium"
                title="S/L 선택"
                items={filterMenu.site}
                hideShadow={true}
                checkbox={true}
                setChecked={(item: SearchListValue) => {
                  return selectedFilters.site.has(item.text === '미정' ? 'NA' : item.text);
                }}
                getCheckedItem={(isChecked, chItem) =>
                  checkItem('site', isChecked, chItem.text === '미정' ? { text: 'NA' } : chItem)
                }
                disabled={true}
                style={{ width: '100%', maxHeight: '140px', marginBottom: 10 }}
              />
              <SearchListModal
                size="medium"
                title="D/N 선택"
                items={filterMenu.timeslot}
                hideShadow={true}
                checkbox={true}
                setChecked={(item: SearchListValue) => {
                  return selectedFilters.timeslot.has(item.text);
                }}
                getCheckedItem={(isChecked, chItem) => checkItem('timeslot', isChecked, chItem)}
                disabled={true}
                style={{ width: '100%', maxHeight: '140px' }}
              />
            </div>
            <div style={filterMenuStyle}>
              <SearchListModal
                size="medium"
                title="대장소 검색"
                items={filterMenu.bigPlayPlaces}
                hideShadow={true}
                checkbox={true}
                setChecked={(item: SearchListValue) => {
                  return selectedFilters.bigPlayPlaces.has(`${item.id}`);
                }}
                getCheckedItem={(isChecked, chItem) => checkItem('bigPlayPlaces', isChecked, chItem)}
                style={{ width: '100%', maxHeight: '140px', marginBottom: 10 }}
              />
              <SearchListModal
                size="medium"
                title="소장소 검색"
                items={filterMenu.playPlaces}
                hideShadow={true}
                checkbox={true}
                setChecked={(item: SearchListValue) => {
                  return selectedFilters.playPlaces.has(`${item.id}`);
                }}
                getCheckedItem={(isChecked, chItem) => checkItem('playPlaces', isChecked, chItem)}
                style={{ width: '100%', maxHeight: '140px' }}
              />
            </div>
            <SearchListModal
              size="medium"
              title="등장인물 검색"
              items={filterMenu.characters}
              hideShadow={true}
              checkbox={true}
              setChecked={(item: SearchListValue) => {
                return selectedFilters.characters.has(`${item.id!}`);
              }}
              getCheckedItem={(isChecked, chItem) => checkItem('characters', isChecked, chItem)}
              style={filterMenuStyle}
            />
            <SearchListModal
              size="medium"
              title="촬영상태 선택"
              items={filterMenu.proceed}
              hideShadow={true}
              checkbox={true}
              setChecked={(item: SearchListValue) => {
                return selectedFilters.proceed.has(proceedState[item.text as ProceedState]);
              }}
              getCheckedItem={(isChecked, chItem) =>
                checkItem('proceed', isChecked, { text: proceedState[chItem.text as ProceedState] })
              }
              disabled={true}
              style={filterMenuStyle}
            />
          </div>
        )}
      </div>
      <div className={styles.preview}>
        {!filteredScenes ? (
          <>필터링 결과 없음</>
        ) : (
          <StripsTable
            style={{ height: '150px' }}
            data={filteredScenes}
            columnInfo={[
              {
                title: 'EP',
                width: '3.5%',
                align: 'left',
                data: (t: SceneResource) => t.episode,
              },
              {
                title: '씬',
                width: '3.5%',
                align: 'left',
                data: (t: SceneResource) => t.sceneNum,
              },
              {
                title: 'D/N',
                width: '3.5%',
                align: 'left',
                data: (t: SceneResource) => t.timeslot,
              },
              {
                title: 'S/L',
                width: '3.5%',
                align: 'left',
                data: (t: SceneResource) => t.site,
              },
              {
                title: '대장소',
                width: '15%',
                align: 'left',
                data: (t: SceneResource) => (t.bigPlayPlaces?.length ? t.playPlaces.map((v) => v.name).join() : '-'),
              },
              {
                title: '소장소',
                width: '15%',
                align: 'left',
                data: (t: SceneResource) => (t.playPlaces?.length ? t.playPlaces.map((v) => v.name).join() : '-'),
              },
              {
                title: '등장인물',
                width: '15%',
                align: 'left',
                data: (t: SceneResource) => (t.characters ? t.characters.map((v) => v.name).join() : '-'),
              },
              {
                title: '내용',
                width: '15%',
                align: 'left',
                data: (t: SceneResource) => t.summarize,
              },
              // {
              //   title: '소품/의상/분장',
              //   width: '15%',
              //   align: 'left',
              //   data: (t: SceneResource) => {
              //     let propsCostumesMakeups = [];
              //     if (t.props) propsCostumesMakeups.push(t.props.map((v) => v.name).join());
              //     if (t.costumes) propsCostumesMakeups.push(t.costumes.map((v) => v.name).join());
              //     if (t.makeups) propsCostumesMakeups.push(t.makeups.map((v) => v.name).join());
              //     return propsCostumesMakeups.join();
              //   },
              // },
              // {
              //   title: '예상 소요 시간',
              //   width: '10%',
              //   align: 'left',
              //   data: (t: SceneResource) => toHHMM(t.shootingTime),
              // },
              {
                title: '촬영상태',
                width: '10%',
                align: 'left',
                data: (t: SceneResource) => (
                  <BadgeButtonCell badgeType={t.proceed} badgeText={getProceed(t.proceed)} onSelected={() => {}} />
                ),
              },
            ]}
          />
        )}
      </div>
    </FormModal>
  );
};

export default SceneFilterModal;
