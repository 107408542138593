import { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { Button, Form } from 'react-bootstrap';
import { Typography, FileButton } from 'components';
import { CompanyActorInfo, GenderType } from 'shared';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'modules';
import { closeModal } from 'modules/modal/modalReducer';
import { Modal } from 'components/modal';
import { validateEmail, validateNumber } from 'util/validation';
import { createActor } from 'modules/actor/actorService';
import classNames from 'classnames';
import FileService from 'modules/file/FileService';
import { fileUrl } from 'util/fileHelper';

export interface ActorFormProps {
  name: string;
  imgUrl: string;
  birthday: string;
  mobile: string;
  email: string;
  filmography: string;
  agency: string;
  nickname?: string;
  pay?: number;
  enable?: boolean;
  gender?: GenderType;
  debutTitle?: string;
  height?: number;
  weight?: number;
}

const defaultActorInfo = {
  name: '',
  imgUrl: '',
  birthday: '',
  mobile: '',
  email: '',
  filmography: '',
  agency: '',
  gender: GenderType.NA,
  enable: true,
};

export interface ActorRegModalProps {
  name?: string;
  onConfirm?: (actor: CompanyActorInfo) => void;
}

export const ActorRegModal = ({ name, onConfirm }: ActorRegModalProps) => {
  const dispatch = useAppDispatch();
  const { project } = useAppSelector((state: RootState) => state.project);
  const [formdata, setFormdata] = useState<ActorFormProps>(defaultActorInfo);
  const [filmography, setFilmography] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setFormdata({ ...formdata, name: name || '' });
  }, [name]);

  const handleChange = (key: string, value: string) => {
    if (['mobile', 'birthday'].includes(key) && value && !validateNumber(value)) return;
    setFormdata({ ...formdata, [key]: value });
    setErrors({ ...errors, [key]: '' });
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;

    new FileService().updateImage(files[0], undefined, false).then((value: string) => {
      handleChange('imgUrl', value);
    });
  };

  const handleChangeFilmography = (index: number, value: string) => {
    let filmographyList = [...filmography];
    filmographyList[index] = value;
    setFilmography(filmographyList);
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleComplete = async () => {
    if (!project?.data?.companyNo) return;

    let error = {};
    if (!formdata.name) error = { ...error, name: '이름을 입력해주세요.' };
    if (formdata.birthday && formdata.birthday.length !== 8) error = { ...error, birthday: '8글자를 입력해주세요.' };
    if (formdata.mobile && formdata.mobile.length !== 11) error = { ...error, mobile: '11글자를 입력해주세요.' };
    if (formdata.email && !validateEmail(formdata.email)) error = { ...error, email: '이메일을 다시 입력해주세요.' };

    if (Object.keys(error).length > 0) {
      setErrors(error);
      return;
    }

    const result = await createActor(project?.data?.companyNo, {
      ...formdata,
      birthday: `${formdata.birthday.slice(0, 4)}-${formdata.birthday.slice(4, 6)}-${formdata.birthday.slice(6, 8)}`,
      filmography: filmography.join(','),
    });
    if (result) {
      onConfirm?.(result);
      handleClose();
    }
  };

  return (
    <Modal.Container size="xl" style={{ padding: 'var(--f45)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          배우 추가하기
        </Typography>
        <Typography className="text-blue-gray-300">배우 DB에 신규 배우를 추가합니다.</Typography>
      </div>
      <div className="text-left">
        <div className="items-center flex-column py-8">
          <FileButton
            className={classNames('p-0 bg-white', errors.imgUrl ? 'border-danger' : 'border-0')}
            onFileChange={handleChangeImage}>
            <Avatar
              src={formdata.imgUrl ? fileUrl(formdata.imgUrl) : ''}
              variant="rounded"
              sx={{ width: 224, height: 120 }}
            />
          </FileButton>
          <a
            role="button"
            className="underline py-0 mt-2 text-blue-gray-300"
            onClick={() => handleChange('imgUrl', '')}>
            삭제하기
          </a>
        </div>
        <div className="d-flex gap-x-16">
          <div className="flex-1">
            <Typography variant="f12-166--48" className="text-blue-gray-300">
              이름
            </Typography>
            <Form.Control
              className="input-gray px-3 py-2.5 mt-1 mb-4"
              placeholder="이름을 입력해주세요."
              isInvalid={!!errors.name}
              value={formdata?.name || ''}
              onChange={(e) => handleChange('name', e.target.value)}
            />
            <Typography variant="f12-166--48" className="text-blue-gray-300">
              생년월일
            </Typography>
            <Form.Control
              className="input-gray px-3 py-2.5 mt-1 mb-4"
              placeholder="생년월일 8글자를 입력해주세요."
              maxLength={8}
              isInvalid={!!errors.birthday}
              value={formdata?.birthday || ''}
              onChange={(e) => handleChange('birthday', e.target.value)}
            />
            <Typography variant="f12-166--48" className="text-blue-gray-300">
              전화번호
            </Typography>
            <Form.Control
              className="input-gray px-3 py-2.5 mt-1 mb-4"
              placeholder="전화번호 11자리를 입력해주세요."
              maxLength={11}
              isInvalid={!!errors.mobile}
              value={formdata?.mobile || ''}
              onChange={(e) => handleChange('mobile', e.target.value)}
            />
            <Typography variant="f12-166--48" className="text-blue-gray-300">
              이메일
            </Typography>
            <Form.Control
              className="input-gray px-3 py-2.5 mt-1 mb-4"
              placeholder="이메일 주소를 입력해주세요."
              isInvalid={!!errors.email}
              value={formdata?.email || ''}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </div>
          <div className="flex-1">
            <Typography variant="f12-166--48" className="text-blue-gray-300">
              필모그래피 (최대5개)
            </Typography>
            <div className="mb-4">
              {Array.from(Array(5)).map((_, index) => (
                <Form.Control
                  className="input-gray px-3 py-2.5 mt-1"
                  placeholder="예) 2010년 SBS드라마 ‘별에서 온 그대'"
                  value={filmography[index] || ''}
                  onChange={(e) => handleChangeFilmography(index, e.target.value)}
                />
              ))}
            </div>
            <Typography variant="f12-166--48" className="text-blue-gray-300">
              소속사
            </Typography>
            <Form.Control
              className="input-gray px-3 py-2.5 mt-1 mb-4"
              placeholder="배우 소속사를 입력해주세요."
              value={formdata?.agency || ''}
              onChange={(e) => handleChange('agency', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="items-center justify-content-end gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleComplete}>
          완료
        </Button>
      </div>
    </Modal.Container>
  );
};
