import { PermisionType } from 'shared';

export const parseQueryString = (query: string): { [key: string]: string } => {
  if (!query) return {};

  const queryString = query.split('?')[1];
  if (!queryString) return {};

  const params: { [key: string]: string } = queryString.split('&').reduce((reducer, param) => {
    const keyValue = param.split('=');
    if (!keyValue[0]) return reducer;

    return { ...reducer, [keyValue[0]]: keyValue[1] || '' };
  }, {});

  return params;
};

export const makeQueryString = (data?: { [key: string]: string | number }) => {
  if (!data) return '';

  const arr = Object.keys(data).map((key) => `${key}=${data[key]}`);
  return `?${arr.join('&')}`;
};

export const formatDateString = (dateString?: string | Date, format?: string) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  switch (format) {
    case '년월일':
      return `${year}년 ${month}월 ${day}일`;
    case 'YYYY-MM':
      return `${year}-${month}`;
    default:
      return `${year}-${month}-${day}`;
  }
};

export const permissionString = (permissions: PermisionType[]) => {
  if (!permissions?.length) return '';
  return permissions
    .map((p) => {
      let permisionStr = '';
      switch (p) {
        case 'ALL':
          permisionStr = '전체가능';
          break;
        case 'READ':
          permisionStr = '읽기가능';
          break;
        case 'WRITE':
          permisionStr = '편집가능';
          break;
        default:
          permisionStr = '접근 권한 없음';
          break;
      }

      return permisionStr;
    })
    .join(', ');
};

export const getPercentage = (use: number, quota: number) => {
  const result =  Math.round((use / quota) * 100);
  if (result > 100) return 100;
  return parseFloat(result.toFixed(1));
};
