import * as React from 'react';
import styles from './ScriptUploadModal.module.scss';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { AppContext } from 'shared/context/AppContext';
import { useEffect, useRef } from 'react';
import { Modal } from 'components/modal';
import { Checkbox } from 'components';
import { ANAL_MODEL } from '../../constants';
import { Typography } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';

import script_upload_cancel from '../../../public/common/script_upload_cancel.svg';
import { closeModal, openModal } from 'modules/modal/modalReducer';
import { ModalTypes } from 'components/modal';
import { RootState } from 'modules';
import { LabelDropdown } from 'components/dropdown';
import { getUserResourceUsage } from '../../../modules/user/UserReducer';
import { useSelector } from 'react-redux';
import { AiResourceQuota } from '../../../modules/user/User';

export type ScriptUploadModalProps = {
  projectNo: number;
  onConfirm?: (scriptNo: number) => void;
};

export default function ScriptUploadModal({ projectNo, onConfirm }: ScriptUploadModalProps) {
  const dispatch = useAppDispatch();
  const appContext = React.useContext(AppContext);
  const { project } = useAppSelector((state: RootState) => state.project);
  const [file, setFile] = React.useState<File>();
  const [epiName, setEpiName] = React.useState('');
  const inputFile = useRef<any>(null);
  const [selectedModel, setSelectedModel] = React.useState<string>('1');
  const { resourceUsage } = useSelector((state: RootState) => state.user);

  const maxEP = Number(project?.data?.episode || 1);

  useEffect(() => {
    dispatch(getUserResourceUsage());
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setFile(e.target.files[0]);
  };

  const handleEpiNameChange = (value: string) => {
    setEpiName(value);
  };

  const handleUploadSuccess = async (scriptNo: number) => {
    if (!projectNo) return;
    // await tagAllScript(projectNo, scriptNo);
    onConfirm?.(scriptNo);
    dispatch(closeModal(ModalTypes.ScriptUpload));
  };

  const handleUploadAndAnal = async () => {
    if (!appContext.user || !projectNo) return;

    console.log(selectedModel);

    const aiResourceUsage = AiResourceQuota.fromQuotaUsage(resourceUsage.data);

    let errorMsg = '';
    // chatgpt 분석일 경우 토큰이 부족하면 에러 메시지 출력
    if (aiResourceUsage.percent >= 100 && selectedModel === '2') errorMsg = '토큰이 부족 합니다.';
    if (!epiName) errorMsg = 'EP를 선택해주세요.';
    if (!file) errorMsg = '파일을 선택해주세요';

    if (errorMsg) {
      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: { text: errorMsg, confirmBtnText: '확인', autoClose: true },
        })
      );
      return;
    }

    if (!file) return;

    dispatch(
      openModal({
        type: ModalTypes.ScriptProcessing,
        props: {
          projectNo,
          episode: epiName,
          file: file,
          duration: 100,
          model: selectedModel,
          onComplete: handleUploadSuccess,
        },
        overlayOptions: {
          disableOverlayClick: true,
        },
      })
    );
  };

  return (
    <Modal.Container size="wd" style={{ borderRadius: `var(--f4)`, paddingTop: `var(--f20)`, width: `var(--f196)` }}>
      <InputGroup className="mb-2">
        <FormControl
          className={styles.input}
          style={{
            background: `var(--gray-10)`,
            borderRight: 'none',
          }}
          disabled={true}
          value={file ? file.name : ''}
        />
        <Button
          style={{
            background: `var(--gray-10)`,
            border: `1px solid var(--gray-30)`,
            borderLeft: 'none',
            height: `var(--f32)`,
          }}
          onClick={() => setFile(undefined)}>
          <div className={styles.btn_upload_cancel}>
            <img src={script_upload_cancel} alt="script_upload_cancel" />
          </div>
        </Button>
      </InputGroup>
      <Button
        className={styles.button}
        style={{
          background: `var(--gray-90)`,
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          if (inputFile.current != null) {
            inputFile.current.click();
          }
        }}>
        <Typography variant="f12-166--48">파일을 선택 하세요</Typography>
        <input hidden ref={inputFile} type="file" accept=".hwp, .pdf" onChange={handleFileChange} />
      </Button>

      <div className="text-left">
        <Modal.Label text="EP 선택" style={{ fontSize: `var(--f10)`, color: ` var(--gray-50)` }} />
        <LabelDropdown
          labelPos="top"
          inputStyle={{ fontSize: 12 }}
          items={Array.from(Array(maxEP)).map((_, index) => ({ id: index + 1, text: String(index + 1) }))}
          content="EP를 선택해주세요"
          onSelected={(val) => handleEpiNameChange(String(val?.id || ''))}
        />
      </div>

      <div className="text-right pt-2 px-2">
        <Typography variant="f12-166--48" className="text-primary">
          버전명: 1.1.0
        </Typography>
      </div>

      <div className="text-left mt-2">
        <Modal.Label text="분석 모델 선택" style={{ fontSize: `var(--f10)`, color: ` var(--gray-50)` }} />
        {ANAL_MODEL.map((item, index) => (
          <div key={index} className="items-center justify-between mt-3">
            <Typography className="text-blue-gray-300">{item.text}</Typography>
            <Checkbox
              id={`checkbox-${item.text}-${index}`}
              checked={selectedModel === item.value}
              onChange={() => setSelectedModel(item.value)}
            />
          </div>
        ))}
      </div>

      <div className={styles.control_btn_group}>
        <Modal.Button
          text="취소"
          style={{ background: 'none', color: `var(--gray-50)`, padding: `var(--f10)`, fontWeight: 500 }}
        />
        <Button variant="none" className="bg-white fs-2.5 p-2.5 border-0" onClick={handleUploadAndAnal}>
          확인
        </Button>
      </div>
    </Modal.Container>
  );
}
