import { createRef } from 'react';
import style from './Calendar.module.scss';
import { format } from 'date-fns/esm';

import FullCalendar, { CustomContentGenerator, EventApi, EventContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarHeader } from './CalendarHeader';
import { PlusSquareButton } from 'components/button';

import './fullcalendar.css';

type EventType = {
  title: string;
  start: string;
  end?: string;
  color?: string;
  extendedProps: any;
};

type CalendarProps = {
  eventList: EventType[];
  onClick?: (e: EventApi) => void;
  onRightClick?: (e: EventApi) => void;
  onMouseOver?: (e: EventApi) => void;
  onMouseLeave?: () => void;
  clickPlusBtn?: (date: Date) => void;
  eventContent?: (arg: EventContentArg) => CustomContentGenerator<EventContentArg>;
  onChangeDate?: (date?: Date) => void;
};
export const Calendar = ({
  eventList,
  onClick,
  onRightClick,
  onMouseOver,
  onMouseLeave,
  clickPlusBtn,
  eventContent,
  onChangeDate,
}: CalendarProps) => {
  const calendarRef = createRef<FullCalendar>();

  return (
    <div className={style.calendar} >
      <CalendarHeader calendarRef={calendarRef} onChange={onChangeDate}/>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        headerToolbar={false}
        locale="ko"
        firstDay={1}
        editable={true}
        height={650}

        contentHeight={600}
        dayCellContent={function (arg) {
          return (
            <div className={style.column}>
              <div className={style.column_top}>
                {clickPlusBtn && (
                  <div className="btn_plus">
                    <PlusSquareButton onClick={()  => clickPlusBtn(arg.date)} />
                  </div>
                )}
                <span className={style.day_number}>{format(arg.date, 'd')}</span>
              </div>
            </div>
          );
        }}
        events={eventList}
        eventClick={onClick ? (arg) => {
          arg.jsEvent.preventDefault();
          onClick(arg.event);
        } : undefined}
        eventMouseEnter={onMouseOver ? (arg) => {
          arg.jsEvent.preventDefault();
          arg.jsEvent.stopPropagation();
          onMouseOver(arg.event);
        } : undefined}
        eventMouseLeave={onMouseLeave}
        eventDidMount={(arg) => {
          if (onRightClick) {
            arg.el.addEventListener('contextmenu', (jsEvent) => {
              jsEvent.preventDefault();
              onRightClick(arg.event);
              return;
            });
          }
        }}
        eventContent={eventContent}
      />
    </div>
  );
};


        
