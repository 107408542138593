export const serverHost = process.env.REACT_APP_API_HOST;

export const fileName = (url?: string) => {
  if (!url) return '';

  return url.split('/').splice(-1)[0];
};

export const fileUrl = (url?: string, skipPrefix = false) => {
  if (!url) return '';

  if (url.slice(0, 5) === 'http:' || url.slice(0, 6) === 'https:') return url;

  let filePath = url;
  if (filePath.slice(0, 1) === '/') filePath = filePath.slice(1);
  if (filePath.slice(0, 13) !== 'upload/image/' && !skipPrefix) filePath = 'upload/image/' + filePath;

  return `${serverHost}/${filePath}`;
};

export const fileListToArray = (files?: FileList) => {
  if (!files?.length) return [];

  let arr = [];
  for (let i = 0; i < files.length; i++) {
    arr.push(files[i]);
  }

  return arr;
};
