import { AvatarGroup } from 'components/AvatarGroup/AvatarGroup';
import { ModalTypes } from 'components/modal';
import { useAppDispatch } from 'hooks';
import { openModal } from 'modules/modal/modalReducer';
import { AccessModifyModalProp } from '../modal/AccessModifyModal';


export const AccessButton = ({ projectNo, enable = false }: AccessModifyModalProp) => {
  const dispatch = useAppDispatch();

  const handleOpenAccessModal = () => {
    dispatch(openModal({ type: ModalTypes.AccessModify, props: { projectNo: projectNo } }));
  };
  return (
    enable ? <AvatarGroup
      avatars={[
        '/mock-images/avatars/user-4.jpg',
        '/mock-images/avatars/user-2.jpg',
        '/mock-images/avatars/user-3.jpg',
        '/mock-images/avatars/user-1.jpg',
      ]}
      size="sm"
      onClick={handleOpenAccessModal}
    /> : <></>
  );
};
