import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import SvAPI from 'util/SvAPI';
import { QuotaUsage, UserProfile } from './User';

export type UserState = {
  profile: AsyncState<UserProfile, Error>;
  resourceUsage: AsyncState<QuotaUsage[], Error>
};

const initialState: UserState = {
  profile: asyncState.initial(),
  resourceUsage: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<UserState, 'user'>(initialState);

export const getUserProfile = reducerGenerator.createThunkAction<
'getUserProfile',
{},
UserProfile
>({
  action: 'user/getUserProfile',
  key: 'profile',
  thunk: () => SvAPI.get(SvAPI.profileUrl(), {}).then((r) => r.data),
});

export const logoutUser = reducerGenerator.createThunkAction<
'logoutUser',
{},
any
>({
  action: 'user/logoutUser',
  thunk: () => SvAPI.get(SvAPI.logoutUrl(), {}).then((r) => r.data),
});

export const getUserResourceUsage = reducerGenerator.createThunkAction<
'getUserResourceUsage',
{},
any>({
  action: 'user/getUserResourceUsage',
  key: 'resourceUsage',
  thunk: () => SvAPI.get(SvAPI.userResourceUsage(), {}).then((r) => r.data),
});

const userReducer = reducerGenerator.createReducer();
export default userReducer;
