import * as React from 'react';
import styles from './Badge.module.scss';

type Props = {
  type?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

export const Badge: React.FC<Props> = ({ style, children, type }: Props) => {
  const getBackgroundColor = (inputType: string) => {
    switch (inputType) {
      case 'BEFILMED':
        return '#FDE9C1';
      case 'NOTFILMED':
        return '#E8EAED';
      case 'REFILMED':
        return '#F5E2E5';
      case 'COMPLETE':
        return '#E6F7F6';
      case 'OMIT':
        return '#93969B';
      default:
        return '#000000';
    }
  };

  const getTextColor = (inputType: string) => {
    switch (inputType) {
      case 'BEFILMED':
        return '#CE9837';
      case 'NOTFILMED':
        return '#93969B';
      case 'REFILMED':
        return '#E6615D';
      case 'COMPLETE':
        return '#3AC8B4';
      case 'OMIT':
        return '#FFFFFF';
      default:
        return '#FFFFFF';
    }
  };

  return (
    <div
      className={styles.badge}
      style={{
        ...style,
        backgroundColor: getBackgroundColor(type ? type : ''),
        color: getTextColor(type ? type : ''),
      }}>
      {children}
    </div>
  );
};
