import { AxiosError } from 'axios';
import { createAsyncAction, ActionType } from 'typesafe-actions';
import ErrorResponse from '../common/ErrorResponse';
import Company from '../company/Company.types';
import Project from '../project/Project';

export const INDEX_INIT_ACTION = 'index/init-req';
export const INDEX_SUCCESS_ACTION = 'index/init-success';
export const INDEX_FAIL_ACTION = 'index/init-fail';

export interface IndexData {
  company: Company[];
  project: Project[];
}

export const indexInitAction = createAsyncAction(
  INDEX_INIT_ACTION,
  INDEX_SUCCESS_ACTION,
  INDEX_FAIL_ACTION
)<void, IndexData, AxiosError<ErrorResponse>>();

export const actions = { index : indexInitAction };
export type IndexInitAction = ActionType<typeof actions>;
