import * as React from 'react';
import styles from './Modal.module.scss';
import { useAppDispatch } from 'hooks';
import { closeModal, resetModalState } from 'modules/modal/modalReducer';
import { ModalContainerProps, ModalButtonGroupProps, ModalButtonProps, ModalInputProps, ModalTextAreaProps, ModalTitleProps, ModalSubTitleProps, ModalLabelProps, ModalDropdownInputProps } from './type';
import close from 'public/common/close.svg';
import { Controller, FieldValues } from 'react-hook-form';
import { DropDown, DropdownList } from 'components/dropdown';
import caret_left from 'public/common/caret_left.svg';


function ModalContainer({ className, size, children, style }:ModalContainerProps) {

  return (
      <div className={`${styles.container} ${styles[size]} ${className && styles[className]}`} style={style}>
        {Array.isArray(children) ? (
        <>
          {children.map((child, idx) => {
            if (!child) return;
            return <div key={idx}>{React.cloneElement(child)}</div>;
          }
          )}
        </>
        ) : (
        <>{React.cloneElement(children as React.ReactElement)}</>
        )}
      </div>
  );
}

function ModalTitle({ title, size, isCloseButton, iconButtonConfig }:ModalTitleProps) {
  const dispatch = useAppDispatch();
  const fontSize = size === 'small' ? styles.title_small : size === 'medium' ? styles.title_medium : '';
  return (
    <div className={styles.modal_title_container}>
      <span className={`${styles.modal_title} ${fontSize}`}>{title}</span>
      {isCloseButton && <button className={styles.modal_title_button} onClick={()=>{dispatch(closeModal());}}><img src={close} /></button>}
      {iconButtonConfig && <button className={styles.modal_title_button} onClick={iconButtonConfig.onClick}><img src={iconButtonConfig.src} /></button>}
    </div>
  )
  ;
}
function ModalSubTitle({ title, size }:ModalSubTitleProps) {
  const fontSize = size === 'small' ? styles.title_small : size === 'medium' ? styles.title_medium : '';
  return (
    <div className={styles.modal_sub_title_container}>
      <span className={`${styles.modal_sub_title} ${fontSize}`}>{title}</span>
    </div>
  )
  ;
}

function ModalButton({ text, type = 'button', buttonType = 'confirm', style, onClick, keepOpen = false, closeAll }: ModalButtonProps) {
  const dispatch = useAppDispatch();

  const clickModalBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (onClick) onClick();
    if (!keepOpen) {
      if (closeAll) {dispatch(resetModalState());} else {dispatch(closeModal());}
    }
  };
  return (
    <button
      className={buttonType === 'cancel' ? styles.cancel_button : styles.confirm_button}
      type={type}
      style={style}
      onClick={clickModalBtn}>
      {text}
    </button>
  );
}

function ModalButtonGroup({
  cancelBtnText = '취소',
  confirmBtnText = '확인',
  buttonGroupPosition,
  onCancel,
  onConfirm,
  notCloseWhenConfirm,
}: ModalButtonGroupProps) {
  const buttonGroupStyles = buttonGroupPosition ? (buttonGroupPosition === 'right' ? styles.btns_right : '') : '';

  return (
    <div className={`${styles.button_group} ${buttonGroupStyles}`}>
      {onCancel && 
        <ModalButton text={cancelBtnText} buttonType="cancel" onClick={onCancel} />
      }
      {onConfirm && 
        <ModalButton
          text={confirmBtnText}
          buttonType="confirm"
          onClick={onConfirm}
          keepOpen={notCloseWhenConfirm}
        />
      }
    </div>
  );
}

function ModalLabel({ text, style }: ModalLabelProps) {
  return <label className={styles.modal_label} style={style}>{text}</label>;
}

function ModalInput({ refs, value, type, inputStyle, placeholder, disabled, formValidation, onChange, getValue }: ModalInputProps) {
  const [text, setText] = React.useState('');

  const register = { ...formValidation ?
    formValidation.register(formValidation.registeredKey ? formValidation.registeredKey
      : '', formValidation.validationObject) : {} };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (register.onChange) register.onChange(e);
    if (getValue) getValue(e.currentTarget.value);
    if (onChange) onChange(e);

    setText(e.currentTarget.value);
  };

  const printError = () => {
    if (formValidation && formValidation.fieldErros && formValidation.messages) {
      for (const msg of formValidation.messages) {
        if (formValidation.fieldErros?.type === msg.type) {
          return <span className={styles.error_message}>{msg.message}</span>;
        }
      }
    }
  };

  return (
    <>
      <input
        className={styles.modal_input}
        disabled={disabled ? true : false}
        ref={refs}
        value={value ? value : text}
        type={type ? type : 'text'}
        style={inputStyle}
        placeholder={placeholder}
        {...{ ...register, onChange:onChangeInput }}
      />
      <div className={styles.error_container}>
        {printError()}
      </div>
    </>
  );
}

function ModalDropdownInput<T extends FieldValues>({ controllerConfig, dropdownItems, showArrow, placeholder, formValidation, initialValue, onClickListItem }: ModalDropdownInputProps<T>) {

  const [value, setValue] = React.useState<string | undefined>();
  const printError = () => {
    if (formValidation && formValidation.fieldErros && formValidation.messages) {
      for (const msg of formValidation.messages) {
        if (formValidation.fieldErros?.type === msg.type) {
          return <span className={styles.error_message}>{msg.message}</span>;
        }
      }
    }
  };
  
  return (
    <>
      { controllerConfig ?
          <>
            <Controller
              control={controllerConfig.control}
              name={controllerConfig.name}
              defaultValue={initialValue}
              rules={{ required: true }}
              render={({ field }) => (
                <DropDown element={<DropdownList style={{ width:'100px' }} items={dropdownItems} onClickItem={(item) => {field.onChange(item!.text); setValue(item!.text); if (onClickListItem) onClickListItem(item);}} />}>
                  <div className={styles.dropdown_input_container}>
                    <div><input className={styles.dropdown_input} placeholder={placeholder} value={field.value} readOnly /></div>
                    {showArrow && <img className={styles.dropdown_arrow} src={caret_left} alt="arrow_down" />}
                  </div>
                </DropDown>
              )}
            />
            <div className={styles.error_container}>
              {printError()}
            </div>
          </> : (
            <DropDown element={<DropdownList style={{ width:'100px' }} items={dropdownItems} onClickItem={(item) => {setValue(item!.text); if (onClickListItem) onClickListItem(item);}} />}>
              <div className={styles.dropdown_input_container}>
                <div><input disabled={true} className={styles.dropdown_input} placeholder={placeholder} value={value} readOnly /></div>
                {showArrow && <img className={styles.dropdown_arrow} src={caret_left} alt="arrow_down" />}
              </div>
            </DropDown>
          )
      }
    </>
  );
}


function ModalTextArea<T extends FieldValues>({
  value,
  inputStyle,
  placeholder,
  refs,
  getValue,
  name,
  formValidation,
  controllerConfig,
}: ModalTextAreaProps<T>) {

  const printError = () => {
    if (formValidation && formValidation.fieldErros && formValidation.messages) {
      for (const msg of formValidation.messages) {
        if (formValidation.fieldErros?.type === msg.type) {
          return <span className={styles.error_message}>{msg.message}</span>;
        }
      }
    }
  };


  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (getValue) getValue(e.currentTarget.value);
  };

  return (
    <>
    {controllerConfig ?
      <>
        <Controller
          control={controllerConfig.control}
          name={controllerConfig.name}
          rules={{ required: true }}
          render={({ field }) => {
            return ( 
              <textarea
                className={`${styles.modal_input} ${styles.modal_textarea}`}
                style={inputStyle}
                placeholder={placeholder}
                {...{ ...field }}
                />
            );
          }}
         />
        
        <div className={styles.error_container}>
          {printError()}
        </div>
      </>
      : <textarea
          name={name}
          className={`${styles.modal_input} ${styles.modal_textarea}`}
          ref={refs}
          value={value}
          style={inputStyle}
          placeholder={placeholder}
          onChange={onChange}
        />
    }
      
    </>
  );
}

function ModalInputError({ error }:{ error:string }) {

  return (
    <div className={styles.error_container}>
      <span className={styles.error_message}>{error}</span>
    </div>
  );
}


export const Modal = {
  Container:ModalContainer,
  Title: ModalTitle,
  SubTitle:ModalSubTitle,
  Button: ModalButton,
  ButtonGroup: ModalButtonGroup,
  TextArea: ModalTextArea,
  Input: ModalInput,
  Label:ModalLabel,
  InputError:ModalInputError,
  DropdownInput:ModalDropdownInput,
};
