import { ConfirmModalProps } from 'components/modal';
import { SvgIconSource } from 'components';

export const CONFIRM_MESSAGES: { [key: string]: ConfirmModalProps } = {
  PERMISSION_UPDATED: {
    text: '접근 권한을 수정했습니다.',
    confirmBtnText: '확인',
    type: 'permit_updated',
  },
  CASTING_REG_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '후보 배우 등록을 완료했습니다.',
    type: 'casting_reg_success',
    autoClose: true,
  },
  CHARACTER_DELETE_SUCCESS: {
    text: '인물을 삭제했습니다.',
    confirmBtnText: '확인',
    type: 'delete_success',
  },
  CASTING_EXCEL: {
    text: '캐스팅 후보리스트를 엑셀 파일로 저장합니다.',
    confirmBtnText: '파일저장',
    cancelBtnText: '취소',
    type: 'casting_excel',
  },
  PLACE_REG_SUCCESS: {
    text: '장소 등록을 완료했습니다.',
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    confirmBtnText: '확인',
    type: 'place_reg_success',
  },
  PLACE_DELETE_SUCCESS: {
    text: '장소를 삭제했습니다.',
    confirmBtnText: '확인',
    type: 'delete_success',
  },
  PROJECT_DELETE_SUCCESS: {
    text: '프로젝트를 삭제했습니다.',
    confirmBtnText: '확인',
    type: 'delete_success',
  },
  LOCATION_ADD_SUCCESS: {
    text: '후보 촬영지 등록을 완료했습니다.',
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    confirmBtnText: '확인',
    type: 'location_add_success',
  },
  LOCATION_DELTE_SUCCESS: {
    text: '촬영지를 삭제했습니다.',
    confirmBtnText: '확인',
    type: 'delete_success',
  },
  CONFIRM_DELETE: {
    text: '정말 삭제하시겠습니까?',
    confirmBtnText: '삭제',
    cancelBtnText: '취소',
    type: 'confirm_delete',
  },
  STAFF_DELETE_SUCCESS: {
    text: '스태프를 목록에서 삭제했습니다.',
    confirmBtnText: '확인',
    type: 'delete_success',
  },
  STAFF_INVITE_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '스태프 초대가 완료되었습니다.',
    type: 'invite_success',
    autoClose: true,
  },
  FILE_DELETE_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '파일을 삭제했습니다.',
    type: 'file_delete_success',
  },
  FOLDER_ADDED: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '폴더가 추가되었습니다.',
    type: 'folder_added',
  },
  FOLDER_DELETE_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '폴더를 삭제했습니다.',
    type: 'folder_delete_success',
  },
  SCHEDULE_DELETE_SUCCESS: {
    text: '일정을 삭제했습니다.',
    confirmBtnText: '확인',
    autoClose: true,
    type: 'schedule_delete_success',
  },
  INVITE_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '초대 메일 발송을 완료했습니다.',
    type: 'invite_success',
  },
  INVITE_LINK_COPIED: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '초대링크가 복사되었습니다.',
    type: 'invite_copy',
  },
  COMPANY_CREATE_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '조직이 생성되었습니다.',
    type: 'company_created',
    autoClose: true,
  },
  SCENE_UPDATE_SUCCESS: {
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    text: '씬 정보를 저장했습니다.',
    type: 'scene_updated',
  },
  COMPANY_UPDATE_SUCCESS: {
    text: '내 조직 정보 수정이 완료되었습니다.',
    icon: {
      source: SvgIconSource.Check,
      size: 48,
      color: 'white',
    },
    type: 'company_modified',
    autoClose: true,
  },
};
