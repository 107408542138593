import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import style from './ErrorPage.module.scss';
import icon from 'public/common/error_icon.svg';

export const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div id={style.error_page}>
      <img className={style.image} alt="error_icon" src={icon} />
      <div className={style.title}>오류가 발생했습니다.</div>
      <div className={style.main}>요청하신 페이지를 찾을 수 없습니다.</div>
      <Button className={style.button} onClick={() => navigate(`/`)}>
        홈으로 돌아가기
      </Button>
    </div>
  );
};
