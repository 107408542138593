/* eslint-disable @typescript-eslint/indent */
import SvAPI from 'util/SvAPI';
import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import { PayloadAction } from 'typesafe-actions';
import Scene from 'pages/scene/Scene';
import {
  CharactersResponse,
  EpisodeResponse,
  PlacesResponse,
  ScheduleFilterSetType,
} from 'pages/schedule/type';
import { getFilteredScenes } from 'util/scheduleHelper';

export type UnassignedScenesState = {
  scenes: AsyncState<Scene[], Error>;
  filteredScenes: Scene[] | null;
  episode: AsyncState<string[], Error>;
  playPlaces: AsyncState<string[], Error>;
  bigPlayPlaces: AsyncState<string[], Error>;
  characters: AsyncState<string[], Error>;
  site: string[];
  timeslot: string[];
  proceed: string[];
  filters: ScheduleFilterSetType;
  unmatchedScenes: Scene[] | null;
};

const initialState: UnassignedScenesState = {
  scenes: asyncState.initial(),
  filteredScenes: null,
  unmatchedScenes: null,
  episode: asyncState.initial(),
  playPlaces: asyncState.initial(),
  bigPlayPlaces: asyncState.initial(),
  characters: asyncState.initial(),
  site: ['S', 'L', 'NA'],
  timeslot: ['D', 'N'],
  proceed: ['BEFILMED', 'NOTFILMED', 'REFILMED', 'COMPLETE', 'OMIT'],
  filters: {
    episode: new Set(),
    playPlaces: new Set(),
    bigPlayPlaces: new Set(),
    characters: new Set(),
    site: new Set(),
    timeslot: new Set(),
    proceed: new Set(),
  },
};

const reducerGenerator = new ReducerGenerator<UnassignedScenesState, 'schedule'>(initialState);

const getScenes = reducerGenerator.createThunkAction<
  'getScenes',
  { projectNo: number; groupNo: number; isSort: boolean },
  Scene[]
>({
  action: 'schedule/getScenes',
  key: 'scenes',
  thunk: (params: any) =>
    SvAPI.get(SvAPI.unassingedScenes(params!.projectNo, params!.groupNo, params!.isSort)).then(
      (res) => res.data as Scene[]
    ),
  extraReducers: {
    success: (state, action) => {
      const filteredScenes = getFilteredScenes(action.payload || [], state.filters);
      return {
        ...state,
        scenes: { ...state.scenes, loading: false, data: action.payload },
        filteredScenes: filteredScenes.matched,
        unmatchedScenes: filteredScenes.unmatched,
      };
    },
  },
});

const setSceneFilters = reducerGenerator.createAction<
  'setScenes',
  { scenes?: Scene[]; filters?: ScheduleFilterSetType }
>({
  action: 'schedule/setScenes',
  reducer: (state, action) => {
    const filters = action.payload.filters || state.filters;
    const filteredScenes = getFilteredScenes(state.scenes.data || [], filters);
    return {
      ...state,
      filters,
      filteredScenes: filteredScenes.matched,
      unmatchedScenes: filteredScenes.unmatched,
    };
  },
});

const getEpisodes = reducerGenerator.createThunkAction<'getEpisodes', { projectNo: number }, EpisodeResponse[]>({
  action: 'schedule/getEpisodes',
  thunk: (params) => SvAPI.get(SvAPI.scriptsUrl(params!.projectNo)).then((res) => res.data),
  extraReducers: {
    success: (state, action) => {
      let epi: string[] = action.payload.map((item: any) => item.episode);
      return {
        ...state,
        episode: { ...state.episode, loading: false, data: epi },
      };
    },
  },
});

const getPlaces = reducerGenerator.createThunkAction<'getPlaces', { projectNo: number }, PlacesResponse[]>({
  action: 'schedule/getPlaces',
  thunk: (params) =>
    SvAPI.get(SvAPI.projectPlacesUrl(params!.projectNo), { noPage: true }).then((res) => res.data.body),
  extraReducers: {
    success: (state, action: PayloadAction<string, any>) => {
      let places: string[] = action.payload.map((item: any) => item.name);
      let bigPlaces: string[] = action.payload.map((item: any) => item.bigName || '').filter((bn: string) => !!bn);
      return {
        ...state,
        playPlaces: { ...state.playPlaces, loading: false, data: places },
        bigPlayPlaces: { ...state.bigPlayPlaces, loading: false, data: bigPlaces },
      };
    },
  },
});

const getCharacters = reducerGenerator.createThunkAction<'getCharacters', { projectNo: number }, CharactersResponse[]>({
  action: 'schedule/getCharacters',
  thunk: (params) => SvAPI.get(SvAPI.charactersUrl(params!.projectNo) + '?noPage=true').then((res) => res.data.body),
  extraReducers: {
    success: (state, action) => {
      let characters: string[] = action.payload.map((item: any) => item.name);
      return {
        ...state,
        characters: { ...state.characters, loading: false, data: characters },
      };
    },
  },
});

const resetFilters = reducerGenerator.createEmptyAction<'resetFilters'>({
  action: 'schedule/resetFilters',
  reducer: (state) => {
    return {
      ...state,
      filters: {
        episode: new Set(),
        playPlaces: new Set(),
        bigPlayPlaces: new Set(),
        characters: new Set(),
        site: new Set(),
        timeslot: new Set(),
        proceed: new Set(),
      },
      filteredScenes: state.scenes.data,
    };
  },
});

const unassignedScenesReducer = reducerGenerator.createReducer();

export { getScenes, setSceneFilters, getEpisodes, getPlaces, getCharacters, resetFilters };

export default unassignedScenesReducer;
