import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { Item } from 'pages/item/Item';

export const GET_PROPS = 'item/GET_PROPS';
export const GET_PROPS_SUCCESS = 'item/GET_PROPS_SUCCESS';
export const GET_PROPS_ERROR = 'item/GET_PROPS_ERROR';

export const GET_COSTUMES = 'item/GET_COSTUMES';
export const GET_COSTUMES_SUCCESS = 'item/GET_COSTUMES_SUCCESS';
export const GET_COSTUMES_ERROR = 'item/GET_COSTUMES_ERROR';

export const GET_EXTRAS = 'item/GET_EXTRAS';
export const GET_EXTRAS_SUCCESS = 'item/GET_EXTRAS_SUCCESS';
export const GET_EXTRAS_ERROR = 'item/GET_EXTRAS_ERROR';

export const GET_MAKEUPS = 'item/GET_MAKEUPS';
export const GET_MAKEUPS_SUCCESS = 'item/GET_MAKEUPS_SUCCESS';
export const GET_MAKEUPS_ERROR = 'item/GET_MAKEUPS_ERROR';

export const GET_ETCS = 'item/GET_ETCS';
export const GET_ETCS_SUCCESS = 'item/GET_ETCS_SUCCESS';
export const GET_ETCS_ERROR = 'item/GET_ETCS_ERROR';

export const GET_ITEMS = 'item/GET_ITEMS';
export const GET_ITEMS_SUCCESS = 'item/GET_ITEMS_SUCCESS';
export const GET_ITEMS_ERROR = 'item/GET_ITEMS_ERROR';

export const GET_SFXS = 'item/GET_SFXS';
export const GET_SFXS_SUCCESS = 'item/GET_SFXS_SUCCESS';
export const GET_SFXS_ERROR = 'item/GET_SFXS_ERROR';

export const getPropsAsync = createAsyncAction(
  GET_PROPS,
  GET_PROPS_SUCCESS,
  GET_PROPS_ERROR,
)<any, Item[], AxiosError>();

export const getCostumesAsync = createAsyncAction(
  GET_COSTUMES,
  GET_COSTUMES_SUCCESS,
  GET_COSTUMES_ERROR,
)<any, Item[], AxiosError>();

export const getExtrasAsync = createAsyncAction(
  GET_EXTRAS,
  GET_EXTRAS_SUCCESS,
  GET_EXTRAS_ERROR,
)<any, Item[], AxiosError>();

export const getMakeupsAsync = createAsyncAction(
  GET_MAKEUPS,
  GET_MAKEUPS_SUCCESS,
  GET_MAKEUPS_ERROR,
)<any, Item[], AxiosError>();

export const getEtcsAsync = createAsyncAction(
  GET_ETCS,
  GET_ETCS_SUCCESS,
  GET_ETCS_ERROR,
)<any, Item[], AxiosError>();

export const getItemsAsync = createAsyncAction(
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERROR,
)<any, Item[], AxiosError>();

export const getSfxsAsync = createAsyncAction(
  GET_SFXS,
  GET_SFXS_SUCCESS,
  GET_SFXS_ERROR,
)<any, Item[], AxiosError>();

const actions = { getPropsAsync, getCostumesAsync, getExtrasAsync, getMakeupsAsync, getEtcsAsync, getItemsAsync, getSfxsAsync };
export type ItemAsyncAction = ActionType<typeof actions>;
