import React from 'react';
import styles from './CustomDropdownToggle.module.scss';

export const CustomDropdownToggle = React.forwardRef<HTMLButtonElement>((props: any, ref) => (
  <button
    ref={ref}
    className={styles.toggle}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      
      props?.onClick?.(e);
    }}>
    {props?.children}
  </button>
));
