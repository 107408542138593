import axios from 'axios';
import { FinchResourceType } from 'shared';

export const serverHost = process.env.REACT_APP_API_HOST;
export const serverUrl = `${serverHost}/strips/v1.0`;
export const serverUrlV1 = `${serverHost}/strips/v1.1`;

export default class SvAPI {
  // --------  이미지 API
  static resizeImageUrl(type: FinchResourceType, resourceNo: number, imageNo: number) {
    return `${serverUrl}/${type}/${resourceNo}/image/${imageNo}/size`;
  }

  // --------- 회사관련
  static companyBaseUrl() {
    return `${serverUrl}/company`;
  }

  static companyUserUrl(companyNo: number) {
    return `${serverUrl}/company/${companyNo}/user`;
  }

  static companiesUrl() {
    return `${serverUrl}/companies`;
  }

  static companyInviteUrl(companyNo: number) {
    return `${serverUrl}/company/${companyNo}/staff`;
  }

  static companyInviteConfirmUrl(inviteCode: string) {
    return `${serverUrl}/company/staff/${inviteCode}`;
  }

  // -------- 프로젝트 관련
  static projectBaseUrl() {
    return `${serverUrl}/project`;
  }

  static projectListUrl() {
    return `${serverUrl}/project`;
  }

  static projectUrl(projectNo: number) {
    return `${serverUrl}/project/${projectNo}`;
  }

  static projectCompanyUrl(projectNo: number) {
    return `${serverUrl}/project/${projectNo}/company`;
  }

  static projectGroupBaseUrl(projectNo: number) {
    return `${serverUrl}/project/${projectNo}/group`;
  }

  static projectGroupListUrl(projectNo: number) {
    return `${serverUrlV1}/project/${projectNo}/groups`;
  }

  static projectGroupUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/project/${projectNo}/group/${groupNo}`;
  }

  // 대시보드
  static projectTodayMemoUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedules/today/note`;
  }

  static projectDdayUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedules/dday`;
  }

  static projectSchedulesUrl(projectNo: number) {
    return `${serverUrlV1}/projects/${projectNo}/schedules`;
  }

  // -----------

  //  스트립트 (대본)
  static scriptAnalysisUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/analysis`;
  }

  static scriptAnalysisChatGPTUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/analysis-chat-gpt`;
  }

  static scriptProgressUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/progress`;
  }

  static scriptTaggingAllUrl(projectNo: number, scriptNo: number) {
    return `${serverUrl}/projects/${projectNo}/scripts/${scriptNo}/tagging-all`;
  }

  static scriptsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/scripts`;
  }

  static scriptUploadUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/scripts/upload`;
  }

  static sceneUrl(scriptNo: number, sceneNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/scenes/${sceneNo}`;
  }

  static sceneUrl2(sceneNo: number) {
    //씬번호로 해당 씬 조회
    return `${serverUrl}/scenes/${sceneNo}`;
  }

  static sceneDetailUrl(scriptNo: number, sceneNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/scenes/${sceneNo}/details`;
  }

  static scriptPlacesUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/places`;
  }

  static scriptCharactersUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/characters`;
  }

  static scriptResourceModifyUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/resource-modify`;
  }

  static scenesUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/scenes`;
  }

  static scenesUrl2(sceneNo: number, noPage: boolean) {
    //입력된 씬이 속한 스크립트 내에 모든 씬 조회
    return `${serverUrl}/scenes/${sceneNo}/scenes${noPage ? `?noPage=true` : ``}`;
  }

  static sceneCopyUrl(scriptNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/scenes/copy`;
  }

  static sceneDivideUrl(scriptNo: number, sceneNo: number) {
    return `${serverUrl}/scripts/${scriptNo}/scenes/${sceneNo}/divide`;
  }

  static sceneStatisticsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/scenes/statistics`;
  }

  static companyLocationUrl(companyNo: number) {
    return `${serverUrl}/companies/${companyNo}/locations`;
  }

  static locationEditUrl(locationNo: number) {
    return `${serverUrl}/locations/${locationNo}`;
  }

  static locationImageUrl(locationNo: number, imageNo?: number) {
    return imageNo
      ? `${serverUrl}/locations/${locationNo}/images/${imageNo}`
      : `${serverUrl}/locations/${locationNo}/images`;
  }

  // 스태프 APIs
  static staffsUrl(projectNo: number) {
    return `${serverUrl}/project/${projectNo}/staffs`;
  }

  static staffUrl(projectNo: number) {
    return `${serverUrl}/project/${projectNo}/staff`;
  }

  static staffSendUrl(projectNo: number) {
    return `${serverUrlV1}/project/${projectNo}/staff`;
  }

  static staffInviteUrl(inviteId: number | string) {
    return `${serverUrl}/staff/${inviteId}`;
  }

  // 인물 APIs
  static charactersUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/characters`;
  }

  static characterCardsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/character-cards`;
  }

  static characterUrl(projectNo: number, characterNo: number) {
    return `${serverUrl}/projects/${projectNo}/characters/${characterNo}`;
  }

  static actorsUrl(projectNo: number, characterNo: number) {
    return `${serverUrl}/projects/${projectNo}/characters/${characterNo}/actors`;
  }

  static actorUrl(projectNo: number, characterNo: number, actorNo: number) {
    return `${serverUrl}/projects/${projectNo}/characters/${characterNo}/actors/${actorNo}`;
  }

  static actorListUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/characters/actors`;
  }

  static actorExcelUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/characters/actors/excel`;
  }

  static actorSchedulesUrl(projectNo: number, actorNo: number) {
    return `${serverUrl}/projects/${projectNo}/actors/${actorNo}/schedules`;
  }

  static actorAllSchedulesUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/actors/schedules`;
  }

  static actorScheduleMemoUrl(projectNo: number, actorNo: number) {
    return `${serverUrl}/projects/${projectNo}/actors/${actorNo}/schedules/groups`;
  }

  // 극중 장소 APIs
  static projectPlacesUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/places`;
  }

  static projectPlaceUrl(projectNo: number, placeNo: number) {
    return `${serverUrl}/projects/${projectNo}/places/${placeNo}`;
  }

  static projectPlaceImagesUrl(projectNo: number, placeNo: number) {
    return `${serverUrl}/projects/${projectNo}/places/${placeNo}/images`;
  }

  static projectPlaceImageUrl(projectNo: number, placeNo: number, imageNo: number) {
    return `${serverUrl}/projects/${projectNo}/places/${placeNo}/images/${imageNo}`;
  }

  static placeLocationsUrl(projectNo: number, placeNo: number) {
    return `${serverUrl}/projects/${projectNo}/places/${placeNo}/locations`;
  }

  static placeLocationUrl(projectNo: number, placeNo: number, locationNo: number) {
    return `${serverUrl}/projects/${projectNo}/places/${placeNo}/locations/${locationNo}`;
  }

  static placeAndLocationUrl() {
    return `${serverUrl}/projects/place-location`;
  }

  // 촬영지 APIs
  static locationUrl(locationNo: number) {
    return `${serverUrl}/locations/${locationNo}`;
  }

  static projectLocationsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/locations`;
  }

  static projectAllLocationSchedulesUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/locations/schedules`;
  }

  static projectLocationSchedulesUrl(projectNo: number, locationNo: number) {
    return `${serverUrl}/projects/${projectNo}/locations/${locationNo}/schedules`;
  }

  static projectLocationUrl(projectNo: number, locationNo: number) {
    return `${serverUrl}/projects/${projectNo}/locations/${locationNo}`;
  }

  // 배우 API
  static companyActorsUrl(companyNo: number) {
    return `${serverUrl}/companies/${companyNo}/actors`;
  }

  static itemsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/items`;
  }

  static sceneLocationUrl(sceneNo: number) {
    return `${serverUrl}/scenes/${sceneNo}/locations`;
  }

  static deleteScenesUrl(scriptNo: number, sceneNos: string) {
    return `${serverUrl}/scripts/${scriptNo}/scenes${sceneNos ? `?sceneNos=${sceneNos}` : ``}`;
  }

  static unassingedScenes(projectNo: number, groupNo: number, sorted?: boolean) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/unassigned-scenes${
      sorted ? `?sorted=${sorted}` : ``
    }`;
  }

  static stripsUrl(sceneNo: number) {
    return `${serverUrl}/scenes/${sceneNo}/strips`;
  }

  static stripUrl(stripNo: number) {
    return `${serverUrl}/scenes/strips/${stripNo}`;
  }

  static shootingInfoUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/shooting-info`;
  }

  static scheduleGroupsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups`;
  }

  static scheduleGroupUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}`;
  }

  static passedSchedulesUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/passed`;
  }

  static completedSchedulesUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/complete`;
  }

  static shareScheduleGroupUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/share`;
  }

  static confirmScheduleGroupUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/confirm`;
  }

  static scheduleStripUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules`;
  }

  static schedulesUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/detail`;
  }

  static scheduleUrl(projectNo: number, groupNo: number, scheduleNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/${scheduleNo}`;
  }

  static scheduleListUrl(projectNo: number, groupNo: number, scheduleNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/${scheduleNo}/lists`;
  }

  static scheduleListModifyUrl(projectNo: number, listNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-lists/${listNo}`;
  }

  static deleteSchedulesWithListNo(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/lists`;
  }

  static scheduleListCopyUrl(projectNo: number, groupNo: number, scheduleNo: number, listNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/${scheduleNo}/lists/${listNo}/copy`;
  }

  static scheduleListChangeOrdersWithListNo(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/lists/change-orders`;
  }

  static scheduleListChangeOrders(projectNo: number, groupNo: number, scheduleNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/${scheduleNo}/lists/change-orders`;
  }

  static autoScheduleUrl(projectNo: number, groupNo: number) {
    return `${serverUrl}/projects/${projectNo}/schedule-groups/${groupNo}/schedules/auto-scheduling?ai`;

  }

  static shootingTablesUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/shooting-tables`;
  }

  static getPlaceConfirmedLocation() {
    return `${serverUrl}/projects/place-location`;
  }

  static shootingTableUrl(projectNo: number, scheduleNo: number) {
    return `${serverUrl}/projects/${projectNo}/shooting-tables/${scheduleNo}`;
  }

  static confirmCallsheetUrl(projectNo: number, scheduleNo: number) {
    return `${serverUrl}/projects/${projectNo}/shooting-tables/${scheduleNo}/confirm`;
  }

  static placesUrl(sceneNo: number) {
    return `${serverUrl}/scenes/${sceneNo}/places`;
  }

  static placeUrl(sceneNo: number, placeNo: number) {
    return `${serverUrl}/scenes/${sceneNo}/places/${placeNo}`;
  }

  static loginUrl() {
    return `${process.env.REACT_APP_API_HOST}/strips/login`;
  }

  static logoutUrl() {
    return `${process.env.REACT_APP_API_HOST}/strips/logout`;
  }

  static userResourceUsage() {
    return `${serverUrl}/user/usages`;
  }

  static profileUrl() {
    return `${serverUrl}/session`;
  }

  static loginCheckUrl(url: string) {
    return `${process.env.REACT_APP_API_HOST}/strips/login?url=${encodeURIComponent(url)}`;
  }

  static joinUrl() {
    return `${serverUrl}/join`;
  }

  static joinCompleteUrl(session: string) {
    return `${serverUrl}/join/${session}`;
  }

  static joinAvailableUrl() {
    return `${serverUrl}/join/available`;
  }

  static emailAuthUrl(session: string) {
    return `${serverUrl}/email/auth?session=${session}`;
  }

  static tagsUrl(projectNo: number) {
    return `${serverUrl}/projects/${projectNo}/tags`;
  }

  static tagUrl(tagNo: number) {
    return `${serverUrl}/tags/${tagNo}`;
  }

  static getTaggedUrl(sceneNo: number) {
    return `${serverUrl}/scenes/${sceneNo}/taggeds`;
  }

  static assignTaggedUrl(tagNo: number) {
    return `${serverUrl}/scripts/tags/${tagNo}/assign`;
  }

  static updateTaggedUrl(taggedNo: number) {
    return `${serverUrl}/scripts/taggeds/${taggedNo}/re-assign`;
  }

  static deleteTaggedUrl(taggedNo: number, sceneNo: number) {
    return `${serverUrl}/scripts/taggeds/${taggedNo}/assign?sceneNo=${sceneNo}`;
  }

  // 회사 파일 DB
  static companyFoldersUrl(companyNo: number, path?: string) {
    return `${serverUrl}/companies/${companyNo}/folders/${path || ''}`;
  }

  static companyFilesUrl(companyNo: number, path?: string) {
    return `${serverUrl}/companies/${companyNo}/files/${path || ''}`;
  }

  static companyFileDownloadUrl(companyNo: number, path?: string) {
    return `${serverUrl}/companies/${companyNo}/files-download/${path || ''}`;
  }

  static companyRecentFilesUrl(companyNo: number) {
    return `${serverUrl}/companies/${companyNo}/files-recent`;
  }

  static companyAllFilesUrl(companyNo: number, path?: string) {
    return `${serverUrl}/companies/${companyNo}/files-all/${path}`;
  }

  static makeFormData(obj: any) {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      if (key !== 'id') {
        console.log(`append : ${key} : ${obj[key].toString()}`);
        formData.append(key, JSON.stringify(obj[key]));
      }
    });
    return formData;
  }

  static get(url: string, params: object = {}) {
    return axios.get(url, { params, withCredentials: true });
  }

  static post(url: string, formData: object = {}, params: object = {}) {
    return axios.post(url, formData, { params, withCredentials: true });
  }

  static put(url: string, formData: object = {}, params: object = {}) {
    return axios.put(url, formData, { params, withCredentials: true });
  }

  static delete(url: string, params: object = {}, data: object = {}) {
    return axios.delete(url, { params, withCredentials: true, data });
  }
}
