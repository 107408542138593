import classNames from 'classnames';
import { TabBarProps } from './TabBar.types';
import styles from './TabBar.module.scss';
import { Typography } from 'components/Typography';

export const TabBar = ({ selected, tabs, wrapperClass = '', onChange }: TabBarProps) => {
  return (
    <div className={classNames(styles.root, wrapperClass)}>
      <ul>
        {tabs?.map((tab, index) => (
          <li
            key={index}
            className={selected === tab.value ? styles.selected : ''}
            onClick={() => onChange?.(tab.value)}>
              <Typography variant='f16-166--64'>{tab.label}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};
