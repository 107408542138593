import SvAPI from '../../util/SvAPI';
import { asyncState, AsyncState } from '../../util/ReducerTypes';
import ReducerGenerator from '../../util/ReducerGenerator';
import { LocationProps, LocationSchedulesInfo } from 'pages/location/Location.types';
import CommonResponse from 'modules/common/CommonResponse';
import { findAllLocationSchedules, findLocationSchedules } from './LocationService';
import { RealResourceSchedulesInfo } from 'shared';

export type LocationAsyncState<T, E = any> = {
  action: string | null;
  loading: boolean;
  data: T | null;
  error: E | null;
};

export type LocationState = {
  location: AsyncState<LocationProps, Error>;
  locations: AsyncState<CommonResponse<LocationProps[]>, Error>;
  schedules: AsyncState<LocationSchedulesInfo, Error>;
  allSchedules: AsyncState<RealResourceSchedulesInfo[], Error>;
  sceneLocations: LocationAsyncState<LocationProps[], Error>;
  companyLocations: LocationAsyncState<CommonResponse<LocationProps[]>, Error>;
};

const initialState: LocationState = {
  location: asyncState.initial(),
  locations: asyncState.initial(),
  schedules: asyncState.initial(),
  allSchedules: asyncState.initial(),
  sceneLocations: {
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  companyLocations: {
    action: null,
    loading: false,
    data: {
      body: [],
    },
    error: null,
  },
};

const reducerGenerator = new ReducerGenerator<LocationState, 'locations'>(initialState);

// 촬영지 전체 조회
export const getLocations = reducerGenerator.createThunkAction<
'getLocations',
{ companyNo: number; params: {} },
CommonResponse<LocationProps[]>
>({
  action: 'locations/getLocations',
  key: 'locations',
  thunk: (params) => SvAPI.get(SvAPI.companyLocationUrl(params!.companyNo), params!.params).then((r) => r.data),
});
// 촬영지 단건 조회
export const getLocation = reducerGenerator.createThunkAction<
'getLocation',
{ projectNo: number; locationNo: number; params: {} },
LocationProps
>({
  action: 'locations/getLocation',
  key: 'location',
  thunk: (params) =>
    SvAPI.get(SvAPI.projectLocationUrl(params!.projectNo, params!.locationNo), params!.params).then((r) => r.data),
});

// 씬 촬영지 목록 조회  ??? 씬 reducer 에로 이동 ???
export const getSceneLocations = reducerGenerator.createThunkAction<
'getSceneLocations',
{ sceneNo: number; params: {} },
LocationProps
>({
  action: 'locations/getSceneLocations',
  key: 'sceneLocations',
  thunk: (params) => SvAPI.get(SvAPI.sceneLocationUrl(params!.sceneNo), params!.params).then((r) => r.data),
});

// 촬영지 목록 조회  ??? 씬 reducer 에로 이동 ???
export const getCompanyLocations = reducerGenerator.createThunkAction<
'getCompanyLocations',
{ companyNo: number; params: {} },
LocationProps
>({
  action: 'locations/getCompanyLocations',
  key: 'companyLocations',
  thunk: (params) => SvAPI.get(SvAPI.companyLocationUrl(params!.companyNo), params!.params).then((r) => r.data),
});

export const getLocationShedules = reducerGenerator.createThunkAction<
'getLocationSchedules',
{ projectNo: number; locationNo: number },
LocationSchedulesInfo
>({
  action: 'locations/getLocationSchedules',
  key: 'schedules',
  thunk: (params) => findLocationSchedules(params.projectNo, params.locationNo),
});

export const getAllLocationShedules = reducerGenerator.createThunkAction<
'getAllLocationShedules',
{ projectNo: number; params: {} },
RealResourceSchedulesInfo[]
>({
  action: 'locations/getAllLocationShedules',
  key: 'allSchedules',
  thunk: (params) => findAllLocationSchedules(params.projectNo, params.params),
});

const locationReducer = reducerGenerator.createReducer();
export default locationReducer;
