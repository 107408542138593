export enum TagType {
  /**
   * 등장인물_주연
   */
  CHARACTER_MAIN = 'CHARACTER_MAIN',
  /**
   * 등장인물_조연
   */
  CHARACTER_SUB = 'CHARACTER_SUB',
  /**
   * 소품
   */
  PROP = 'PROP',
  /**
   * 의상
   */
  COSTUME = 'COSTUME',
  /**
   * 분장
   */
  MAKEUP = 'MAKEUP',
  /**
   * 보조 출연
   */
  EXTRA = 'EXTRA',
  /**
   * 특수 효과
   */
  SFX = 'SFX',
  /**
   * 기타
   */
  ETC = 'ETC',
}

export enum TagTypeColor {
  /**
   * 등장인물
   */
  CHARACTER = 'var(--yellow-100)',
  /**
   * 소품
   */
  PROP = 'var(--red-30)',
  /**
   * 의상
   */
  COSTUME = 'var(--blue-60)',
  /**
   * 분장
   */
  MAKEUP = 'var(--lightGreen-50)',
  /**
   * 보조 출연
   */
  EXTRA = 'var(--white-30)',
  /**
   * 특수 효과
   */
  SFX = 'var(--brown-90)',
  /**
   * 기타
   */
  ETC = 'black',
}

export interface Tag {
  tagGroup: number[];
  tagNo: number;
  tagType: string;
  name: string;
  projectNo: number;
  describe: string;
}
