/* eslint-disable @typescript-eslint/indent */
import { ProjectInfo } from 'shared';
import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import { findGroups, findProject } from './ProjectService';
import { GetGroupListResponse } from './Project.module.types';

export type ProjectState = {
  project: AsyncState<ProjectInfo, Error>;
  groups: AsyncState<GetGroupListResponse, Error>;
};

const initialState: ProjectState = {
  project: asyncState.initial(),
  groups: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<ProjectState, 'project'>(initialState);

// 프로젝트 정보 조회
export const getProject = reducerGenerator.createThunkAction<'getProject', { projectNo: number }, ProjectInfo>({
  action: 'project/getProject',
  key: 'project',
  thunk: (params) => findProject(params.projectNo),
});

export const getGroupList = reducerGenerator.createThunkAction<
  'getGroupList',
  { projectNo: number },
  GetGroupListResponse
>({
  action: 'project/getGroupList',
  key: 'groups',
  thunk: (params) => findGroups(params.projectNo),
});

const projectReducer = reducerGenerator.createReducer();
export default projectReducer;
