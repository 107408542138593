import classNames from 'classnames';
import { ImageCompProps, ImageType } from './ImageComp.types';

import fakeUser from 'public/fake/fake_character.svg';
import fakeCommon from 'public/fake/fake_image.svg';
import fakeLocation from 'public/fake/fake_location.svg';
import { fileUrl } from 'util/fileHelper';

const FakeImages = {
  [ImageType.COMMON]: fakeCommon,
  [ImageType.USER]: fakeUser,
  [ImageType.LOCATION]: fakeLocation,
};

export const ImageComp = ({
  src,
  type = ImageType.COMMON,
  wrapperClass = '',
  className = '',
  width = 'auto',
  height = 'auto',
  position = 'bottom',
  circle = false,
}: ImageCompProps) => {
  return (
    <div
      className={classNames(
        'd-flex justify-content-center bg-blue-gray-200 overflow-hidden',
        wrapperClass,
        position === 'bottom' ? 'items-end' : 'items-center',
        circle && 'aspect-square rounded-full'
      )}
      style={{ width, height }}>
      {src ? (
        <img src={fileUrl(src)} className={classNames('w-100 h-100', className || 'object-cover')} />
      ) : (
        <img src={FakeImages[type]} style={{ maxWidth: '80%', maxHeight: '80%' }} className="object-contain" />
      )}
    </div>
  );
};
