import axios, { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
import { ActionType } from 'typesafe-actions';

/**
 * 에러 처리
 * @param error http 요청 에러
 * @returns 개별 error 핸들링 처리가 필요한 경우 true, 따로 함수 내부에서 처리하는 경우 false
 */
export const needResponseErrorHandling = (error: AxiosError) => {
  switch (error.response?.status) {
    case 401:
      window.location.href = `/login?url=${encodeURIComponent(window.location.href)}`;
      return false;
  }

  return true;
};

export const asyncHelper = <S = any, AT = any>(
  promise: Promise<any>,
  action: ActionType<AT>,
  param?: any
): ThunkAction<void, S, null, ActionType<AT>> => {
  return (dispatch) => {
    const { request, success, failure } = action;

    dispatch(request(param));
    promise
      .then((r) => {
        if (r.data) {
          dispatch(success(r.data));
        } else {
          dispatch(success(r));
        }
      })
      .catch((e) => {
        if (e instanceof AxiosError && !needResponseErrorHandling(e)) {
          return;
        }
        console.log(e);
        dispatch(failure(e));
      });
  };
};

export default class AsyncUtils<S, AT> {
  getAsyncThunk = (url: string, asyncAction: any): ThunkAction<void, S, null, ActionType<AT>> => {
    return async (dispatch) => {
      const { request, success, failure } = asyncAction;
      dispatch(request());

      try {
        const response = await axios.get(url, { withCredentials: true });
        if (response.data.body) dispatch(success(response.data.body));
        else dispatch(success(response.data));
      } catch (error: any) {
        if (error instanceof AxiosError && !needResponseErrorHandling(error)) {
          return;
        }
        dispatch(failure(error));
      }
    };
  };

  getAsyncThunkWithParam = (
    url: string,
    asyncAction: any,
    params: {} = {}
  ): ThunkAction<void, S, null, ActionType<AT>> => {
    return async (dispatch) => {
      const { request, success, failure } = asyncAction;
      dispatch(request());
      try {
        const response = await axios.get(url, { params, withCredentials: true });
        dispatch(success(response.data));
      } catch (error: any) {
        dispatch(failure(error));
      }
    };
  };

  postAsyncThunk = (
    url: string,
    formData: any,
    asyncAction: any,
    options?: any
  ): ThunkAction<void, S, null, ActionType<AT>> => {
    return async (dispatch) => {
      const { request, success, failure } = asyncAction;
      dispatch(request());
      try {
        const response = await axios.post(url, formData, { params: options, withCredentials: true });
        // dispatch(success(response.data.body));
        dispatch(success(response.data));
      } catch (error: any) {
        if (error instanceof AxiosError && !needResponseErrorHandling(error)) {
          return;
        }
        dispatch(failure(error));
      }
    };
  };

  putAsyncThunk = (
    url: string,
    formData: any,
    options: any,
    asyncAction: any
  ): ThunkAction<void, S, null, ActionType<AT>> => {
    return async (dispatch) => {
      const { request, success, failure } = asyncAction;
      dispatch(request());
      try {
        const response = await axios.put(url, formData, { params: options, withCredentials: true });
        dispatch(success(response.data.body));
      } catch (error: any) {
        if (error instanceof AxiosError && !needResponseErrorHandling(error)) {
          return;
        }
        dispatch(failure(error));
      }
    };
  };

  deleteAsyncThunk = (url: string, options: any, asyncAction: any): ThunkAction<void, S, null, ActionType<AT>> => {
    return async (dispatch) => {
      const { request, success, failure } = asyncAction;
      dispatch(request());
      try {
        const response = await axios.delete(url, { params: options, withCredentials: true });
        dispatch(success(response.data.body));
      } catch (error: any) {
        if (error instanceof AxiosError && !needResponseErrorHandling(error)) {
          return;
        }
        dispatch(failure(error));
      }
    };
  };
}
