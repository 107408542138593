import { useEffect } from 'react';
import { useNavigate } from 'react-router';


export const LoginRedirect = () => {
  const nav = useNavigate();
  useEffect(() => {
    nav(`/login?url=${encodeURIComponent(window.location.href)}`, { state: {} });
  }, []);
  return <></>;
};
