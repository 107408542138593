import SvAPI from '../../util/SvAPI';
import { asyncState, AsyncState } from '../../util/ReducerTypes';
import ReducerGenerator from '../../util/ReducerGenerator';
import CommonResponse from 'modules/common/CommonResponse';
import { InvitedStaffInfo, StaffProps } from 'pages/staff/Staff.types';

export type StaffState = {
  staffs: AsyncState<StaffProps[], Error>;
  invited: AsyncState<InvitedStaffInfo[], Error>;
};

const initialState:StaffState = {
  staffs: asyncState.initial(),
  invited: asyncState.initial(),
};
const reducerGenerator = new ReducerGenerator<StaffState, 'staff'>(initialState);

// 스캐프 전체 조회
export const getStaffs = reducerGenerator.createThunkAction<
'getStaffs',
{ projectNo: number; params: {} },
CommonResponse<StaffProps[]>
>({
  action: 'staff/getStaffs',
  key: 'staffs',
  thunk: (params) => SvAPI.get(SvAPI.staffsUrl(params!.projectNo), params!.params).then((r) => r.data),
});

export const getInvitedStaffs = reducerGenerator.createThunkAction<
'getInvitedStaffs',
{ projectNo: number; params: {} },
InvitedStaffInfo[]
>({
  action: 'staff/getInvitedStaffs',
  key: 'invited',
  thunk: (params) => SvAPI.get(SvAPI.staffsUrl(params!.projectNo) + '/invite', params!.params).then((r) => r.data),
});

const staffReducer = reducerGenerator.createReducer();
export default staffReducer;
