/* eslint-disable @typescript-eslint/indent */
import { AsyncState, asyncState } from 'util/ReducerTypes';
import ReducerGenerator from 'util/ReducerGenerator';
import Project from '../project/Project';
import { AxiosError } from 'axios';
import ProjectGetService from '../project/ProjectGetService';
import { DashboardScheduleResponse, GetScheduleParam, getScheduledScenes } from '../schedules/ScheduleServices';
import SceneService, { SceneUpdateParam } from '../scenes/SceneService';
import SvAPI from 'util/SvAPI';
import { DDayInfo } from 'pages/dashboard/ProjectDashboard.types';
import CommonResponse from 'modules/common/CommonResponse';

export interface ProjectDashboardState {
  project: AsyncState<Project, Error>;
  schedule: AsyncState<CommonResponse<DashboardScheduleResponse[]>, Error>;
  todayMemo: AsyncState<string, Error>;
  dday: AsyncState<DDayInfo, Error>;
  error?: AxiosError;
}

const initState: ProjectDashboardState = {
  project: asyncState.initial(),
  schedule: asyncState.initial(),
  todayMemo: asyncState.initial(),
  dday: asyncState.initial(),
};

const projectDashboard = new ReducerGenerator<ProjectDashboardState, 'projectDashboard'>(initState);

export const initDashboard = projectDashboard.createThunkAction<'initSchedule', { projectNo: number }, Project>({
  action: 'projectDashboard/initSchedule',
  key: 'project',
  thunk: (param) => {
    return new ProjectGetService().getProject(param.projectNo);
  },
});

export const getScheduleScenes = projectDashboard.createThunkAction<
  'getScheduleScenes',
  GetScheduleParam,
  CommonResponse<DashboardScheduleResponse[]>
>({
  action: 'projectDashboard/getScheduleScenes',
  key: 'schedule',
  thunk: (param: GetScheduleParam) => getScheduledScenes(param.projectNo, param.type, param.params),
});

export const updateSceneProceed = projectDashboard.createThunkAction<'updateScene', SceneUpdateParam, {}>({
  action: 'projectDashboard/updateScene',
  key: 'schedule',
  thunk: (param: SceneUpdateParam) => {
    return new SceneService().updateScene(param.sceneNo, param.scene);
  },
});

export const getTodayDashboardMemo = projectDashboard.createThunkAction<
  'getTodayDashboardMemo',
  { projectNo: number },
  string
>({
  action: 'projectDashboard/getTodayDashboardMemo',
  key: 'todayMemo',
  thunk: (param) => SvAPI.get(SvAPI.projectTodayMemoUrl(param.projectNo), {}).then((res) => res.data),
});

export const updateTodayDashboardMemo = projectDashboard.createThunkAction<
  'updateTodayDashboardMemo',
  { projectNo: number, content: string },
  string
>({
  action: 'projectDashboard/updateTodayDashboardMemo',
  key: 'todayMemo',
  thunk: (param) => 
    SvAPI.put(SvAPI.projectTodayMemoUrl(param.projectNo), { note: param.content || '' })
      .then(() => SvAPI.get(SvAPI.projectTodayMemoUrl(param.projectNo), {}))
      .then((res) => res.data),
});

export const getProjectDday = projectDashboard.createThunkAction<'getProjectDday', { projectNo: number }, DDayInfo>({
  action: 'projectDashboard/getProjectDday',
  key: 'dday',
  thunk: (param) => SvAPI.get(SvAPI.projectDdayUrl(param.projectNo), {}).then((res) => res.data),
});

export const projectDashboardReducer = projectDashboard.createReducer();
