import styles from './LabelCheckBox.module.scss';
import * as React from 'react';
import { useRef } from 'react';
import { TextValue } from 'pages/scene/Scene';
import _ from 'lodash';
import { Checkbox } from './Checkbox';
import { Col } from 'react-bootstrap';

type LabelCheckBoxProps = {
  label: string;
  items?: TextValue[];
  activeItem?: TextValue;
  onChecked: (item?: TextValue) => void;
  totalWidth?: LengthRatio;
  labelWidth?: LengthRatio;
};

export const LabelCheckBox = ({
  label,
  items,
  onChecked,
  activeItem,
  totalWidth = 12,
  labelWidth = 2,
}: LabelCheckBoxProps) => {
  const [selectedItem, setSelectedItems] = React.useState<string | undefined>(undefined);
  const prevInitItem = useRef<TextValue>();
  React.useEffect(() => {
    if (!_.isEqual(prevInitItem.current, activeItem)) {
      setSelectedItems(activeItem?.value);
    }
    prevInitItem.current = activeItem;
  }, [activeItem]);

  const handleCheck = (item: string) => {
    return selectedItem === item;
  };
  const handleCheckItem = (e: any, item: TextValue) => {
    if (e.target.checked) {
      setSelectedItems(item.value);
      onChecked(item);
    } else {
      setSelectedItems(undefined);
      onChecked(undefined);
    }
  };
  const getItems = () => {
    return items?.map((item, index) => {
      return (
        <div className={styles.item} key={index}>
          <Checkbox
            id={`checkbox-${label}-${index}`}
            checked={handleCheck(item.value)}
            onChange={(e) => handleCheckItem(e, item)}
          />
          <label htmlFor={`checkbox-${label}-${index}`} className={styles.item_text}>
            {item.text}
          </label>
        </div>
      );
    });
  };

  return (
    <>
      <Col sm={labelWidth} className={styles.label}>
        {label}
      </Col>
      <Col sm={totalWidth - labelWidth} className={styles.checklist}>
        {getItems()}
      </Col>
    </>
  );
};
