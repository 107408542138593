import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useLocation, matchRoutes } from 'react-router-dom';
import LeftMenu from './LeftMenu';
import Header from './Header';
import style from './Frame.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'modules';
import classNames from 'classnames';
import { managerRouteBreadcrumbs } from '../../shared/managerNavigations';
import { Breadcrumb } from '../Breadcrumb/Breadcrumb';
import { BreadcrumbInfo } from '../../shared';

type Props = {
  enableLeftMenu: boolean;
};

export default function ManagerFrame({ enableLeftMenu }: Partial<Props>) {
  const { companyId } = useParams();
  const location = useLocation();
  const { sidebarCollapsed } = useSelector((state: RootState) => state.layout);
  const match = matchRoutes(managerRouteBreadcrumbs, location);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInfo[]>([]);
  const { pathname } = location;
  const [logoOnly, setLogoOnly] = useState<boolean>(false);
  const baseUrl = `/manager/company/${companyId}`;

  useEffect(() => {
    const route = match?.[0]?.route;
    if (route) {
      setBreadcrumbs(route?.breadcrumb || []);
      setLogoOnly(route?.logoOnly || false);
    }
  }, [match]);

  useEffect(() => {
    if (pathname.search('/join(/.*)?') != -1) setLogoOnly(true);
  }, [pathname]);


  return (
    <div>
      <Header logoOnly={logoOnly} />
      {enableLeftMenu ? (
        <>
          <LeftMenu isManagerPage={true}/>
          <div className={classNames(style.withSidebar, sidebarCollapsed ? style.collapsed : '')}>
            <div className={style.content}>
              <Breadcrumb baseUrl={baseUrl} breadcrumbs={breadcrumbs} projectNo={0} />
              <Outlet />
            </div>
          </div>
        </>
      ) : (
        <div className={style.withoutSidebar}>
          <div className={style.content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}
