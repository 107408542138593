import React from 'react';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { getMonth, getYear, getDate } from 'date-fns/esm';
import style from './DatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker-library-style.css';

import arrow_back from 'public/common/arrow_back.svg';
import arrow_next from 'public/common/arrow_next.svg';

type CustomDatePickerProps = {
  selectedDate?: Date;
  lastDate?: Date;
  isEnd?: boolean;
  minDate?: Date;
  maxDate?: Date;
  excludeDates?: Date[];
  highlightDates?: Date[];
  readOnly?: boolean;
  getStartDate?: (date: Date) => void;
  getEndDate?: (date: Date) => void;
  getStartDateString?: (date: string) => void;
  getEndDateString?: (date: string) => void;
};

export const CustomDatePicker = ({
  selectedDate,
  lastDate,
  isEnd,
  minDate,
  maxDate,
  excludeDates,
  highlightDates,
  readOnly = false,
  getStartDate,
  getEndDate,
  getStartDateString,
  getEndDateString,
}: CustomDatePickerProps) => {
  const [startDate, setStartDate] = React.useState<Date>(selectedDate ? selectedDate : new Date());
  const [endDate, setEndDate] = React.useState<Date>(isEnd && lastDate ? lastDate : new Date());
  const calendar = React.useRef<ReactDatePicker<never, undefined>>(null);

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  React.useEffect(() => {
    if (getStartDate) getStartDate(startDate);
    if (getEndDate) getEndDate(endDate);
    if (getStartDateString)
      getStartDateString(`${getYear(startDate)}년 ${months[getMonth(startDate)]}월 ${getDate(startDate)}일`);
    if (getEndDateString)
      getEndDateString(`${getYear(endDate)}년 ${months[getMonth(endDate)]}월 ${getDate(endDate)}일`);
  }, []);

  const makeDateString = (d: Date) => {
    return `${getYear(d)}년 ${months[getMonth(d)]}월 ${getDate(d)}일`;
  };

  const onChange = (d: Date | [Date | null, Date | null] | null) => {
    if (readOnly) return;

    if (isEnd) {
      const [start, end] = d as [Date, Date];
      setStartDate(start);
      setEndDate(end);
      if (getStartDate) getStartDate(start);
      if (getEndDate) getEndDate(end === null ? start : end);
      if (getStartDateString) getStartDateString(makeDateString(start));
      if (getEndDateString) getEndDateString(makeDateString(end === null ? start : end));
    } else {
      setStartDate(d as Date);
      if (getStartDate) getStartDate(d as Date);
      if (getStartDateString) getStartDateString(makeDateString(d as Date));
    }
  };

  return (
    <DatePicker
      inline
      selectsRange={isEnd ? true : false}
      selected={startDate}
      startDate={isEnd ? startDate : undefined}
      endDate={isEnd ? endDate : undefined}
      minDate={minDate}
      maxDate={maxDate}
      excludeDates={excludeDates}
      onChange={onChange}
      locale={ko}
      shouldCloseOnSelect={false}
      ref={calendar}
      highlightDates={highlightDates}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
        return (
          <div className={style.calendar_header}>
            <button
              className={style.btn_month}
              onClick={(e) => {
                e.preventDefault();
                decreaseMonth();
              }}>
              <img src={arrow_back} alt="arrow_back" />
            </button>
            <div>
              {getYear(date)}년 {months[getMonth(date)]}월
            </div>
            <button
              className={style.btn_month}
              onClick={(e) => {
                e.preventDefault();
                increaseMonth();
              }}>
              <img src={arrow_next} alt="arrow_next" />
            </button>
          </div>
        );
      }}
    />
  );
};
