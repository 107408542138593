import axios from 'axios';

export const requestConfigure = () => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response?.status >= 400 && error.response?.status < 500) {
        if (error.response?.status === 404)
          window.location.href = '/not-found';
        else {
          return Promise.reject(error);
        }
        // } if (error.response?.status === 500) {
        //   window.location.href = '/error';
      } else {
        const errorMessage: string = error.response?.data?.errors
          ?.reduce((iter: string[], err: any) => (err.message ? [...iter, err.message] : iter), [])
          .join('\n');

        return Promise.reject({
          code: error.code,
          status: error.response?.status,
          statusText: errorMessage || error.response?.data?.detail || 'Server Error',
          data: null,
        });
      }
    }
  );
};
