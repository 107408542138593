import classNames from 'classnames';
import { SvgIconProps } from './SvgIcon.types';

export const SvgIcon = ({ source, size, color = 'default', className = '' }: SvgIconProps) => {
  if (!source?.src) return null;

  const ratio = size ? size / source.height : 1;
  const property = {
    with: source.width * ratio,
    height: source.height * ratio,
    viewBox: `0 0 ${source.width} ${source.height}`,
  };

  return (
    <svg
      {...property}
      className={classNames(`svg-icon-${color}`, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {source.src}
    </svg>
  );
};
