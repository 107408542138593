import ReducerGenerator from 'util/ReducerGenerator';

export type LayoutState = {
  sidebarCollapsed: boolean;
};

const initialState: LayoutState = {
  sidebarCollapsed: false,
};

const reducerGenerator = new ReducerGenerator<LayoutState, 'layout'>(initialState);

export const toggleSideBar = reducerGenerator.createAction<'toggleSdiebar', { collapsed: boolean }>({
  action: 'layout/toggleSdiebar',
  reducer: (state, action) => {
    return {
      ...state,
      sidebarCollapsed: action.payload.collapsed,
    };
  },
});

const layoutReducer = reducerGenerator.createReducer();
export default layoutReducer;
