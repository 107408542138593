export enum ImageType {
  USER,
  LOCATION,
  COMMON,
}

export interface ImageCompProps {
  src?: string;
  type?: ImageType;
  wrapperClass?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  position?: 'center' | 'bottom';
  circle?: boolean;
}
