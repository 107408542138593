import CommonResponse from 'modules/common/CommonResponse';
import { CharacterDetailProps, CharacterFormProps, CharacterProps, CharacterSchedulesInfo } from 'pages/character/Character.types';
import { CompanyActorInfo, ItemInfo, RealResourceSchedulesInfo } from 'shared';
import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import SvAPI from 'util/SvAPI';
import { findExtraCharacters } from './CharacterService';

export type CharacterState = {
  modalMessage: string | null;
  characters: AsyncState<CommonResponse<CharacterProps[]>, Error>;
  supCharacters: AsyncState<CommonResponse<CharacterProps[]>, Error>;
  extras: AsyncState<ItemInfo[], Error>;
  character: CharacterDetailProps | null;
  actors: AsyncState<CompanyActorInfo[], Error>;
  actorSchedules: AsyncState<CharacterSchedulesInfo, Error>;
  allSchedules: AsyncState<RealResourceSchedulesInfo[], Error>;
};

const initialState: CharacterState = {
  modalMessage: null,
  characters: asyncState.initial(),
  supCharacters: asyncState.initial(),
  extras: asyncState.initial(),
  character: null,
  actors: asyncState.initial(),
  actorSchedules: asyncState.initial(),
  allSchedules: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<CharacterState, 'characters'>(initialState);

// 주연 목록 조회
export const getCharacters = reducerGenerator.createThunkAction<
'getCharacters',
{ projectNo: number; params: {} },
CommonResponse<CharacterProps[]>
>({
  action: 'characters/getCharacters',
  key: 'characters',
  thunk: (params) => SvAPI.get(SvAPI.charactersUrl(params!.projectNo), { ...params.params, role: 'MAIN' }).then((r) => r.data),
});
// 주연 카드 목록 조회
export const getCharacterCards = reducerGenerator.createThunkAction<
'getCharacterCards',
{ projectNo: number; params: {} },
CommonResponse<CharacterProps[]>
>({
  action: 'characters/getCharacterCards',
  key: 'characters',
  thunk: (params) => SvAPI.get(SvAPI.characterCardsUrl(params!.projectNo), { ...params.params, role: 'MAIN' }).then((r) => r.data),
});

// 조연 목록 조회
export const getSupCharacters = reducerGenerator.createThunkAction<
'getSupCharacters',
{ projectNo: number; params: {} },
CommonResponse<CharacterProps[]>
>({
  action: 'characters/getSupCharacters',
  key: 'supCharacters',
  thunk: (params) => SvAPI.get(SvAPI.charactersUrl(params!.projectNo), { ...params.params, role: 'SUB' } ).then((r) => r.data),
});
// 조연 카드 목록 조회
export const getSupCharacterCards = reducerGenerator.createThunkAction<
'getSupCharacterCards',
{ projectNo: number; params: {} },
CommonResponse<CharacterProps[]>
>({
  action: 'characters/getSupCharacterCards',
  key: 'supCharacters',
  thunk: (params) => SvAPI.get(SvAPI.characterCardsUrl(params!.projectNo), { ...params.params, role: 'SUB' } ).then((r) => r.data),
});

// 보조출연 조회
export const getExtraCharacters  = reducerGenerator.createThunkAction<
'getExtraCharacters',
{ projectNo: number, params: {} },
ItemInfo[]
>({
  action: 'characters/getExtraCharacters',
  key: 'extras',
  thunk: (params) => findExtraCharacters(params.projectNo, params.params),
});
// 캐릭터 상세 조회
export const getCharacter = reducerGenerator.createThunkAction<
'getCharacter',
{ projectNo: number; characterNo: number; params: {} },
CharacterDetailProps
>({
  action: 'characters/getCharacter',
  key: 'characters',
  thunk: (params) =>
    SvAPI.get(SvAPI.characterUrl(params!.projectNo, params!.characterNo), params!.params).then((r) => r.data),
});
// 캐릭터 수정
export const updateCharacter = reducerGenerator.createThunkAction<
'putCharacter',
{ projectNo: number; characterNo: number; character: CharacterFormProps },
any
>({
  action: 'characters/putCharacter',
  key: 'characters',
  modal: {
    failText: '캐릭터 수정 실패',
    successText: '캐릭터 수정 완료',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.put(SvAPI.characterUrl(params!.projectNo, params!.characterNo), params!.character).then((r) => r.data),
});
// 인물 스케줄 전체 조회
export const getActorSchedules = reducerGenerator.createThunkAction<
'getActorSchedules',
{ projectNo: number; actorNo: number; },
any
>({
  action: 'characters/getActorSchedules',
  key: 'actorSchedules',
  thunk: (params) => SvAPI.get(SvAPI.actorSchedulesUrl(params!.projectNo, params!.actorNo), {}).then((r) => r.data),
});

export const getAllActorSchedules = reducerGenerator.createThunkAction<
'getAllActorSchedules',
{ projectNo: number; params: {} },
RealResourceSchedulesInfo[]
>({
  action: 'characters/getAllActorSchedules',
  key: 'allSchedules',
  thunk: (params) => SvAPI.get(SvAPI.actorAllSchedulesUrl(params!.projectNo), params?.params).then((r) => r.data),
});



// 캐스팅 목록 조회 ????
export const getActors = reducerGenerator.createThunkAction<
'getActors',
{ projectNo: number },
CompanyActorInfo[]
>({
  action: 'characters/getActors',
  key: 'actors',
  thunk: (params) => SvAPI.get(SvAPI.actorListUrl(params!.projectNo)).then((r) => r.data),
});
// 캐스팅 후보 추가
export const addActors = reducerGenerator.createThunkAction<
'addActors',
{ projectNo: number; characterNo: number; params: {} },
any
>({
  action: 'characters/addActors',
  key: 'actors',
  thunk: (params) =>
    SvAPI.post(SvAPI.actorsUrl(params!.projectNo, params!.characterNo), params!.params).then((r) => r.data),
});
// 캐스팅 후보 삭제
export const deleteActors = reducerGenerator.createThunkAction<
'deleteActors',
{ projectNo: number; characterNo: number; params: {} },
any
>({
  action: 'characters/deleteActors',
  key: 'actors',
  thunk: (params) =>
    SvAPI.delete(SvAPI.actorsUrl(params!.projectNo, params!.characterNo), params!.params).then((r) => r.data),
});

const characterReducer = reducerGenerator.createReducer();
export default characterReducer;
