import Table from 'rc-table';
import { DataTableProps } from './DataTable.types';
import styles from './DataTable.module.scss';
import classNames from 'classnames';

export const DataTable = ({ isSpacing = false, ...props }: DataTableProps) => {
  return (
    <div>
      <Table
        {...props}
        className={classNames(styles.table, {
          [styles.table_spacing]: isSpacing,
        })}
      />
    </div>
  );
};
