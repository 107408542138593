import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { BreadcrumbInfo } from 'shared';
import { Typography } from 'components';
import styles from './Breadcrumb.module.scss';
import { AccessButton } from 'components/AccessButton/AccessButton';

type BreadcrumbProps = {
  baseUrl: string;
  breadcrumbs: BreadcrumbInfo[];
  projectNo: number;
  accessMode?: string;
};

export const Breadcrumb = ({ baseUrl, breadcrumbs, accessMode, projectNo }: BreadcrumbProps) => {
  return (
    <>
      <Navbar className={styles.root}>
        {breadcrumbs?.map((item, index) => {
          const isLast = breadcrumbs.length === index + 1;
          return (
            <React.Fragment key={index}>
              {item.url ? (
                <Link to={`${baseUrl}${item.url}`} className={styles.link}>
                  <Typography tag="span" variant="f32-166--128" className={index === 0 ? 'fw-bolder' : ''}>
                    {item.title}
                  </Typography>
                </Link>
              ) : (
                <Typography tag="span" variant="f32-166--128" className="fw-bolder">
                  {item.title}
                </Typography>
              )}
              {!isLast && (
                <Typography tag="span" variant="f32-166--128">
                  {'>'}
                </Typography>
              )}
            </React.Fragment>
          );
        })}
        {accessMode && <AccessButton projectNo={projectNo}/>}
      </Navbar>
    </>
  );
};
