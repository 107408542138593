import { Dispatch } from 'redux';
import { EmptyAction, EmptyActionCreator, PayloadAction, PayloadActionCreator } from 'typesafe-actions';


/**
 * @desc 공통 타입과 개별 액션 타입을 받아서 `${공통타입}/${개별액션타입}`반환. (ex.'schedule/getSchedules')
 */
export type MakeActionString<CommonType extends string, ActionType extends string> = `${CommonType}/${ActionType}`;

/**
 * @desc payload가 없는 액션 함수 타입
 */
export type EmptyCreator<ActionType extends string> = EmptyActionCreator<ActionType>;

/**
 * @desc payload가 있는 액션 함수 타입
 */
export type ActionCreator<ActionType extends string, R> = [R] extends [undefined] ? unknown extends R ? PayloadActionCreator<ActionType, R> : EmptyActionCreator<ActionType> : PayloadActionCreator<ActionType, R>;

/**
 * @desc 비동기 액션 함수 타입
 */
export type ThunkActionCreator<ThunkParameter, ThunkResponse> = (params?:ThunkParameter)=> (dispatch:Dispatch)=>Promise<ThunkResponse | undefined>;

/**
 * @desc 모든 액션 함수의 Union 타입 (EmptyCreator | ActionCreator | ThunkActionCreator)
 */
export type ActionCreators<ActionType extends string, Payload = any, ThunkParameter=any, ThunkResponse=any> = ActionCreator<ActionType, Payload> | EmptyCreator<ActionType> | ThunkActionCreator<ThunkParameter, ThunkResponse>;


/**
 * @desc 리듀서 payload 타입
 */
export type ReducerPayload<ActionType extends string, R = any> = R extends Object ? PayloadAction<ActionType, R> : EmptyAction<ActionType>;

/**
 * @desc 리듀서 타입
 */
export type Reducer<ActionType extends string, State, Payload = any> = (state: State, action: ReducerPayload<ActionType, Payload>) => State;

/**
 * @desc thunk 함수 타입 (Promise 반환)
 */
export type PromiseType<ThunkParameter, ThunkResponse> = (params:ThunkParameter)=>Promise<ThunkResponse>;


/**
 * @desc createEmptyAction 함수(payload가 없는 액션 함수)의 options
 */
export interface EmptyActionTemplate<ActionType extends string, State> {
  action: ActionType;
  reducer:Reducer<ActionType, State>;
}

/**
 * @desc createAction 함수(payload가 있는 액션 함수)의 options
 */
export interface ActionTemplate<ActionType extends string, State, ActionParameter> {
  action:ActionType,
  reducer: Reducer<ActionType, State, ActionParameter>;
}

/**
 * @desc createThunkAction 함수(비동기 액션 함수)의 options
 */
export interface ThunkActionTemplate<ActionType extends string, State, ThunkParameter, ThunkResponse extends Object> {
  action:ActionType,
  key?:keyof State,
  thunk?:PromiseType<ThunkParameter, ThunkResponse>;
  extraReducers?:{
    loading?:Reducer<ActionType, State>,
    success?:Reducer<ActionType, State, ThunkResponse>,
    error?:Reducer<ActionType, State, Error>
  },
  modal?:AsyncModalString
}

/**
 * @desc 비동기 상태에 따른 모달 텍스트
 */
export type AsyncModalString = {
  loadingText?:string;
  successText?:string;
  failText?:string;
  useFailRspDetail?:boolean;
};

/**
 * @desc 비동기 상태 타입
 */
export type AsyncState<Data, Error = any> = {
  loading: boolean;
  data: Data | null;
  error: Error | null;
};

/**
 * @desc 비동기 상태에 대한 객체 (initial, load, success, error)
 */
export const asyncState = {
  initial: <Data, Error = any>(initialData?: Data): AsyncState<Data, Error> => ({
    loading: false,
    data: initialData || null,
    error: null,
  }),
  load: <Data>(data?: Data)=> (data !== undefined ? {
    loading: true,
    data: data,
    error: null,
  } : {
    loading: true,
    error: null,
  }),
  success: <Data>(data: Data) => (data ? {
    loading: false,
    data: data,
    error: null,
  } : {
    loading: false,
    error: null,
  }),
  error: <Data, Error>(error: Error): AsyncState<Data, Error> => ({
    loading: false,
    data: null,
    error: error,
  }),
};
