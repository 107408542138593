import { LocationProps, PlaceProps } from 'pages/location/Location.types';
import { SiteCode } from 'shared';

export const placeListMock: Array<PlaceProps> = [
  {
    placeNo: 1,
    name: '하민이네 집 거실',
    projectNo: 1,
    placeImg: [{ imgNo: 1, placeNo: 1, imgPath: '/mock-images/location_1.jpeg' }],
  },
  {
    placeNo: 2,
    name: '서연고등학교',
    projectNo: 1,
    placeImg: [{ imgNo: 2, placeNo: 1, imgPath: '/mock-images/location_2.jpeg' }],
  },
  {
    placeNo: 3,
    name: '중앙공원',
    projectNo: 1,
    placeImg: [{ imgNo: 3, placeNo: 1, imgPath: '/mock-images/location_3.jpeg' }],
  },
  {
    placeNo: 4,
    name: '로데오 옷가게 앞',
    projectNo: 1,
    placeImg: [{ imgNo: 4, placeNo: 1, imgPath: '/mock-images/location_4.jpeg' }],
  },
  {
    placeNo: 5,
    name: '스터디 카페',
    projectNo: 1,
    placeImg: [{ imgNo: 5, placeNo: 1, imgPath: '/mock-images/location_5.jpeg' }],
  },
  {
    placeNo: 6,
    name: '강릉 해수욕장',
    projectNo: 1,
    placeImg: [{ imgNo: 6, placeNo: 1, imgPath: '/mock-images/location_6.jpeg' }],
  },
];

export const locationList: Array<LocationProps> = [
  {
    locationNo: 1,
    name: '하민이네 방',
    addr: '경기도 화성시 동탄대로4가길 6',
    contact: '',
    companyNo: 1,
    registerNo: 1,
    locationType: SiteCode.S,
    locationImg: [{ imgNo: 1, locationNo: 1, imgPath: '/mock-images/location_1.jpeg' }],
  },
  {
    locationNo: 2,
    name: '소망이네 집 거실',
    addr: '경기도 화성시 동탄대로4가길 6',
    contact: '',
    companyNo: 1,
    registerNo: 2,
    locationType: SiteCode.S,
    locationImg: [{ imgNo: 2, locationNo: 2, imgPath: '/mock-images/location_2.jpeg' }],
  },
  {
    locationNo: 3,
    name: '소망이네 방',
    addr: '',
    contact: '',
    companyNo: 1,
    registerNo: 3,
    locationType: SiteCode.S,
    locationImg: [{ imgNo: 3, locationNo: 3, imgPath: '/mock-images/location_3.jpeg' }],
  },
];
