import React from 'react';
import style from './Modal.module.scss';
import { ModalComponents, ModalTypes } from './Modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import classNames from 'classnames';

export const ModalWrapper = () => {
  const { modalList } = useAppSelector((state) => state.modal);

  const refs = React.useRef<any>([]);

  const dispatch = useAppDispatch();

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  // 모달 오버레이에서 스크롤 방지
  React.useEffect(() => {
    if (modalList.length > 0) {
      document.body.style.cssText = `overflow:hidden;`;
    }
    return () => {
      document.body.style.cssText = 'overflow:auto;';
    };
  }, [modalList.length]);

  const renderModal = modalList.map(({ type, props, overlayOptions }, i) => {
    const ModalComponent = ModalComponents[type as ModalTypes];

    const isDimmed = overlayOptions?.dim === undefined || overlayOptions?.dim === true;
    const hideBackdrop = !!overlayOptions?.hideBackdrop;

    return (
      <div
        key={i}
        className={classNames(style.modal_container, {
          [style.dimmed]: isDimmed,
          [style.hideBackdrop]: hideBackdrop,
        })}
        onClick={() => {
          if (overlayOptions?.disableOverlayClick) return;
          dispatch(closeModal());
        }}>
        <div
          className={classNames(style.modal_component, hideBackdrop && 'shadow')}
          ref={(el) => {
            refs.current[i] = el;
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <ModalComponent key={type} {...props} />
        </div>
      </div>
    );
  });

  if (modalList.length > 0) {
    return (
      <div className={style.wrapper} ref={wrapperRef}>
        {modalList.length ? renderModal : <></>}
      </div>
    );
  } else {
    // 표시할 모달이 없을 때 아무것도 표시 하지 않도록 변경
    return <></>;
  }
};
