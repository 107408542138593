import { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useLocation, matchRoutes } from 'react-router-dom';
import LeftMenu from './LeftMenu';
import Header from './Header';
import Footer from './Footer';
import style from './Frame.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'modules';
import classNames from 'classnames';
import { routeBreadcrumbs } from 'shared/navigations';
import { Breadcrumb } from 'components';
import { BreadcrumbInfo } from 'shared';
import { useAppDispatch } from 'hooks';
import { getProject } from 'modules/project/ProjectReducer';

type Props = {
  enableLeftMenu: boolean;
};

export default function Frame({ enableLeftMenu }: Partial<Props>) {
  const { projectId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { sidebarCollapsed } = useSelector((state: RootState) => state.layout);
  const match = matchRoutes(routeBreadcrumbs, location);
  const { pathname } = location;
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInfo[]>([]);
  const [accessMode, setAccessMode] = useState<string>();
  const [logoOnly, setLogoOnly] = useState<boolean>(false);

  const baseUrl = `/project/${projectId}`;

  useEffect(() => {
    const route = match?.[0]?.route;
    if (route) {
      setBreadcrumbs(route?.breadcrumb || []);
      setAccessMode(route?.accessMode);
      setLogoOnly(route?.logoOnly || false);
    }
  }, [match]);

  useEffect(() => {
    if (pathname.search('/join(/.*)?') != -1) setLogoOnly(true);
  }, [pathname]);

  useEffect(() => {
    if (projectId) {
      dispatch(getProject({ projectNo: Number(projectId) }));
    }
  }, [projectId]);

  return (
    <div>
      <Header logoOnly={logoOnly} />
      {enableLeftMenu ? (
        <>
          <LeftMenu />
          <div className={classNames(style.withSidebar, sidebarCollapsed ? style.collapsed : '')}>
            <div className={style.content}>
              <Breadcrumb baseUrl={baseUrl} breadcrumbs={breadcrumbs} projectNo={Number(projectId)} accessMode={accessMode} />
              <Outlet />
            </div>
          </div>
        </>
      ) : (
        <div className={style.withoutSidebar}>
          <div className={style.content}>
            <Outlet />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
