import { useSearchParams } from 'react-router-dom';
import { SceneQueryParamsType } from 'pages/scene/Scene';

const useQueryString = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = (key:SceneQueryParamsType)=>{
    const params = searchParams.get(key);
    return params;
  };

  const getAllParams = (key:SceneQueryParamsType)=>{
    const params = searchParams.get(key)?.split(' ');
    return params;
  };

  const setParams = (key:SceneQueryParamsType, value:string | string[])=>{
    if (typeof value === 'string') {
      searchParams.set(key, value);
    } else {
      searchParams.set(key, value.join(' '));
    }
    setSearchParams(searchParams);
  };

  const deleteParams = (key:SceneQueryParamsType)=>{
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const resetParams = (key?:SceneQueryParamsType, value?:string | string[])=>{
    if (key && value) {
      if (typeof value === 'string') {
        setSearchParams({ [key]:value });
      } else {
        setSearchParams({ [key]:value.join(' ') });
      }
    } else {
      setSearchParams({});
    }
  };

  return { searchParams, getParams, getAllParams, setParams, deleteParams, resetParams };
};

export default useQueryString;