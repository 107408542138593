
import {
  SceneCreate, SceneModify,
  SceneResource,
  SceneStatistics,
  SceneTagged,
  TaggedList,
} from 'pages/scene/Scene';


import CommonResponse from '../common/CommonResponse';
import { asyncState, AsyncState } from '../../util/ReducerTypes';
import ReducerGenerator from '../../util/ReducerGenerator';

import SvAPI from '../../util/SvAPI';


export type ScenesState = {
  modalMessage: string | null;
  scenes: AsyncState<CommonResponse<SceneResource[]>, Error>;
  sceneCreate: AsyncState<SceneCreate, Error>;
  sceneStatistics: AsyncState<SceneStatistics, Error>;
  sceneTaggeds: AsyncState<SceneTagged[], Error>;
  taggedList: AsyncState<TaggedList, Error>;
};

const initialState: ScenesState = {
  modalMessage: null,
  scenes: asyncState.initial(),
  sceneCreate: asyncState.initial(),
  sceneStatistics: asyncState.initial(),
  sceneTaggeds: asyncState.initial(),
  taggedList: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<ScenesState, 'scenes'>(initialState);

export const getScenes = reducerGenerator.createThunkAction<
'getScenes',
{ scriptNo: number, params: {} },
CommonResponse<SceneResource[]>
>({
  action: 'scenes/getScenes',
  key: 'scenes',
  thunk: (params) => SvAPI.get(SvAPI.scenesUrl(params!.scriptNo), params!.params).then((r) => r.data),

});

export const getScenesBysceneNo = reducerGenerator.createThunkAction<
'getScenesBysceneNo',
{ sceneNo: number, noPage: boolean, params: {} },
any
>({
  action: 'scenes/getScenesBysceneNo',
  key: 'scenes',
  thunk: (params) =>
    SvAPI.get(SvAPI.scenesUrl2(params!.sceneNo, params!.noPage), params!.params).then((r) => r.data),
});

export const deleteScenes = reducerGenerator.createThunkAction<
'deleteScenes',
{ scriptNo: number, sceneNos: string },
any
>({
  action: 'scenes/deleteScenes',
  key: 'scenes',
  modal: {
    loadingText: 'Scene 삭제중 ...',
    successText: 'Scene 삭제 완료',
    failText: 'Scene 삭제 실패',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.delete(SvAPI.deleteScenesUrl(params!.scriptNo, params!.sceneNos)).then((r) => r.data),
});

/**
 * 스크립트도 같이 삭제한다.
 */
export const deleteAllScenes = reducerGenerator.createThunkAction<
'deleteAllScenes',
{ scriptNo: number },
any
>({
  action: 'scenes/deleteAllScenes',
  key: 'scenes',
  modal: {
    loadingText: 'Script 삭제중 ...',
    successText: 'Script 삭제 완료',
    failText: 'Script 삭제 실패',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.delete(SvAPI.deleteScenesUrl(params!.scriptNo, '')).then((r) => r.data),
});

export const modifySceneDetail = reducerGenerator.createThunkAction<
'modifySceneDetail',
{ scriptNo: number, sceneNo: number, scene: SceneResource },
any
>({
  action: 'scenes/modifySceneDetail',
  key: 'scenes',
  modal: {
    failText: '씬 수정 실패',
    successText: '씬 수정 완료',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.put(SvAPI.sceneDetailUrl(params!.scriptNo, params!.sceneNo), params!.scene).then((r) => r.data),
});

export const modifyScene = reducerGenerator.createThunkAction<
'modifyScene',
{ scriptNo: number, sceneNo: number, scene: SceneModify },
any
>({
  action: 'scenes/modifyScene',
  key: 'scenes',
  modal: {
    failText: '씬 수정 실패',
    successText: '씬 수정 완료',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.put(SvAPI.sceneUrl(params!.scriptNo, params!.sceneNo), params!.scene).then((r) => r.data),
});

export const addScene = reducerGenerator.createThunkAction<
'addScene',
{ scriptNo: number, sceneCreate: SceneCreate },
any
>({
  action: 'scenes/addScene',
  key: 'scenes',
  modal: {
    loadingText: 'Scene 추가중 ...',
    successText: 'Scene 추가 완료',
    failText: 'Scene 추가 실패',
  },
  thunk: (params) =>
    SvAPI.post(SvAPI.scenesUrl(params!.scriptNo), params!.sceneCreate).then((r) => r.data),
});

export const copyScene = reducerGenerator.createThunkAction<
'copyScene',
{ scriptNo: number, sceneNos: number[] },
any
>({
  action: 'scenes/copyScene',
  key: 'scenes',
  modal: {
    loadingText: 'Scene 복사중 ...',
    successText: '선택한 씬이 복사되었습니다.',
    failText: 'Scene 복사 실패',
  },
  thunk: (params) =>
    SvAPI.post(SvAPI.sceneCopyUrl(params!.scriptNo), { sceneNos: params!.sceneNos }).then((r) => r.data),
});

export const divideScene = reducerGenerator.createThunkAction<
'divideScene',
{ scriptNo: number, sceneNo: number, count: number },
any
>({
  action: 'scenes/divideScene',
  key: 'scenes',
  modal: {
    loadingText: 'Scene 나누는 중 ...',
    successText: 'Scene 나누기 완료',
    failText: 'Scene 나누기 실패',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.post(SvAPI.sceneDivideUrl(params!.scriptNo, params!.sceneNo), { }, { count: params!.count }).then((r) => r.data),
});

export const getSceneStatistics = reducerGenerator.createThunkAction<
'getSceneStatistics',
{ projectNo: number },
SceneStatistics
>({
  action: 'scenes/getSceneStatistics',
  key: 'sceneStatistics',
  thunk: (params) =>
    SvAPI.get(SvAPI.sceneStatisticsUrl(params!.projectNo)).then((r) => r.data),
});

export const getSceneTaggeds = reducerGenerator.createThunkAction<
'getSceneTaggeds',
{ sceneNo: number },
SceneTagged[]
>({
  action: 'scenes/getSceneTaggeds',
  key: 'sceneTaggeds',
  thunk: (params) =>
    SvAPI.get(SvAPI.getTaggedUrl(params!.sceneNo)).then((r) => r.data),
  extraReducers: {
    loading: (state) => {
      return {
        ...state,
        sceneTaggeds: { ...state.sceneTaggeds, loading: true },
        taggedList: { ...state.taggedList, loading: true },
      };
    },
    success: (state, action) => {
      //태그 카테고리별 분류
      const temp: TaggedList = { 0: [] };

      action.payload?.forEach((val) => {
        val.taggedPositions.forEach((val2) => {
          if (!temp[val2.tagNo]) {
            temp[val2.tagNo] = [];
          }
          if (temp[val2.tagNo] && temp[val2.tagNo].findIndex((elem) => elem.taggedNo === val2.taggedNo) == -1) {
            temp[val2.tagNo].push(val2);
          }
        });
      });
      return {
        ...state,
        sceneTaggeds: { ...state.sceneTaggeds, loading: false, data: action.payload || [] },
        taggedList: { ...state.taggedList, loading: false, data: temp },
      };

    },
    error: (state, action) => {
      return {
        ...state,
        sceneTaggeds: { ...state.sceneTaggeds, loading: false, error: action.payload },
        taggedList: { ...state.taggedList, loading: false, error: action.payload },
      };
    },

  },
});

const scenes = reducerGenerator.createReducer();
export default scenes;
