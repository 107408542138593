import styles from './ScheduleModal.module.scss';
import { useAppDispatch } from '../../../hooks';
import { addScheduleGroup } from '../../../modules/schedules/scheduleReducer';
import { Modal, FormModal } from 'components/modal';
import { useForm } from 'react-hook-form';

type AddScheduleFormType = {
  name:string;
};
export type AddScheduleModalProps = {
  projectNo: number
};

const AddScheduleModal = ({ projectNo }:AddScheduleModalProps) => {

  const { register, handleSubmit, formState:{ errors } } = useForm<AddScheduleFormType>();

  const dispatch = useAppDispatch();

  const onConfirm = (data?:AddScheduleFormType)=>{
    if (!data) return;
    dispatch(addScheduleGroup({
      name:data.name,
      projectNo,
    }));
  };


  return (
    <FormModal<AddScheduleFormType> title='스케줄 추가' size='sm' handleSubmit={handleSubmit} onConfirm={onConfirm}>
      <div className={styles.add_schedule_input}>
        <Modal.Label text={'스케줄 명'} />
        <Modal.Input
          placeholder='스케줄명'
          formValidation={{
            registeredKey: 'name',
            validationObject: { required: true },
            register: register,
            fieldErros: errors.name,
            messages: [
              { type: 'required', message: '스케줄명을 입력해주세요.' },
            ],
          }}
        />
      </div>
    </FormModal>
  );
};

export default AddScheduleModal;