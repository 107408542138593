import { CharacterFormProps } from 'pages/character/Character.types';
import { CharacterScheduleFormProps } from 'pages/character/modals';
import SvAPI from 'util/SvAPI';

const findCharacter = async (projectNo: number, characterNo: number) => {
  return SvAPI.get(SvAPI.characterUrl(projectNo, characterNo), {}).then((res) => res.data);
};

const findExtraCharacters = async (projectNo: number, params: {}) => {
  return SvAPI.get(SvAPI.itemsUrl(projectNo), params).then((res) => res.data);
};

const createCharacter = async (projectNo: number, params: CharacterFormProps) => {
  return SvAPI.post(SvAPI.charactersUrl(projectNo), params).then((res) => res.data);
};

const updateCharacter = async (projectNo: number, characterNo: number, params: CharacterFormProps) => {
  return SvAPI.put(SvAPI.characterUrl(projectNo, characterNo), params).then((res) => res.data);
};

const deleteCharacter = async (projectNo: number, characterNo: number) => {
  return SvAPI.delete(SvAPI.characterUrl(projectNo, characterNo)).then((res) => res.data);
};

const updateActorScheduleMemo = async (projectNo: number, actorNo: number, params: {}) => {
  return SvAPI.put(SvAPI.actorScheduleMemoUrl(projectNo, actorNo), params).then((res) => res.data);
};

// 캐스팅
const insertCastingList = async (projectNo: number, characterNo: number, actorNos: number[]) => {
  const query = `?companyActorNos=${actorNos.join(',')}`;
  return SvAPI.post(SvAPI.actorsUrl(projectNo, characterNo) + query);
};
// 캐시팅 확정 및 취소 및 갱신
const updateCastingStatus = async (projectNo: number, characterNo: number, actorNo: number, confirm: string) => {
  const query = `?confirm=${confirm}`;
  return SvAPI.put(SvAPI.actorUrl(projectNo, characterNo, actorNo) + query, {}).then((res) => res.data);
};

const deleteCasting = async (projectNo: number, characterNo: number, actorNos: number[]) => {
  const query = `?companyActorNos=${actorNos.join(',')}`;
  return SvAPI.delete(SvAPI.actorsUrl(projectNo, characterNo) + query, { }).then((res) => res.data);
};

const findExcelDownloadUrl = (projectNo: number, actorNos: number[]) => {
  const query = `?excludeCompanyActorNos=${actorNos.join(',')}`;
  return `${SvAPI.charactersUrl(projectNo)}/actors/excel${query}`;
};

// 인물 스케줄
const createCharacterSchedule = async (projectNo: number, actorNo: number, params: CharacterScheduleFormProps) => {
  return SvAPI.post(SvAPI.actorSchedulesUrl(projectNo, actorNo), params).then((res) => res.data);
};

const updateCharacterSchedule = async (projectNo: number, actorNo: number, params: CharacterScheduleFormProps) => {
  return SvAPI.put(SvAPI.actorSchedulesUrl(projectNo, actorNo), params).then((res) => res.data);
};

export {
  findExtraCharacters,
  findCharacter,
  createCharacter,
  updateCharacter,
  deleteCharacter,
  updateCastingStatus,
  deleteCasting,
  insertCastingList,
  updateActorScheduleMemo,
  findExcelDownloadUrl,
  createCharacterSchedule,
  updateCharacterSchedule,
};
