import React from 'react';
import styles from './Checkbox.module.scss';
import checkedImg from 'public/common/checked.svg';
import classNames from 'classnames';

type CheckboxProps = {
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  className?: string;
};

export const Checkbox = ({ id, checked, onChange, style, className }: CheckboxProps) => {
  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <div
      className={classNames(styles.checkbox, className || '')}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <input type="checkbox" id={id} className={styles.show_checkbox} checked={checked} onChange={onChangeCheckBox} />
      <label
        htmlFor={id}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {checked && <img src={checkedImg} alt="checked" />}
      </label>
    </div>
  );
};
