import { HeadstripType } from 'pages/schedule/type';
import { AgeRangeType, GenderType, ItemType } from './constants';

export type YesNoType = 'Y' | 'N' | null;

export type ScheduleShareType = 'N' | 'S' | 'D' | 'C';

export interface OptionProps {
  label: string;
  value: string;
}

export interface ImageInfo {
  imgNo: number;
  imgPath: string;
}

export interface RealResourceInfo {
  resourceNo: number;
  name: string;
}

export interface BreadcrumbInfo {
  title: string;
  url?: string;
}

export interface CompanyUserInfo {
  name: string;
  thumbnail: string;
  email: string;
  job: string;
}

export interface CompanyActorInfo {
  companyActorNo: number;
  companyNo?: number;
  userNo?: number;
  nickname?: string;
  imgUrl?: string;
  registerNo?: number;
  pay?: number;
  email?: string;
  name: string;
  mobile?: string;
  birthday?: string;
  enable?: boolean;
  gender?: GenderType;
  ageRange?: AgeRangeType;
  debutTitle?: string;
  agency?: string;
  height?: number;
  weight?: number;
  filmography?: string;
  createTime?: string;
  updateTime?: string;
}

export interface ProjectInfo {
  projectNo: number;
  name: string;
  description: string;
  public: boolean;
  companyNo?: number;
  registerNo: number;
  director?: string;
  writer?: string;
  producer?: string;
  plan?: string;
  format?: 'MOVIE' | 'SERIES';
  episode?: string;
  thumbnail?: string;
  actor?: string;
  shootStart?: string;
  dday?: string;
  shootEnd?: string;
  createTime?: string;
  updateTime?: string;
}

export interface ItemInfo {
  itemNo: number;
  projectNo: number;
  tagNo: number;
  name: string;
  imgUrl: string;
  quantity: number;
  itemType: ItemType;
  managerNo: number;
  createTime: string;
  updateTime: string;
}

export type ScheduleAvailability = 'POSSIBLE' | 'IMPOSSIBLE';

export interface ScheduleDetailInfo {
  scheduleNo: number;
  projectNo: number;
  groupNo: number;
  date: string;
  share: ScheduleShareType;
  scheduleType: HeadstripType;
  contents: string;
  startTime: {
    hour: number;
    minute: number;
    second: number;
    nano: number;
  };
  unitNo: number;
  srcScheduleNo: number;
  note: string;
  createTime: string;
  updateTime: string;
}

export interface ShootingSummaryInfo {
  shootingSceneCount: number;
  shootingDay: number;
  firstShootingDate: string;
  nextShootingDate: string;
  lastShootingDate: string;
  sceneCount: number;
}

export type SortDirectionType = 'ASC' | 'DESC';

export type UserRole = 'PRODUCER' | 'ACTOR' | 'DIRECTOR' | 'WRITER';

export enum PermisionType {
  ALL = 'ALL',
  READ = 'READ',
  WRITE = 'WRITE',
  NA = 'NA',
}

export interface PermisionInfo {
  userNo: number;
  name: string;
  thumbnail: string;
  permissions: PermisionType[];
}

export interface ScheduleAvailabilityDetailInfo {
  name?: string;
  schedule: ScheduleDetailInfo;
  availability: ScheduleAvailability;
}

export interface RealResourceSchedulesInfo {
  groupNo: number;
  projectNo: number;
  realResourceSchedule: {
    resource: RealResourceInfo;
    schedules: ScheduleAvailabilityDetailInfo[];
  };
}

export enum FinchResourceType {
  LOCATION = 'LOCATION',
  PLACE = 'PLACE',
  CHARACTER = 'CHARACTER',
}
