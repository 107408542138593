const NUMBER_REGX = /^\d*$/;
const EMAIL_REGX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const validateEmail = (email: string) => {
  if (!email) return false;
  return EMAIL_REGX.test(email);
};

export const validateNumber = (value: string) => {
  if (!value) return false;
  return NUMBER_REGX.test(value);
};
