
import ScriptService, { Script, ScriptUploadItem } from './ScriptService';
import { asyncState, AsyncState } from '../../util/ReducerTypes';
import ReducerGenerator from '../../util/ReducerGenerator';

export type ScriptsState = {
  scripts: AsyncState<Script[], Error>;
};

const initialState: ScriptsState = {
  scripts: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<ScriptsState, 'script'>(initialState);


export const getScripts = reducerGenerator.createThunkAction<
'getScripts',
{ projectNo: number },
Script[]
>({
  action: 'script/getScripts',
  key: 'scripts',
  thunk: (params) => {
    const scriptService = new ScriptService();
    return scriptService.getScripts(params!.projectNo).then((res) => res);
  },
  extraReducers: {
    loading: (state) => state,
  },
});

export const addAndAnalScript = reducerGenerator.createThunkAction<
'addAndAnalScript',
{ projectNo: number, scriptUploadItem: ScriptUploadItem },
any
>({
  action: 'script/addAndAnalScript',
  key: 'scripts',
  modal: {
    loadingText: '대본 업로드 중입니다.',
    successText: '대본 업로드를 완료했습니다.',
    failText: '대본 업로드 실패했습니다.',
    useFailRspDetail: true,
  },
  thunk: (params) => {
    const scriptService = new ScriptService();
    return scriptService.addScript(params!.projectNo, params!.scriptUploadItem)
      .then((res) => scriptService.analScript(res.scriptNo)
        .then(() => scriptService.taggingAfterAnal(params!.projectNo, res.scriptNo)));
  },
});

export const addAndAnalScriptWithChatGPT = reducerGenerator.createThunkAction<
'addAndAnalScriptWithChatGPT',
{ projectNo: number, scriptUploadItem: ScriptUploadItem },
any
>({
  action: 'script/addAndAnalScriptWithChatGPT',
  key: 'scripts',
  modal: {
    loadingText: '대본 업로드 중입니다.',
    successText: '대본 업로드를 완료했습니다.',
    failText: '대본 업로드 실패했습니다.',
    useFailRspDetail: true,
  },
  thunk: (params) => {
    const scriptService = new ScriptService();
    return scriptService.addScript(params!.projectNo, params!.scriptUploadItem)
      .then((res) => scriptService.analScriptWithChatGPT(res.scriptNo)
        .then(() => scriptService.taggingAfterAnal(params!.projectNo, res.scriptNo)));
  },
});

const scripts = reducerGenerator.createReducer();
export default scripts;
