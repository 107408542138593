import { ActorFormProps } from 'pages/character/modals';
import SvAPI from 'util/SvAPI';

const findActors = async (companyNo: number, params: { [key: string]: string }) => {
  return SvAPI.get(SvAPI.companyActorsUrl(companyNo), params).then((res) => res.data);
};

const createActor = async (companyNo: number, params: ActorFormProps) => {
  return SvAPI.post(SvAPI.companyActorsUrl(companyNo), params).then((res) => res.data);
};

export { findActors, createActor };
