import { INDEX_SUCCESS_ACTION, INDEX_FAIL_ACTION, INDEX_INIT_ACTION, IndexInitAction } from './IndexAction';
import { createReducer } from 'typesafe-actions';
import Company from '../company/Company.types';
import Project from '../project/Project';
import ErrorResponse from '../common/ErrorResponse';

export interface IndexState {
  companies: Array<Company>;
  projects: Array<Project>;
  error?: ErrorResponse;
}


export const indexReducer = createReducer<IndexState, IndexInitAction>({ companies : [], projects : [] }, {
  [INDEX_INIT_ACTION]: (statue) => {
    return statue;
  },
  [INDEX_SUCCESS_ACTION]: (status, action) => {
    return {
      ...status,
      companies: action.payload.company,
      projects: action.payload.project,
    };
  },
  [INDEX_FAIL_ACTION]: (status, action) => {
    const error = action.payload;
    const errorResponse = error.response?.data;
    return { ...status, error: errorResponse };
  },
});
