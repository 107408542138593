import ReducerGenerator from '../../util/ReducerGenerator';
import { AsyncState } from '../../util/ReducerTypes';
import Project from './Project';
import ProjectModService, { CreateProject } from './ProjectModService';

export interface ProjectModState {
  complete: AsyncState<number>;
}
const initState: ProjectModState = {
  complete: {
    loading: false,
    data: null,
    error: null,
  },
};
const projectMod = new ReducerGenerator<ProjectModState, 'projectMod'>(initState);

export const createProject = projectMod.createThunkAction<'createProject', CreateProject, number>({
  action: 'projectMod/createProject',
  key: 'complete',
  thunk: (param: CreateProject) => new ProjectModService().addProject(param),
  extraReducers: {
    success: (s, a) => {
      return { ...s, complete: { loading: false, data: a.payload, error: null } };
    },
  },
});

interface UpdateProjectParam {
  project: Project;
  updateProject: CreateProject;
}

export const updateProject = projectMod.createThunkAction<'updateProject', UpdateProjectParam, number>({
  action: 'projectMod/updateProject',
  key: 'complete',
  thunk: ({ project, updateProject: updateParam }: UpdateProjectParam) =>
    new ProjectModService().updateProject(project, updateParam).then(p => p.projectNo),
  extraReducers: {
    success: (s, a) => {
      return { ...s, complete: { loading: false, data: a.payload, error: null } };
    },
  },
});

export const clearProjectMod = projectMod.createEmptyAction<
'reset'
>({
  action: 'projectMod/reset',
  reducer: () => initState,
});

export const projectModReducer = projectMod.createReducer();
