import { Address } from 'react-daum-postcode/lib/loadPostcode';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';

export type PostCodeModalProps = {
  onComplete: (address: string) => void;
};

export const PostCodeModal = ({ onComplete }: PostCodeModalProps) => {
  const dispatch = useAppDispatch();
  const handleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    onComplete(fullAddress);
    dispatch(closeModal());
  };

  return (
    <div>
      <DaumPostcodeEmbed onComplete={handleComplete} />
    </div>
  );
};
