export const SCENE_FILTER_PARAMS_KEY = [
  'proceed', 
  'playPlaces', 
  'characters', 
  'site', 
  'timeslot', 
] as const;

export const SCENE_PARAMS_KEY = [
  'scriptNo', 
  'query', 
  'proceed', 
  'playPlaces', 
  'characters', 
  'site', 
  'timeslot', 
] as const;

export const proceedState = {
  '예정' : 'BEFILMED',
  '미촬영' : 'NOTFILMED',
  '재촬영' : 'REFILMED',
  '완료' : 'COMPLETE',
  '오밋' : 'OMIT',
};