import React from 'react';
import styles from './Modal.module.scss';
import { Modal } from './ModalComponents';
import { ModalSizeType } from './type';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { FieldValues, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
export type FormModalProps<T extends FieldValues> = {
  title:string;
  subTitle?:string;
  titleIconButtonConfig?:{ src:string, onClick:()=>void };
  children: React.ReactNode;
  size:ModalSizeType;
  buttonGroupPosition?:'center' | 'right';
  confirmBtnText?: string;
  cancelBtnText?: string;
  keepOpen?:boolean;
  style?:React.CSSProperties;
  hideCancel?: boolean;
  handleSubmit?:UseFormHandleSubmit<T>;
  onConfirm:(data?:T)=>void;
  onCancel?:()=>void;
};

export const FormModal = <T extends FieldValues>({
  title,
  subTitle,
  titleIconButtonConfig,
  children,
  size,
  confirmBtnText,
  cancelBtnText,
  buttonGroupPosition,
  keepOpen = false,
  style,
  hideCancel = false,
  handleSubmit,
  onConfirm,
  onCancel,
}:FormModalProps<T>) => {

  const dispatch = useAppDispatch();

  const onSubmit:SubmitHandler<T> = (data:T)=>{
    if (onConfirm) onConfirm(data);
    if (!keepOpen) dispatch(closeModal());
  };

  const buttonGroupStyles = buttonGroupPosition ? (buttonGroupPosition === 'right' ? styles.btns_right : '') : '';

  return (
    <Modal.Container size={size} className={`form_${size}`} style={style}>
      <Modal.Title title={title} size={size === 'sm' ? 'small' : size === 'md' ? 'medium' : 'large'} iconButtonConfig={titleIconButtonConfig} />
      {subTitle && <Modal.SubTitle title={subTitle} size={'medium'}/>}
      <form className={styles.form_modal__content} onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (handleSubmit) {
          handleSubmit(onSubmit);
        } else {
          onConfirm();
        }
      }}>
        {children}
        <div className={`${styles.button_group} ${buttonGroupStyles}`}>
          {!hideCancel &&
            <Modal.Button text={cancelBtnText ? cancelBtnText : '취소'} buttonType="cancel" onClick={onCancel} />
          }
          <Modal.Button type='submit' text={confirmBtnText ? confirmBtnText : '확인'} buttonType="confirm" onClick={handleSubmit ? handleSubmit(onSubmit) : onConfirm} keepOpen={handleSubmit ? true : keepOpen}/>
        </div>
      </form>
    </Modal.Container>
  );
};
