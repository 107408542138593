import * as React from 'react';
import styles from './SceneDivideCountInputModal.module.scss';
import { Modal } from 'components/modal';
import { useAppDispatch } from '../../../hooks';
import { divideScene } from '../../../modules/scenes/SceneReducer';
import useGetScenes from '../../../hooks/scene/useGetScenes';

export type SceneDivideCountInputModalProps = {
  scriptNo: number;
  sceneNo: number;
};

export default function SceneDivideCountInputModal({ scriptNo, sceneNo }: SceneDivideCountInputModalProps) {
  const [inputValue, setInputValue] = React.useState<string>('');
  const dispatch = useAppDispatch();
  const { getSceneList } = useGetScenes();

  const onDivide = (count: number) => {
    dispatch(divideScene({ scriptNo: scriptNo, sceneNo: sceneNo, count: count })).then(() => {
      getSceneList(scriptNo);
    });
  };
  return (
    <Modal.Container size="sm" style={{ borderRadius: `var(--f4)` }}>
      <Modal.Label text="개수 입력" />
      <Modal.Input getValue={setInputValue} />
      <div className={styles.buttons}>
        <Modal.Button
          text="취소"
          style={{ background: 'none', color: `var(--gray-50)`, padding: `var(--f10)`, fontWeight: 500 }}
        />
        <Modal.Button
          text="확인"
          style={{ background: 'none', color: `var(--default-text)`, padding: `var(--f10)`, fontWeight: 500 }}
          onClick={() => onDivide(parseInt(inputValue))}
        />
      </div>
    </Modal.Container>
  );
}
