import { useSelector, useDispatch } from 'react-redux';
import { Avatar } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { SvgIcon, SvgIconSource, Typography } from 'components';
import { navigations } from 'shared/navigations';
import { RootState } from 'modules';
import { toggleSideBar } from 'modules/layout/LayoutReducer';
import { fileUrl } from 'util/fileHelper';

import style from './LeftMenu.module.scss';
import fakeImage from 'public/fake/fake_image.svg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { UserRoleName } from 'shared';
import { openModal } from 'modules/modal/modalReducer';
import { ModalTypes } from 'components/modal';
import { managerNavigations } from '../../shared/managerNavigations';

type MenuProps = {
  isManagerPage?: boolean;
};
const LeftMenu = ({ isManagerPage }: MenuProps) => {
  const dispatch = useAppDispatch();
  const { sidebarCollapsed } = useSelector((state: RootState) => state.layout);
  const { project } = useSelector((state: RootState) => state.project);
  const { profile } = useAppSelector((state: RootState) => state.user);
  // const sidebarCollapsed = false;
  const dispath = useDispatch();
  const { projectId, companyId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleToggleSideMenu = () => {
    dispath(toggleSideBar({ collapsed: !sidebarCollapsed }));
  };

  const handleClickedMenu = (item: any, isManager: boolean) => {
    if (item.uid === 'alarm' || isManager && item.uid !== 'company' && item.uid !== 'project') {
      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: { text: '준비중인 기능입니다.', autoClose: true, onConfirm: () => {} },
        })
      );
      return;
    }

    if (item.type === 'link') {
      if (isManager) {
        if (item.uid === 'company') {
          navigate(`/manager${item.link}`);
        } else {
          navigate(`/manager/company/${companyId}${item.link}`);
        }
      } else {
        navigate(`/project/${projectId}${item.link}`);
      }
      return;
    }

  };

  const drawNavigationMenu = () => {
    const navis = isManagerPage ? managerNavigations : navigations;
    return navis.map((item, index) => (
      <li
        key={index}
        className={classNames({
          [style.menuItem]: item.type !== 'title',
          [style.activedMenu]: pathname.includes(item.uid),
        })}
        onClick={() => handleClickedMenu(item, isManagerPage ? isManagerPage : false)}>
        {item.icon && <SvgIcon source={SvgIconSource[item.icon]} />}
        {(!sidebarCollapsed || item.type === 'title') && (
          <Typography
            className={classNames(
              'text-nowrap',
              item.type === 'title' ? 'text-blue-gray-400 fw-medium' : 'fw-bolder'
            )}>
            {item.label}
          </Typography>
        )}
      </li>
    ));
  };

  return (
    <div id={style.left_menu} className={sidebarCollapsed ? style.collapsed : ''}>
      <div>
        <div
          className={classNames(
            style.companyWrapper,
            'd-flex align-items-center border-bottom',
            sidebarCollapsed ? 'flex-column gap-y-2 px-3 py-5' : 'flex-row gap-x-3 px-5 py-8'
          )}>
          <Avatar
            src={project?.data?.thumbnail ? fileUrl(project?.data?.thumbnail) : fakeImage}
            variant="rounded"
            className={style.companyLogo}
            alt="Project"
          />
          <div>
            {!sidebarCollapsed && (
              <Typography variant="none" className="fs-3.5 fw-bold text-nowrap">
                {project?.data?.name || ''}
              </Typography>
            )}
            <Typography variant="f12-166--48" className={style.role}>
              {profile?.data?.job ? UserRoleName[profile?.data?.job] : ''}
            </Typography>
          </div>
        </div>
        <div className={style.menuContent}>
          <ul className={style.menuList}>
            {drawNavigationMenu()}
          </ul>
        </div>
      </div>
      <div
        className={classNames(style.collapseBtn, sidebarCollapsed ? 'justify-content-center' : 'justify-content-end')}>
        <Button variant="white" className="p-4 border-0" onClick={handleToggleSideMenu}>
          <SvgIcon
            source={sidebarCollapsed ? SvgIconSource.ArrowRight : SvgIconSource.ArrowLeft}
            color="primary"
            size={20}
          />
        </Button>
      </div>
    </div>
  );
};

export default LeftMenu;
