import { ProjectFormProps } from 'pages/project/Project.types';
import { TeamFormProps } from 'pages/staff/Staff.types';
import SvAPI from 'util/SvAPI';

//  프로젝트
const findProject = async (projectNo: number) => {
  return SvAPI.get(SvAPI.projectUrl(projectNo), {}).then((res) => res.data);
};

const createProject = async (data: ProjectFormProps) => {
  return SvAPI.post(SvAPI.projectBaseUrl(), data).then((res) => res.data);
};

const deleteProject = async (projectNo: number) => {
  return SvAPI.delete(SvAPI.projectUrl(projectNo), {}).then((res) => res.data);
};

// 스태프 - 팀
const findGroups = async (projectNo: number) => {
  return SvAPI.get(SvAPI.projectGroupListUrl(projectNo), {}).then((res) => res.data);
};

const createGroup = async (projectNo: number, params: TeamFormProps) => {
  return SvAPI.post(SvAPI.projectGroupBaseUrl(projectNo), params).then((res) => res.data);
};

const deleteGroup = async (projectNo: number, groupNo: number) => {
  return SvAPI.delete(SvAPI.projectGroupUrl(projectNo, groupNo), {}).then((res) => res.data);
};

export { findProject, createProject, findGroups, createGroup, deleteGroup, deleteProject };
