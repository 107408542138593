import { ShootingTableDetail } from 'pages/callsheet/type';
import { ScheduleGroup, ScheduleList } from 'pages/schedule/type';
import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import SvAPI from 'util/SvAPI';
import { findDailySheets } from './CallSheetServices';

type ScheduleSceneListType = { scheduleNo:number, scheduleList:ScheduleList[] };

export type CallSheetState = {
  dailyShoot:AsyncState<ShootingTableDetail, Error>;
  dailyShoots:AsyncState<ScheduleGroup, Error>;
  scheduleSceneList:AsyncState<ScheduleSceneListType, Error>;
};

const initialState:CallSheetState = {
  dailyShoot:asyncState.initial(),
  dailyShoots:asyncState.initial(),
  scheduleSceneList:{ loading:false, data:{ scheduleNo:-1, scheduleList:[] }, error:null },
};

const reducerGenerator = new ReducerGenerator<CallSheetState, 'callsheet'>(initialState);

export const getDetailDailyShoot = reducerGenerator.createThunkAction<'getDetailDailyShoot', { projectNo:number, scheduleNo:number }, ShootingTableDetail>({
  action:'callsheet/getDetailDailyShoot',
  key:'dailyShoot',
  thunk:(params)=>SvAPI.get(SvAPI.shootingTableUrl(params!.projectNo, params!.scheduleNo)).then(res=>res.data),
});

export const getDailyShoots = reducerGenerator.createThunkAction<'getDailyShoots', { projectNo:number }, ScheduleGroup>({
  action:'callsheet/getDailyShoots',
  key:'dailyShoots',
  thunk:(params)=> findDailySheets(params.projectNo),
});

export const getScheduleScenes = reducerGenerator.createThunkAction<'getScheduleScenes', { projectNo:number, groupNo:number, scheduleNo:number }, ScheduleSceneListType>({
  action:'callsheet/getScheduleScenes',
  key:'scheduleSceneList',
  thunk:(params)=>SvAPI.get(SvAPI.scheduleListUrl(params!.projectNo, params!.groupNo, params!.scheduleNo)).then(res=>{return { scheduleNo:params.scheduleNo, scheduleList:res.data };}),
});

export const deleteSchedule = reducerGenerator.createThunkAction<
'deleteSchedule',
{ projectNo: number; groupNo: number; scheduleNo: number; },
any
>({
  action: 'callsheet/deleteSchedule',
  key: 'dailyShoots',
  thunk: (params) =>
    SvAPI.delete(SvAPI.scheduleStripUrl(params!.projectNo, params!.groupNo), {
      scheduleNos: `${params!.scheduleNo}`,
    }),
});

export const resetScheduleScenes = reducerGenerator.createEmptyAction<'resetScheduleScenes'>({
  action:'callsheet/resetScheduleScenes',
  reducer:(state)=>{return { ...state, scheduleSceneList:{ ...state.scheduleSceneList, data:null } };},
});

export const confirmCallsheet = reducerGenerator.createThunkAction<'confirmCallsheet', { projectNo:number, scheduleNo:number, confirm: boolean }, any>({
  action:'callsheet/confirmCallsheet',
  modal: {
    failText: '콜시트 확정 실패',
    useFailRspDetail: true,
  },
  thunk:(params)=>SvAPI.put(SvAPI.confirmCallsheetUrl(params.projectNo, params.scheduleNo), { confirm:params.confirm }),
});
const callsheetReducer = reducerGenerator.createReducer();


export default callsheetReducer;
