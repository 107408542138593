
export const AREA = {
  SCHEDULE: 'schedule',
  SCENE: 'scene',
  PLACE:'place',
};

export const SCHEDULE_SCENE_FILTER_PARAMS_KEY = [
  'episode',
  'proceed', 
  'playPlaces', 
  'characters', 
  'site', 
  'timeslot', 
] as const;