import { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource, FileButton } from 'components';
import FileService from 'modules/file/FileService';
import { LocationFormProps, LocationProps } from 'pages/location/Location.types';
import { fileName } from 'util/fileHelper';
import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { Modal, ModalTypes } from 'components/modal';
import { createLocation, updateLocation } from 'modules/locations/LocationService';
import { RootState } from 'modules';

export interface LocationInfoModalProps {
  data?: LocationProps;
  onDelete?: () => void;
  onConfirm?: (locationNo: number) => void;
}

const defaultFormData = {
  name: '',
  imgUrl: '',
  contact: '',
  addr: '',
};

export const LocationInfoModal = ({ data, onConfirm }: LocationInfoModalProps) => {
  const dispatch = useAppDispatch();
  const { project } = useAppSelector((state: RootState) => state.project);
  const [info, setInfo] = useState<LocationFormProps>(defaultFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (data) {
      setInfo({
        name: data?.name || '',
        imgUrl: data?.locationImg?.[0]?.imgPath || '',
        contact: data?.contact || '',
        addr: data?.addr || '',
      });
    } else {
      setInfo(defaultFormData);
    }
  }, [data]);

  const handleClose = () => {
    dispatch(closeModal(ModalTypes.LocationInfo));
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;

    setErrors({ ...errors, imgUrl: '' });
    new FileService().updateImage(files[0], undefined, false).then((value: string) => {
      setInfo({ ...info, imgUrl: value });
    });
  };

  const handleDelteImage = () => {
    setInfo({ ...info, imgUrl: '' });
  };

  const handleChangeInfo = (key: string, value: string) => {
    setErrors({ ...errors, [key]: '' });
    setInfo({ ...info, [key]: value });
  };

  const handleSave = async () => {
    if (!project?.data?.companyNo) return;

    let error = {};
    if (!info?.name) error = { name: '촬영지명을 입력해주세요.' };
    // if (!info?.imgUrl) error = { ...error, imgUrl: '이미지 파일을 업로드 해주세요.' };

    if (Object.keys(error).length) {
      setErrors(error);
      return;
    }

    let result;
    if (data?.locationNo) {
      result = await updateLocation(data?.locationNo, info);
    } else {
      result = await createLocation(project.data.companyNo, info);
    }

    if (result?.locationNo) {
      onConfirm?.(result.locationNo);
      handleClose();
    }
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          기본 정보
        </Typography>
      </div>
      <div className="text-left">
        <InputGroup className="input-sm no-split no-border">
          <Form.Control className="bg-light" readOnly value={fileName(info?.imgUrl || '')} placeholder="이미지" />
          <InputGroup.Text className="bg-light">
            <Button
              variant="gray"
              className="d-flex align-items-center justify-content-center bs-20px p-0 rounded-circle"
              onClick={handleDelteImage}>
              <SvgIcon source={SvgIconSource.Close} size={16} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <FileButton variant="dark" className="w-100 h-8 mt-3 mb-6" accept="image/*" onFileChange={handleChangeImage}>
          <Typography variant="f12-166--48">이미지 파일 업로드</Typography>
        </FileButton>

        <div className="d-flex flex-column gap-y-4">
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              촬영지 명
            </Typography>
            <InputGroup className="input-sm no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="촬영지 명 입력"
                isInvalid={!!errors.name}
                value={info?.name}
                onChange={(e) => handleChangeInfo('name', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              연락처
            </Typography>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="연락처 입력"
                value={info?.contact || ''}
                onChange={(e) => handleChangeInfo('contact', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              주소
            </Typography>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="주소 입력"
                value={info?.addr || ''}
                onChange={(e) => handleChangeInfo('addr', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="items-center gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleSave}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
};
