import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'components/modal';
import { SvgIcon, SvgIconSource } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { Calendar } from 'components';
import moment from 'moment';
import { EventApi } from '@fullcalendar/common';
import { getAllActorSchedules } from 'modules/characters/CharacterReducer';
import { getAllLocationShedules } from 'modules/locations/locationReducer';
import { RootState } from 'modules';
import { formatDateString } from 'util/common';
import { ScheduleAvailabilityDetailInfo } from 'shared';

export type ScheduleCalendarModalProps = {
  projectNo: number;
  onConfirm: () => void;
};

export default function ScheduleCalendarModal({ projectNo }: ScheduleCalendarModalProps) {
  const dispatch = useAppDispatch();
  const { allSchedules: actorSchedules } = useAppSelector((state: RootState) => state.character);
  const { allSchedules: locationSchedules } = useAppSelector((state: RootState) => state.locations);
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  React.useEffect(() => {
    dispatch(getAllActorSchedules({ projectNo, params: { month: formatDateString(selectedDate, 'YYYY-MM') } }));
    dispatch(getAllLocationShedules({ projectNo, params: { month: formatDateString(selectedDate, 'YYYY-MM') } }));
  }, [projectNo, selectedDate]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleClick = (event: EventApi) => {
    console.log(event);
  };

  const handleChangeDate = (date?: Date) => {
    setSelectedDate(date || new Date());
  };

  const shoots = React.useMemo(() => {
    const list1 =
      actorSchedules?.data?.reduce((arr, s) => {
        const name = s.realResourceSchedule.resource?.name;
        const schList = s.realResourceSchedule?.schedules.map((sh) => ({ ...sh, name }));
        return [...arr, ...schList];
      }, [] as ScheduleAvailabilityDetailInfo[]) || [];
    const list2 =
      locationSchedules?.data?.reduce((arr, s) => {
        const name = s.realResourceSchedule.resource?.name;
        const schList = s.realResourceSchedule?.schedules.map((sh) => ({ ...sh, name }));
        return [...arr, ...schList];
      }, [] as ScheduleAvailabilityDetailInfo[]) || [];

    return [...list1, ...list2].map((sch) => ({
      title: `${sch.name} ${sch.availability === 'IMPOSSIBLE' ? `불가능` : '가능'}`,
      content: sch.schedule?.contents,
      start: moment(sch.schedule.date).format('YYYY-MM-DD'),
      extendedProps: sch,
      color: sch.availability === 'IMPOSSIBLE' ? '#E5615D' : '#3AC8B4',
    }));
  }, [actorSchedules, locationSchedules]);

  return (
    <Modal.Container size="wd" style={{ borderRadius: `var(--f4)`, padding: 0 }}>
      <div className="pt-8 px-8 pb-3 d-flex justify-content-end">
        <Button variant="white" className="p-1" onClick={handleClose}>
          <SvgIcon source={SvgIconSource.Close} />
        </Button>
      </div>
      <div className="px-16 pb-12">
        <Calendar eventList={shoots || []} onClick={handleClick} onChangeDate={handleChangeDate} />
      </div>
    </Modal.Container>
  );
}
