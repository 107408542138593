import { Badge } from 'components';
import * as React from 'react';
import { DropDown, ProgressDropdownMenu } from 'components/dropdown';
import { ProceedCode } from '../Scene';

type Props = {
  badgeType: string;
  badgeText: string;
  onSelected: (value: ProceedCode) => void;
  style?: React.CSSProperties;
};

export default function BadgeButtonCell({ style, badgeType, badgeText, onSelected }: Props) {
  return (
    <DropDown element={<ProgressDropdownMenu onSelected={onSelected} />} style={style}>
      <Badge type={badgeType}>{badgeText}</Badge>
    </DropDown>
  );
}
