import { UserRole } from 'shared';
import { getPercentage } from '../../util/common';

export interface User {
  userNo: number;
  email: string;
  job: string;
  name: string;
  birthday: Date;
  mobile: string;
  thumbnail?: string;
  enable: boolean;
  createTime: Date;
  updateTime: Date;
}

export interface UserProfile {
  name: string
  email: string
  job: UserRole
  thumbnail: string
}

export interface QuotaUsage {
  use: number
  quota: Quota
}

export interface Quota {
  type: string
  quota: number
}

export const defaultQuotaUsage: QuotaUsage = {
  use: 0,
  quota: {
    type: 'AI_TOKEN_ONE_DAY',
    quota: 0,
  },
};

export class AiResourceQuota {
  use: number = 0;

  quota: number = 0;

  percent: number = 0;

  constructor(use: number, quota: number, percent: number) {
    this.use = use;
    this.quota = quota;
    this.percent = percent;
  }

  static fromQuotaUsage(quotaUsage: QuotaUsage[] | null): AiResourceQuota {
    if (!quotaUsage) return new AiResourceQuota(0, 0, 0);
    const aiResourceUsage = quotaUsage.find((usage) => usage.quota.type === 'AI_TOKEN_ONE_DAY') || defaultQuotaUsage;
    const percent = getPercentage(aiResourceUsage.use, aiResourceUsage.quota.quota);
    return new AiResourceQuota(aiResourceUsage.use, aiResourceUsage.quota.quota, percent);
  }
}


