/* eslint-disable @typescript-eslint/indent */
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { Strip } from 'pages/scene/Scene';

export const GET_STRIP = 'strips/GET_STRIP';
export const GET_STRIP_SUCCESS = 'strips/GET_STRIP_SUCCESS';
export const GET_STRIP_ERROR = 'strips/GET_STRIP_ERROR';

export const GET_STRIPS = 'strips/GET_STRIPS';
export const GET_STRIPS_SUCCESS = 'strips/GET_STRIPS_SUCCESS';
export const GET_STRIPS_ERROR = 'strips/GET_STRIPS_ERROR';

export const getStripAsync = createAsyncAction(GET_STRIP, GET_STRIP_SUCCESS, GET_STRIP_ERROR)<any, Strip, AxiosError>();

export const getStripsAsync = createAsyncAction(GET_STRIPS, GET_STRIPS_SUCCESS, GET_STRIPS_ERROR)<
  any,
  Strip[],
  AxiosError
>();

const actions = { getStripAsync, getStripsAsync };
export type StripAsyncAction = ActionType<typeof actions>;
