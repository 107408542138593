import { asyncState, AsyncState } from 'util/ReducerTypes';
import ReducerGenerator from 'util/ReducerGenerator';
import { FileInfo } from 'pages/file/File.types';
import { findAllFiles, findFolders, findRecentFiles } from './FileService';

export type FileState = {
  folders: AsyncState<FileInfo[], Error>;
  files: AsyncState<FileInfo[], Error>;
  recents: AsyncState<FileInfo[], Error>;
};

const initialState: FileState = {
  folders: asyncState.initial(),
  files: asyncState.initial(),
  recents: asyncState.initial(),
};
const reducerGenerator = new ReducerGenerator<FileState, 'file'>(initialState);

// 폴더 조회
export const getFolders = reducerGenerator.createThunkAction<
'getFolders',
{ companyNo: number; params: {}; path?: string },
FileInfo[]
>({
  action: 'file/getFolders',
  key: 'folders',
  thunk: (params) => findFolders(params.companyNo, params.params, params.path),
});

// 전체 파일 조회
export const getFiles = reducerGenerator.createThunkAction<
'getFiles',
{ companyNo: number; params: {}; path?: string },
FileInfo[]
>({
  action: 'file/getFiles',
  key: 'files',
  thunk: (params) => findAllFiles(params.companyNo, params.params, params.path),
});

// 최근 파일 조회
export const getRecentFiles = reducerGenerator.createThunkAction<'getRecentFiles', { companyNo: number, projectNo?: number }, FileInfo[]>({
  action: 'file/getRecentFiles',
  key: 'recents',
  thunk: (params) => findRecentFiles(params.companyNo, params.projectNo),
});

const fileReducer = reducerGenerator.createReducer();
export default fileReducer;
