import SvAPI, { serverUrl } from 'util/SvAPI';
import { post } from '../common/HttpCaller';
import { FinchResourceType } from 'shared';

export interface ImageCrop {
  width?: number;
  height?: number;
  x?: number;
  y?: number;
}

const setNumberToFromData = (data: FormData, name: string, value: number | undefined) => {
  if (value) {
    data.append(name, value.toString());
  }
};

export default class FileService {
  /**
   * 이미지 업로드
   * @param file 업로드할 파일
   * @returns 파일 경로
   */
  updateImage = async (file: File, crop?: ImageCrop, returnFullUrl = true, session = '') => {
    const data = new FormData();
    data.append('image', file);
    if (crop) {
      const { width, height, x, y } = crop;
      setNumberToFromData(data, 'width', width);
      setNumberToFromData(data, 'height', height);
      setNumberToFromData(data, 'x', x);
      setNumberToFromData(data, 'y', y);
    }

    const url = session ? `/join/${session}/image` : '/file/image';
    const filePath: string = await post(`${serverUrl}${url}`, { body: data });

    if (returnFullUrl) {
      return `${window.location.origin}/upload/image/${filePath}`;
    }

    return filePath;
  };
}

const updateImageSize = async (type: FinchResourceType, resourceNo: number, imageNo: number, params: ImageCrop) => {
  return SvAPI.put(SvAPI.resizeImageUrl(type, resourceNo, imageNo), params).then((res) => res.data);
};

const findFolders = async (companyNo: number, params: {}, path?: string) => {
  return SvAPI.get(SvAPI.companyFoldersUrl(companyNo, path), params).then((res) => res.data);
};

const findRecentFiles = async (companyNo: number, projectNo?: number) => {
  const query = `?projectNo=${projectNo || ''}`;
  return SvAPI.get(SvAPI.companyRecentFilesUrl(companyNo) + query, {}).then((res) => res.data);
};

const findAllFiles = async (companyNo: number, params: {}, path?: string) => {
  return SvAPI.get(SvAPI.companyAllFilesUrl(companyNo, path), params).then((res) => res.data);
};

const createFolder = async (companyNo: number, projectNo: number, path: string) => {
  const query = `?projectNo=${projectNo}`;
  return SvAPI.post(SvAPI.companyFoldersUrl(companyNo, path) + query, {}).then((res) => res.data);
};

const createFile = async (companyNo: number, projectNo: number, path: string, data: FormData) => {
  const query = `?projectNo=${projectNo}`;
  return SvAPI.post(SvAPI.companyFilesUrl(companyNo, path) + query, data);
};

const deleteFile = async (companyNo: number, projectNo: number, path: string) => {
  const query = `?projectNo=${projectNo}`;
  return SvAPI.delete(SvAPI.companyFilesUrl(companyNo, path) + query, {}).then((res) => res.data);
};

const fileDownloadUrl = (companyNo: number, projectNo: number, path: string) => {
  const query = `?projectNo=${projectNo}`;
  return SvAPI.companyFileDownloadUrl(companyNo, path) + query;
};

export {
  findFolders,
  findRecentFiles,
  findAllFiles,
  createFolder,
  createFile,
  deleteFile,
  fileDownloadUrl,
  updateImageSize,
};
