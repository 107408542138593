import { useState } from 'react';
import { Modal } from 'components/modal';
import { LabelInput } from 'components';
import { Container, Row } from 'react-bootstrap';
import * as React from 'react';
import styles from './LLMSchedulingModal.module.scss';
import { LabelDropdown } from '../../../components/dropdown';
import { TextValue } from '../../scene/Scene';
import { getScripts } from '../../../modules/scripts/ScriptReducer';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { LabelBadgeListInput } from '../../../components/Form/LabelBadgeListInput';
import { SubmitHandler, useForm } from 'react-hook-form';

export type LLMSchedulingModalProps = {
  projectNo: number;
  groupNo: number;
  onConfirm: (form: AiSchedulingForm) => void;
};

export type AiSchedulingForm = {
  scriptNos: number[];
  startDate: string;
  userPrompt: string;
};

export default function LLMSchedulingModal({ projectNo, onConfirm }: LLMSchedulingModalProps) {
  const dispatch = useAppDispatch();
  const [aiSchedulingForm, setAiSchedulingForm] = React.useState<AiSchedulingForm>({
    scriptNos: [],
    startDate: '',
    userPrompt: '',
  });
  const [selectedEps, setSelectedEps] = useState<TextValue[]>([]);
  const { scripts } = useSelector((state: RootState) => state.scripts);
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<AiSchedulingForm>();

  React.useEffect(() => {
    dispatch(getScripts({ projectNo: projectNo }));
  }, [projectNo]);

  const onSubmit: SubmitHandler<AiSchedulingForm> = (data) => {
    let hasError = false;
    if (!selectedEps?.length) {
      hasError = true;
      setError('scriptNos', { message: 'Episode를 선택해 주세요.' });
    }

    if (hasError) return;

    onConfirm({
      scriptNos: selectedEps.map((item) => item.value),
      startDate: data.startDate,
      userPrompt: data.userPrompt,
    });
  };

  return (
    <Modal.Container size="wd" style={{ padding: `var(--f45)` }}>
      <Container className={styles.container}>
        <Row>
          <Modal.Title title="AI 스케쥴링"/>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)} className="text-left">
          <Row className={styles.form}>
            <Row>
              <LabelDropdown
                label="EP"
                content="EP를 선택해주세요."
                labelStyle={{ color: `var(--gray-50)` }}
                totalWidth={6}
                onSelected={(textValue?: TextValue) => {
                  if (selectedEps.find((item) => item.value === textValue?.value)) return;
                  setSelectedEps([...selectedEps, textValue!]);
                }}
                items={
                  scripts.data
                    ? scripts.data.map((item) => {
                      return { value: item.scriptNo, text: item.episode };
                    })
                    : []
                }
              />
            </Row>
            <LabelBadgeListInput
              label={'선택된 EP'}
              values={selectedEps}
              error={errors.scriptNos?.message}
              onDelete={(value: TextValue) => {
                setSelectedEps(selectedEps.filter((item) => item.text !== value.text));
              }}
              onChange={() => {
                console.log('onchaged');
                clearErrors('scriptNos');
              }}
            />
            <LabelInput
              label={'스케쥴 시작일'}
              formValidation={{
                registeredKey: 'startDate',
                validationObject: { required: true, pattern: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/ },
                register: register,
                fieldErros: errors.startDate,
                messages: [
                  { type: 'required', message: '스케쥴 시작 날짜를 입력해주세요.' },
                  { type: 'pattern', message: '날짜 형식 YYYY-MM-DD 형식만 가능합니다.' },
                ],
              }}
              placeholder={'2024-01-01'}
              onChange={(event: any) => {
                setAiSchedulingForm({
                  ...aiSchedulingForm,
                  startDate: event.target.value,
                });
              }}
            />
            <LabelInput
              label={'요청'}
              placeholder={'예) 극중 장소를 기준 으로 효율적인 촬영 순서를 나열해줘'}
              formValidation={{
                registeredKey: 'userPrompt',
                validationObject: { required: true },
                register: register,
                fieldErros: errors.userPrompt,
                messages: [
                  { type: 'required', message: '요청 내용을 입력해 주세요' },
                ],
              }}
              onChange={(event: any) => {
                setAiSchedulingForm({
                  ...aiSchedulingForm,
                  userPrompt: event.target.value,
                });
              }}
            />
          </Row>
        </form>
        <div className="items-center justify-content-center gap-4 pt-8">
          <Modal.Button text={'취소'} buttonType="cancel"/>
          <Modal.Button text={'확인'} buttonType="confirm" keepOpen onClick={handleSubmit(onSubmit)}/>
        </div>
      </Container>
    </Modal.Container>
  );
}
