import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { Typography } from 'components';
import { AvatarGroupProps } from './AvatarGroup.types';
import styles from './AvatarGroup.module.scss';

export const AvatarGroup = ({ avatars = [], maxCount = 3, size = 'md', className = '', onClick }: AvatarGroupProps) => {
  const shows = avatars?.slice(0, maxCount)?.reverse();
  const activedIdx = shows?.length - 1;
  const remainCount = avatars?.length - shows?.length;

  const rootStyle = classNames('pointer', {
    [styles.smRoot]: size === 'sm',
    [styles.mdRoot]: size === 'md',
    [styles.lgRoot]: size === 'lg',
  });

  const avatarStyle = classNames('bg-white border border-white', {
    [styles.sm]: size === 'sm',
    [styles.md]: size === 'md',
    [styles.lg]: size === 'lg',
  });

  return (
    <div className={classNames(rootStyle, className || '')} onClick={() => onClick && onClick()}>
      <div className="d-flex align-items-center flex-row-reverse w-fit">
        <div className='ms-1 text-blue-gray-300'>
          <Typography tag="span" variant="f16-166--64">
            +{remainCount}
          </Typography>
        </div>
        {shows?.map((avatar, index) => {
          return (
            <Avatar
              key={index}
              src={avatar}
              classes={{
                root: avatarStyle,
                img: index == activedIdx ? '' : 'opacity-50',
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
