import style from './Modal.module.scss';
import { Modal } from './ModalComponents';
import { ModalButtonProps } from './type';

export type AlertModalProps = {
  text: string;
  onConfirm?: () => void;
  buttonProps?:Omit<ModalButtonProps, 'text'>;
};

export default function AlertModal({ text, onConfirm, buttonProps }: AlertModalProps) {
  return (
    <Modal.Container size='md' className='alert_container'>
      <span className={style.modal_text}>{text}</span>
      <div className={style.single_button_group}>
        <Modal.Button text={'확인'} buttonType="confirm" onClick={onConfirm} {...buttonProps} />
      </div>
    </Modal.Container>
  );
}
