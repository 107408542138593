import { put } from './../common/HttpCaller';
import { SceneModify } from 'pages/scene/Scene';
import SvAPI, { serverUrl } from '../../util/SvAPI';

export interface SceneUpdateParam {
  sceneNo: number;
  scene: Partial<SceneModify>;
}

export interface CharacterReplaceInfo {
  sceneNo: number;
  characterNo: number;
  newCharacterNo?: number;
}

export interface PlaceReplaceInfo {
  sceneNo: number;
  placeNo: number;
  newPlaceNo?: number;
  bigName?: string;
}

export default class SceneService {
  updateScene = (sceneNo: number, scene: Partial<SceneModify>) => {
    return put(`${serverUrl}/scene/${sceneNo}`, { param: scene });
  };
}

const parseScriptCharacter = async (scriptNo: number) => {
  return SvAPI.get(SvAPI.scriptCharactersUrl(scriptNo)).then((res) => res.data);
};

const parseScriptPlaces = async (scriptNo: number) => {
  return SvAPI.get(SvAPI.scriptPlacesUrl(scriptNo)).then((res) => res.data);
};

const replaceScriptCharacters = async (scriptNo: number, data: CharacterReplaceInfo[]) => {
  return SvAPI.put(SvAPI.scriptCharactersUrl(scriptNo), { replaceInfo: data }).then((res) => res.data);
};

const scriptResourceModify = async (scriptNo: number, state: boolean) => {
  return SvAPI.put(SvAPI.scriptResourceModifyUrl(scriptNo), undefined, { state }).then((res) => res.data);
};

const replaceScriptPlaces = async (scriptNo: number, data: PlaceReplaceInfo[]) => {
  return SvAPI.put(SvAPI.scriptPlacesUrl(scriptNo), { replaceInfo: data }).then((res) => res.data);
};

const deleteScenesAndScripts = async (scriptNo: number, sceneNos: number[]) => {
  const strNos = sceneNos?.length ? sceneNos.join(',') : '';
  return SvAPI.delete(SvAPI.deleteScenesUrl(scriptNo, strNos));
};

export {
  parseScriptCharacter,
  parseScriptPlaces,
  replaceScriptCharacters,
  replaceScriptPlaces,
  deleteScenesAndScripts,
  scriptResourceModify,
};
