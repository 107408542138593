import React from 'react';
import styles from './ScheduleModal.module.scss';
import { Modal, FormModal } from 'components/modal';
import { addSchedules, setEventKey } from '../../../modules/schedules/scheduleReducer';
import { AddScheduleRequest } from '../type';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import useInput from '../../../hooks/useInput';
import { useForm } from 'react-hook-form';
import { showToastMessage } from 'hooks/useToastNotification';

type BannerFormType = {
  round:string;
  content:string;
};

export type BannerModalProps = {
  projectNo:number,
  groupNo:number,
  round?:number,
  scheduleNo?:number;
};

export default function BannerModal({ projectNo, groupNo, round, scheduleNo }:BannerModalProps) {
  const { control, register, handleSubmit, formState:{ errors } } = useForm<BannerFormType>();

  const dispatch = useAppDispatch();
  const { scheduleLists, eventKeyList } = useSelector((state: RootState) => state.schedule);


  const [scNo, setScNo] = React.useState<number>(scheduleNo ? scheduleNo : -1);
  const [rounds, setRounds] = React.useState<{ round:string, scheduleNo:number }[]>([]);
  const [describe, setDescribe] = React.useState('');

  const [duration, onChangeDuration] = useInput('');

  React.useEffect(()=>{
    const roundList:{ round:string, scheduleNo:number }[] = scheduleLists.filter((schedule)=>schedule.scheduleType === 'SCHEDULE').map((schedule)=> {
      return {
        round:`${schedule.round + 1}`,
        scheduleNo:schedule.scheduleNo };
    });

    setRounds(roundList);
  }, []);

  const onSaveBanner = (data?:BannerFormType) => {
    if (!data) return;
    if (!scNo) return;

    const scItem:AddScheduleRequest = {
      scheduleNo:scNo,
      targets:[
        {
          describe: data.content,
          duration: parseInt(duration) * 60,
          order:0,
        },
      ],
    };
    const isIncludeScNo = eventKeyList.some((v)=>{
      if (scheduleNo) {
        return v === `${scheduleNo}`;
      } else {
        return v === `${scNo}`;
      }
    });
    if (!isIncludeScNo) dispatch(setEventKey({ eventKey:`${scheduleNo ? scheduleNo : scNo}` }));
    dispatch(addSchedules( { projectNo, groupNo, scheduleItem:scItem } )).then(() => {
      showToastMessage('새로운 배너가 추가되었습니다.');
    });
  };

  return (
      <FormModal
          title='배너 추가하기'
          size='sm'
          onConfirm={onSaveBanner}
          handleSubmit={handleSubmit}>
        <div className={styles.banner_content}>
          <Modal.Label text={'촬영회차'} />
          <Modal.DropdownInput<BannerFormType>
            initialValue={round ? `${round}` : undefined}
            placeholder={'회차선택'}
            showArrow
            controllerConfig={{ control, name:'round' }}
            formValidation={{
              registeredKey: 'round',
              validationObject: { required: true },
              register: register,
              fieldErros: errors.round,
              messages: [
                { type: 'required', message: '회차를 선택해주세요.' },
              ],
            }}
            dropdownItems = {rounds.map((r)=>{return { text:r.round, id:r.scheduleNo };})}
            onClickListItem={(item)=>{if (item)setScNo(item.id as number);}}
          />
          <Modal.Label text={'예상 소요 시간'} />
          <div className={styles.duration_input}>
            <input className={styles.duration} placeholder='0' type='number' min='0' onChange={onChangeDuration}/> <span>분</span>
          </div>
          <Modal.TextArea
            placeholder='내용을 입력하세요.'
            value={describe}
            getValue={setDescribe}
            controllerConfig={{ control, name:'content' }}
            formValidation={{
              registeredKey: 'content',
              validationObject: { required: true },
              register: register,
              fieldErros: errors.content,
              messages: [
                { type: 'required', message: '내용을 입력해주세요.' },
              ],
            }}
            />
        </div>
      </FormModal>
  );
}
