import { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage, LoadingSpinner } from 'components';

const withLazyComponent = (importFunc: () => Promise<{ default: React.ComponentType }>) => {
  const Component = lazy(importFunc);
  return (props: any) => (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Suspense fallback={<LoadingSpinner />}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default withLazyComponent;
