import { useAppDispatch } from '..';
import useQueryString from './useQueryString';
import { SCENE_FILTER_PARAMS_KEY } from 'shared/sceneConfig';
import { FilterListType, SceneResource } from 'pages/scene/Scene';
import { getFilteredInfos } from 'util/sceneHelper';
import { getScenes } from 'modules/scenes/SceneReducer';

const useGetScenes = ()=>{

  const dispatch = useAppDispatch();
  const { searchParams, getAllParams } = useQueryString();

  const getSceneList = (scriptNo:number, proceed?:string)=>{

    const params:{ [key:string]:boolean | string } = {
      noPage: true,
    };

    const query = searchParams.get('query');

    if (proceed)params.proceed = proceed;
    if (query) params.searchStr = query;

    dispatch(getScenes({ scriptNo, params }));
  };

  const filterSceneList = (scenes:SceneResource[]):SceneResource[]=>{
    const filters:FilterListType = {
      episode: [],
      bigPlayPlaces: [],
      playPlaces:[],
      characters:[],
      site:[],
      timeslot:[],
      proceed:[],
    };
    SCENE_FILTER_PARAMS_KEY.forEach((filterKey)=>{
      const params = getAllParams(filterKey);
      if (params) filters[filterKey] = params;
    });
    return getFilteredInfos(scenes, filters).filteredScenes;
  };

  return { getSceneList, filterSceneList };
};

export default useGetScenes;
