import { useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Typography } from 'components';
import { useState } from 'react';
import { formatDateString } from 'util/common';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { Modal, ModalTypes } from 'components/modal';
import { CharacterScheduleInfo } from '../Character.types';
import { createCharacterSchedule, updateCharacterSchedule } from 'modules/characters/CharacterService';
import { ScheduleAvailability } from 'shared';
import { deleteSchedules } from 'modules/schedules/ScheduleServices';
import { toDateString } from 'util/Times';

export interface CharacterScheduleFormProps {
  status: ScheduleAvailability;
  contents?: string;
  startDt: string;
  endDt?: string;
}

export interface CharacterScheduleModalProps {
  projectNo: number;
  actorNo: number;
  selectedSchedule?: CharacterScheduleInfo;
  selectedDate?: string;
  onChange?: () => void;
}

export const CharacterScheduleModal = ({
  projectNo,
  actorNo,
  selectedSchedule,
  selectedDate,
  onChange,
}: CharacterScheduleModalProps) => {
  const dispatch = useAppDispatch();
  const [formdata, setFormdata] = useState<CharacterScheduleFormProps>({
    startDt: '',
    status: 'IMPOSSIBLE',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (selectedSchedule) {
      setFormdata({
        status: selectedSchedule.availability,
        contents: selectedSchedule.schedule.contents || '',
        startDt: selectedSchedule.schedule.date,
        endDt: selectedSchedule.schedule.date,
      });
    } else if (selectedDate) {
      setFormdata({
        status: 'IMPOSSIBLE',
        contents: '',
        startDt: selectedDate,
        endDt: '',
      });
    }
  }, [selectedDate, selectedSchedule]);

  const handleClose = () => {
    dispatch(closeModal(ModalTypes.CharacterSchedulePopup));
  };

  const handleChangeInfo = (key: string, value: string) => {
    setFormdata({ ...formdata, [key]: value });
  };

  const handleDelete = async () => {
    if (selectedSchedule) {
      const { groupNo, scheduleNo } = selectedSchedule?.schedule;
      if (projectNo && groupNo && scheduleNo) {
        await deleteSchedules(projectNo, groupNo, [scheduleNo]);
        onChange?.();
      }
    }

    handleClose();
  };

  const handleSave = async () => {
    if (!formdata.contents) {
      setErrors({ contents: '내용을 입력해주세요.' });
      return;
    }

    const data = { ...formdata, startDt: toDateString(new Date(formdata.startDt)) };
    
    
    if (selectedSchedule) {
      await updateCharacterSchedule(projectNo, actorNo, data);
    } else {
      await createCharacterSchedule(projectNo, actorNo, data);
    }

    onChange?.();
    handleClose();
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f14-166--56" className="fw-bold">
          스케줄 {selectedSchedule ? '수정하기' : '추가하기'}
        </Typography>
      </div>
      <div className="text-left">
        <div>
          <Typography variant="f12-166--48" className="fw-bold">
            촬영 일자
          </Typography>
          <InputGroup className="input-sm no-border mt-1">
            <Form.Control
              className="bg-light"
              placeholder="촬영 일자"
              value={formatDateString(formdata?.startDt, '년월일')}
              readOnly
            />
          </InputGroup>
          <InputGroup className="input-sm no-border mt-1">
            <Form.Control
              as="textarea"
              rows={8}
              className="bg-light"
              placeholder="내용을 입력해주세요."
              isInvalid={!!errors.contents}
              value={formdata?.contents || ''}
              onChange={(e) => handleChangeInfo('contents', e.target.value)}
            />
          </InputGroup>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5">
          <Typography variant="f12-166--48">스케줄 불가능</Typography>
          <Form.Check
            checked={formdata.status === 'IMPOSSIBLE'}
            onChange={() => handleChangeInfo('status', 'IMPOSSIBLE')}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5">
          <Typography variant="f12-166--48">스케줄 가능</Typography>
          <Form.Check
            checked={formdata?.status === 'POSSIBLE'}
            onChange={() => handleChangeInfo('status', 'POSSIBLE')}
          />
        </div>
      </div>
      <div className="items-center gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleDelete}>
          {selectedSchedule ? '삭제' : '취소'}
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleSave}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
};
