import SvAPI from '../../util/SvAPI';

const findDailySheets = async (projectNo: number) => {
  return SvAPI.get(SvAPI.shootingTablesUrl(projectNo)).then(res=>res.data);
};

const updateCallSheetConfirm = async (projectNo: number, scheduleNo: number, confirm: boolean) => {
  return SvAPI.put(SvAPI.confirmCallsheetUrl(projectNo, scheduleNo), { confirm });
};

export { findDailySheets, updateCallSheetConfirm };
