import { Col } from 'react-bootstrap';
import styles from './LabelInput.module.scss';
import * as React from 'react';
import Select from 'react-select';

const selectStyles = {
  control: (baseStyles: any) => ({
    ...baseStyles,
    border: '0!important',
    outline: 0,
    backgroundColor: '#f9fafb',
    fontSize: 14,
    minHeight: 42,
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: 12,
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    maxHeight: 200,
  }),
};

export interface LabelSelectProps {
  label: string;
  placeholder?: string;
  labelPos?: 'left' | 'top';
  style?: React.CSSProperties;
  totalWidth?: LengthRatio;
  labelWidth?: LengthRatio;

  name?: string;
  isMultiple?: boolean;
  value?: any;
  error?: string;
  options?: any[];
  getLabel?: (option: any) => string;
  getValue?: (option: any) => string;
  renderOption?: (option: any) => React.ReactElement;
  onChange?: (newValue: any) => void;
}

const getMainClassNames = (labelPos?: string) => {
  if (labelPos === 'top') {
    return '';
  } else {
    return 'row flex-nowrap';
  }
};

export const LabelSelect = ({
  label,
  placeholder,
  labelPos,
  totalWidth = 12,
  labelWidth = 2,

  name,
  isMultiple,
  options,
  value,
  error,
  getLabel,
  getValue,
  renderOption,
  onChange,
}: LabelSelectProps) => {
  const content = (
    <>
      <Col
        sm={labelPos === 'top' ? totalWidth : labelWidth}
        className={labelPos === 'top' ? styles.label_top_box : styles.label_left_box}>
        <label className={labelPos === 'top' ? styles.label_top : styles.label}>{label}</label>
      </Col>
      <Col className={styles.input_container} sm={labelPos === 'top' ? totalWidth : totalWidth - labelWidth}>
        <Select
          name={name}
          styles={selectStyles}
          placeholder={placeholder || ''}
          isSearchable
          isMulti={isMultiple}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          options={options}
          formatOptionLabel={renderOption}
          value={value}
          onChange={onChange}
        />
        <div className={styles.error_message_container}>
          {error && <span className={styles.error_message}>{error}</span>}
        </div>
      </Col>
    </>
  );

  return (
    <>{totalWidth && totalWidth !== 12 ? content : <div className={getMainClassNames(labelPos)}>{content}</div>}</>
  );
};
