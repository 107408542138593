import React, { useRef } from 'react';
import styles from './Modal.module.scss';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { ImageCrop } from 'modules/file/FileService';
import { Modal } from './ModalComponents';
import 'cropperjs/dist/cropper.css';

export type ImageCropModalProps = {
  src:string;
  ratio?:number;
  onCropImage:(croppedInfo:ImageCrop)=>void;
};

const ImageCropModal = ({ src, ratio, onCropImage }:ImageCropModalProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const onCrop = ()=>{
    const cropData = cropperRef.current?.cropper.getData();
    if (!cropData) {
      onCropImage({ x:0, y:0 });
      return;
    }
    const { x, y, width, height } = cropData;
    onCropImage({ x:Math.round(x), y:Math.round(y), width:Math.round(width), height:Math.round(height) });
  };

  return (
    <div className={styles.modal} style={{  padding:'10px', background:'white' }}>
      <div style={{ width:'500px' }}>
        <Cropper 
          ref={cropperRef} 
          style={{ width:'100%', height:'300px' }}
          viewMode={2}
          src={src}
          aspectRatio={ratio}
        />
      </div>
      <Modal.Button text='잘라내기' onClick={onCrop} style={{ marginTop:`var(--f10)` }}/>
    </div>
  );
};

export default ImageCropModal;
