import moment, { Moment } from 'moment';
import { TextValue } from 'pages/scene/Scene';

const toHHMM = (secs:number) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  //const seconds = secNum % 60;

  return [hours, minutes]
    .map((val) => (val < 10 ? `0${val}` : val))
  //.filter((val, index) => val !== "00" || index > 0)
    .join(':');
};

const toDateString = (date:Date):string => {
  return moment(date).format('YYYY-MM-DD[T]HH:mm:ss');
};

const formatDateToString = (date:Date, form:string):string=>{
  return moment(date).format(form);
};

const makeTimeList = () => {
  // 10분 간격으로 시간 리스트 생성
  const timeList: TextValue[] = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 6; j++) {
      const time = `${i < 10 ? '0' + i : i}시간 ${j === 0 ? '00' : j * 10}분`;
      timeList.push({ text: time, value: i * 3600 + j * 60 * 10 });
    }
  }
  return timeList;
};

const readableTime = (start: Moment, end: Moment) => {
  const diff = end.diff(start);
  const diffMinutes = moment.duration(diff).asMinutes();

  let formattedDiff;
  if (diffMinutes < 60) {
    formattedDiff = Math.floor(diffMinutes) + ' 분';
  } else {
    let hours = Math.floor(diffMinutes / 60);
    let minutes = Math.floor(diffMinutes % 60);
    formattedDiff = hours + ' 시간 ' + (minutes != 0 ? minutes + ' 분' : '');
  }
  return formattedDiff;
};

export { toHHMM, toDateString, formatDateToString, makeTimeList, readableTime };
