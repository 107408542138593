/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import styles from './Modal.module.scss';
import { Avatar } from '@mui/material';

import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { SearchListValue } from './type';

import profile from 'public/119674.jpg';
import checked from 'public/common/checked.svg';

export type SearchListModalProps = {
  children?: React.ReactNode;
  title: string;
  disabled?: boolean;
  items: SearchListValue[];
  description?: string;
  hideShadow?: boolean;
  showItemImage?: boolean;
  loading?: boolean;
  checkbox?: boolean;
  setChecked?: (item: SearchListValue) => boolean;
  getCheckedItem?: (isChecked: boolean, checkedItem: SearchListValue) => void;
  size: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
};
export const SearchListModal = ({
  children,
  items,
  title,
  hideShadow,
  description,
  showItemImage = false,
  loading = false,
  checkbox,
  setChecked,
  getCheckedItem,
  size,
  style,
}: SearchListModalProps) => {
  const [keyword, setKeyword] = React.useState('');

  const filteredItems = React.useMemo(() => {
    if (!keyword || !items?.length) return items || [];
    return items.filter((item) => item.text.includes(keyword || ''));
  }, [items, keyword]);

  React.useEffect(() => {
    setKeyword('');
  }, [items]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: SearchListValue) => {
    if (getCheckedItem) getCheckedItem(e.currentTarget.checked, item);
  };

  const showProfileImage = (item: SearchListValue) => {
    if (showItemImage) {
      if (item.imgPath) {
        return <Avatar src={item.imgPath} sx={{ width: 24, height: 24 }} />;
      } else {
        return <Avatar src={profile} sx={{ width: 24, height: 24 }} />;
      }
    }
  };

  const getItems = () => {
    if (filteredItems === null) return;
    if (!loading && filteredItems.length === 0) {
      return <div className={styles.no_list}>목록 없음</div>;
    }
    return filteredItems.map((item, index) => {
      return (
        <div className={styles.search_list_item} key={index} onClick={item.event}>
          <div className={styles.profile_and_text}>
            {showProfileImage(item)}
            <p className={styles.search_list_item_text} style={item.style}>
              {item.text}
            </p>
          </div>
          {checkbox && (
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                id={`cb-${title}-${item.text}`}
                checked={setChecked && setChecked(item)}
                onChange={(e) => onInputChange(e, item)}
              />
              <label htmlFor={`cb-${title}-${item.text}`}>
                <img src={checked} alt="checked" />
              </label>
            </div>
          )}
        </div>
      );
    });
  };

  const modalSize =
    size === 'small'
      ? styles.search_list_small
      : size === 'medium'
      ? styles.search_list_medium
      : styles.search_list_large;

  return (
    <div className={`${styles.search_list_modal} ${hideShadow ? styles.hide_shadow : ''} ${modalSize}`} style={style}>
      <div className="border-bottom">
        <input
          className="form-control bg-blue-gray-100 border-0 fs-3 fw-medium"
          placeholder={`${title ? title : '검색'}`}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      <span className={`${styles.search_list_description} ${description ? '' : styles.hidden}`}>{description}</span>
      {loading && <LoadingSpinner />}
      {children ? children : <div className={styles.search_list_items}>{getItems()}</div>}
    </div>
  );
};
