import classNames from 'classnames';
import styles from './Modal.module.scss';
import { Typography } from 'components/Typography';
import { ProgressBar } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { Modal } from './ModalComponents';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

export type FileDownloadModalProps = {
  url: string,
};

type FileInfo = {
  data: Blob,
  filename: string,
};

type DownloadState = {
  started: boolean,
  total: number,
  curr: number,
  complete: boolean,
  file?: FileInfo,
};

const DEFAULT_STATE: DownloadState = {
  started : false,
  total : 0,
  curr : 0,
  complete : false,
};

const download = (url: string): DownloadState => {
  const [downloadState, setDownloadState] = useState<DownloadState>(DEFAULT_STATE);

  if (!downloadState.started && !downloadState.complete) {
    axios.get(
      url,
      {
        responseType: 'blob',
        onDownloadProgress: (event) => {
          setDownloadState({
            ...downloadState,
            started: true,
            total: event.total,
            curr: event.loaded,
          });
        },
      }
    ).then(res => {
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const disposition = res.headers['content-disposition'] || '';
      const filename = disposition.substring(disposition.indexOf('"') + 1, disposition.lastIndexOf('"'));
      const decodedFileName = decodeURIComponent(filename);
      setDownloadState({
        ...downloadState,
        complete: true,
        file: {
          data : blob,
          filename: decodedFileName,
        },
      });
    });
  }

  return downloadState;
};

export default function FileDownloadModal({ url }: FileDownloadModalProps) {
  const { total, curr, complete, file } = download(url);

  const dispatch = useAppDispatch();

  const saveFile = () => {
    if (file) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file.data);
      link.download = file.filename;
      link.click();
      URL.revokeObjectURL(link.href);
      dispatch(closeModal());
    }
  };

  return (
    <Modal.Container size={'md'}>
      <div className={classNames('px-9 pt-5 pb-9')}>
        <div className='py-2'>
          <Typography variant='f18-normal--72' className='fw-bold'>
            파일을 다운로드 받는 중입니다.
          </Typography>
        </div>

        {(total == 0 && complete === false) && (
        <>
          <LoadingSpinner />
        </>
        )}
        {total > 0 && (
        <>
          <div className='pt-6 pb-3'>
            <ProgressBar now={curr * 100 / total}/>
          </div>
          <Typography variant="f14-normal--56" className="text-blue-gray-300">
            {`${curr} / ${total} (${curr * 100 / total}%)`}
          </Typography>
        </>)}
        {complete && (
            <>
            <div className={`${styles.button_group}`}>
              <Modal.Button text='취소' buttonType="cancel" />
              <Modal.Button type='submit' text='다운로드' buttonType='confirm' onClick={saveFile} keepOpen={false} />
            </div>
            </>
        )}
      </div>
    </Modal.Container>
  );
}
