import nodata from './nodata.svg';
import style from './EmptyListView.module.scss';

export interface NoDataListProps {
  title?: string;
  subTitle?: string;
  className?: string;
}

export const EmptyListView = ({ title, subTitle, className = '' }: NoDataListProps) => {
  return (
    <div className={className}>
      <div className={style.frame}>
        <img src={nodata} alt="no schedule" />
        <div>
          {title && <h3>{title}</h3>}
          {subTitle && <small>{subTitle}</small>}
        </div>
      </div>
    </div>
  );
};
