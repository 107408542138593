import { createReducer } from 'typesafe-actions';
import { Strip } from 'pages/scene/Scene';
import {
  GET_STRIPS,
  GET_STRIPS_ERROR,
  GET_STRIPS_SUCCESS,
  // GET_STRIP,
  // GET_STRIP_ERROR,
  // GET_STRIP_SUCCESS,
  StripAsyncAction,
} from './actions';

export type StripAsyncState<T, E = any> = {
  action: string | null;
  loading: boolean;
  data: T | null;
  error: E | null;
};

export type StripsState = {
  strips: StripAsyncState<Strip[], Error>;
};

const initialState: StripsState = {
  strips: {
    action: null,
    loading: false,
    data: [],
    error: null,
  },
};

const strips = createReducer<StripsState, StripAsyncAction>(initialState, {
  [GET_STRIPS]: (state) => {
    return { ...state, strips: { ...state.strips, loading: true, action: GET_STRIPS } };
  },
  [GET_STRIPS_SUCCESS]: (state, action) => {
    return {
      ...state,
      strips: {
        ...state.strips,
        loading: false,
        data: action.payload,
        action: GET_STRIPS_SUCCESS,
      },
    };
  },
  [GET_STRIPS_ERROR]: (state, action) => {
    return { ...state, strips: { ...state.strips, loading: false, error: action.payload, action: GET_STRIPS_ERROR } };
  },
});

export default strips;
