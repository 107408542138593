import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { FormModal } from 'components/modal';
import style from './ProjectModal.module.scss';
import { useForm } from 'react-hook-form';
import FileService, { ImageCrop } from '../../modules/file/FileService';
import { openModal } from '../../modules/modal/modalReducer';
import { ModalTypes } from 'components/modal';
import Project from '../../modules/project/Project';
import { useAppDispatch } from '../../hooks';
import { ImageComp, Typography } from 'components';
import { Form } from 'react-bootstrap';
import { ProjectFormProps, ProjectFormat } from './Project.types';
import { fileUrl } from 'util/fileHelper';

export interface CreateProjectModalProp {
  project?: Project;
  onConfirm?: (data: ProjectFormProps) => void;
}

export default function CreateProjectModal({ project, onConfirm }: CreateProjectModalProp) {
  const fileRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [thumbnail, setThumbnail] = useState<string>(project?.thumbnail || '');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ProjectFormProps>();

  const isEdit = !!project?.projectNo;

  useEffect(() => {
    if (isEdit) {
      setValue('name', project.name || '');
      setValue('description', project.description || '');
      setValue('format', project.format ? (project.format as ProjectFormat) : undefined);
      setValue('plan', project.plan || '');
      setValue('episode', project.episode || '');
    }
  }, [isEdit]);

  const reloadImage = async (f: File, croppedInfo: ImageCrop) => {
    const filePath = await new FileService().updateImage(f, croppedInfo, false);
    setThumbnail(filePath);
  };

  const uploadImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const f = e.target.files?.item(0);
    if (f) {
      const filePath = await new FileService().updateImage(f, undefined, false);
      if (!filePath) return;

      dispatch(
        openModal({
          type: ModalTypes.ImageCrop,
          props: {
            src: fileUrl(filePath),
            onCropImage: (croppedInfo: ImageCrop) => {
              reloadImage(f, croppedInfo);
            },
          },
          overlayOptions: { dim: true },
        })
      );
      setThumbnail(filePath);
    }
  };

  const resetForm = () => {
    reset();
    setThumbnail('');
  };

  const save = (data?: ProjectFormProps) => {

    if (data == undefined) return;

    const formdata: ProjectFormProps = {
      ...data,
      thumbnail: thumbnail || undefined,
    };

    resetForm();
    onConfirm?.(formdata);


  };

  const resetImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setThumbnail('');
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  return (
    <FormModal
      size="lg"
      title={isEdit ? '프로젝트 수정' : '프로젝트 생성'}
      subTitle={isEdit ? '' : '새로운 프로젝트 페이지를 생성합니다.'}
      onConfirm={save}
      handleSubmit={handleSubmit}
      onCancel={resetForm}
      style={{ padding: `var(--f43)` }}>
      <div className="center mt-4">
        <input hidden ref={fileRef} type="file" accept=".jpg,.jpeg,.png" onChange={uploadImage} />
        <div
          className="items-center justify-content-center"
          onClick={() => {
            fileRef?.current?.click();
          }}>
            <ImageComp src={thumbnail} wrapperClass='rounded' position='center' width={224} height={120}/>
        </div>
      </div>
      <div className="center mt-2">
        <button className={style.del_file} onClick={resetImage}>
          삭제하기
        </button>
      </div>
      <div className="items-center flex-column mt-8 gap-4 text-left">
        <div className="w-80">
          <Typography variant="f12-166--48" className="fw-bolder text-blue-gray-300">
            프로젝트 명
          </Typography>
          <Form.Control
            className="input-gray"
            isInvalid={!!errors.name?.message}
            {...register('name', {
              required: { value: true, message: '프로젝트 명을 입력해주세요.' },
              pattern: { value: /^.{1,50}$/, message: '최대 50자까지 입력 가능합니다.' },
            })}
          />
          {errors.name && (
            <Typography variant="f12-166--48" className="text-danger">
              {errors.name?.message}
            </Typography>
          )}
        </div>
        <div className="w-80">
          <Typography variant="f12-166--48" className="fw-bolder text-blue-gray-300">
            프로젝트 설명
          </Typography>
          <Form.Control
            className="input-gray"
            as="textarea"
            rows={5}
            placeholder="프로젝트 설명을 입력해주세요."
            isInvalid={!!errors.description?.message}
            {...register('description', {
              pattern: { value: /.{0,200}$/, message: '최대 200자까지 입력 가능합니다.' },
            })}
          />
          {errors.description && (
            <Typography variant="f12-166--48" className="text-danger">
              {errors.description?.message}
            </Typography>
          )}
        </div>
        <div className="w-80">
          <Typography variant="f12-166--48" className="fw-bolder text-blue-gray-300">
            포맷
          </Typography>
          <Form.Select
            className="input-gray"
            isInvalid={!!errors.format?.message}
            {...register('format', {
              required: { value: true, message: '프로젝트 포맷을 선택해주세요.' },
            })}>
            <option value="">포맷 선택</option>
            <option value={ProjectFormat.MOVIE}>영화</option>
            <option value={ProjectFormat.SERIES}>드라마</option>
          </Form.Select>
          {errors.format && (
            <Typography variant="f12-166--48" className="text-danger">
              {errors.format?.message}
            </Typography>
          )}
        </div>
        <div className="w-80">
          <Typography variant="f12-166--48" className="fw-bolder text-blue-gray-300">
            EP/RT
          </Typography>
          <div className="items-start gap-3">
            <div className="flex-1">
              <Form.Control
                className="input-gray"
                isInvalid={!!errors.episode?.message}
                placeholder="EP 갯수 입력"
                {...register('episode', {
                  required: { value: true, message: 'episode 갯수를 입력해 주세요.' },
                  pattern: { value: /^[0-9]{1,9}$/, message: '숫자만 입력 가능합니다.' },
                })}
              />
              {errors.episode && (
                <Typography variant="f12-166--48" className="text-danger">
                  {errors.episode?.message}
                </Typography>
              )}
            </div>
            <div className="flex-1">
              <Form.Control
                className="input-gray"
                isInvalid={!!errors.plan?.message}
                placeholder="RT 입력"
                {...register('plan', {
                  pattern: { value: /^[0-9]{1,9}$/, message: '숫자만 입력 가능합니다.' },
                })}
              />
              {errors.plan && (
                <Typography variant="f12-166--48" className="text-danger">
                  {errors.plan?.message}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </FormModal>
  );
}
