import { Button } from 'react-bootstrap';
import styles from './BigNumberButton.module.scss';

import caret_left from 'public/common/caret_left.svg';
import caret_right_white from 'public/common/caret_right_white.svg';

type BigNumberButtonProps = {
  onClick?: () => void;
  text: string;
  unit?: string;
  count?: number;
  active?: boolean;
  render?: () => string | JSX.Element;
  showCaret?: boolean;
};

export const BigNumberButton = ({
  onClick,
  text,
  count,
  unit,
  active,
  render,
  showCaret = true,
}: BigNumberButtonProps) => {
  return (
    <Button id={active ? styles.button_active : styles.button} onClick={onClick}>
      <div className={styles.frame}>
        <div className={styles.sub_frame}>
          <div className={active ? styles.desc_active : styles.desc}>{text}</div>
          <div className={active ? styles.count_active : styles.count}>{render ? render() : `${count} ${unit}`}</div>
        </div>
        {showCaret && (
          <div>
            {active ? (
              <img alt="image" src={caret_right_white} className={styles.icon_active} />
            ) : (
              <img src={caret_left} alt={'caret'} className={styles.icon} />
            )}
          </div>
        )}
      </div>
    </Button>
  );
};
