import axios, { AxiosResponse } from 'axios';
import CommonResponse from './CommonResponse';

export interface RequestParam<P, B> {
  param?: P;
  body?: B;
}

export const get = <P, R = CommonResponse<any>>(url: string, param: RequestParam<P, undefined>): Promise<R> => {
  return axios.get<R, AxiosResponse<R, any>, P>(url, { params: param.param, withCredentials:true })
    .then(r => r.data);
};

export const post = <P, B, R = CommonResponse<any>>(url: string, param: RequestParam<P, B>): Promise<R> => {
  return axios.post<R, AxiosResponse<R, any>, B>(url, param.body, { params: param.param, withCredentials: true })
    .then(r => r.data);
};

export const put = <P, B, R = CommonResponse<any>>(url: string, param: RequestParam<P, B>): Promise<R> => {
  return axios.put<R, AxiosResponse<R, any>, B>(url, param.body, { params: param.param, withCredentials:true })
    .then(r => r.data);
};

export const del = <P, R = CommonResponse<any>>(url: string, param: RequestParam<P, undefined>): Promise<R> => {
  return axios.delete<R, AxiosResponse<R, any>, P>(url, { params: param.param, withCredentials:true })
    .then(r => r.data);
};
