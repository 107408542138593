export const navigations = [
  // {
  //   uid: 'alarm',
  //   label: '알림',
  //   icon: 'Bell',
  //   type: 'link',
  //   link: '/',
  // },
  {
    uid: 'dashboard',
    label: '대시보드',
    icon: 'Home',
    type: 'link',
    link: '/',
  },
  // {
  //   uid: 'common',
  //   label: '일반',
  //   type: 'title',
  // },
  // {
  //   uid: 'staff',
  //   label: '스태프',
  //   icon: 'UserGroup',
  //   type: 'link',
  //   link: '/staff',
  // },
  // {
  //   uid: 'file',
  //   label: '파일',
  //   icon: 'File',
  //   type: 'link',
  //   link: '/files',
  // },
  {
    uid: 'common',
    label: '대본',
    type: 'title',
  },
  {
    uid: 'scene',
    label: '씬',
    icon: 'Document',
    type: 'link',
    link: '/scenes',
  },
  {
    uid: 'schedule',
    label: '스케줄',
    icon: 'Watch',
    type: 'link',
    link: '/schedule',
  },
  {
    uid: 'common',
    label: '촬영',
    type: 'title',
  },
  {
    uid: 'callsheet',
    label: '촬영표',
    icon: 'Calendar',
    type: 'link',
    link: '/callsheets',
  },
  {
    uid: 'common',
    label: '관리',
    type: 'title',
  },
  {
    uid: 'character',
    label: '인물',
    icon: 'Emoji',
    type: 'link',
    link: '/characters',
  },
  {
    uid: 'location',
    label: '세트&로케이션',
    icon: 'Location',
    type: 'link',
    link: '/locations',
  },
];

export const routeBreadcrumbs = [
  {
    path: '/project/:projectId/characters',
    breadcrumb: [{ title: '인물', url: '' }],
    accessMode: '',
    logoOnly: false,
  },
  {
    path: '/project/:projectId/characters/extra',
    breadcrumb: [{ title: '인물', url: '' }],
  },
  {
    path: '/project/:projectId/characters/register',
    breadcrumb: [
      { title: '인물', url: '/characters' },
      { title: '인물추가', url: '' },
    ],
  },
  {
    path: '/project/:projectId/characters/:uid',
    breadcrumb: [{ title: '인물', url: '/characters' }],
  },

  {
    path: '/project/:projectId/locations',
    breadcrumb: [{ title: '세트&로케이션', url: '' }],
  },
  {
    path: '/project/:projectId/locations/register',
    breadcrumb: [
      { title: '세트&로케이션', url: '/locaions' },
      { title: '장소추가', url: '' },
    ],
  },
  {
    path: '/project/:projectId/locations/:placeNo',
    breadcrumb: [{ title: '세트&로케이션', url: '/locations' }],
  },
  {
    path: '/project/:projectId/locations/location',
    breadcrumb: [{ title: '세트&로케이션', url: '' }],
  },
  {
    path: '/project/:projectId/locations/location/register',
    breadcrumb: [
      { title: '세트&로케이션', url: '/locaions/location' },
      { title: '촬영지추가', url: '' },
    ],
  },
  {
    path: '/project/:projectId/locations/location/:locationNo',
    breadcrumb: [{ title: '세트&로케이션', url: '/locations/location' }],
  },

  {
    path: '/profile',
    breadcrumb: [{ title: '마이페이지', url: '' }],
  },
  {
    path: '/project/:projectId/callsheets',
    breadcrumb: [{ title: '촬영표', url: '' }],
  },
  {
    path: '/project/:projectId/callsheet/:callSheetId',
    breadcrumb: [
      { title: '촬영표', url: '/callsheets' },
      { title: '일촬영표', url: '' },
    ],
  },
  {
    path: '/project/:projectId/schedule',
    breadcrumb: [{ title: '스케줄', url: '' }],
    accessMode: 'schedule',
  },
  {
    path: '/project/:projectId/schedule/scheduling/:scheduleGroupId',
    breadcrumb: [{ title: '스케줄', url: '' }],
    accessMode: 'schedule',
  },
  {
    path: '/project/:projectId/schedule/work/:scheduleGroupId',
    breadcrumb: [{ title: '스케줄', url: '/schedule' }, { title: '작업중인 스케줄', url: '' }],
  },
  {
    path: '/project/:projectId/schedule/shared/:scheduleGroupId',
    breadcrumb: [{ title: '스케줄', url: '/schedule' }, { title: '가 스케줄', url: '' }],
    accessMode: 'schedule',
  },
  {
    path: '/project/:projectId/schedule/calendar',
    breadcrumb: [{ title: '스케줄', url: '' }],
    accessMode: 'schedule',
  },
  {
    path: '/project/:projectId/schedule/:scheduleGroupId/calendar',
    breadcrumb: [{ title: '스케줄', url: '' }],
    accessMode: 'schedule',
  },

  {
    path: '/project/:projectId/scenes',
    breadcrumb: [{ title: '씬', url: '' }],
    accessMode: 'scene',
  },
  {
    path: '/project/:projectId/scene/:sceneNo',
    breadcrumb: [
      { title: '씬', url: '/scenes' },
      { title: '씬 정보', url: '' },
    ],
    accessMode: 'scene',
  },

  {
    path: '/project/:projectId/files',
    breadcrumb: [{ title: '파일', url: '' }],
    accessMode: 'file',
  },
  {
    path: '/project/:projectId/files/:folderNo',
    breadcrumb: [{ title: '파일', url: '' }],
    accessMode: 'file',
  },

  {
    path: '/project/:projectId/staff',
    breadcrumb: [{ title: '스태프', url: '' }],
  },
  {
    path: '/project/:projectId',
    breadcrumb: [{ title: '대시보드', url: '' }],
  },
  {
    path: '/project/:projectId/search',
    breadcrumb: [{ title: '검색', url: '' }],
  },
];
