import SvAPI from '../../util/SvAPI';
import { asyncState, AsyncState } from '../../util/ReducerTypes';
import ReducerGenerator from '../../util/ReducerGenerator';
import { PlaceLocation } from 'pages/callsheet/type';
import { LocationFormProps, LocationProps, PlaceDetailProps, PlaceFormProps, PlaceProps } from 'pages/location/Location.types';
import CommonResponse from 'modules/common/CommonResponse';

export type PlaceState = {
  place: AsyncState<PlaceDetailProps, Error>;
  places: AsyncState<CommonResponse<PlaceProps[]>, Error>;
  locations: AsyncState<LocationProps, Error>;
  placeDefault:AsyncState<String, Error>;
  placeLocations:AsyncState<PlaceLocation[], Error>;
};

const initialState:PlaceState = {
  place:asyncState.initial(),
  places:asyncState.initial(),
  locations:asyncState.initial(),
  placeDefault:asyncState.initial(),
  placeLocations:asyncState.initial(),

};
const reducerGenerator = new ReducerGenerator<PlaceState, 'place'>(initialState);

// 극중 장소 전체 조회
export const getPlaces = reducerGenerator.createThunkAction<
'getPlaces',
{ projectNo: number; params: {} },
CommonResponse<PlaceProps[]>
>({
  action: 'place/getPlaces',
  key: 'places',
  thunk: (params) => SvAPI.get(SvAPI.projectPlacesUrl(params!.projectNo), params!.params).then((r) => r.data),
});
// 극중 장소 생성
export const createPlace = reducerGenerator.createThunkAction<
'createPlace',
{ projectNo: number; place: PlaceFormProps },
any
>({
  action: 'place/createPlace',
  key: 'place',
  thunk: (params) => SvAPI.post(SvAPI.projectPlacesUrl(params!.projectNo), params!.place).then((r) => r.data),
});
// 극중 장소 단건 조회
export const getPlace = reducerGenerator.createThunkAction<
'getPlace',
{ projectNo: number; placeNo: number; params: {} },
CommonResponse<PlaceDetailProps>
>({
  action: 'place/getPlace',
  key: 'place',
  thunk: (params) => SvAPI.get(SvAPI.projectPlaceUrl(params!.projectNo, params!.placeNo), params!.params).then((r) => r.data),
});
// 극중 장소 수정
export const updatePlace = reducerGenerator.createThunkAction<
'updatePlace',
{ projectNo: number; placeNo: number; place: PlaceFormProps },
any
>({
  action: 'place/updatePlace',
  key: 'place',
  thunk: (params) => SvAPI.put(SvAPI.projectPlaceUrl(params!.projectNo, params!.placeNo), params!.place).then((r) => r.data),
});
// 극중 장소 삭제
export const deletePlace = reducerGenerator.createThunkAction<
'deletePlace',
{ projectNo: number; placeNo: number; params: {} },
any
>({
  action: 'place/deletePlace',
  key: 'place',
  thunk: (params) => SvAPI.delete(SvAPI.projectPlaceUrl(params!.projectNo, params!.placeNo), params!.params).then((r) => r.data),
});
// 극중 장소 이미지 추가
export const addPlaceImage = reducerGenerator.createThunkAction<
'addPlaceImage',
{ projectNo: number; placeNo: number; params: {} },
any
>({
  action: 'place/addPlaceImage',
  key: 'place',
  thunk: (params) => SvAPI.post(SvAPI.projectPlaceImagesUrl(params!.projectNo, params!.placeNo), params!.params).then((r) => r.data),
});
// 극중 장소 이미지 수정
export const updatePlaceImage = reducerGenerator.createThunkAction<
'updatePlaceImage',
{ projectNo: number; placeNo: number; imageNo: number; params: {} },
any
>({
  action: 'place/updatePlaceImage',
  key: 'place',
  thunk: (params) => SvAPI.put(SvAPI.projectPlaceImageUrl(params!.projectNo, params!.placeNo, params!.imageNo), params!.params).then((r) => r.data),
});
// 극중 장소 이미지 삭제
export const deletePlaceImage = reducerGenerator.createThunkAction<
'deletePlaceImage',
{ projectNo: number; placeNo: number; imageNo: number; params: {} },
any
>({
  action: 'place/deletePlaceImage',
  key: 'place',
  thunk: (params) => SvAPI.delete(SvAPI.projectPlaceImageUrl(params!.projectNo, params!.placeNo, params!.imageNo), params!.params).then((r) => r.data),
});
// 극중 장소 촬영지 추가
export const createPlaceLocation = reducerGenerator.createThunkAction<
'createPlaceLocation',
{ projectNo: number; placeNo: number; location: LocationFormProps },
any
>({
  action: 'place/createPlaceLocation',
  key: 'locations',
  thunk: (params) => SvAPI.post(SvAPI.placeLocationsUrl(params!.projectNo, params!.placeNo), params!.location).then((r) => r.data),
});
// 극중 장소 촬영지 할당
export const assignPlaceLocation = reducerGenerator.createThunkAction<
'assignPlaceLocation',
{ projectNo: number; placeNo: number; locationNo: number; params: {} },
any
>({
  action: 'place/assignPlaceLocation',
  key: 'locations',
  thunk: (params) => SvAPI.post(SvAPI.placeLocationUrl(params!.projectNo, params!.placeNo, params!.locationNo), params!.params).then((r) => r.data),
});
// 극중 장소 촬영지 할당 해제
export const deassignPlaceLocation = reducerGenerator.createThunkAction<
'deassignPlaceLocation',
{ projectNo: number; placeNo: number; locationNo: number; params: {} },
any
>({
  action: 'place/deassignPlaceLocation',
  key: 'locations',
  thunk: (params) => SvAPI.delete(SvAPI.placeLocationUrl(params!.projectNo, params!.placeNo, params!.locationNo), params!.params).then((r) => r.data),
});




export const getPlaceConfirmedLocation = reducerGenerator.createThunkAction<'getPlaceConfirmedLocation', { sceneNos: number[] }, PlaceLocation[]>({
  action:'place/getPlaceConfirmedLocation',
  key:'placeLocations',
  thunk:(params)=>SvAPI.get(SvAPI.getPlaceConfirmedLocation(), { sceneNos: params.sceneNos.join(',') }).then(r=>r.data),
});

const placeReducer = reducerGenerator.createReducer();
export default placeReducer;
