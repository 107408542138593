import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { ActionType, createAsyncAction, deprecated, createReducer, PayloadAction } from 'typesafe-actions';
import { needResponseErrorHandling } from './AsyncUtils';
import {
  asyncState,
  EmptyCreator,
  PromiseType,
  MakeActionString,
  ActionCreator,
  AsyncModalString,
  Reducer,
  ThunkActionCreator,
  ActionCreators,
  EmptyActionTemplate,
  ActionTemplate,
  ThunkActionTemplate,
} from './ReducerTypes';
import ErrorResponse from '../modules/common/ErrorResponse';
import { closeModal, openModal } from '../modules/modal/modalReducer';
import { ModalTypes } from '../components/modal';
import { showToastMessage } from '../hooks/useToastNotification';

const { createStandardAction } = deprecated;

export default class ReducerGenerator<State, Type extends string> {
  private initial: State = {} as State;

  private actionCreators: { [k: string]: ActionCreators<any> } = {};

  private reducers: { [k: string]: Reducer<any, State> } = {};

  /**
   * @param initial 초기 상태 값
   */
  constructor(initial: State) {
    this.initial = initial;
  }

  /**
   * @desc 앞서 정의된 리듀서들을 묶어서 하나의 리듀서를 반환합니다.
   */
  createReducer() {
    return createReducer<State, PayloadAction<string, typeof this.actionCreators>>(this.initial, this.reducers);
  }

  /**
   * @desc createEmptyAction: payload가 없는 일반 액션 함수를 생성합니다.
   */
  createEmptyAction<ActionType extends string>(
    options: EmptyActionTemplate<MakeActionString<Type, ActionType>, State>
  ): EmptyCreator<MakeActionString<Type, ActionType>> {
    let actionCreator = createStandardAction(options.action)();
    this.actionCreators[options.action] = actionCreator;
    this.reducers[options.action] =
      options.reducer ||
      (() => {
        return this.initial;
      });
    return actionCreator;
  }

  /**
   * @desc payload가 있는 일반 액션 함수를 생성합니다.
   */
  createAction<ActionType extends string, Payload>(
    options: ActionTemplate<MakeActionString<Type, ActionType>, State, Payload>
  ): ActionCreator<MakeActionString<Type, ActionType>, Payload> {
    let actionCreator = createStandardAction(options.action)<Payload>();
    this.actionCreators[options.action] = actionCreator;
    this.reducers[options.action] = options.reducer;
    return actionCreator;
  }

  /**
   * @desc 비동기 액션 함수를 반환합니다.
   */
  createThunkAction<ActionType extends string, ThunkParameter, ThunkResponse extends Object>(
    options: ThunkActionTemplate<MakeActionString<Type, ActionType>, State, ThunkParameter, ThunkResponse>
  ): ThunkActionCreator<ThunkParameter, ThunkResponse> {
    const asyncObj = this.createAsyncAction(options.action);
    const [request, success, error] = asyncObj.actions;
    const asyncAction = asyncObj.asyncAction;

    const thunk = this.createAsyncThunk<ThunkParameter, ThunkResponse>(asyncAction, options.thunk!, options.modal);
    const thunkCreator = (params?: ThunkParameter) => (dispatch: Dispatch) => thunk(dispatch, params!);
    this.actionCreators[options.action] = thunkCreator;

    if (options.extraReducers?.loading) {
      this.reducers[request] = options.extraReducers?.loading || (() => this.initial);
    } else {
      this.reducers[request] = (state) => {
        return {
          ...state,
          [options!.key!]: { ...state[options!.key!], ...asyncState.load() },
        };
      };
    }

    if (options.extraReducers?.success) {
      this.reducers[success] = options.extraReducers?.success || (() => this.initial);
    } else {
      const reducer: Reducer<MakeActionString<Type, ActionType>, State, ThunkResponse> = (state, action) => {
        if ('payload' in action) {
          let res: any = action.payload;
          if (!res || JSON.stringify(res) === '{}') {
            res = undefined;
          }
          return {
            ...state,
            [options!.key!]: { ...state[options!.key!], ...asyncState.success(res) },
          };
        } else return state;
      };
      this.reducers[success] = reducer;
    }

    if (options.extraReducers?.error) {
      this.reducers[error] = options.extraReducers?.error || (() => this.initial);
    } else {
      const reducer: Reducer<MakeActionString<Type, ActionType>, State, Error> = (state, action) => {
        return {
          ...state,
          [options!.key!]: { ...state[options!.key!], ...asyncState.error(action.payload) },
        };
      };
      this.reducers[error] = reducer;
    }
    return thunkCreator;
  }

  private createAsyncAction(action: string) {
    const request = action;
    const success = `${action}Success` as const;
    const error = `${action}Error` as const;

    return {
      actions: [request, success, error],
      asyncAction: createAsyncAction(request, success, error)<any, any, AxiosError>(),
    };
  }

  private createAsyncThunk<Parameter, Response>(
    asyncActionCreator: ActionType<any>,
    promiseCreator: PromiseType<Parameter, Response>,
    modal?: AsyncModalString
  ) {
    return async (dispatch: Dispatch, params: Parameter) => {
      const { request, success, failure } = asyncActionCreator;
      dispatch(request(params));
      if (modal && modal.loadingText) {
        dispatch(openModal({ type: ModalTypes.AsyncStatus, props: { type: 'loading', text: modal.loadingText } }));
      }
      try {
        let response = await promiseCreator(params);
        dispatch(success(response));
        if (modal && modal.successText) {
          dispatch(closeModal());
          showToastMessage(modal.successText);
          // dispatch(openModal({ type: ModalTypes.AsyncStatus, props: { type: 'success', text: modal.successText } }));
          // setTimeout(() => {
          //   dispatch(closeModal());
          // }, 1000);
        }
        return response;
      } catch (e) {
        if (e instanceof AxiosError) {
          if (!needResponseErrorHandling(e)) {
            return;
          }
        }
        if (modal && modal.failText) {
          dispatch(closeModal());
          let errText = modal.failText;
          try {
            // @ts-ignore
            errText = this.getErrText(e, modal);
          } catch (error) {}
          // dispatch(openModal({ type: ModalTypes.AsyncStatus, props: { type: 'fail', text: errText } }));
          showToastMessage(errText);
        }
        dispatch(failure(e));
        // throw e;
      }
    };
  }

  private getErrText(e: AxiosError, modal: AsyncModalString) {
    if (modal.useFailRspDetail) {
      const errorRsp = e?.response?.data as ErrorResponse;
      const errorText =
        errorRsp.errors?.reduce((acc, cur) => {
          acc += `${cur.message}\n`;
          return acc;
        }, '\n') || '';
      return `${modal.failText}\n${errorRsp.detail ? `(${errorRsp.detail})` : ``} ${errorText ? `${errorText}` : ``}`;
    }
    return modal.failText;
  }
}
