import React, { useEffect } from 'react';
import { closeModal, openModal } from 'modules/modal/modalReducer';
import { ModalTypes } from './Modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Modal } from './ModalComponents';
import style from './AccessModifyModal.module.scss';
import arrow_down from 'public/common/arrow_down.svg';
import { PermisionInfo, PermisionType } from 'shared';
import { permissionString } from 'util/common';
import { CheckedListType } from 'components/checkbox';
import { getStaffs } from '../../modules/staff/staffReducer';
import { RootState } from '../../modules';
import profile from 'public/119674.jpg';

export type AccessModifyModalProp = {
  projectNo: number;
  enable?: boolean;
};

export const AccessModifyModal = ({ projectNo }: AccessModifyModalProp) => {
  const dispatch = useAppDispatch();
  const { staffs } = useAppSelector((state: RootState) => state.staff);
  const [data, setData] = React.useState<PermisionInfo[]>([]);

  const fetchStaffs = async () => {
    if (!projectNo) return;

    dispatch(getStaffs({ projectNo: Number(projectNo), params: {} }))
      .then(() => {
        const permissions = staffs.data?.map((staff) => {
          const permission: PermisionInfo = {
            userNo: staff.userNo,
            name: staff.name,
            thumbnail: staff.thumbnail || profile,
            permissions: [PermisionType.ALL],
          };
          return permission;
        });
        setData(permissions || []);
      });
  };

  useEffect(() => {
    fetchStaffs();
  }, []);



  const handleClose = () => {
    dispatch(closeModal(ModalTypes.AccessModify));
  };

  const handleChangePermission = (info: PermisionInfo, items?: CheckedListType[]) => {
    let permissions = items?.map((item) => item.id as PermisionType) || [];
    const updated = data.map((d) => ({ ...d, permissions: d.userNo === info.userNo ? permissions : d.permissions }));
    setData(updated);
  };

  const openAccessStateToggle = (info: PermisionInfo) => {
    dispatch(
      openModal({
        type: ModalTypes.Checkbox,
        props: {
          title: '권한 선택하기',
          checkList: Object.values(PermisionType).map((t) => ({
            text: permissionString([t]),
            id: t,
            checked: info.permissions.includes(t),
          })),
          onConfirm: (items) => handleChangePermission(info, items),
        },
        overlayOptions: {
          hideBackdrop: true,
        },
      })
    );
  };

  return (
    <Modal.Container size="xl" style={{ padding: 'var(--f45)' }}>
      <Modal.Title title="접근 권한 수정하기" />
      <div className={style.search_user}>
        <Modal.Input value="" placeholder="이메일 또는 이름으로 검색해주세요." />
        <button>검색</button>
      </div>
      <ul className={style.access_modal__user_group}>
        {data?.map((d) => (
          <li>
            <div>
              <img className={style.profile} alt="profile" src={d.thumbnail} />
              <span className={style.name}>{d.name}</span>
            </div>
            <div className={style.access_state}>
              <span>{permissionString(d.permissions)}</span>
              <button onClick={() => openAccessStateToggle(d)}>
                <img src={arrow_down} />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <Modal.ButtonGroup
        cancelBtnText="취소"
        confirmBtnText="완료"
        buttonGroupPosition="right"
        onConfirm={handleClose}
        onCancel={handleClose}
      />
    </Modal.Container>
  );
};
