import { useState } from 'react';
import { Typography } from 'components';
import { Modal } from './ModalComponents';
import { Button, Form } from 'react-bootstrap';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';

export interface MemoModalProps {
  defaultValue?: string;
  onConfirm?: (content: string) => void;
  onCancel?: () => void;
}

export default function MemoModal({ defaultValue, onConfirm, onCancel }: MemoModalProps) {
  const dispatch = useAppDispatch();
  const [content, setContent] = useState(defaultValue || '');

  const handleClose = () => {
    onCancel?.();
    dispatch(closeModal());
  };

  const handleComplete = () => {
    onConfirm?.(content);
    dispatch(closeModal());
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f14-166--56" className="fw-bold">
          내 메모 작성하기
        </Typography>
      </div>
      <div className="text-left">
        <Form.Control
          as="textarea"
          className="fs-3 bg-blue-gray-100"
          placeholder="내용을 입력하세요."
          rows={10}
          value={content}
          onChange={(e) => {
            setContent(e.target.value || '');
          }}
        />
      </div>
      <div className="items-center justify-content-end gap-2.5 pt-6">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleComplete}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
}
