import { CompanyUserInfo } from 'shared';
import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import { deleteCompany, findCompanyUsers } from './CompanyService';
import Company from './Company.types';

export type CompanyState = {
  users: AsyncState<CompanyUserInfo[], Error>;
  com: AsyncState<Company, Error>;
};

const initialState: CompanyState = {
  users: asyncState.initial(),
  com: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<CompanyState, 'company'>(initialState);

// 조직 구성원 목록 조회
export const getCompanyUsers = reducerGenerator.createThunkAction<
'getCompanyUsers',
{ companyNo: number, name?: string },
CompanyUserInfo[]
>({
  action: 'company/getCompanyUsers',
  key: 'users',
  thunk: (params) => findCompanyUsers(params.companyNo, params.name),
});

export const delCompany = reducerGenerator.createThunkAction<
'deleteCompany',
{ companyNo: number, id: string, passwd: string },
any
>({
  action: 'company/deleteCompany',
  key: 'com',
  modal: {
    loadingText: '조직 삭제중...',
    successText: '조직이 삭제되었습니다.',
    failText: '조직 삭제에 실패했습니다.',
  },
  thunk: (params) => deleteCompany(params.companyNo, params.id, params.passwd),
});

const companyReducer = reducerGenerator.createReducer();
export default companyReducer;
