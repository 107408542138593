import * as React from 'react';
import { DIRECTION } from 'shared/constants';

import styles from './Button.module.scss';

import play_back from 'public/common/play_back.svg';
import play_skip_back from 'public/common/play_skip_back.svg';
import play_forward from 'public/common/play_forward.svg';
import play_skip_forward from 'public/common/play_skip_forward.svg';

type EpisodeMoveButtonsProps = {
  type?: string;
  style?: React.CSSProperties;
  onClick: (name: string) => void;
};

export const EpisodeMoveButtons = ({ style, onClick }: EpisodeMoveButtonsProps) => {
  return (
    <div className={styles.episode_move_button_group} style={style}>
      <button type="button" onClick={() => onClick(DIRECTION.MOVE_SKIP_BACK)}>
        <img src={play_skip_back} alt="play_skip_back" />
      </button>
      <button type="button" onClick={() => onClick(DIRECTION.MOVE_BACK)}>
        <img src={play_back} alt="play_back" />
      </button>
      <button type="button" onClick={() => onClick(DIRECTION.MOVE_FORWARD)}>
        <img src={play_forward} alt="play_forward" />
      </button>
      <button type="button" onClick={() => onClick(DIRECTION.MOVE_SKIP_FORWARD)}>
        <img src={play_skip_forward} alt="play_skip_forward" />
      </button>
    </div>
  );
};
