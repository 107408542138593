import styles from './SceneAddModal.module.scss';
import * as React from 'react';
import { LabelDropdown } from 'components/dropdown';
import { LabelCheckBox, LabelInput, LabelSelect } from 'components';
import { SceneCreate, SceneCreateForm, SiteCode, TextValue, TimeSlotCode } from '../Scene';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Script } from '../../../modules/scripts/ScriptService';
import { Container, Row } from 'react-bootstrap';
import { Modal } from 'components/modal';
import { makeTimeList } from '../../../util/Times';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCharacters } from 'modules/characters/CharacterReducer';
import { RootState } from 'modules';
import { getPlaces } from 'modules/place/placeReducer';
import { fileUrl } from 'util/fileHelper';
import { Avatar } from '@mui/material';
import { CharacterProps } from 'pages/character/Character.types';
import { PlaceProps } from 'pages/location/Location.types';

import arrow_counter_clockwise from 'public/common/arrow_counter_clockwise.svg';

export type SceneAddModalProps = {
  projectNo: number;
  scriptNo: number;
  epList: Script[] | null;
  onConfirm: (scene: SceneCreate) => void;
  onCancel: () => void;
};

const defaultForm: SceneCreateForm = {
  episode: '',
  sceneNum: '',
  script: { text: '', value: '' },
  timeslot: { text: TimeSlotCode.D, value: TimeSlotCode.D },
  site: { text: SiteCode.S, value: SiteCode.S },
  playPlaces: '',
  characters: '',
  extras: '',
  summarize: '',
  props: '',
  makeups: '',
  costumes: '',
  shootingTime: undefined,
};

export default function SceneAddModal({ projectNo, scriptNo, epList, onConfirm }: SceneAddModalProps) {
  const dispatch = useAppDispatch();
  const { characters } = useAppSelector((state: RootState) => state.character);
  const { places } = useAppSelector((state: RootState) => state.place);
  const [sceneCreate, setSceneCreate] = React.useState<SceneCreateForm>(defaultForm);
  const [selectedPlaces, setSelectedPlaces] = React.useState<PlaceProps[]>([]);
  const [selectedCharacters, setSelectedCharacters] = React.useState<CharacterProps[]>([]);
  const {
    control,
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SceneCreateForm>();

  React.useEffect(() => {
    if (!projectNo) return;
    dispatch(getCharacters({ projectNo, params: { noPage: 'true' } }));
    dispatch(getPlaces({ projectNo, params: { noPage: 'true' } }));
  }, [projectNo]);

  const resetData = () => {
    reset(() => defaultForm);
    setSceneCreate({
      ...sceneCreate,
      ...defaultForm,
    });
    setSelectedPlaces([]);
    setSelectedCharacters([]);
  };

  const onSubmit: SubmitHandler<SceneCreateForm> = (data) => {
    let hasError = false;
    if (!selectedCharacters?.length) {
      hasError = true;
      setError('playPlaces', { message: '장소를 선택해주세요' });
    }
    if (!selectedPlaces?.length) {
      hasError = true;
      setError('characters', { message: '인물을 선택해주세요' });
    }

    if (hasError) return;

    onConfirm({
      sceneNum: data.sceneNum,
      scriptNo: scriptNo,
      timeslot: sceneCreate.timeslot?.value,
      site: sceneCreate.site?.value,
      playPlaces: selectedPlaces.map((p) => p.name),
      characters: selectedCharacters.map((c) => c.name),
      // extras: data.extras?.split(','),
      summarize: data.summarize,
      // props: data.props ? data.props.split(',') : undefined,
      // makeups: data.makeups ? data.makeups.split(',') : undefined,
      // costumes: data.costumes ? data.costumes.split(',') : undefined,
      shootingTime: sceneCreate.shootingTime ? sceneCreate.shootingTime?.value : undefined,
    });
    resetData();
  };

  return (
    <Modal.Container size="wd" style={{ padding: `var(--f45)` }}>
      <Container className={styles.container}>
        <Row>
          <Modal.Title title="씬 추가" iconButtonConfig={{ src: arrow_counter_clockwise, onClick: resetData }} />
          <Modal.SubTitle title={'새로운 씬을 생성합니다.'} size={'medium'} />
        </Row>
        <form onSubmit={handleSubmit(onSubmit)} className="text-left">
          <Row className={styles.form}>
            <Row>
              <LabelDropdown
                label="EP"
                initItem={sceneCreate.script}
                controllerConfig={{ control, name: 'episode' }}
                formValidation={{
                  registeredKey: 'episode',
                  validationObject: { required: true },
                  register: register,
                  fieldErros: errors.episode,
                  messages: [{ type: 'required', message: 'EP를 입력해주세요.' }],
                }}
                content="EP를 선택해주세요."
                onSelected={(value?: TextValue) => {
                  setSceneCreate({
                    ...sceneCreate,
                    script: value || { text: '', value: '' },
                  });
                }}
                items={
                  epList
                    ? epList.map((item) => {
                      return { value: item.scriptNo, text: item.episode };
                    })
                    : []
                }
                labelStyle={{ color: `var(--gray-50)` }}
                totalWidth={6}
              />
              <LabelInput
                formValidation={{
                  registeredKey: 'sceneNum',
                  validationObject: { required: true, pattern: /^[0-9]+$/ },
                  register: register,
                  fieldErros: errors.sceneNum,
                  messages: [
                    { type: 'required', message: '씬 번호를 입력해주세요.' },
                    { type: 'pattern', message: '씬 번호는 숫자만 입력 가능합니다.' },
                  ],
                }}
                label={'S#'}
                placeholder={'씬넘버를 입력하세요'}
                onChange={(event: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    sceneNum: event.target.value,
                  });
                }}
                labelWidth={2}
                totalWidth={6}
              />
            </Row>
            <Row>
              <LabelCheckBox
                label="D/N"
                activeItem={sceneCreate.timeslot}
                items={Object.keys(TimeSlotCode)
                  .slice(0, 2)
                  .map((key) => {
                    return { text: key, value: key };
                  })}
                onChecked={(value: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    timeslot: value,
                  });
                }}
                labelWidth={2}
                totalWidth={6}
              />
              <LabelCheckBox
                label="S/L"
                activeItem={sceneCreate.site}
                items={Object.keys(SiteCode)
                  .slice(0, 2)
                  .map((key) => {
                    return { text: key, value: key };
                  })}
                onChecked={(value: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    site: value,
                  });
                }}
                labelWidth={2}
                totalWidth={6}
              />
            </Row>
            <LabelSelect
              label="장소"
              name="playPlaces"
              placeholder="장소를 선택해주세요"
              isMultiple
              options={places?.data?.body || []}
              getLabel={(op) => op.name}
              getValue={(op) => op.name}
              renderOption={(option) => (
                <span className="items-center gap-2">
                  <Avatar
                    src={option.placeImg?.length ? fileUrl(option.placeImg[0].imgPath) : ''}
                    sx={{ width: 20, height: 20 }}
                  />
                  <span>{option.name}</span>
                </span>
              )}
              value={selectedPlaces}
              onChange={(val) => {
                clearErrors('playPlaces');
                setSelectedPlaces(val);
              }}
              error={errors.playPlaces?.message}
            />
            <LabelSelect
              label="등장인물"
              placeholder="등장인물을 선택해주세요"
              isMultiple
              options={characters?.data?.body || []}
              getLabel={(op) => op.name}
              getValue={(op) => op.characterNo}
              renderOption={(option) => (
                <span className="items-center gap-2">
                  <Avatar
                    src={option.characterImg?.length ? fileUrl(option.characterImg[0].imgPath) : ''}
                    sx={{ width: 20, height: 20 }}
                  />
                  <span>{option.name}</span>
                </span>
              )}
              error={errors.characters?.message}
              value={selectedCharacters}
              onChange={(val) => {
                clearErrors('characters');
                setSelectedCharacters(val);
              }}
            />
            {/* <Row>
              <LabelInput
                formValidation={{
                  registeredKey: 'extras',
                  register: register,
                }}
                label={'보조 출연'}
                placeholder={'보조 출연을 입력해주세요'}
                onChange={(event: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    extras: event.target.value,
                  });
                }} />
            </Row> */}
            <LabelInput
              formValidation={{
                registeredKey: 'summarize',
                register: register,
              }}
              label={'내용'}
              placeholder={'씬에 대한 내용을 입력해주세요'}
              onChange={(event: any) => {
                setSceneCreate({
                  ...sceneCreate,
                  summarize: event.target.value,
                });
              }}
            />
            {/* <Row>
              <LabelInput
                formValidation={{
                  registeredKey: 'props',
                  register: register,
                }}
                label={'소품'} placeholder={'소품을 입력해주세요'} onChange={(event: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    props: event.target.value,
                  });
                }} />
            </Row>
            <Row>
              <LabelInput
                formValidation={{
                  registeredKey: 'costumes',
                  register: register,
                }}
                label={'의상'} placeholder={'의상을 입력해주세요'} onChange={(event: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    costumes: event.target.value,
                  });
                }} />
            </Row>
            <Row>
              <LabelInput
                formValidation={{
                  registeredKey: 'makeups',
                  register: register,
                }}
                label={'분장'} placeholder={'분장을 입력해주세요'} onChange={(event: any) => {
                  setSceneCreate({
                    ...sceneCreate,
                    makeups: event.target.value,
                  });
                }} />
            </Row> */}
            <Row>
              <LabelDropdown
                initItem={sceneCreate.shootingTime}
                label="예상 소요 시간"
                content="시간 선택"
                onSelected={(value?: TextValue) => {
                  setSceneCreate({
                    ...sceneCreate,
                    shootingTime: value,
                  });
                }}
                items={makeTimeList()}
                labelStyle={{ color: `var(--gray-50)` }}
                totalWidth={6}
              />
            </Row>
          </Row>
        </form>
        <div className={styles.button_group}>
          <Modal.Button text="취소" buttonType="cancel" />
          <Modal.Button text="추가" type="submit" buttonType="confirm" keepOpen onClick={handleSubmit(onSubmit)} />
        </div>
      </Container>
    </Modal.Container>
  );
}
