import { useState, useEffect, useMemo } from 'react';
import { Avatar } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource } from 'components';

import { SearchDropdown } from 'components/SearchDropdown/SearchDropdown';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getLocations } from 'modules/locations/locationReducer';
import { RootState } from 'modules';
import { closeModal } from 'modules/modal/modalReducer';
import { LocationProps } from '../Location.types';
import { fileUrl } from 'util/fileHelper';
import { insertLocationList } from 'modules/place/PlaceService';
import { Modal, ModalTypes } from 'components/modal';

export interface LocationRegModalProps {
  placeNo: number;
  registered: LocationProps[];
  onChange?: () => void;
}

export const LocationRegModal = ({ placeNo, registered, onChange }: LocationRegModalProps) => {
  const dispatch = useAppDispatch();
  const { project } = useAppSelector((state: RootState) => state.project);
  const { locations } = useAppSelector((state: RootState) => state.locations);
  const [selected, setSelected] = useState<LocationProps[]>([]);
  const [excludedIds, setExcludedIds] = useState<number[]>([]);

  const { companyNo, projectNo } = project?.data || {};

  const fetchLoactions = (key?: string) => {
    const searchTxt = key || '';
    if (!companyNo) return;
    dispatch(getLocations({ companyNo, params: { name: searchTxt, noPage: 'true' } }));
  };

  useEffect(() => {
    fetchLoactions();
  }, [project]);

  useEffect(() => {
    if (!registered?.length) setExcludedIds([]);
    else {
      const Idlist = registered.filter((r) => !!r.locationNo).map((r) => r.locationNo);
      setExcludedIds(Idlist);
    }
  }, [registered]);

  const handleClose = () => {
    dispatch(closeModal(ModalTypes.LocationRegPopup));
  };

  const handleAddLocation = (loc: LocationProps) => {
    setSelected([...selected, loc]);
    if (loc.locationNo) {
      setExcludedIds([...excludedIds, loc.locationNo]);
    }
  };

  const handleRemoveLocation = (idx: number) => {
    const selectedList = [...selected];
    const removed = selectedList.splice(idx, 1);
    setSelected(selectedList);

    const removedId = removed[0].locationNo;
    if (removedId) {
      const excludedIdList = [...excludedIds];
      const excludedIndex = excludedIdList?.indexOf(removedId);
      excludedIdList.splice(excludedIndex);

      setExcludedIds(excludedIdList);
    }
  };

  const handleComplete = async () => {
    if (!projectNo) return;

    const locationNos = selected.map((l) => l.locationNo);
    if (locationNos?.length > 0) {
      await insertLocationList(projectNo, placeNo, locationNos);
      onChange?.();
    }

    handleClose();
  };

  const availableLocations = useMemo(() => {
    if (!locations?.data?.body?.length) return [];
    return locations.data?.body?.filter((a) => a.locationNo && !excludedIds.includes(a.locationNo));
  }, [locations, excludedIds]);

  return (
    <Modal.Container size="lg" style={{ padding: 'var(--f45)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          후보 촬영지 등록하기
        </Typography>
      </div>
      <div className="d-flex flex-column h-90 text-left">
        <SearchDropdown
          placeholder="키워드 또는 촬영지 명으로 검색해주세요 ."
          list={availableLocations}
          filterKeys={[]}
          activeInFocus={true}
          onSearch={(v: string) => fetchLoactions(v)}
          onSelect={(item: any) => handleAddLocation(item as LocationProps)}
          onChange={(v: string) => fetchLoactions(v)}
          rednerItem={(item: LocationProps) => (
            <>
              <Avatar
                src={item.locationImg?.length ? fileUrl(item.locationImg?.[0].imgPath) : ''}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="f12-166--48" className="fw-medium">
                {item.name}
              </Typography>
            </>
          )}
        />
        <div className="py-6">
          {selected.map((item, index) => (
            <div key={index} className="d-flex align-items-center bg-light rounded py-2 px-5 mb-2">
              <Avatar
                src={item.locationImg?.length ? fileUrl(item.locationImg?.[0].imgPath) : ''}
                className="me-4"
                sx={{ width: 40, height: 40 }}
              />
              <Typography className="fw-bold  flex-1">{item.name}</Typography>
              <div>
                <Typography tag="span" className="text-blue-gray-300">
                  촬영지 후보 {index + 1}
                </Typography>{' '}
                <span className="pointer" onClick={() => handleRemoveLocation(index)}>
                  <SvgIcon source={SvgIconSource.CloseCircle} className="ms-1" color="danger" />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="items-center justify-content-end gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleComplete}>
          완료
        </Button>
      </div>
    </Modal.Container>
  );
};
