import { Badge, Button, Col } from 'react-bootstrap';
import styles from './LabelInput.module.scss';
import * as React from 'react';
import { Typography } from '../Typography';
import { TextValue } from '../../pages/scene/Scene';

export interface LabelListInputProps {
  label: string;
  placeholder?: string;
  values: TextValue[];
  error?: string;
  labelPos?: 'left' | 'top';
  style?: React.CSSProperties;
  totalWidth?: LengthRatio;
  labelWidth?: LengthRatio;
  onDelete?: (value: TextValue) => void;
  onChange?: (value: string) => void;
}

export const getMainClassNames = (labelPos?: string) => {
  if (labelPos === 'top') {
    return '';
  } else {
    return 'row flex-nowrap';
  }
};


export const LabelBadgeListInput = ({
  label,
  placeholder,
  values,
  labelPos,
  totalWidth = 12,
  labelWidth = 2,
  onDelete,
  onChange,
  error,
}: LabelListInputProps) => {

  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    if (onChange) {
      onChange(values && values.toString());
    }

    setValue(values ? values.map(val => val.text).join(',') : '');
  }, [values]);

  const getBadgesFromValues = () => {
    return values.map((val, index) => {
      return (

        <div key={index}>
          <Badge pill={true} bg="sub">
            <div className={styles.delete_badge}>
              <Typography variant="f14-166--56" className="fw-bold">
                {val.text}
              </Typography>
              <Button
                className={styles.badge_delete_button}
                onClick={() => {
                  if (onDelete) {
                    onDelete(val);
                  }
                }}
              >&#10005;</Button>
            </div>
          </Badge>
        </div>
      );
    });
  };

  const content = (
    <>
      <Col sm={labelPos === 'top' ? totalWidth : labelWidth}
        className={labelPos === 'top' ? styles.label_top_box : styles.label_left_box}>
        <label className={labelPos === 'top' ?  styles.label_top : styles.label}>
          {label}
        </label>
      </Col>
      <Col
        className={styles.input_container}
        sm={labelPos === 'top' ? totalWidth : totalWidth - labelWidth}
      >
        <input
          hidden={true}
          disabled={true}
          className={styles.input_box}
          placeholder={placeholder}
          value={value}/>
        <div className={styles.badge_container}>
          {getBadgesFromValues()}
        </div>
        <div className={styles.error_message_container}>
          {error && <span className={styles.error_message}>{error}</span>}
        </div>
      </Col>
    </>
  );

  return (
    <>
      {totalWidth && totalWidth !== 12 ? content :
        <div className={getMainClassNames(labelPos)}>
          {content}
        </div>
      }
    </>
  );
};
