import { createContext } from 'react';

type User = {
  userId: string
  isAuthenticated: boolean
};

type Context = {
  user?: User;
  projectNo: number;
  scriptNo: number; // 임시
};

const appContext: Context = {
  user: { userId: 'test@test.com', isAuthenticated: true },
  projectNo : 1,
  scriptNo: 1, // 임시
};

const AppContext = createContext(appContext);

export { AppContext };
