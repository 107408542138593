import { Button } from 'react-bootstrap';
import { FileButtonProps } from './FileButton.types';
import { useRef } from 'react';

export const FileButton = ({ onFileChange, accept = '', multiple = false, ...props }: FileButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Button {...props} onClick={() => inputRef?.current?.click()}>
      {props?.children}
      <input ref={inputRef} multiple={multiple} type="file" hidden accept={accept} onChange={onFileChange} />
    </Button>
  );
};
