import React from 'react';
import style from './ScheduleModal.module.scss';
import { Modal, FormModal, ModalTypes } from 'components/modal';
import { CustomDatePicker } from 'components';
import {
  setAddStripType,
  shareScheduleGroup,
} from '../../../modules/schedules/scheduleReducer';
import { toDateString } from '../../../util/Times';
import { useAppDispatch } from '../../../hooks';
import { openModal, resetModalState } from '../../../modules/modal/modalReducer';
import { Schedule, ShareType } from '../type';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { filterSchedulesByDateRange } from '../../../util/scheduleHelper';
import { showToastMessage } from 'hooks/useToastNotification';
import { confirmWorkSchedule } from 'modules/schedules/ScheduleServices';

type ShareScheduleFormType = {
  name: string;
};

export type ShareScheduleModalProps = {
  min: Date;
  max: Date;
  excludedDates?: Date[];
  type: ShareType;
  projectNo: number;
  groupNo: number;
  position?: 'schedule' | 'callsheet',
  onNavigate?: (url: string, date?: string) => void;
  onRefresh?: () => void;
};
export default function ShareScheduleModal({
  type,
  projectNo,
  groupNo,
  min,
  max,
  excludedDates,
  position = 'schedule',
  onNavigate,
  onRefresh,
}: ShareScheduleModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ShareScheduleFormType>();

  const navigate = useNavigate();

  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [startDateString, setStartDateString] = React.useState<string>('');
  const [endDateString, setEndDateString] = React.useState<string>('');

  const { scheduleLists } = useSelector((state: RootState) => state.schedule);

  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch(setAddStripType({ type: null }));
  };

  const checkForSharingErrors = (schedules: Schedule[]): string => {
    let shareErrorMessage = '';

    for (let schedule of schedules) {
      if (!schedule.scheduleList.length) {
        shareErrorMessage = '빈 스케줄이 존재합니다.';
        break;
      }
      if (schedule.share === 'C' || schedule.share === 'D') {
        shareErrorMessage = '이미 일촬영표에 등록된 스케줄입니다.';
        break;
      }
    }
    return shareErrorMessage;
  };

  const shareSchedule = (data?: ShareScheduleFormType) => {
    if (!data) return;

    const filteredSchedules = filterSchedulesByDateRange(scheduleLists, startDate, endDate);
    const shareErrorMessage = checkForSharingErrors(filteredSchedules);

    if (shareErrorMessage) {
      dispatch(
        openModal({
          type: ModalTypes.Alert,
          props: {
            text: shareErrorMessage,
            buttonProps: { closeAll: true },
          },
        })
      );
    }

    startDate?.setMinutes(startDate.getMinutes() + 5);
    endDate?.setMinutes(endDate.getMinutes() + 5);

    const query = {
      endDt: toDateString(endDate!),
      name: data.name,
      projectNo,
      startDt: toDateString(startDate!),
    };

    if (type === 'SHARED') {
      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: {
            text: position === 'callsheet' ? '일촬영표를 공유하시겠습니까?' : '현재 작업 중인 스케줄을\n 공유하시겠습니까?',
            onConfirm: () => {
              dispatch(shareScheduleGroup({ projectNo, groupNo, query })).then(() => {
                showToastMessage('가스케줄로 공유되었습니다.');
                if (position === 'callsheet') {
                  onRefresh?.();
                  return;
                }
                // dispatch(getScheduleGroups({ projectNo, shareType: 'SHARED' }));
                navigate(`/project/${projectNo}/schedule/`);
              });
              dispatch(resetModalState());
            },
          },
        })
      );
    } else if (type === 'DAILYSHOOT') {
      confirmWorkSchedule(projectNo, groupNo, query).then((d) => {
        onRefresh?.();
        let nextUrl = `/project/${projectNo}/callsheets/`;
        if (d.schedules?.[0]?.scheduleNo) {
          nextUrl = `/project/${projectNo}/callsheet/${d.schedules?.[0]?.scheduleNo}`;
        }
        
        dispatch(
          openModal({
            type: ModalTypes.Confirm,
            props: {
              text: '스케줄이 확정되었습니다. \n일촬영표를 확인하시겠습니까?',
              onConfirm: () => {
                onNavigate?.(nextUrl);
                dispatch(resetModalState());
              },
            },
          })
        );
      }).catch((error) => {
        console.log(error);
      });
    }
  };
  return (
    <FormModal
      title={type === 'SHARED' ? '가 스케줄 공유' : '스케줄 확정'}
      size="sm"
      onConfirm={shareSchedule}
      onCancel={onCancel}
      handleSubmit={handleSubmit}
      keepOpen={true}>
      <CustomDatePicker
        minDate={min}
        maxDate={max}
        isEnd={true}
        excludeDates={excludedDates}
        getStartDateString={(date: string) => setStartDateString(date)}
        getStartDate={(date: Date) => setStartDate(date)}
        getEndDateString={(date: string) => setEndDateString(date)}
        getEndDate={(date: Date) => setEndDate(date)}
      />
      <div className={style.section}>
        <Modal.Label text={'시작일'} />
        <Modal.Input value={startDateString} disabled />
      </div>
      <div className={style.section}>
        <Modal.Label text={'종료일'} />
        <Modal.Input value={endDateString} disabled />
      </div>
      {(type === 'SHARED' || type === 'WORK') && (
        <div className={style.section}>
          <Modal.Label text={'스케줄 명'} />
          <Modal.Input
            formValidation={{
              registeredKey: 'name',
              validationObject: { required: true },
              register: register,
              fieldErros: errors.name,
              messages: [{ type: 'required', message: '스케줄 명을 입력해주세요.' }],
            }}
          />
        </div>
      )}
    </FormModal>
  );
}
