import { SvgIcon, SvgIconProps } from 'components';
import style from './Modal.module.scss';
import { Modal } from './ModalComponents';

export type ConfirmModalProps = {
  icon?: SvgIconProps;
  text: string;
  subText?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm?: (data?: any) => void;
  onCancel?: (data?: any) => void;

  id?: string | number;
  type?: string;
  autoClose?: boolean;
};

export default function ConfirmModal({
  icon,
  text,
  subText,
  confirmBtnText,
  cancelBtnText,
  onConfirm,
  onCancel,
  id,
  type,
  autoClose = false,
}: ConfirmModalProps) {
  return (
    <Modal.Container size="md" className="confirm_container">
      {icon && (
        <div className="d-flex align-items-center justify-content-center mb-4">
          <div className="bg-primary w-fit rounded-full">
            <SvgIcon {...icon} />
          </div>
        </div>
      )}
      <span className={style.modal_text}>{text}</span>
      {subText && <span className={style.confirm_modal__sub_text}>{subText}</span>}
      <div className="items-center justify-content-center gap-4 pt-8">
        {!autoClose && (
          <Modal.Button text={cancelBtnText || '취소'} buttonType="cancel" onClick={() => onCancel?.({ id, type })} />
        )}
        {(confirmBtnText || onConfirm) && (
          <Modal.Button
            text={confirmBtnText || '확인'}
            buttonType="confirm"
            onClick={() => onConfirm?.({ id, type })}
          />
        )}
      </div>
    </Modal.Container>
  );
}
