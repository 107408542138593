import { toast } from 'react-toastify';
import icon from 'public/common/calendar_fill.svg';

export const showToastMessage = (message: string, type?: 'info' | 'success' | 'warning' | 'error' | 'default') => {
  toast(
    <span>
      <img src={icon} className="me-2" />
      {message}
    </span>,
    {
      position: 'bottom-center',
      type: type || 'default',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'dark',
    }
  );
};
