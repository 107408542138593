import { SearchListModal } from 'components/modal';
import profile from '../../../public/119674.jpg';

export default function CostumeModal() {
  return (
    <SearchListModal
      title="캐릭터"
      size="small"
      description="의상을 착용할 캐릭터 검색"
      showItemImage
      items={[
        { text: '소망', imgPath: profile },
        { text: '도준', imgPath: profile },
      ]}></SearchListModal>
  );
}
