import SvAPI, { serverUrl } from 'util/SvAPI';
import { get } from '../common/HttpCaller';
import Company from './Company.types';
import { CompanyFormProps } from 'pages/company/Company.types';

export default class CompanyService {
  getMyCompany = async () => {
    return get<undefined, Company[]>(`${serverUrl}/companies`, {});
  };
}

const createCompany = async (data: CompanyFormProps) => {
  return SvAPI.post(SvAPI.companyBaseUrl(), data).then((res) => res.data);
};

const modifyCompany = async (companyNo: number, data: CompanyFormProps) => {
  const baseUrl = SvAPI.companyBaseUrl();
  return SvAPI.put(`${baseUrl}/${companyNo}`, data).then((res) => res.data);
};

const deleteCompany = async (companyNo: number, id: string, passwd: string) => {
  const baseUrl = SvAPI.companyBaseUrl();
  return SvAPI.delete(`${baseUrl}/${companyNo}`, {}, {
    username: id,
    password: passwd,
  }).then((res) => res.data);
};

const verifyCompanyName = async (name: string, companyNo?: number) => {
  const query = `/name?name=${name}&companyNo=${companyNo}`;
  return SvAPI.get(SvAPI.companyBaseUrl() + query, {}).then((res) => res.data);
};

const findCompanies = async () => {
  return SvAPI.get(SvAPI.companiesUrl(), {}).then((res) => res.data);
};

const findCompanyUsers = async (companyNo: number, name?: string) => {
  const query = `?name=${name || ''}`;
  return SvAPI.get(SvAPI.companyUserUrl(companyNo) + query, {}).then((res) => res.data);
};


const sendCompanyInvitation = async (companyNo: number, staffs: string[]) => {
  return SvAPI.post(SvAPI.companyInviteUrl(companyNo), staffs).then((res) => res.data);
};

const findInvitedCompanyInfo = async (code: string) => {
  return SvAPI.get(SvAPI.companyInviteConfirmUrl(code), {}).then((res) => res.data);
};

const acceptCompanyInvite = async (code: string) => {
  return SvAPI.post(SvAPI.companyInviteConfirmUrl(code), {}).then((res) => res.data);
};

export {
  createCompany,
  deleteCompany,
  modifyCompany,
  verifyCompanyName,
  findCompanies,
  findCompanyUsers,
  sendCompanyInvitation,
  findInvitedCompanyInfo,
  acceptCompanyInvite,
};
