import { AxiosError } from 'axios';
import { ActionType, createAsyncAction } from 'typesafe-actions';

export const LOGIN_CHECK_ACTION = 'login-check/LoginCheck';
export const LOGIN_CHECK_SUCCESS_ACTION = 'login-check/LoginCheckSuccess';
export const LOGIN_CHECK_FAIL_ACTION = 'login-check/LoginCheckFail';

export interface LoginCheckParam {
  url: string;
}

export interface LoginCheckSuccessParam {
  isLogin: boolean;
  isJoinEnabled: boolean;
  url: string;
}

export const loginCheckAction = createAsyncAction(
  LOGIN_CHECK_ACTION,
  LOGIN_CHECK_SUCCESS_ACTION,
  LOGIN_CHECK_FAIL_ACTION)<LoginCheckParam, LoginCheckSuccessParam, AxiosError>();


export const actions = { loginCheck : loginCheckAction };
export type LoginCheckAction = ActionType<typeof actions>;
