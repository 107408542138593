import React from 'react';
import { Modal, FormModal } from 'components/modal';
import { CustomDatePicker } from 'components';
import { useAppDispatch } from '../../../hooks';
import { formatDateString } from 'util/common';
import { closeModal } from 'modules/modal/modalReducer';
import { DDayInfo } from '../ProjectDashboard.types';

export type DdaySettingModalProps = {
  projectNo: number;
  dday: DDayInfo;
  onConfirm?: () => void;
};

export const DdaySettingModal = ({ dday }: DdaySettingModalProps) => {
  const dispatch = useAppDispatch();
  const today = new Date();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const startDate = new Date(dday?.startDt);
  const endDate = new Date(dday?.endDt);

  return (
    <FormModal title={'디데이 설정하기'} size="sm" confirmBtnText="닫기" onConfirm={handleClose} hideCancel>
      <CustomDatePicker
        minDate={today}
        maxDate={today}
        isEnd
        selectedDate={startDate || today}
        lastDate={endDate || today}
        readOnly
        // excludeDates={excludeDates}
        // getStartDate={(d: Date) => setSDate(d.toISOString())}
        // getEndDate={(d: Date) => setEDate(d.toISOString())}
      />
      <div className="text-left">
        <div className="mb-4">
          <Modal.Label text="시작일" />
          <Modal.Input value={formatDateString(startDate, '년월일')} disabled />
        </div>
        <div className="mb-4">
          <Modal.Label text="종료일" />
          <Modal.Input value={formatDateString(endDate, '년월일')} disabled />
        </div>
        <div className="mb-4">
          <Modal.Label text="전체 회차" />
          <Modal.Input type="number" value={dday?.totalShootingDay || ''} disabled />
        </div>
      </div>
    </FormModal>
  );
};
