import React from 'react';
import MainFrame from 'components/Layout/MainFrame';
import { AppContext } from 'shared/context/AppContext';
import { requestConfigure } from 'modules/requestConfigure';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'swiper/css';
import './global.scss';

requestConfigure();

function App() {
  const context = React.useContext(AppContext);

  return (
    <QueryClientProvider client={new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false, // default: true
        },
      },
    })}>
      <AppContext.Provider value={context}>
          <MainFrame />
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
