import { AuthFormProps, JoinInfoProps } from 'pages/join/Join.types';
import { YesNoType } from 'shared';
import ReducerGenerator from 'util/ReducerGenerator';

export type ResetJoinState = {
  session?: string;
  emailVerified?: YesNoType;
  joinAuth?: AuthFormProps;
  joinInfo?: JoinInfoProps;
};

export type JoinState = {
  session: string;
  joinAuth: AuthFormProps;
  joinInfo: JoinInfoProps;
  emailVerified: YesNoType;
};

const initialState: JoinState = {
  session: '',
  joinAuth: {
    name: '',
    email: '',
    authCode: '',
  },
  joinInfo: {
    name: '',
    password: '',
    email: '',
    emailAuthCode: '',
    mobile: '',
    birthDate: '',
    job: '',
    thumbnail: '',
  },
  emailVerified: null,
};

const reducerGenerator = new ReducerGenerator<JoinState, 'join'>(initialState);

// 리셋
export const resetJoinState = reducerGenerator.createAction<'reset', ResetJoinState>({
  action: 'join/reset',
  reducer: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});

const JoinReducer = reducerGenerator.createReducer();
export default JoinReducer;
