import * as React from 'react';
import styles from './DropDown.module.scss';
import { Badge } from '../Badge/Badge';
import { PROCEED } from 'shared/constants';
import { ProceedCode } from 'pages/scene/Scene';
import { DropDown } from './DropDownContainer';


type ProgressDropdownMenuProps = {
  onSelected: (value: ProceedCode) => void;
};
export const ProgressDropdownMenu = ({ onSelected }:ProgressDropdownMenuProps) => {
  return (
    <DropDown.Container style={{ width:'196px' }}>
      <DropDown.SubTitle text={'진행여부 선택'} />
      <DropDown.Menu style={{ paddingBottom:'13px' }}>
        {PROCEED.map((proceed) =>  (
          <DropDown.Item
            key={proceed.value}
            onClick={()=>{
              onSelected(proceed.value as ProceedCode);
            }}>
              <div className={styles.badge_item}>
                <Badge type={proceed.value}>
                  {proceed.text}
                </Badge>
              </div>
          </DropDown.Item>
        ))}
      </DropDown.Menu>
    </DropDown.Container>
  );
};
