import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  getCharacters,
  getEpisodes,
  getPlaces,
  setSceneFilters,
} from '../../../modules/schedules/unassginedScenesReducer';
import { FormModal, SearchListModal, SearchListValue } from 'components/modal';
import BadgeButtonCell from '../../scene/cell/BadgeButtonCell';
import styles from './ScheduleModal.module.scss';
import { StripsTable } from 'components';
import Scene, { ProceedState } from '../../scene/Scene';
import { proceedState } from '../../constants/sceneConfig';
import { getProceed } from '../../../util/sceneHelper';
import arrow_counter_clockwise from '../../../public/common/arrow_counter_clockwise.svg';
import { ScheduleFilterSetType, ScheduleSceneFilterType } from '../type';
import { getFilteredScenes } from '../../../util/scheduleHelper';

export type ScheduleSceneFilterModalProps = {
  projectNo: number;
};

export default function ScheduleSceneFilterModal({ projectNo }: ScheduleSceneFilterModalProps) {
  const filterMenuStyle: React.CSSProperties = { width: '20%', height: `var(--f340)` };
  const dispatch = useAppDispatch();
  const { scenes, filteredScenes, filters, episode, bigPlayPlaces, playPlaces, characters } = useAppSelector(
    (state) => state.unassignedScenes
  );
  const [selectedFilters, setSelectedFilters] = useState<ScheduleFilterSetType>(filters);
  const [filteredSceneList, setFilteredSceneList] = useState<{ matched: Scene[], unmatched: Scene[] }>({ matched : filteredScenes || [], unmatched: [] });

  React.useEffect(() => {
    dispatch(getEpisodes({ projectNo }));
    dispatch(getPlaces({ projectNo }));
    dispatch(getCharacters({ projectNo }));
  }, []);

  const resetFilterSet = () => {
    setFilteredSceneList({ matched: scenes.data!, unmatched: [] });
    setSelectedFilters({
      episode: new Set(),
      playPlaces: new Set(),
      bigPlayPlaces: new Set(),
      characters: new Set(),
      site: new Set(),
      timeslot: new Set(),
      proceed: new Set(),
    });
  };

  const selectFilterItem = (type: ScheduleSceneFilterType, isChecked: boolean, value: SearchListValue) => {
    if (isChecked) {
      selectedFilters[type].add(value.text);
    } else {
      selectedFilters[type].delete(value.text);
    }

    const filtered = getFilteredScenes(scenes.data!, selectedFilters);
    setFilteredSceneList(filtered);
  };

  return (
    <FormModal
      title="씬 필터링"
      subTitle="조건에 맞는 씬을 검색합니다."
      titleIconButtonConfig={{ src: arrow_counter_clockwise, onClick: resetFilterSet }}
      size="sw"
      style={{ padding: `var(--f45)` }}
      onConfirm={() => {
        dispatch(setSceneFilters({ filters: selectedFilters }));
      }}>
      <div className={styles.search_lists}>
        <SearchListModal
          size="medium"
          title="EP선택"
          items={
            episode.data
              ? (episode.data.map((value) => {
                return { text: value };
              }) as SearchListValue[])
              : []
          }
          hideShadow={true}
          checkbox={true}
          loading={episode.loading}
          setChecked={(item: SearchListValue) => selectedFilters.episode.has(item.text)}
          getCheckedItem={(isChecked, chItem) => selectFilterItem('episode', isChecked, chItem)}
          disabled={true}
          style={filterMenuStyle}
        />
        <div style={filterMenuStyle}>
          <SearchListModal
            size="medium"
            title="D/N 선택"
            items={[{ text: 'D' }, { text: 'N' }]}
            hideShadow={true}
            checkbox={true}
            setChecked={(item: SearchListValue) => selectedFilters.timeslot.has(item.text)}
            getCheckedItem={(isChecked, chItem) => selectFilterItem('timeslot', isChecked, chItem)}
            disabled={true}
            style={{ width: '100%', maxHeight: '208px', marginBottom: 10 }}
          />
          <SearchListModal
            size="medium"
            title="S/L 선택"
            items={[{ text: 'S' }, { text: 'L' }, { text: '미정' }]}
            hideShadow={true}
            checkbox={true}
            setChecked={(item: SearchListValue) => selectedFilters.site.has(item.text === '미정' ? 'NA' : item.text)}
            getCheckedItem={(isChecked, chItem) =>
              selectFilterItem('site', isChecked, chItem.text === '미정' ? { text: 'NA' } : chItem)
            }
            disabled={true}
            style={{ width: '100%', maxHeight: '208px' }}
          />
        </div>
        <div style={filterMenuStyle}>
          <SearchListModal
            size="medium"
            title="대장소 검색"
            items={
              bigPlayPlaces.data
                ? (bigPlayPlaces.data.map((value) => {
                  return { text: value };
                }) as SearchListValue[])
                : []
            }
            hideShadow={true}
            checkbox={true}
            loading={bigPlayPlaces.loading}
            setChecked={(item: SearchListValue) => selectedFilters.bigPlayPlaces.has(item.text)}
            getCheckedItem={(isChecked, chItem) => selectFilterItem('bigPlayPlaces', isChecked, chItem)}
            style={{ width: '100%', maxHeight: '208px', marginBottom: 10 }}
          />
          <SearchListModal
            size="medium"
            title="소장소 검색"
            items={
              playPlaces.data
                ? (playPlaces.data.map((value) => {
                  return { text: value };
                }) as SearchListValue[])
                : []
            }
            hideShadow={true}
            checkbox={true}
            loading={playPlaces.loading}
            setChecked={(item: SearchListValue) => selectedFilters.playPlaces.has(item.text)}
            getCheckedItem={(isChecked, chItem) => selectFilterItem('playPlaces', isChecked, chItem)}
            style={{ width: '100%', maxHeight: '208px' }}
          />
        </div>

        <SearchListModal
          size="medium"
          title="등장인물 검색"
          items={
            characters.data
              ? (characters.data.map((value) => {
                return { text: value };
              }) as SearchListValue[])
              : []
          }
          hideShadow={true}
          checkbox={true}
          loading={characters.loading}
          setChecked={(item: SearchListValue) => selectedFilters.characters.has(item.text)}
          getCheckedItem={(isChecked, chItem) => selectFilterItem('characters', isChecked, chItem)}
          style={filterMenuStyle}
        />
        <SearchListModal
          size="medium"
          title="촬영상태 선택"
          items={[{ text: '예정' }, { text: '미촬영' }, { text: '재촬영' }, { text: '완료' }, { text: '오밋' }]}
          hideShadow={true}
          checkbox={true}
          setChecked={(item: SearchListValue) => selectedFilters.proceed.has(proceedState[item.text as ProceedState])}
          getCheckedItem={(isChecked, chItem) =>
            selectFilterItem('proceed', isChecked, { text: proceedState[chItem.text as ProceedState] })
          }
          disabled={true}
          style={filterMenuStyle}
        />
      </div>
      <div className={styles.preview}>
        <StripsTable
          style={{ height: '150px' }}
          data={filteredSceneList.matched}
          columnInfo={[
            {
              title: 'EP',
              width: '3.5%',
              align: 'left',
              data: (t: Scene) => t.episode,
            },
            {
              title: '씬',
              width: '3.5%',
              align: 'left',
              data: (t: Scene) => t.sceneNum,
            },
            {
              title: 'D/N',
              width: '3.5%',
              align: 'left',
              data: (t: Scene) => t.timeslot,
            },
            {
              title: 'S/L',
              width: '3.5%',
              align: 'left',
              data: (t: Scene) => t.site,
            },
            {
              title: '대장소',
              width: '15%',
              align: 'left',
              data: (t: Scene) => (t.bigPlayPlaces?.length ? t.bigPlayPlaces.join() : '-'),
            },
            {
              title: '소장소',
              width: '15%',
              align: 'left',
              data: (t: Scene) => (t.playPlaces ? t.playPlaces.join() : '-'),
            },
            {
              title: '등장인물',
              width: '15%',
              align: 'left',
              data: (t: Scene) => (t.characters ? t.characters.join() : '-'),
            },
            {
              title: '내용',
              width: '15%',
              align: 'left',
              data: (t: Scene) => t.summarize,
            },
            // {
            //   title: '소품/의상/분장',
            //   width: '15%',
            //   align: 'left',
            //   data: (t: Scene) => {
            //     let propsCostumesMakeups = [];
            //     if (t.props) propsCostumesMakeups.push(t.props?.join());
            //     if (t.costumes) propsCostumesMakeups.push(t.costumes?.join());
            //     if (t.makeups) propsCostumesMakeups.push(t.makeups?.join());
            //     return propsCostumesMakeups.join();
            //   },
            // },
            // {
            //   title: '예상 소요 시간',
            //   width: '10%',
            //   align: 'left',
            //   data: (t: Scene) => toHHMM(t.shootingTime),
            // },
            {
              title: '촬영상태',
              width: '10%',
              align: 'left',
              data: (t: Scene) => (
                <BadgeButtonCell badgeType={t.proceed} badgeText={getProceed(t.proceed)} onSelected={() => {}} />
              ),
            },
          ]}
        />
      </div>
    </FormModal>
  );
}
