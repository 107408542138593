export enum ProjectFormat {
  MOVIE = 'MOVIE',
  SERIES = 'SERIES',
}

export interface ProjectFormProps {
  companyNo: number;
  name: string;
  thumbnail?: string;
  description?: string;
  format?: ProjectFormat;
  plan?: string;
  episode?: string;
}

export interface ProjectDetailInfo {
  projectNo: number;
  name: string;
  description?: string;
  public?: boolean;
  companyNo: number;
  registerNo: number;
  director?: string;
  writer?: string;
  producer?: string;
  plan?: string;
  format: ProjectFormat;
  episode?: string;
  thumbnail: string;
  actor?: string;
  shootStart?: string;
  dday?: string;
  shootEnd?: string;
  createTime: string;
  updateTime: string;
}
