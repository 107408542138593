/* eslint-disable @typescript-eslint/indent */
import SvAPI from '../../util/SvAPI';
import ReducerGenerator from '../../util/ReducerGenerator';
import { AsyncState, asyncState } from '../../util/ReducerTypes';
import { Tag } from 'pages/tag/Tag';

export type TagsState = {
  tags: AsyncState<Tag[], Error>;
  tagSum: AsyncState<Tag[], Error>;
  tagged: AsyncState<null, Error>;
};

const initialState: TagsState = {
  tags: asyncState.initial(),
  tagSum: asyncState.initial(),
  tagged: asyncState.initial(),
};

const generator = new ReducerGenerator<TagsState, 'tags'>(initialState);

export const getTags = generator.createThunkAction<'GET_TAGS', { projectNo: number }, Tag[]>({
  action: 'tags/GET_TAGS',
  key: 'tags',
  thunk: (params) => SvAPI.get(SvAPI.tagsUrl(params!.projectNo)).then((res) => res.data),
  extraReducers: {
    success: (state, action) => {
      //태그 카테고리 병합
      const Sum: Tag[] = [...action.payload];
      const character = Sum.find((elem) => elem.tagType == 'CHARACTER_MAIN');
      character!.tagGroup = [];

      Sum.forEach((val, idx) => {
        //EXTRA 병합 추가여부 검토중
        if (val.tagType == 'CHARACTER_SUB') {
          character!.tagGroup.push(val.tagNo);
          Sum.splice(idx, 1);
        }
      });

      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
          data: action.payload,
        },
        tagSum: {
          ...state.tagSum,
          loading: false,
          data: Sum,
        },
      };
    },
  },
});

export const createTag = generator.createThunkAction<'CREATE_TAG', { projectNo: number; formData: any }, any>({
  action: 'tags/CREATE_TAG',
  key: 'tags',
  thunk: (params) => SvAPI.post(SvAPI.tagsUrl(params!.projectNo), params!.formData).then((res) => res.data),
});

export const updateTag = generator.createThunkAction<'UPDATE_TAG', { tagNo: number; formData: any; options: any }, any>(
  {
    action: 'tags/UPDATE_TAG',
    key: 'tags',
    thunk: (params) =>
      SvAPI.put(SvAPI.tagUrl(params!.tagNo), params!.formData, params!.options).then((res) => res.data),
  }
);

export const deleteTag = generator.createThunkAction<'DELETE_TAG', { tagNo: number; options: any }, any>({
  action: 'tags/DELETE_TAG',
  key: 'tags',
  thunk: (params) => SvAPI.delete(SvAPI.tagUrl(params!.tagNo), params!.options).then((res) => res.data),
});

export const createTagged = generator.createThunkAction<'CREATE_TAGGED', { tagNo: number; formData: any }, any>({
  action: 'tags/CREATE_TAGGED',
  key: 'tagged',
  modal: {
    loadingText: '태그를 등록중입니다...',
    successText: '등록 완료',
    failText: '등록 실패',
  },
  thunk: (params) => SvAPI.post(SvAPI.assignTaggedUrl(params!.tagNo), params!.formData).then((res) => res.data),
});

export const updateTagged = generator.createThunkAction<'UPDATE_TAGGED', { taggedNo: number; formData: any }, any>({
  action: 'tags/UPDATE_TAGGED',
  key: 'tagged',
  modal: {
    loadingText: '태그를 수정중입니다...',
    successText: '수정 완료',
    failText: '수정 실패',
  },
  thunk: (params) => SvAPI.post(SvAPI.updateTaggedUrl(params!.taggedNo), params!.formData).then((res) => res.data),
});

export const deleteTagged = generator.createThunkAction<
  'DELETE_TAGGED',
  { taggedNo: number; sceneNo: number; options: any },
  any
>({
  action: 'tags/DELETE_TAGGED',
  key: 'tagged',
  modal: {
    loadingText: '태그를 삭제중입니다...',
    successText: '삭제 완료',
    failText: '삭제 실패',
  },
  thunk: (params) =>
    SvAPI.delete(SvAPI.deleteTaggedUrl(params!.taggedNo, params!.sceneNo), params!.options).then((res) => res.data),
});

const tagReducer = generator.createReducer();

export default tagReducer;
