import classNames from 'classnames';
import { SubNavigationProps } from './SubNavigation.types';
import styles from './SubNavigation.module.scss';
import { Typography } from 'components/Typography';

export const SubNavigation = ({ activeIdx = 0, menuList, content, onClick, addOn, isManager }: SubNavigationProps) => {
  const noMenu = !content && !menuList?.length;

  return (
    <div className={classNames(styles.root, noMenu && styles.noMenu)}>
      {content ? (
        <div>{content}</div>
      ) : (
        <ul className={isManager ? styles.managerMenu : styles.menu}>
          {menuList?.map((menu, index) => {
            return (
              <li
                key={index}
                className={classNames(styles.item, activeIdx === index ? styles.itemActive : '')}
                onClick={() => onClick?.(index)}>
                <Typography tag="div" variant="f20-166--80" className={`fw-bold ${isManager ? 'text-manager' : ''}`}>
                  {menu}
                </Typography>
              </li>
            );
          })}
        </ul>
      )}
      <div className={classNames(styles.addon, noMenu && styles.onlyAddon)}>{addOn}</div>
    </div>
  );
};
