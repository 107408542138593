import moment from 'moment';
import { Schedule, ScheduleFilterSetType, ScheduleSceneFilterType, ShareState } from 'pages/schedule/type';
import Scene from 'pages/scene/Scene';

const generateSchedulePeriodString = (start:string, end:string):string=>{
  const startDateFormat = 'MM/DD';
  const endDateFormat = moment(start).month() === moment(end).month() ? 'DD' : 'MM/DD';

  return `${moment(start).format(startDateFormat)}~${moment(end).format(endDateFormat)}`;
};

const getStartDateOfHeadStripModal = (excludeDates:Date[]):Date=>{

  let selected = moment();

  let i = 0;
  while (i < excludeDates.length) {
    const selectedDate = selected.format('YYYY-MM-DD');
    const excludedDate = moment(excludeDates[i]).format('YYYY-MM-DD');

    if (selectedDate === excludedDate) {
      selected.add(1, 'days');
      i++;
      continue;
    }
    if (moment(excludedDate).isAfter(selectedDate)) {
      if (selectedDate !== excludedDate) {
        break;
      } else {
        selected.add(1, 'days');
        i++;
      }
    } else {
      i++;
    }
  }
  return selected.toDate();
};


const generateShareTag = (share: ShareState)=>{
  if (share === 'S') {
    return '공유';
  } else if (share === 'D' || share === 'C') {
    return '확정';
  } else {
    return '미공유';
  }
};

const filterSchedulesByQuery = (schedules:Schedule[], query:string):Schedule[] =>{
  return schedules.filter((schedule) => {
    for (let scItem of schedule.scheduleList) {
      if (scItem.scene) {
        if (scItem.scene.playPlaces) {
          if (scItem.scene.playPlaces.toString().includes(query)) return true;
        }
        if (scItem.scene.characters) {
          if (scItem.scene.characters.toString().includes(query)) return true;
        }
      } else {
        if (scItem.describe.includes(query)) return true;
      }
    }
    return false;
  });
};

const sortScheduleOrder = (scList: Schedule[]): Schedule[] => {
  const newSchedule: Schedule[] = [];
  for (let i = 0; i < scList.length; i++) {
    const sclist = scList[i].scheduleList.map((item, idx) => {
      return { ...item, scheduleNo: scList[i].scheduleNo, order: idx };
    });
    const newItem: Schedule = {
      ...scList[i],
      scheduleList: sclist,
    };
    newSchedule.push(newItem);
  }
  return newSchedule;
};

const filterSchedulesByDateRange = (schedules:Schedule[], startDate:Date, endDate:Date):Schedule[]=>{
  const schedulesInRange = schedules.filter((schedule)=>{
    const scheduleDate = moment(schedule.date);
    if (
      scheduleDate.isSameOrAfter(moment(startDate).format('YYYY-MM-DD'))
    && scheduleDate.isSameOrBefore(moment(endDate).format('YYYY-MM-DD'))
    ) {
      return schedule;
    }
  });
  return schedulesInRange;
};

const isMatchedScene = (scene: Scene, selectedFilters: ScheduleFilterSetType) => {
  for (let key in selectedFilters) {
    const filterKey = key as ScheduleSceneFilterType;
    const filterList = selectedFilters[filterKey];

    if (filterList.size === 0) {
      continue;
    }

    let isPassed = false;

    switch (filterKey) {
      case 'characters':
        if (!scene[filterKey]) break;
        const checkedCharacters = Array.from(filterList);
        isPassed = checkedCharacters.every((checkedCharacter) => {
          return scene[filterKey].some((c) => {
            return checkedCharacter === c;
          });
        });
        break;
      case 'playPlaces':
      case 'bigPlayPlaces':
        if (!scene[filterKey]) break;
        for (let listItem of scene[filterKey]) {
          if (filterList.has(listItem)) {
            isPassed = true;
            break;
          }
        }
        break;
      default:
        if (filterList.has(scene[filterKey])) {
          isPassed = true;
          break;
        }
        break;
    }
    if (!isPassed) return false;
  }
  return true;
};

const getFilteredScenes = (scenes: Scene[], selectedFilters: ScheduleFilterSetType) => {
  const newFilteredScenes = scenes.reduce((acc, scene) => {
    if (isMatchedScene(scene, selectedFilters)) {
      return {
        matched: [...acc.matched, scene],
        unmatched: acc.unmatched,
      };
    }
    return {
      matched: acc.matched,
      unmatched: [...acc.unmatched, scene],
    };
  }, {
    matched: new Array<Scene>(),
    unmatched: new Array<Scene>(),
  });

  return newFilteredScenes;
};

export {
  generateSchedulePeriodString,
  getStartDateOfHeadStripModal,
  generateShareTag,
  filterSchedulesByQuery,
  sortScheduleOrder,
  filterSchedulesByDateRange,
  getFilteredScenes,
};
