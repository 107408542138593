import * as React from 'react';
import styles from './LabelDropdown.module.scss';
import { useRef } from 'react';
import { TextValue } from 'pages/scene/Scene';
import _ from 'lodash';
import caret_left from 'public/common/caret_left.svg';
import { DropDown } from './DropDownContainer';
import { DropdownList } from './DropdownList';
import { SearchListValue } from 'components/modal';
import { Col } from 'react-bootstrap';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type Props<T extends FieldValues> = {
  items: SearchListValue[];
  label?: string;
  content: string;
  initItem?: TextValue;
  labelPos?: 'left' | 'top';
  controllerConfig?: { control?: Control<T> | undefined; name: Path<T> };
  formValidation?: FormValidation;
  showArrow?: boolean;
  showLabel?: boolean;
  totalWidth?: LengthRatio;
  labelWidth?: LengthRatio;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onSelected: (value?: SearchListValue) => void;
};

export const LabelDropdown = <T extends FieldValues>({
  items,
  label,
  content,
  initItem,
  labelPos,
  controllerConfig,
  formValidation,
  showArrow = true,
  showLabel = true,
  totalWidth = 12,
  labelWidth = 2,
  labelStyle,
  inputStyle,
  onSelected,
}: Props<T>) => {
  const [selectedItem, setSelectedItem] = React.useState<string>('');
  const prevInitItem = useRef<TextValue>();

  React.useEffect(() => {
    if (!_.isEqual(prevInitItem.current, initItem)) {
      onSelected(initItem);
      setSelectedItem(initItem?.text ? initItem.text : '');
    }
    prevInitItem.current = initItem;
  }, [initItem]);

  const printError = () => {
    if (formValidation && formValidation.fieldErros && formValidation.messages) {
      for (const msg of formValidation.messages) {
        if (formValidation.fieldErros?.type === msg.type) {
          return <span className={styles.error_message}>{msg.message}</span>;
        }
      }
    }
  };

  const onSelect = (listItem?: SearchListValue) => {
    if (!listItem) return;
    setSelectedItem(listItem.text);
    onSelected(listItem);
  };

  return (
    <>
      {showLabel && (
        <Col
          sm={labelPos === 'top' ? totalWidth : labelWidth}
          className={labelPos === 'top' ? styles.label_top_box : styles.label_left_box}>
          <label className={styles.label} style={labelStyle}>
            {label}
          </label>
        </Col>
      )}
      <Col className={styles.dropdown_input} sm={labelPos === 'top' ? totalWidth : totalWidth - labelWidth}>
        {controllerConfig ? (
          <Controller
            control={controllerConfig.control}
            name={controllerConfig.name}
            rules={{ required: true }}
            render={({ field }) => (
              <DropDown
                fitWidth
                element={
                  <DropdownList title={content} items={items} onClickItem={(item) => field.onChange(item!.text)} />
                }>
                <div className={styles.input_container}>
                  <input className={styles.input} style={inputStyle} placeholder={content} value={field.value} readOnly />
                  {showArrow && <img src={caret_left} alt="arrow_down" />}
                </div>
              </DropDown>
            )}
          />
        ) : (
          <DropDown fitWidth element={<DropdownList title={content} items={items} onClickItem={onSelect} />}>
            <div className={styles.input_container}>
              <input className={styles.input} style={inputStyle} placeholder={content} value={selectedItem} readOnly />
              {showArrow && <img src={caret_left} alt="arrow_down" />}
            </div>
          </DropDown>
        )}
        {printError()}
      </Col>
    </>
  );
};
