import { useState, useEffect, useMemo } from 'react';
import { Avatar } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource } from 'components';
import { CompanyActorInfo } from 'shared';
import { SearchDropdown } from 'components/SearchDropdown/SearchDropdown';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'modules';
import { getCompanyActors } from 'modules/actor/ActorReducer';
import { insertCastingList } from 'modules/characters/CharacterService';
import { closeModal, openModal } from 'modules/modal/modalReducer';
import { Modal, ModalTypes } from 'components/modal';

export interface CastingRegModalProps {
  characterNo: number;
  registered: CompanyActorInfo[];
  onConfirm?: () => void;
}

export const CastingRegModal = ({ characterNo, registered, onConfirm }: CastingRegModalProps) => {
  const dispatch = useAppDispatch();
  const { actors } = useAppSelector((state: RootState) => state.actor);
  const { project } = useAppSelector((state: RootState) => state.project);
  const [castings, setCastings] = useState<CompanyActorInfo[]>([]);
  const [excludedIds, setExcludedIds] = useState<number[]>([]);

  const { companyNo, projectNo } = project?.data || {};

  const fetchActors = (key?: string) => {
    const searchTxt = key || '';
    if (!companyNo) return;
    dispatch(getCompanyActors({ companyNo, params: { noPage: 'true', name: searchTxt, email: searchTxt } }));
  };

  useEffect(() => {
    fetchActors();
  }, [project]);

  useEffect(() => {
    if (!registered?.length) setExcludedIds([]);
    else {
      const Idlist = registered.filter((r) => !!r.companyActorNo).map((r) => r.companyActorNo);
      setExcludedIds(Idlist);
    }
  }, [registered]);

  const handleClose = () => {
    dispatch(closeModal(ModalTypes.CastingRegPopup));
  };

  const handleAddActor = (actor: CompanyActorInfo) => {
    setCastings([...castings, actor]);
    if (actor.companyActorNo) {
      setExcludedIds([...excludedIds, actor.companyActorNo]);
    }
  };

  const handleRemoveCasting = (idx: number) => {
    const casting = [...castings];
    const removed = casting.splice(idx, 1);
    setCastings(casting);

    const removedId = removed[0].companyActorNo;
    if (removedId) {
      const excludedIdList = [...excludedIds];
      const excludedIndex = excludedIdList?.indexOf(removedId);
      excludedIdList.splice(excludedIndex);

      setExcludedIds(excludedIdList);
    }
  };

  const handleCreateActor = (val: string) => {
    dispatch(
      openModal({
        type: ModalTypes.ActorRegPopup,
        props: {
          name: val,
          onConfirm: handleAddActor,
        },
      })
    );
  };

  const handleComplete = async () => {
    if (!projectNo) return;

    const actorNos = castings?.map((c) => c.companyActorNo || 0);
    if (actorNos?.length > 0) {
      await insertCastingList(projectNo, characterNo, actorNos);
      onConfirm?.();
    }

    handleClose();
  };

  const availableActors = useMemo(() => {
    if (!actors?.data?.body?.length) return [];
    return actors.data?.body?.filter((a) => a.companyActorNo && !excludedIds.includes(a.companyActorNo));
  }, [actors, excludedIds]);

  return (
    <Modal.Container size="lg" style={{ padding: 'var(--f45)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          후보 배우 등록하기
        </Typography>
      </div>
      <div className="d-flex flex-column h-90 text-left">
        <div className="mb-6">
          <SearchDropdown
            placeholder="이메일 또는 이름으로 검색해주세요."
            typeName="배우"
            list={availableActors}
            filterKeys={[]}
            activeInFocus={true}
            onSearch={(val: string) => fetchActors(val)}
            onCreate={(value: string) => handleCreateActor(value)}
            onSelect={(item: any) => handleAddActor(item as CompanyActorInfo)}
            onChange={(val: string) => fetchActors(val)}
            rednerItem={(item: CompanyActorInfo) => (
              <>
                <Avatar src={item.imgUrl} sx={{ width: 24, height: 24 }} />
                <div>
                  <Typography variant="f12-166--48" className="fw-medium">
                    {item.name}
                  </Typography>
                  <Typography variant="f10-166--40" className="text-blue-gray-300">
                    {item.agency || ' '}
                  </Typography>
                </div>
              </>
            )}
          />
        </div>

        <div className="flex-1 overflow-y-auto pb-6">
          {castings.map((casting, index) => (
            <div key={index} className="d-flex align-items-center bg-light rounded py-2 px-5 mb-2">
              <Avatar src={casting.imgUrl} className="me-4" sx={{ width: 40, height: 40 }} />
              <Typography className="fw-bold  flex-1">{casting.name}</Typography>
              <div>
                <Typography tag="span" className="text-blue-gray-300">
                  캐스팅 후보 {index + 1}
                </Typography>{' '}
                <span className="pointer" onClick={() => handleRemoveCasting(index)}>
                  <SvgIcon source={SvgIconSource.CloseCircle} className="ms-1" color="danger" />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="items-center justify-content-end gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleComplete}>
          완료
        </Button>
      </div>
    </Modal.Container>
  );
};
