import { PlaceFormProps } from 'pages/location/Location.types';
import SvAPI from 'util/SvAPI';

const findPlace = async (projectNo: number, placeNo: number) => {
  return SvAPI.get(SvAPI.projectPlaceUrl(projectNo, placeNo), {}).then((res) => res.data);
};

const createPlace = async (projectNo: number, params: PlaceFormProps) => {
  return SvAPI.post(SvAPI.projectPlacesUrl(projectNo), params).then((res) => res.data);
};

const updatePlace = async (projectNo: number, placeNo: number, params: PlaceFormProps) => {
  return SvAPI.put(SvAPI.projectPlaceUrl(projectNo, placeNo), params).then((res) => res.data);
};

const deletePlace = async (projectNo: number, placeNo: number) => {
  return SvAPI.delete(SvAPI.projectPlaceUrl(projectNo, placeNo)).then((res) => res.data);
};

const insertLocationList = async (projectNo: number, placeNo: number, locationNos: number[]) => {
  const query = `/locations?locationNos=${locationNos.join(',')}`;
  return SvAPI.post(SvAPI.projectPlaceUrl(projectNo, placeNo) + query, {}).then((res) => res.data);
};

const removeLocationList = async (projectNo: number, placeNo: number, locationNos: number[]) => {
  const query = `/locations?locationNos=${locationNos.join(',')}`;
  return SvAPI.delete(SvAPI.projectPlaceUrl(projectNo, placeNo) + query, {}).then((res) => res.data);
};

const updateLocationConfirmStatus = async (projectNo: number, placeNo: number, locationNo: number, confirm: string) => {
  const query = `/locations/${locationNo}/confirm?confirm=${confirm}`;
  return SvAPI.put(SvAPI.projectPlaceUrl(projectNo, placeNo) + query, {}).then((res) => res.data);
};

export {
  findPlace,
  createPlace,
  updatePlace,
  deletePlace,
  insertLocationList,
  removeLocationList,
  updateLocationConfirmStatus,
};
