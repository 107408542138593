import { Avatar } from '@mui/material';
import { Badge, Button } from 'react-bootstrap';

import { Typography, SvgIcon, SvgIconSource } from 'components';

import { StaffProps } from 'pages/staff/Staff.types';
import { Modal } from 'components/modal';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { fileUrl } from 'util/fileHelper';

export interface StaffDetailModalProps {
  teamName: string;
  detail: StaffProps;
}

export const StaffDetailModal = ({ teamName, detail }: StaffDetailModalProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal.Container size="md" style={{ padding: 'var(--f24)', position: 'relative' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          스태프 정보
        </Typography>
        <Button variant="white" className="absolute top-6 right-8 p-1" onClick={handleClose}>
          <SvgIcon source={SvgIconSource.Close} />
        </Button>
      </div>
      <div className="text-left">
        <div className="d-flex align-items-center py-8 border-bottom">
          <div className="d-flex align-items-center justify-content-center flex-1">
            <Avatar src={fileUrl(detail?.thumbnail)} sx={{ width: 142, height: 142 }} />
          </div>
          <div className="flex-1">
            <div className="d-flex align-items-center gap-x-1">
              <Typography variant="f20-166--80">{detail?.name}</Typography>
              <Badge pill bg="sub" className="px-3 py-1 ms-2">
                <Typography variant="f12-166--48" className="fw-bold">
                  {teamName || detail.group || '미정'}
                </Typography>
              </Badge>
            </div>
            <div className="d-flex align-items-center gap-x-5 mt-6">
              <Typography variant="f14-166--56">소속</Typography>
              <Typography variant="f14-166--56">{detail.companyName}</Typography>
            </div>
            <div className="d-flex align-items-center gap-x-5 mt-2">
              <Typography variant="f14-166--56">직책</Typography>
              <Typography variant="f14-166--56">{detail.role}</Typography>
            </div>
          </div>
        </div>
        <div className="py-8">
          <Typography variant="f14-166--56" className="fw-bolder">
            연락처 정보
          </Typography>
          <div className="d-flex align-items-center gap-x-3 mt-5">
            <div className="w-6 lh-0">
              <SvgIcon source={SvgIconSource.Mobile} />
            </div>
            <Typography variant="f14-166--56" className="fw-medium">
              없음
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-x-3 mt-5">
            <div className="w-6 lh-0">
              <SvgIcon source={SvgIconSource.Envelope} />
            </div>
            <Typography variant="f14-166--56" className="fw-medium">
              없음
            </Typography>
          </div>
        </div>
      </div>
    </Modal.Container>
  );
};
