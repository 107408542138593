import { createReducer } from 'typesafe-actions';
import { LoginCheckAction, LoginCheckSuccessParam, LOGIN_CHECK_ACTION, LOGIN_CHECK_FAIL_ACTION, LOGIN_CHECK_SUCCESS_ACTION } from './CheckActions';

export type LoginCheckState = {
  isLogin: boolean;
  joinable: boolean;
  nextUrl?: string;
};

export const DefaultLoginCheckState: LoginCheckState = {
  isLogin: false,
  joinable: false,
  nextUrl: '/',
};


export const loginCheckReducer = createReducer<LoginCheckState, LoginCheckAction>(
  DefaultLoginCheckState,
  {
    [LOGIN_CHECK_ACTION]: (state) => {
      return state;
    },
    [LOGIN_CHECK_SUCCESS_ACTION]: (curr, action) => {
      const param: LoginCheckSuccessParam = action.payload;
      console.log('login check success', curr, param);
      return { nextUrl: param.url, isLogin: param.isLogin, joinable: param.isJoinEnabled };
    },
    [LOGIN_CHECK_FAIL_ACTION]: (state, action) => {
      const error = action.payload;
      console.log('login check fail %s', error.response?.data);
      return { ...state };
    },
  }
);
