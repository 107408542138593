import styles from './Button.module.scss';

type FormButtonType = {
  type?: 'form';
  background?: 'white' | 'default';
  text: string;
  icon?: string;
  style?: React.CSSProperties;
  onClick: () => void;
};

export const DefaultButton = ({ background = 'default', type, text, icon, style, onClick }: FormButtonType) => {
  const clickBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <button
      className={`${styles.default_button} 
         ${background === 'white' ? styles.btn_white : styles.btn_default} 
         ${type === 'form' ? styles.form : ''}
        `}
      onClick={clickBtn}
      style={style}>
      {icon && <img alt="image" src={icon} />}
      <span>{text}</span>
    </button>
  );
};
