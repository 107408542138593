import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Typography } from 'components';

import { TeamFormProps } from 'pages/staff/Staff.types';
import { createGroup } from 'modules/project/ProjectService';
import { Modal } from 'components/modal';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';

const defaultTeam: TeamFormProps = {
  name: '',
  description: '',
};

export interface TeamRegModalProps {
  projectNo: number;
  onConfirm?: (info?: TeamFormProps) => void;
}

export const TeamRegModal = ({ projectNo, onConfirm }: TeamRegModalProps) => {
  const dispatch = useAppDispatch();
  const [info, setInfo] = useState<TeamFormProps>(defaultTeam);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChangeInfo = (key: string, value: string) => {
    setErrorMessage(null);
    setInfo({ ...info, [key]: value });
  };

  const handleSave = async () => {
    let error = '';
    if (!info?.name) error = '팀 이름을 입력해주세요.';

    if (error) {
      setErrorMessage(error);
      return;
    }

    const result = await createGroup(projectNo, info);
    if (result) {
      onConfirm?.();
    }

    handleClose();
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          팀 추가하기
        </Typography>
      </div>
      <div className="text-left">
        <div className="d-flex flex-column gap-y-4">
          <div>
            <InputGroup className="input-sm no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="팀 이름을 입력해주세요."
                value={info?.name}
                onChange={(e) => handleChangeInfo('name', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
          <div>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control
                as="textarea"
                className="bg-light"
                placeholder="팀에 대한 설명을 입력해주세요."
                rows={8}
                value={info.description}
                onChange={(e) => handleChangeInfo('description', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
        </div>
        {errorMessage && (
          <div className="mt-4">
            <Typography variant="f12-166--48" className="text-danger">
              {errorMessage}
            </Typography>
          </div>
        )}
      </div>
      <div className="items-center gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleSave}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
};
