import * as React from 'react';
import { useSelector } from 'react-redux';
import { createTagged, updateTagged } from '../../../modules/tags/tagReducer';
import { closeModal } from '../../../modules/modal/modalReducer';
import { useAppDispatch } from '../../../hooks';
import { RootState } from '../../../modules';
import { SearchListModal } from 'components/modal';

export type CharacterModalProps = {
  param?: {
    resource: string;
    projectNo: string;
    sceneNo: string;
    highlightIndex: number;
    stripNo: string;
    type: number;
  };
  edit?: { taggedNo: number; curTag: number };
  tagNo: number;
};

export default function CharacterModal({ param, edit, tagNo }: CharacterModalProps) {
  const dispatch = useAppDispatch();

  const { tags } = useSelector((state: RootState) => state.tags);

  const formData = {
    highLightIndex: param?.highlightIndex,
    resourceName: param?.resource,
    stripNo: Number(param?.stripNo),
  };

  return (
    <>
      <SearchListModal
        size="medium"
        title="등장인물"
        disabled
        description="등장인물의 포지션 선택"
        items={[
          {
            text: '주연',
            event: () => {
              dispatch(closeModal());
              let main;
              if (tags.data) main = tags.data.find((elem) => elem.tagType == 'CHARACTER_MAIN');
              if (param) dispatch(createTagged({ tagNo: main?.tagNo || tagNo, formData: formData }));
              else if (edit)
                dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: main?.tagNo || tagNo } }));
            },
          },
          {
            text: '조단역',
            event: () => {
              dispatch(closeModal());
              let sub;
              if (tags.data) sub = tags.data.find((elem) => elem.tagType == 'CHARACTER_SUB');
              if (param) dispatch(createTagged({ tagNo: sub?.tagNo || tagNo, formData: formData }));
              else if (edit)
                dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: sub?.tagNo || tagNo } }));
            },
          },
          {
            text: '보조출연',
            event: () => {
              dispatch(closeModal());
              let extra;
              if (tags.data) extra = tags.data.find((elem) => elem.tagType == 'EXTRA');
              if (param) dispatch(createTagged({ tagNo: extra?.tagNo || tagNo, formData: formData }));
              else if (edit)
                dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: extra?.tagNo || tagNo } }));
            },
          },
        ]}
      />
    </>
  );
}
