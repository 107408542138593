import {
  AddScheduleGroupRequest,
  ScheduleGroup,
  AddScheduleRequest,
  Schedule,
  ShareType,
  Schedules,
  ScheduleList,
  SceneOrSchedule,
  HeadstripType,
  ShootingInfo,
} from 'pages/schedule/type';
import SvAPI from '../../util/SvAPI';
import ReducerGenerator from '../../util/ReducerGenerator';
import { AsyncState, asyncState } from '../../util/ReducerTypes';
import { AxiosResponse } from 'axios';

import _ from 'lodash';
import {  sortScheduleOrder } from '../../util/scheduleHelper';
import { AiSchedulingForm } from '../../pages/schedule/modal/LLMSchedulingModal';

export type ScheduleState = {
  shareType: ShareType | null;
  shootingInfo: AsyncState<ShootingInfo, Error>;
  scheduleGroups: AsyncState<ScheduleGroup[], Error>;
  sharedScheduleGroups: AsyncState<ScheduleGroup[], Error>;
  passedSchedules: AsyncState<Schedules[], Error>;
  completedSchedules: AsyncState<Schedules[], Error>;
  dailyScheduleGroups: AsyncState<ScheduleGroup[], Error>;
  schedules: AsyncState<Schedule[], Error>;
  scheduleLists: Schedule[];
  scheduleListControl: AsyncState<ScheduleList, Error>;
  addStripType?: HeadstripType | null;
  checkType: SceneOrSchedule | null;
  checkState: boolean;
  checkedScheduleLists: ScheduleList[];
  checkedSchedules: { [scheduleIdx: number]: Set<number> } | null;
  checkedScenes: Set<number>;
  eventKeyList: string[];
};

const initialState: ScheduleState = {
  shootingInfo: asyncState.initial(),
  shareType: null,
  scheduleGroups: asyncState.initial(),
  sharedScheduleGroups: asyncState.initial(),
  passedSchedules: asyncState.initial(),
  completedSchedules: asyncState.initial(),
  dailyScheduleGroups: asyncState.initial(),
  schedules: asyncState.initial(),
  scheduleLists: [],
  scheduleListControl: asyncState.initial(),
  addStripType: null,
  checkType: null,
  checkState: false,
  checkedScheduleLists: [],
  checkedSchedules: null,
  checkedScenes: new Set<number>(),
  eventKeyList: [],
};

const getSchedulesAfterRequest = (func: Promise<any>, projectNo: number, groupNo: number) => {
  return func.then(() => SvAPI.get(SvAPI.schedulesUrl(projectNo, groupNo), { noPage: true }).then((res) => res.data.body));
};

const reducerGenerator = new ReducerGenerator<ScheduleState, 'schedule'>(initialState);

const getShootingInfo = reducerGenerator.createThunkAction<'getShootingInfo', { projectNo: number }, any>({
  action: 'schedule/getShootingInfo',
  key: 'shootingInfo',
  thunk: (params) => SvAPI.get(SvAPI.shootingInfoUrl(params!.projectNo)).then((res) => res.data),
});

const getScheduleGroups = reducerGenerator.createThunkAction<
'getScheduleGroups',
{ projectNo: number; groupNo?: number; shareType?: ShareType },
{ shareType?: ShareType; groupNo?: number; scheduleGroups: ScheduleGroup[] }
>({
  action: 'schedule/getScheduleGroups',
  thunk: (params) =>
    params!.shareType
      ? SvAPI.get(
        SvAPI.scheduleGroupsUrl(params!.projectNo),
        params!.shareType ? { shareType: params!.shareType } : {}
      ).then((res) => ({ shareType: params!.shareType, grouNo: params!.groupNo, scheduleGroups: res.data }))
      : SvAPI.get(SvAPI.scheduleGroupsUrl(params!.projectNo)).then((res) => ({
        shareType: params!.shareType,
        groupNo: params!.groupNo,
        scheduleGroups: res.data,
      })),
  extraReducers: {
    success: (state, action) => {
      const shareType =
        action.payload.shareType !== undefined
          ? action.payload.shareType
          : action.payload.scheduleGroups.filter((sg) => sg.groupNo === action.payload.groupNo)[0].shareType;
      let groups = action.payload.scheduleGroups;
      if (shareType === 'SHARED') {
        if (!action.payload.shareType) {
          groups = action.payload.scheduleGroups.filter((sg) => sg.shareType === 'SHARED');
        }
        return {
          ...state,
          shareType,
          sharedScheduleGroups: { ...state.sharedScheduleGroups, loading: false, data: groups },
        };
      } else if (shareType === 'WORK') {
        groups = action.payload.scheduleGroups.filter((sg) => sg.shareType === 'WORK');
        return { ...state, shareType, scheduleGroups: { ...state.scheduleGroups, loading: false, data: groups } };
      } else if (shareType === 'DAILYSHOOT') {
        groups = action.payload.scheduleGroups.filter((sg) => sg.shareType === 'DAILYSHOOT');
        return {
          ...state,
          shareType,
          dailyScheduleGroups: { ...state.dailyScheduleGroups, loading: false, data: groups },
        };
      }
      return { ...state, shareType };
    },
  },
});

const getPassedSchedules = reducerGenerator.createThunkAction<
'getPassedSchedules',
{ projectNo: number },
ScheduleGroup[]
>({
  action: 'schedule/getPassedSchedules',
  key: 'passedSchedules',
  thunk: (params) =>
    SvAPI.get(SvAPI.scheduleGroupsUrl(params!.projectNo), { shareType: 'DAILYSHOOT' })
      .then((res) => {
        if (res.data) {
          return res.data[0]?.groupNo;
        }
        return undefined;
      })
      .then((groupNo) => groupNo ? SvAPI.get(SvAPI.passedSchedulesUrl(params!.projectNo, groupNo)) : undefined)
      .then((res) =>{ return res?.data || [];}),
});

const addScheduleGroup = reducerGenerator.createThunkAction<
'addScheduleGroup',
{ endDt?: string; name: string; projectNo: number; startDt?: string },
ScheduleGroup[]
>({
  action: 'schedule/addScheduleGroup',
  key: 'scheduleGroups',
  thunk: (params) =>
    SvAPI.post(SvAPI.scheduleGroupsUrl(params!.projectNo), params).then(() =>
      SvAPI.get(SvAPI.scheduleGroupsUrl(params!.projectNo), { shareType: 'WORK' }).then((res) => res.data)
    ),
});

const deleteScheduleGroup = reducerGenerator.createThunkAction<
'deleteScheduleGroup',
{ projectNo: number; groupNo: number; shareType: ShareType },
{ shareType: ShareType; scheduleGroups: ScheduleGroup[] }
>({
  action: 'schedule/deleteScheduleGroup',
  thunk: (params) =>
    SvAPI.delete(SvAPI.scheduleGroupUrl(params!.projectNo, params!.groupNo)).then(() =>
      SvAPI.get(SvAPI.scheduleGroupsUrl(params!.projectNo), { shareType: params!.shareType }).then((res) => ({
        shareType: params!.shareType,
        scheduleGroups: res.data,
      }))
    ),
  extraReducers: {
    success: (state, action) => {
      if (action.payload.shareType === 'WORK')
        return { ...state, scheduleGroups: { ...state.scheduleGroups, data: action.payload.scheduleGroups } };
      else if (action.payload.shareType === 'SHARED')
        return {
          ...state,
          sharedScheduleGroups: { ...state.sharedScheduleGroups, data: action.payload.scheduleGroups },
        };
      return { ...state };
    },
  },
});

const modifyScheduleGroup = reducerGenerator.createThunkAction<
'modifyScheduleGroup',
{ projectNo: number; groupNo: number; name: string },
ScheduleGroup[]
>({
  action: 'schedule/modifyScheduleGroup',
  key: 'scheduleGroups',
  thunk: (params) =>
    SvAPI.put(SvAPI.scheduleGroupUrl(params!.projectNo, params!.groupNo), { name: params!.name }).then(() =>
      SvAPI.get(SvAPI.scheduleGroupsUrl(params!.projectNo), { shareType: 'WORK' }).then((res) => res.data.body)
    ),
});

const modifySchedule = reducerGenerator.createThunkAction<
'modifySchedule',
{ projectNo: number; groupNo: number; scheduleNo: number; date: string; startTime: string; contents?: string },
any[]
>({
  action: 'schedule/modifySchedule',
  key: 'schedules',
  thunk: (params) =>
    SvAPI.put(SvAPI.scheduleUrl(params!.projectNo, params!.groupNo, params!.scheduleNo), {
      date: params!.date,
      startTime: params.startTime,
      contents: params.contents,
    }).then((res) => res.data.body),
});

const modifyScheduleList = reducerGenerator.createThunkAction<
'modifyScheduleList',
{ projectNo: number; listNo: number, duration: number },
any[]
>({
  action: 'schedule/modifyScheduleList',
  key: 'scheduleListControl',
  thunk: (params) =>
    SvAPI.put(SvAPI.scheduleListModifyUrl(params!.projectNo, params!.listNo), {
      duration: params!.duration,
    }).then((res) => res.data.body),
});

const shareScheduleGroup = reducerGenerator.createThunkAction<
'shareScheduleGroup',
{ projectNo: number; groupNo: number; query: AddScheduleGroupRequest },
any
>({
  action: 'schedule/shareScheduleGroup',
  // key: 'scheduleGroups',
  thunk: (params) =>
    SvAPI.put(SvAPI.shareScheduleGroupUrl(params!.projectNo, params!.groupNo), params!.query).then((res) => res.data),
});

const confirmScheduleGroup = reducerGenerator.createThunkAction<
'confirmScheduleGroup',
{ projectNo: number; groupNo: number; query: AddScheduleGroupRequest },
any
>({
  action: 'schedule/confirmScheduleGroup',
  modal: {
    failText: '확정 실패',
    useFailRspDetail: true,
  },
  thunk: (params) =>
    SvAPI.put(SvAPI.confirmScheduleGroupUrl(params!.projectNo, params!.groupNo), params!.query).then((res) => res.data),
});

const getSchedules = reducerGenerator.createThunkAction<
'getSchedules',
{ projectNo: number; groupNo: number },
Schedule[]
>({
  action: 'schedule/getSchedules',
  key: 'schedules',
  thunk: (params) => SvAPI.get(SvAPI.schedulesUrl(params!.projectNo, params!.groupNo), { noPage: true }).then((res) => res.data.body),
  extraReducers: {
    loading: (state) => {
      return { ...state, schedules: { ...state.schedules, loading: true, data: null } };
    },
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const setSearchResult = reducerGenerator.createAction<'setSchedules', { keyword: string; result: Schedule[] }>({
  action: 'schedule/setSchedules',
  reducer: (state, action) => {
    return {
      ...state,
      scheduleLists: action.payload.keyword === '' ? state.schedules.data! : action.payload.result,
    };
  },
});

const addSchedule = reducerGenerator.createAction<
'addSchedule',
{ toOrder: number; toScIdx: number; newItems: ScheduleList[] }
>({
  action: 'schedule/addSchedule',
  reducer: (state, action) => {
    if (!state.checkedScenes || !state.schedules.data) return state;
    const schedules = _.cloneDeep([...state.schedules.data]);
    let newCheckedSchedules: { [scheduleIdx: number]: Set<number> } = { [action.payload.toScIdx]: new Set() };
    for (let i = 0; i < action.payload.newItems.length; i++) {
      newCheckedSchedules[action.payload.toScIdx].add(action.payload.toOrder + i);
    }
    schedules[action.payload.toScIdx].scheduleList.splice(action.payload.toOrder, 0, ...action.payload.newItems);
    return {
      ...state,
      scheduleLists: sortScheduleOrder(schedules),
      checkedSchedules: newCheckedSchedules,
      checkedScheduleLists: action.payload.newItems,
      checkedScenes: new Set<number>(),
      checkState: !state.checkState,
      checkType:'schedule',
    };
  },
});

const addSchedules = reducerGenerator.createThunkAction<
'addSchedules',
{ projectNo: number; groupNo: number; scheduleItem: AddScheduleRequest },
Schedule[]
>({
  action: 'schedule/addSchedules',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.post(
        SvAPI.scheduleListUrl(params!.projectNo, params!.groupNo, params!.scheduleItem.scheduleNo),
        params!.scheduleItem
      ),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
        checkType:'schedule',
      };
    },
  },
});

const deleteSchedules = reducerGenerator.createThunkAction<
'deleteSchedules',
{ projectNo: number; groupNo: number; scheduleNo: number; id: string },
Schedule[]
>({
  action: 'schedule/deleteSchedules',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.delete(SvAPI.scheduleListUrl(params!.projectNo, params!.groupNo, params!.scheduleNo), {
        order: params!.id,
      }).then((res) => res.data.body),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});
const deleteSchedulesWithListNo = reducerGenerator.createThunkAction<
'deleteSchedules',
{ projectNo: number; groupNo: number; listNos: string },
Schedule[]
>({
  action: 'schedule/deleteSchedules',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.delete(SvAPI.deleteSchedulesWithListNo(params!.projectNo, params!.groupNo), {
        listNos: params!.listNos,
      }).then((res) => res.data.body),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const requestChangeSchedules = (
  projectNo: number,
  groupNo: number,
  arr: { fromOrder: string; toOrder: number; scheduleNo: number; toOrderScheduleNo: number }[]
) => {
  const res = arr.reduce((prev, curr) => {
    const currentPromise: Promise<AxiosResponse<any, any>> = prev.then(() => {
      return SvAPI.put(
        SvAPI.scheduleListChangeOrders(projectNo, groupNo, curr.scheduleNo),
        {},
        { fromOrder: curr.fromOrder, toOrder: curr.toOrder, toOrderScheduleNo: curr.toOrderScheduleNo }
      );
    });
    return currentPromise;
  }, Promise.resolve({}));
  return res;
};

const changeSchedules = reducerGenerator.createThunkAction<
'changeSchedules',
{
  projectNo: number;
  groupNo: number;
  arr: { fromOrder: string; toOrder: number; scheduleNo: number; toOrderScheduleNo: number }[];
},
Schedule[]
>({
  action: 'schedule/changeSchedules',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      requestChangeSchedules(params!.projectNo, params!.groupNo, params!.arr),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const changeSchedulesWithListNo = reducerGenerator.createThunkAction<
'changeSchedulesWithListNo',
{ projectNo: number; groupNo: number; query: { fromListNos: string; toListNo: number; frontOfToListNo: boolean } },
Schedule[]
>({
  action: 'schedule/changeSchedulesWithListNo',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.put(SvAPI.scheduleListChangeOrdersWithListNo(params!.projectNo, params!.groupNo), {}, params!.query).then(
        (res) => res.data
      ),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const changeSchedule = reducerGenerator.createAction<'changeSchedule',
{ toOrder: number; toScIdx: number; toScNo: number }
>({
  action: 'schedule/changeSchedule',
  reducer: (state, action) => {
    if (!state.checkedSchedules || !state.schedules.data) return state;

    const schedules = _.cloneDeep([...state.schedules.data]);

    let insertIdx = action.payload.toOrder;
    let changeIdx = action.payload.toOrder;

    let newCheckedSchedules: { [scheduleIdx: number]: Set<number> } = { [action.payload.toScIdx]: new Set() };
    let newCheckedItems: ScheduleList[] = [];

    for (let scIdx of Object.keys(state.checkedSchedules)) {
      const ords = Array.from(state.checkedSchedules[parseInt(scIdx)]).sort((a, b) => b - a);
      for (let i = 0; i < ords.length; i++) {
        if (parseInt(scIdx) === action.payload.toScIdx && ords[i] < insertIdx) {
          changeIdx -= 1;
        }
        schedules[parseInt(scIdx)].scheduleList.splice(ords[i], 1);
        newCheckedItems.push({
          ...state.schedules.data[parseInt(scIdx)].scheduleList[ords[i]],
          scheduleNo: action.payload.toScNo,
        });
      }
    }

    for (let i = 0; i < state.checkedScheduleLists.length; i++) {
      newCheckedSchedules[action.payload.toScIdx].add(changeIdx + i);
    }
    schedules[action.payload.toScIdx].scheduleList.splice(changeIdx, 0, ...newCheckedItems);
    return {
      ...state,
      scheduleLists: sortScheduleOrder(schedules),
      checkedSchedules: newCheckedSchedules,
      checkedScheduleLists: newCheckedItems,
      checkState: !state.checkState,
    };
  },
});



const deleteSchedule = reducerGenerator.createEmptyAction<'deleteSchedule'>({
  action: 'schedule/deleteSchedule',
  reducer: (state) => {
    if (!state.checkedSchedules || !state.schedules.data) return state;
    const schedules = _.cloneDeep([...state.schedules.data]);
    for (let scIdx of Object.keys(state.checkedSchedules)) {
      const ords = Array.from(state.checkedSchedules[parseInt(scIdx)]).sort((a, b) => b - a);
      for (let i = 0; i < ords.length; i++) {
        schedules[parseInt(scIdx)].scheduleList.splice(ords[i], 1);
      }
    }
    return {
      ...state,
      scheduleLists: sortScheduleOrder(schedules),
      checkedSchedules: null,
      checkedScheduleLists: [],
      checkState: !state.checkState,
    };
  },
});

const copySchedule = reducerGenerator.createThunkAction<
'copySchedule',
{ projectNo: number; groupNo: number; scheduleNo: number; listNo: number },
Schedule[]
>({
  action: 'schedule/copySchedule',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.post(SvAPI.scheduleListCopyUrl(params!.projectNo, params!.groupNo, params!.scheduleNo, params!.listNo)),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const autoScheduling = reducerGenerator.createThunkAction<
'autoScheduling',
{ projectNo: number; groupNo: number; formData: AiSchedulingForm },
Schedule[]
>({
  action: 'schedule/autoScheduling',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.post(SvAPI.autoScheduleUrl(params!.projectNo, params!.groupNo), params!.formData, { ai: 'ai' }),
      params!.projectNo,
      params!.groupNo
    ),
  modal: {
    loadingText: 'AI 스케줄링이 진행중입니다',
    successText: 'AI 스케줄링 완료',
    failText: 'AI 스케줄링 실패',
    useFailRspDetail: true,
  },
  extraReducers: {
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const setAddStripType = reducerGenerator.createAction<'setAddStripType', { type: HeadstripType | null }>({
  action: 'schedule/setAddStripType',
  reducer: (state, action) => {
    return { ...state, addStripType: action.payload.type };
  },
});

const addHeadstrip = reducerGenerator.createThunkAction<
'addHeadstrip',
{ projectNo: number; date: string; groupNo: number; type: string },
Schedule[]
>({
  action: 'schedule/addHeadstrip',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.post(SvAPI.scheduleStripUrl(params!.projectNo, params!.groupNo), params),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const modifyHeadstrip = reducerGenerator.createThunkAction<
'modifyHeadstrip',
{ date: string; groupNo: number; projectNo: number; scheduleNo: number },
Schedule[]
>({
  action: 'schedule/modifyHeadstrip',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.put(SvAPI.scheduleUrl(params!.projectNo, params!.groupNo, params!.scheduleNo), params),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const deleteHeadstrip = reducerGenerator.createThunkAction<
'deleteHeadstrip',
{ projectNo: number; groupNo: number; scheduleNos: number },
Schedule[]
>({
  action: 'schedule/deleteHeadstrip',
  key: 'schedules',
  thunk: (params) =>
    getSchedulesAfterRequest(
      SvAPI.delete(SvAPI.scheduleStripUrl(params!.projectNo, params!.groupNo), {
        scheduleNos: '' + params!.scheduleNos,
      }),
      params!.projectNo,
      params!.groupNo
    ),
  extraReducers: {
    loading: (state) => state,
    success: (state, action) => {
      return {
        ...state,
        schedules: { ...state.schedules, loading: false, data: action.payload },
        scheduleLists: action.payload,
      };
    },
  },
});

const checkAssignedScene = reducerGenerator.createAction<
'checkAssignedScene',
{ scIdx: number; scList: ScheduleList }
>({
  action: 'schedule/checkAssignedScene',
  reducer: (state, action) => {
    if (state.checkedSchedules) {
      // checkedSchedules에 값이 존재하는 경우
      if (state.checkedSchedules[action.payload.scIdx]) {
        //스케줄 넘버에 해당하는 상태가 존재할 때
        state.checkedSchedules[action.payload.scIdx].add(action.payload.scList.order);
        state.checkedScheduleLists.push(action.payload.scList);
      } else {
        // 상태가 비어있는 경우
        state.checkedSchedules[action.payload.scIdx] = new Set<number>().add(action.payload.scList.order);
        state.checkedScheduleLists = [action.payload.scList];
      }
    } else {
      state.checkedSchedules = { [action.payload.scIdx]: new Set<number>().add(action.payload.scList.order) };
      state.checkedScheduleLists = [action.payload.scList];
    }
    return { ...state, checkType:'schedule', checkedScenes: new Set<number>() };
  },
});

const uncheckAssignedScene = reducerGenerator.createAction<
'uncheckAssignedScene',
{ scIdx: number; scList: ScheduleList }
>({
  action: 'schedule/uncheckAssignedScene',
  reducer: (state, action) => {
    if (!state.checkedSchedules) return state;

    //const scItems = state.checkedSchedules[action.payload.scIdx].items.filter((item)=>item.scheduleNo === action.payload.scList.scheduleNo && item.order === action.payload.scList.order);

    const newItems = state.checkedScheduleLists.filter((item) => {
      return item.order !== action.payload.scList.order && item.scheduleNo !== action.payload.scList.scheduleNo;
    });
    state.checkedSchedules[action.payload.scIdx].delete(action.payload.scList.order);

    return { ...state, checkedScheduleLists: newItems };
  },
});

const checkUnassignedScene = reducerGenerator.createAction<'checkUnassignedScene', { order: number }>({
  action: 'schedule/checkUnassignedScene',
  reducer: (state, action) => {
    if (state.checkedScenes) {
      state.checkedScenes.add(action.payload.order);
    } else {
      const newSet = new Set<number>();
      newSet.add(action.payload.order);
      state.checkedScenes = newSet;
    }
    return { ...state, checkType: 'scene', checkedScheduleLists:[], checkedSchedules:null };
  },
});

const uncheckUnassignedScene = reducerGenerator.createAction<'uncheckUnassignedScene', { order: number }>({
  action: 'schedule/uncheckUnassignedScene',
  reducer: (state, action) => {
    state.checkedScenes?.delete(action.payload.order);
    return { ...state };
  },
});

const clearCheckedItems = reducerGenerator.createEmptyAction<'clearCheckedItems'>({
  action: 'schedule/clearCheckedItems',
  reducer: (state) => {
    return { ...state, checkType: null, checkedSchedules: null, checkedScheduleLists: [], checkedScenes: new Set<number>() };
  },
});

const setCheckState = reducerGenerator.createEmptyAction<'setCheckState'>({
  action: 'schedule/setCheckState',
  reducer: (state) => {
    return { ...state, checkState: !state.checkState };
  },
});

const setEventKey = reducerGenerator.createAction<
'setEventKey',
{ eventKey: string | string[] | 'clear'; keepOpen?: boolean }
>({
  action: 'schedule/setEventKey',
  reducer: (state, action) => {
    if (action.payload.eventKey === 'clear') return { ...state, eventKeyList: [] };

    const filteredList = state.eventKeyList.filter((ek) => ek !== `${action.payload.eventKey}`);
    const isCloseAccordion = filteredList.length !== state.eventKeyList.length;

    if (isCloseAccordion) {
      if (!action.payload.keepOpen) return { ...state, eventKeyList: filteredList };
    } else {
      if (typeof action.payload.eventKey === 'string') {
        state.eventKeyList.push(action.payload.eventKey);
      } else {
        state.eventKeyList.concat(action.payload.eventKey);
      }
    }
    return { ...state };
  },
});

export {
  getShootingInfo,
  getScheduleGroups,
  getPassedSchedules,
  addScheduleGroup,
  deleteScheduleGroup,
  modifyScheduleGroup,
  modifySchedule,
  shareScheduleGroup,
  confirmScheduleGroup,
  getSchedules,
  setSearchResult,
  addSchedules,
  deleteSchedules,
  deleteSchedulesWithListNo,
  requestChangeSchedules,
  changeSchedules,
  changeSchedulesWithListNo,
  changeSchedule,
  addSchedule,
  deleteSchedule,
  copySchedule,
  setAddStripType,
  addHeadstrip,
  modifyHeadstrip,
  deleteHeadstrip,
  checkAssignedScene,
  uncheckAssignedScene,
  checkUnassignedScene,
  uncheckUnassignedScene,
  clearCheckedItems,
  setCheckState,
  setEventKey,
  modifyScheduleList,
  autoScheduling,
};

const scheduleReducer = reducerGenerator.createReducer();

export default scheduleReducer;
