import { LocationFormProps } from 'pages/location/Location.types';
import { LocationsScheduleFormProps } from 'pages/location/modals';
import SvAPI from 'util/SvAPI';

const findLocation = async (locationNo: number) => {
  return SvAPI.get(SvAPI.locationUrl(locationNo), {}).then((res) => res.data);
};

const createLocation = async (companyNo: number, params: LocationFormProps) => {
  return SvAPI.post(SvAPI.companyLocationUrl(companyNo), params).then((res) => res.data);
};

const updateLocation = async (locationNo: number, params: LocationFormProps) => {
  return SvAPI.put(SvAPI.locationUrl(locationNo), params).then((res) => res.data);
};

const deleteLocation = async (locationNo: number) => {
  return SvAPI.delete(SvAPI.locationUrl(locationNo)).then((res) => res.data);
};

const findLocationSchedules = async (projectNo: number, locationNo: number) => {
  return SvAPI.get(SvAPI.projectLocationSchedulesUrl(projectNo, locationNo)).then((res) => res.data);
};

const findAllLocationSchedules = async (projectNo: number, params: {}) => {
  return SvAPI.get(SvAPI.projectAllLocationSchedulesUrl(projectNo), params).then((res) => res.data);
};

const updateLocationScheduleMemo = async (projectNo: number, locationNo: number, params: {}) => {
  return SvAPI.put(SvAPI.projectLocationSchedulesUrl(projectNo, locationNo) + '/groups', params).then(
    (res) => res.data
  );
};

const createLocationsSchedule = async (projectNo: number, locationNo: number, params: LocationsScheduleFormProps) => {
  return SvAPI.post(SvAPI.projectLocationSchedulesUrl(projectNo, locationNo), params).then((res) => res.data);
};

const updateLocationsSchedule = async (projectNo: number, locationNo: number, params: LocationsScheduleFormProps) => {
  return SvAPI.put(SvAPI.projectLocationSchedulesUrl(projectNo, locationNo), params).then((res) => res.data);
};

export {
  findLocation,
  createLocation,
  updateLocation,
  deleteLocation,
  findLocationSchedules,
  findAllLocationSchedules,
  updateLocationScheduleMemo,
  createLocationsSchedule,
  updateLocationsSchedule,
};
