import { Col } from 'react-bootstrap';
import styles from './LabelInput.module.scss';
import * as React from 'react';
export interface LabelInputProps<T extends HTMLElement> {
  label: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<T>;
  initValue?: string;
  formValidation?: FormValidation;
  labelPos?: 'left' | 'top';
  style?: React.CSSProperties;
  disabled?: boolean;
  totalWidth?: LengthRatio;
  labelWidth?: LengthRatio;
}

export const getMainClassNames = (labelPos?: string) => {
  if (labelPos === 'top') {
    return '';
  } else {
    return 'row flex-nowrap';
  }
};


export const LabelInput = ({
  label,
  placeholder,
  onChange,
  initValue,
  formValidation,
  labelPos,
  totalWidth = 12,
  labelWidth = 2,
  disabled = false,
}: LabelInputProps<HTMLInputElement>) => {

  const [value, setValue] = React.useState<string>(initValue || '');

  React.useEffect(() => {
    setValue(initValue || '');
  }, [initValue]);

  
  const printError = () => {
    if (formValidation && formValidation.fieldErros && formValidation.messages) {
      for (const msg of formValidation.messages) {
        if (formValidation.fieldErros?.type === msg.type) {
          return <span className={styles.error_message}>{msg.message}</span>;
        }
      }
    }
  };

  const register = { ...formValidation ?
    formValidation.register(formValidation.registeredKey ? formValidation.registeredKey
      : '', formValidation.validationObject) : {} };

  const onChanged: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (register.onChange) {
      register.onChange(event);
    }
    if (onChange) {
      onChange(event);
    } 
    setValue(event.target.value);
    
  };

  const content = (
    <>
      <Col sm={labelPos === 'top' ? totalWidth : labelWidth} 
        className={labelPos === 'top' ? styles.label_top_box : styles.label_left_box}>
        <label className={labelPos === 'top' ?  styles.label_top : styles.label}>
          {label}
        </label>
      </Col>
      <Col 
        className={styles.input_container}
        sm={labelPos === 'top' ? totalWidth : totalWidth - labelWidth}
      >
        <input
          disabled={disabled}
          {...{ ...register, onChange: onChanged }}
          className={styles.input_box}
          placeholder={placeholder}
          value={value}/>
        <div className={styles.error_message_container}>{printError()}</div>
      </Col>
    </>
  );

  return (
    <>
      {totalWidth && totalWidth !== 12 ? content : 
        <div className={getMainClassNames(labelPos)}>
          {content}
        </div>
      }
    </>
  );
};
