import { createReducer } from 'typesafe-actions';
import { LOGIN, LoginAction, LOGIN_FAIL, LOGIN_SUCCESS } from './actions';

const loginReducer = createReducer<{}, LoginAction>({}, {
  [LOGIN]:(state, action) => {
    console.log('login action call %s', action.type);
    return state;
  },
  [LOGIN_SUCCESS]:(_, action) => {
    const redirectUrl = action.payload.url;
    console.log('login success redirect to %s', redirectUrl);
    location.replace(redirectUrl);
    return redirectUrl;
  },
  [LOGIN_FAIL]:(_, action) => {
    alert('아이디가 존재하지 않거나 비밀번호를 확인해주세요.');
    console.log('login fail', action.payload);
    return {};
  },
});

export default loginReducer;
