import { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getActors } from 'modules/characters/CharacterReducer';
import { closeModal, openModal, resetModalState } from 'modules/modal/modalReducer';
import { fileUrl } from 'util/fileHelper';

import { Modal, ModalTypes } from 'components/modal';
import { CONFIRM_MESSAGES } from 'util/constant';
import { findExcelDownloadUrl } from 'modules/characters/CharacterService';

export interface CastingListModalProps {
  projectId: number;
}

export const CastingListModal = ({ projectId }: CastingListModalProps) => {
  const dispatch = useAppDispatch();
  const { actors } = useAppSelector((state) => state.character);
  const [excludedIds, setExcludedIds] = useState<number[]>([]);

  useEffect(() => {
    if (projectId) {
      dispatch(getActors({ projectNo: Number(projectId) }));
    }
  }, [projectId]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleRemoveCasting = (idx: number) => {
    setExcludedIds([...excludedIds, idx]);
  };

  const handleDownloadExcel = () => {
    const url = findExcelDownloadUrl(projectId, excludedIds);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    dispatch(resetModalState());
  };

  const handleConfirmDownload = () => {
    if (!actors.data?.length) return;

    dispatch(
      openModal({
        type: ModalTypes.Confirm,
        props: { ...CONFIRM_MESSAGES.CASTING_EXCEL, onConfirm: handleDownloadExcel },
      })
    );
  };

  return (
    <Modal.Container size="lg" style={{ padding: 'var(--f45)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          캐스팅 후보리스트
        </Typography>
        <Typography className="text-blue-gray-300">리스트업 하고 싶은 후보를 선택해주세요.</Typography>
      </div>
      <div className="h-90 overflow-y-auto text-left">
        <div className="pb-6 pt-3">
          {actors.data?.map((actor, index) => {
            const actorNo = actor.companyActorNo;
            if (!actorNo || excludedIds.indexOf(actorNo) > -1) return null;

            return (
              <div key={index} className="d-flex align-items-center bg-light rounded py-2 px-5 mb-2">
                <Avatar src={fileUrl(actor.imgUrl)} className="me-4" sx={{ width: 40, height: 40 }} />
                <Typography className="fw-bold  flex-1">{actor.name}</Typography>
                <div>
                  <Typography tag="span" className="text-blue-gray-300">
                    캐스팅 후보 {index + 1}
                  </Typography>{' '}
                  <span className="pointer" onClick={() => handleRemoveCasting(actorNo)}>
                    <SvgIcon source={SvgIconSource.CloseCircle} className="ms-1" color="danger" />
                  </span>
                </div>
              </div>
            );
          })}
          {!actors.loading && !actors.data?.length && (
            <Typography className="text-blue-gray-300 text-center">리스트가 비었습니다.</Typography>
          )}
        </div>
      </div>
      <div className="items-center justify-content-end gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleConfirmDownload}>
          파일저장
        </Button>
      </div>
    </Modal.Container>
  );
};
