import * as React from 'react';
import { closeModal, openModal } from '../../../modules/modal/modalReducer';
import { ModalTypes } from 'components/modal';
import { TagTypeColor, TagType } from '../../tag/Tag';
import { createTagged, updateTagged } from '../../../modules/tags/tagReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { useAppDispatch } from '../../../hooks';
import { SearchListModal, SearchListValue } from 'components/modal';

export type SceneTagModalProps = {
  param?: {
    resource: string;
    projectNo: string;
    sceneNo: string;
    highlightIndex: number;
    stripNo: string;
    type: number;
  };
  edit?: { taggedNo: number; curTag: number };
  modalPos: { x: number; y: number };
};

const commonStyle = {
  color: 'white',
  fontWeight: '600',
  padding: 'var(--f2) var(--f14)',
  borderRadius: 'var(--f20)',
};

export default function SceneTagModal({ param, edit, modalPos }: SceneTagModalProps) {
  const dispatch = useAppDispatch();

  const { tags } = useSelector((state: RootState) => state.tags);
  const [tagCategory, setTagCategory] = React.useState<SearchListValue[]>([]);

  const showCharacterModal = (tagNo: number) => {
    dispatch(
      openModal({
        type: ModalTypes.TagCharacter,
        props: {
          param: param,
          edit: edit,
          tagNo: tagNo,
        },
        overlayOptions: {
          modalPos: { x: modalPos.x, y: modalPos.y },
          translate: { x: 0, y: 40 },
          dim: false,
        },
      })
    );
  };

  React.useEffect(() => {
    if (tags.data) {
      tags.data?.forEach((val) => {
        if (val.tagType == TagType.ETC || val.tagType == TagType.CHARACTER_SUB || val.tagType == TagType.EXTRA) return;
        let temp: SearchListValue = {
          text: val.name,
          style: { ...commonStyle },
        };
        const formData = {
          highLightIndex: param?.highlightIndex,
          resourceName: param?.resource,
          stripNo: Number(param?.stripNo),
        };
        switch (val.tagType) {
          case TagType.CHARACTER_MAIN:
            temp.style = { ...temp.style, backgroundColor: TagTypeColor.CHARACTER };
            temp.event = () => {
              dispatch(closeModal());
              showCharacterModal(val.tagNo);
            };
            setTagCategory((prev) => [...prev, temp]);
            break;
          case TagType.PROP:
            if (param?.type == 1) break;
            temp.style = { ...temp.style, backgroundColor: TagTypeColor.PROP };
            temp.event = () => {
              dispatch(closeModal());
              if (param) dispatch(createTagged({ tagNo: val.tagNo, formData: formData }));
              else if (edit) dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: val.tagNo } }));
            };
            setTagCategory((prev) => [...prev, temp]);
            break;
          case TagType.COSTUME:
            if (param?.type == 1) break;
            temp.style = { ...temp.style, backgroundColor: TagTypeColor.COSTUME };
            temp.event = () => {
              dispatch(closeModal());
              if (param) dispatch(createTagged({ tagNo: val.tagNo, formData: formData }));
              else if (edit) dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: val.tagNo } }));
            };
            setTagCategory((prev) => [...prev, temp]);
            break;
          case TagType.MAKEUP:
            if (param?.type == 1) break;
            temp.style = { ...temp.style, backgroundColor: TagTypeColor.MAKEUP };
            temp.event = () => {
              dispatch(closeModal());
              if (param) dispatch(createTagged({ tagNo: val.tagNo, formData: formData }));
              else if (edit) dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: val.tagNo } }));
            };
            setTagCategory((prev) => [...prev, temp]);
            break;
          case TagType.SFX:
            if (param?.type == 1) break;
            temp.style = { ...temp.style, backgroundColor: TagTypeColor.SFX };
            temp.event = () => {
              dispatch(closeModal());
              if (param) dispatch(createTagged({ tagNo: val.tagNo, formData: formData }));
              else if (edit) dispatch(updateTagged({ taggedNo: edit.taggedNo, formData: { tagNo: val.tagNo } }));
            };
            setTagCategory((prev) => [...prev, temp]);
            break;
          default:
            break;
        }
      });
    }
  }, [tags.loading]);

  return (
    <SearchListModal
      size="medium"
      title="태깅 검색"
      description="태깅 선택 또는 생성"
      items={tagCategory}
      style={{ position: 'absolute', top: 0, left: 0, transform: `translate(${modalPos.x}px, ${modalPos.y}px)` }}
    />
  );
}
