import classNames from 'classnames';
import { TypographyProps } from './Typography.types';
import styles from './Typography.module.scss';

export const Typography = ({ tag = 'div', variant = 'body', className = '', children }: TypographyProps) => {
  const Component = tag;

  const style = classNames('m-0', className, {
    [styles.body]: variant === 'body',
    [styles.f10_normal__40]: variant === 'f10-normal--40',
    [styles.f12_normal__48]: variant === 'f12-normal--48',
    [styles.f12_normal__48__shadow]: variant === 'f12-normal--48--shadow',
    [styles.f12_140_normal]: variant === 'f12-140-normal',
    [styles.f16_140_normal]: variant === 'f16-140-normal',
    [styles.f14_normal__56]: variant === 'f14-normal--56',
    [styles.f18_normal__72]: variant === 'f18-normal--72',
    [styles.f32_normal__64]: variant === 'f32-normal--64',
    [styles.f15_normal]: variant === 'f15-normal',
    [styles.f10_166__40]: variant === 'f10-166--40',
    [styles.f12_166__48]: variant === 'f12-166--48',
    [styles.f14_166__56]: variant === 'f14-166--56',
    [styles.f16_166__64]: variant === 'f16-166--64',
    [styles.f18_166__72]: variant === 'f18-166--72',
    [styles.f20_140__40]: variant === 'f20-140--40',
    [styles.f20_166__80]: variant === 'f20-166--80',
    [styles.f24_166__96]: variant === 'f24-166--96',
    [styles.f32_166__128]: variant === 'f32-166--128',
    [styles.f40_166__160]: variant === 'f40-166--160',
    [styles.f44_166__176]: variant === 'f44-166--176',
  });

  return <Component className={style}>{children}</Component>;
};
