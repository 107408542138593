import { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource, FileButton } from 'components';

import FileService from 'modules/file/FileService';
import { PlaceFormProps, PlaceProps } from 'pages/location/Location.types';
import { fileName } from 'util/fileHelper';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { Modal, ModalTypes } from 'components/modal';
import { createPlace, updatePlace } from 'modules/place/PlaceService';

export interface PlaceInfoModalProps {
  projectNo: number;
  data?: PlaceProps;
  onDelete?: () => void;
  onConfirm?: (placeNo: number) => void;
}

const defaultFormData = {
  name: '',
  imgUrl: '',
  contact: '',
  addr: '',
};

export const PlaceInfoModal = ({ projectNo, data, onDelete, onConfirm }: PlaceInfoModalProps) => {
  const dispatch = useAppDispatch();
  const [info, setInfo] = useState<PlaceFormProps>(defaultFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (data) {
      setInfo({
        name: data?.name || '',
        imgUrl: data?.placeImg?.[0]?.imgPath || '',
        contact: '',
        addr: '',
      });
    } else {
      setInfo(defaultFormData);
    }
  }, [data]);

  const handleClose = () => {
    dispatch(closeModal(ModalTypes.PlaceInfo));
  };

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;

    setErrors({ ...errors, imgUrl: '' });
    new FileService().updateImage(files[0], undefined, false).then((value: string) => {
      setInfo({ ...info, imgUrl: value });
    });
  };

  const handleDelteImage = () => {
    setInfo({ ...info, imgUrl: '' });
  };

  const handleChangeInfo = (key: string, value: string) => {
    setErrors({ ...errors, [key]: '' });
    setInfo({ ...info, [key]: value });
  };

  const handleSave = async () => {
    let error = {};
    if (!info?.name) error = { name: '장소명을 입력해주세요.' };
    // if (!info?.imgUrl) error = { ...error, imgUrl: '이미지 파일을 업로드 해주세요.' };

    if (Object.keys(error).length) {
      setErrors(error);
      return;
    }

    let result;
    if (data?.placeNo) {
      result = await updatePlace(projectNo, data?.placeNo, info);
    } else {
      result = await createPlace(projectNo, info);
    }

    if (result?.placeNo) {
      onConfirm?.(result.placeNo);
      handleClose();
    }
  };

  const handleDelete = () => {
    if (!data?.placeNo) {
      handleClose();
      return;
    }

    onDelete?.();
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          기본 정보
        </Typography>
      </div>
      <div className="text-left">
        <InputGroup className="input-sm no-split no-border">
          <Form.Control
            className="bg-light"
            readOnly
            isInvalid={!!errors.imgUrl}
            value={fileName(info?.imgUrl || '')}
            placeholder="이미지"
          />
          <InputGroup.Text className="bg-light">
            <Button
              variant="gray"
              className="d-flex align-items-center justify-content-center bs-20px p-0 rounded-circle"
              onClick={handleDelteImage}>
              <SvgIcon source={SvgIconSource.Close} size={16} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <FileButton variant="dark" className="w-100 h-8 mt-3 mb-6" accept="image/*" onFileChange={handleChangeImage}>
          <Typography variant="f12-166--48">이미지 파일 업로드</Typography>
        </FileButton>

        <div className="d-flex flex-column gap-y-4">
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              장소명
            </Typography>
            <InputGroup className="input-sm no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="장소명 입력"
                isInvalid={!!errors.name}
                value={info?.name}
                onChange={(e) => handleChangeInfo('name', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              연락처
            </Typography>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control className="bg-light" placeholder="연락처 입력"></Form.Control>
            </InputGroup>
          </div>
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              주소
            </Typography>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control className="bg-light" placeholder="주소 입력"></Form.Control>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="items-center gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleDelete}>
          {data?.placeNo ? '삭제' : '취소'}
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleSave}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
};
