import React, { useState } from 'react';
import styles from './Checkbox.module.scss';
import { Checkbox } from './Checkbox';
import { CheckList, CheckedListType } from './type';
import { DefaultButton } from 'components/button';

type CheckboxListProps = {
  checkList: CheckList[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onConfirm?: (checkedItems?: CheckedListType[]) => void;
};

export const CheckboxList = ({ checkList, onChange, onConfirm }: CheckboxListProps) => {
  const [checklist, setChecklist] = useState<CheckList[]>(checkList);

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setChecklist((prev) =>
      prev.map((item) => {
        if (item.text === e.target.id) {
          return { ...item, checked: !item.checked };
        } else return item;
      })
    );
  };

  const onClickButton = () => {
    if (onConfirm) {
      const checkedItems = checklist
        .filter((item) => item.checked)
        .map((item) => {
          return { text: item.text, id: item.id };
        });
      onConfirm(checkedItems);
    }
  };

  return (
    <div className={styles.checkbox_list_container}>
      <ul className={styles.checkbox_list}>
        {checklist.map(({ text, checked }) => (
          <li key={text}>
            <label
              className={styles.checkbox_list_item}
              htmlFor={text}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <span>{text}</span>
              <Checkbox
                id={text}
                checked={checked}
                onChange={(e) => {
                  onChangeCheckbox(e);
                  if (onChange) onChange(e);
                }}
              />
            </label>
          </li>
        ))}
      </ul>
      {onConfirm && <DefaultButton text="확인" onClick={onClickButton} />}
    </div>
  );
};
