
import { createReducer } from 'typesafe-actions';
import {
  GET_COSTUMES,
  GET_COSTUMES_ERROR,
  GET_COSTUMES_SUCCESS,
  GET_ETCS, GET_ETCS_ERROR,
  GET_ETCS_SUCCESS,
  GET_EXTRAS,
  GET_EXTRAS_ERROR,
  GET_EXTRAS_SUCCESS, GET_ITEMS, GET_ITEMS_ERROR, GET_ITEMS_SUCCESS,
  GET_MAKEUPS,
  GET_MAKEUPS_ERROR,
  GET_MAKEUPS_SUCCESS,
  GET_PROPS,
  GET_PROPS_ERROR,
  GET_PROPS_SUCCESS, GET_SFXS, GET_SFXS_ERROR, GET_SFXS_SUCCESS,
  ItemAsyncAction,


} from './actions';
import { Item } from 'pages/item/Item';

export type ItemAsyncState<T, E = any> = {
  action: string | null;
  loading: boolean;
  data: T | null;
  error: E | null;
};


export type ItemsState = {
  props: ItemAsyncState<Item[], Error>;
  makeups: ItemAsyncState<Item[], Error>;
  costumes: ItemAsyncState<Item[], Error>;
  extras: ItemAsyncState<Item[], Error>;
  sfxs: ItemAsyncState<Item[], Error>;
  etcs: ItemAsyncState<Item[], Error>;
  items: ItemAsyncState<Item[], Error>;
};

const initialState:ItemsState = {
  props:{
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  makeups:{
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  costumes:{
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  extras:{
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  sfxs:{
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  etcs:{
    action: null,
    loading: false,
    data: [],
    error: null,
  },
  items: {
    action: null,
    loading: false,
    data: [],
    error: null,
  },
};


const items = createReducer<ItemsState, ItemAsyncAction>(initialState, {

  [GET_PROPS]:(state) => {
    return { ...state, props: { ...state.props, loading:true, action: GET_PROPS } };
  },
  [GET_PROPS_SUCCESS]:(state, action) => {
    return { ...state, props: { ...state.props, loading:false, data: action.payload, action: GET_PROPS_SUCCESS } };
  },
  [GET_PROPS_ERROR]:(state, action) => {
    return { ...state, props: { ...state.props, loading:false, error:action.payload, action: GET_PROPS_ERROR } };
  },
  [GET_MAKEUPS]:(state) => {
    return { ...state, makeups: { ...state.makeups, loading:true, action: GET_MAKEUPS } };
  },
  [GET_MAKEUPS_SUCCESS]:(state, action) => {
    return { ...state, makeups: { ...state.makeups, loading:false, data: action.payload, action: GET_MAKEUPS_SUCCESS } };
  },
  [GET_MAKEUPS_ERROR]:(state, action) => {
    return { ...state, makeups: { ...state.makeups, loading:false, error:action.payload, action: GET_MAKEUPS_ERROR } };
  },
  [GET_COSTUMES]:(state) => {
    return { ...state, costumes: { ...state.costumes, loading:true, action: GET_COSTUMES } };
  },
  [GET_COSTUMES_SUCCESS]:(state, action) => {
    return { ...state, costumes: { ...state.costumes, loading:false, data: action.payload, action: GET_COSTUMES_SUCCESS } };
  },
  [GET_COSTUMES_ERROR]:(state, action) => {
    return { ...state, costumes: { ...state.costumes, loading:false, error:action.payload, action: GET_COSTUMES_ERROR } };
  },
  [GET_EXTRAS]:(state) => {
    return { ...state, extras: { ...state.extras, loading:true, action: GET_EXTRAS } };
  },
  [GET_EXTRAS_SUCCESS]:(state, action) => {
    return { ...state, extras: { ...state.extras, loading:false, data: action.payload, action: GET_EXTRAS_SUCCESS } };
  },
  [GET_EXTRAS_ERROR]:(state, action) => {
    return { ...state, extras: { ...state.extras, loading:false, error:action.payload, action: GET_EXTRAS_ERROR } };
  },
  [GET_SFXS]:(state) => {
    return { ...state, sfxs: { ...state.sfxs, loading:true, action: GET_SFXS } };
  },
  [GET_SFXS_SUCCESS]:(state, action) => {
    return { ...state, sfxs: { ...state.sfxs, loading:false, data: action.payload, action: GET_SFXS_SUCCESS } };
  },
  [GET_SFXS_ERROR]:(state, action) => {
    return { ...state, sfxs: { ...state.sfxs, loading:false, error:action.payload, action: GET_SFXS_ERROR } };
  },
  [GET_ETCS]:(state) => {
    return { ...state, etcs: { ...state.etcs, loading:true, action: GET_ETCS } };
  },
  [GET_ETCS_SUCCESS]:(state, action) => {
    return { ...state, etcs: { ...state.etcs, loading:false, data: action.payload, action: GET_ETCS_SUCCESS } };
  },
  [GET_ETCS_ERROR]:(state, action) => {
    return { ...state, etcs: { ...state.etcs, loading:false, error:action.payload, action: GET_ETCS_ERROR } };
  },
  [GET_ITEMS]:(state) => {
    return { ...state, items: { ...state.items, loading:true, action: GET_ITEMS } };
  },
  [GET_ITEMS_SUCCESS]:(state, action) => {
    return { ...state, items: { ...state.items, loading:false, data: action.payload, action: GET_ITEMS_SUCCESS } };
  },
  [GET_ITEMS_ERROR]:(state, action) => {
    return { ...state, items: { ...state.items, loading:false, error:action.payload, action: GET_ITEMS_ERROR } };
  },
});

export default items;
