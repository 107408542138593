import { combineReducers } from 'redux';
import layoutReducer, { LayoutState } from './layout/LayoutReducer';
import project, { ProjectState } from './project/ProjectReducer';
import JoinReducer, { JoinState } from './join/JoinReducer';
import { loginCheckReducer, LoginCheckState } from './login/CheckReducer';
import loginReducer from './login/reducer';
import scenes, { ScenesState } from './scenes/SceneReducer';
import modalReducer, { ModalState } from './modal/modalReducer';
import scheduleReducer, { ScheduleState } from './schedules/scheduleReducer';
import unassignedScenesReducer, { UnassignedScenesState } from './schedules/unassginedScenesReducer';
import scripts, { ScriptsState } from './scripts/ScriptReducer';
import character, { CharacterState } from './characters/CharacterReducer';
import locations, { LocationState } from './locations/locationReducer';
import items, { ItemsState } from './Item/reducer';
import strips, { StripsState } from './strips/reducer';
import { TagsState } from './tags/tagReducer';
import { indexReducer, IndexState } from './dashboard/IndexReducer';
import callsheetReducer, { CallSheetState } from './callsheet/callsheetReducer';
import { ProjectDashboardState, projectDashboardReducer } from './dashboard/ProjectDashboardReducer';
import tagReducer from './tags/tagReducer';
import placeReducer, { PlaceState } from './place/placeReducer';
import { projectModReducer, ProjectModState } from './project/ProjectModReducer';
import staff, { StaffState } from './staff/staffReducer';
import actorReducer, { ActorState } from './actor/ActorReducer';
import fileReducer, { FileState } from './file/FileReducer';
import userReducer, { UserState } from './user/UserReducer';
import companyReducer, { CompanyState } from './company/CompanyReducer';

export type RootState = {
  place: PlaceState;
  callsheet: CallSheetState;
  unassignedScenes: UnassignedScenesState;
  schedule: ScheduleState;
  scripts: ScriptsState;
  scenes: ScenesState;
  strips: StripsState;
  modal: ModalState;
  character: CharacterState;
  locations: LocationState;
  items: ItemsState;
  loginCheck: LoginCheckState;
  join: JoinState;
  tags: TagsState;
  index: IndexState;
  projectDashboard: ProjectDashboardState;
  projectMod: ProjectModState;
  layout: LayoutState;
  staff: StaffState;
  project: ProjectState;
  actor: ActorState;
  files: FileState;
  user: UserState;
  company: CompanyState;
};

const rootReducer = combineReducers({
  place: placeReducer,
  callsheet: callsheetReducer,
  unassignedScenes: unassignedScenesReducer,
  schedule: scheduleReducer,
  scripts,
  scenes,
  strips,
  modal: modalReducer,
  loginReducer,
  character,
  locations,
  items,
  loginCheck: loginCheckReducer,
  join: JoinReducer,
  tags: tagReducer,
  index: indexReducer,
  projectDashboard: projectDashboardReducer,
  projectMod: projectModReducer,
  layout: layoutReducer,
  staff,
  project,
  actor: actorReducer,
  files: fileReducer,
  user: userReducer,
  company: companyReducer,
});

export default rootReducer;
