import * as React from 'react';
import { useParams } from 'react-router';

type Props = {
  className: string | undefined;
  children: React.ReactElement[];
  data: any;
};

function ScrollRestorationList({ className, children, data }: Props) {
  const { sceneNo } = useParams();
  const parentRef = React.useRef<HTMLDivElement>(null);
  const dataRef = React.useRef<HTMLDivElement[] | null[]>([]);

  React.useEffect(() => {
    const scrollPos = localStorage.getItem(`${sceneNo}_nav_scroll_pos`) || '0';
    parentRef.current?.scrollTo({
      left: 0,
      top: Number(scrollPos),
      // behavior: 'smooth',
    });
  }, []);

  const handler = (i: number) => {
    const scrollPos = parentRef.current?.scrollTop || 0;
    localStorage.setItem(`${data[i].sceneNo}_nav_scroll_pos`, scrollPos.toString());
    if (data[i].sceneNo != sceneNo) localStorage.removeItem(`${sceneNo}_nav_scroll_pos`);
  };

  return (
    <div className={className} ref={parentRef}>
      {React.Children.map(children, (child, i) => {
        return (
          <div key={i} ref={(el) => (dataRef.current[i] = el)} onClick={() => handler(i)}>
            {child}
          </div>
        );
      })}
    </div>
  );
}

const ScrollRestorationMemo = React.memo(ScrollRestorationList);
export { ScrollRestorationMemo };
