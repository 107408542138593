/* eslint-disable @typescript-eslint/indent */
import { CompanyActorInfo } from 'shared';
import ReducerGenerator from 'util/ReducerGenerator';
import { AsyncState, asyncState } from 'util/ReducerTypes';
import { findActors } from './actorService';
import CommonResponse from 'modules/common/CommonResponse';

export type ActorState = {
  actors: AsyncState<CommonResponse<CompanyActorInfo[]>, Error>;
};

const initialState: ActorState = {
  actors: asyncState.initial(),
};

const reducerGenerator = new ReducerGenerator<ActorState, 'actor'>(initialState);

// 배우 목록 조회
export const getCompanyActors = reducerGenerator.createThunkAction<
  'getCompanyActors',
  { companyNo: number; params: {} },
  CommonResponse<CompanyActorInfo[]>
>({
  action: 'actor/getCompanyActors',
  key: 'actors',
  thunk: (params) => findActors(params.companyNo, params.params),
});

const actorReducer = reducerGenerator.createReducer();
export default actorReducer;
