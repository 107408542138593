import AlertModal, { AlertModalProps } from './AlertModal';
import CheckboxModal, { CheckboxModalProps } from './CheckboxModal';
import ConfirmModal, { ConfirmModalProps } from './ConfirmModal';
import { FormModal, FormModalProps } from './FormModal';
import { AsyncStatusModal, StatusModalProps } from './AsyncStatusModal';
import { AccessModifyModal, AccessModifyModalProp } from './AccessModifyModal';
import { InfoModal, InfoModalProps } from './InfoModal';
import { SearchListModal, SearchListModalProps } from './SearchListModal';
import { PostCodeModal, PostCodeModalProps } from './PostCodeModal';

import AddHeadStripModal, { AddHeadStripModalProps } from 'pages/schedule/modal/AddHeadStripModal';
import BannerModal, { BannerModalProps } from 'pages/schedule/modal/BannerModal';

import SceneTagModal, { SceneTagModalProps } from 'pages/scene/modal/SceneTagModal';
import CharacterModal, { CharacterModalProps } from 'pages/scene/modal/CharacterModal';
import CostumeModal from 'pages/scene/modal/CostumeModal';
import TagAddModal, { TagAddProps } from 'pages/scene/modal/TagAddModal';
import ScriptUploadModal, { ScriptUploadModalProps } from 'pages/scene/modal/ScriptUploadModal';

import ScheduleSceneFilterModal, { ScheduleSceneFilterModalProps } from 'pages/schedule/modal/ScheduleSceneFilterModal';
import ScheduleCalendarModal, { ScheduleCalendarModalProps } from 'pages/schedule/modal/ScheduleCalendarModal';
import SceneAddModal, { SceneAddModalProps } from 'pages/scene/modal/SceneAddModal';
import DaySceneListModal, { DaySceneListModalProps } from 'pages/callsheet/modal/DaySceneListModal';
import AddScheduleModal, { AddScheduleModalProps } from 'pages/schedule/modal/AddScheduleModal';
import ShareScheduleModal, { ShareScheduleModalProps } from 'pages/schedule/modal/ShareScheduleModal';
import SceneStatisticModal, { SceneStatisticModalProps } from 'pages/scene/modal/SceneStatisticModal';

import MemoModal, { MemoModalProps } from './MemoModal';
import SceneFilterModal, { SceneFilterModalProps } from 'pages/scene/modal/SceneFilterModal';
import CreateProjectModal, { CreateProjectModalProp } from 'pages/project/CreateProjectModal';
import SceneDivideCountInputModal, {
  SceneDivideCountInputModalProps,
} from 'pages/scene/modal/SceneDivideCountInputModal';
import ImageCropModal, { ImageCropModalProps } from './ImageCropModal';
import AISchedulingModal, { AISchedulingModalProps } from 'pages/schedule/modal/AISchedulingModal';
import ScriptUploadProcessingModal, {
  ScriptUploadProcessingModalProps,
} from 'pages/scene/modal/ScriptUploadProcessingModal';
import ScriptConfirmModal, { ScriptConfirmModalProps } from 'pages/scene/modal/ScriptConfirmModal';
import {
  TeamRegModal,
  TeamRegModalProps,
  StaffDetailModal,
  StaffDetailModalProps,
  StaffRegModal,
  StaffRegModalProps,
} from 'pages/staff/modals';

import {
  CastingListModal,
  CastingListModalProps,
  CastingRegModal,
  CastingRegModalProps,
  CharacterInfoModal,
  CharacterInfoModalProps,
  ActorRegModal,
  ActorRegModalProps,
  CharacterScheduleModal,
  CharacterScheduleModalProps,
} from 'pages/character/modals';

import {
  PlaceInfoModal,
  PlaceInfoModalProps,
  LocationInfoModal,
  LocationInfoModalProps,
  LocationRegModal,
  LocationRegModalProps,
  LocationScheduleModal,
  LocationsScheduleModalProps,
} from 'pages/location/modals';

import {
  FolderRegModal,
  FolderRegModalProps,
  FileUploadProgressModal,
  FileUploadProgressModalProps,
} from 'pages/file/modals';

import {
  DdaySettingModal,
  DdaySettingModalProps,
  LocationViewModal,
  LocationViewModalProps,
} from 'pages/dashboard/modals';
import FileDownloadModal, { FileDownloadModalProps } from './FileDownloadModal';
import LLMSchedulingModal, { LLMSchedulingModalProps } from 'pages/schedule/modal/LLMSchedulingModal';

export enum ModalTypes {
  AccessModify,
  AddHeadStrip,
  AddSchedule,
  ShareSchedule,
  Alert,
  AsyncStatus,
  Banner,
  Checkbox,
  Confirm,
  DaySceneList,
  Form,
  ImageCrop,
  Info,
  SceneAdd,
  SceneFilter,
  SceneDivideCountInput,
  ScheduleSceneFilter,
  ScheduleCalendar,
  TagCreate,
  TagCharacter,
  TagCostume,
  TagAdd,
  SceneStatistic,
  ScriptUpload,
  SearchList,
  PostCode,
  MemoPopup,
  CreateProject,
  AIScheduling,
  LLMScheduling,
  ScriptProcessing,
  ScriptConfirm,
  TeamRegister,
  StaffDetailInfo,
  StaffRegPopup,
  CastingListPopup,
  CastingRegPopup,
  CharacterInfo,
  ActorRegPopup,
  CharacterSchedulePopup,
  PlaceInfo,
  LocationInfo,
  LocationRegPopup,
  LocationSchedulePopup,
  FolderRegPopup,
  FileUploadProgress,
  DdaySetting,
  LocationView,
  FileDownload,
}

type ModalComponentType = {
  [ModalTypes.AccessModify]: (props: AccessModifyModalProp) => JSX.Element;
  [ModalTypes.AddHeadStrip]: (props: AddHeadStripModalProps) => JSX.Element;
  [ModalTypes.AddSchedule]: (props: AddScheduleModalProps) => JSX.Element;
  [ModalTypes.ShareSchedule]: (props: ShareScheduleModalProps) => JSX.Element;
  [ModalTypes.Alert]: (props: AlertModalProps) => JSX.Element;
  [ModalTypes.AsyncStatus]: (props: StatusModalProps) => JSX.Element;
  [ModalTypes.Banner]: (props: BannerModalProps) => JSX.Element;
  [ModalTypes.Checkbox]: (props: CheckboxModalProps) => JSX.Element;
  [ModalTypes.Confirm]: (props: ConfirmModalProps) => JSX.Element;
  [ModalTypes.DaySceneList]: (props: DaySceneListModalProps) => JSX.Element;
  [ModalTypes.Form]: (props: FormModalProps<any>) => JSX.Element;
  [ModalTypes.ImageCrop]: (props: ImageCropModalProps) => JSX.Element;
  [ModalTypes.Info]: (props: InfoModalProps) => JSX.Element;
  [ModalTypes.SceneAdd]: (props: SceneAddModalProps) => JSX.Element;
  [ModalTypes.SceneFilter]: (props: SceneFilterModalProps) => JSX.Element;
  [ModalTypes.SceneDivideCountInput]: (props: SceneDivideCountInputModalProps) => JSX.Element;
  [ModalTypes.ScheduleSceneFilter]: (props: ScheduleSceneFilterModalProps) => JSX.Element;
  [ModalTypes.ScheduleCalendar]: (props: ScheduleCalendarModalProps) => JSX.Element;
  [ModalTypes.TagCreate]: (props: SceneTagModalProps) => JSX.Element;
  [ModalTypes.TagCharacter]: (props: CharacterModalProps) => JSX.Element;
  [ModalTypes.TagCostume]: () => JSX.Element;
  [ModalTypes.TagAdd]: (props: TagAddProps) => JSX.Element;
  [ModalTypes.SceneStatistic]: (props: SceneStatisticModalProps) => JSX.Element;
  [ModalTypes.ScriptUpload]: (props: ScriptUploadModalProps) => JSX.Element;
  [ModalTypes.SearchList]: (props: SearchListModalProps) => JSX.Element;
  [ModalTypes.PostCode]: (props: PostCodeModalProps) => JSX.Element;
  [ModalTypes.MemoPopup]: (props: MemoModalProps) => JSX.Element;
  [ModalTypes.CreateProject]: (props: CreateProjectModalProp) => JSX.Element;
  [ModalTypes.AIScheduling]: (props: AISchedulingModalProps) => JSX.Element;
  [ModalTypes.LLMScheduling]: (props: LLMSchedulingModalProps) => JSX.Element;
  [ModalTypes.ScriptProcessing]: (props: ScriptUploadProcessingModalProps) => JSX.Element;
  [ModalTypes.ScriptConfirm]: (props: ScriptConfirmModalProps) => JSX.Element;
  [ModalTypes.TeamRegister]: (props: TeamRegModalProps) => JSX.Element;
  [ModalTypes.StaffDetailInfo]: (props: StaffDetailModalProps) => JSX.Element;
  [ModalTypes.StaffRegPopup]: (props: StaffRegModalProps) => JSX.Element;
  [ModalTypes.CastingListPopup]: (props: CastingListModalProps) => JSX.Element;
  [ModalTypes.CastingRegPopup]: (props: CastingRegModalProps) => JSX.Element;
  [ModalTypes.CharacterInfo]: (props: CharacterInfoModalProps) => JSX.Element;
  [ModalTypes.ActorRegPopup]: (props: ActorRegModalProps) => JSX.Element;
  [ModalTypes.CharacterSchedulePopup]: (props: CharacterScheduleModalProps) => JSX.Element;
  [ModalTypes.PlaceInfo]: (props: PlaceInfoModalProps) => JSX.Element;
  [ModalTypes.LocationInfo]: (props: LocationInfoModalProps) => JSX.Element;
  [ModalTypes.LocationRegPopup]: (props: LocationRegModalProps) => JSX.Element;
  [ModalTypes.LocationSchedulePopup]: (props: LocationsScheduleModalProps) => JSX.Element;
  [ModalTypes.FolderRegPopup]: (props: FolderRegModalProps) => JSX.Element;
  [ModalTypes.FileUploadProgress]: (props: FileUploadProgressModalProps) => JSX.Element;
  [ModalTypes.DdaySetting]: (props: DdaySettingModalProps) => JSX.Element;
  [ModalTypes.LocationView]: (props: LocationViewModalProps) => JSX.Element;
  [ModalTypes.LocationView]: (props: LocationViewModalProps) => JSX.Element;
  [ModalTypes.FileDownload]: (props: FileDownloadModalProps) => JSX.Element;
};

export const ModalComponents: ModalComponentType = {
  [ModalTypes.AccessModify]: AccessModifyModal,
  [ModalTypes.AddHeadStrip]: AddHeadStripModal,
  [ModalTypes.AddSchedule]: AddScheduleModal,
  [ModalTypes.ShareSchedule]: ShareScheduleModal,
  [ModalTypes.Alert]: AlertModal,
  [ModalTypes.AsyncStatus]: AsyncStatusModal,
  [ModalTypes.Banner]: BannerModal,
  [ModalTypes.Checkbox]: CheckboxModal,
  [ModalTypes.Confirm]: ConfirmModal,
  [ModalTypes.DaySceneList]: DaySceneListModal,
  [ModalTypes.Form]: FormModal,
  [ModalTypes.ImageCrop]: ImageCropModal,
  [ModalTypes.Info]: InfoModal,
  [ModalTypes.SceneAdd]: SceneAddModal,
  [ModalTypes.SceneFilter]: SceneFilterModal,
  [ModalTypes.SceneDivideCountInput]: SceneDivideCountInputModal,
  [ModalTypes.ScheduleSceneFilter]: ScheduleSceneFilterModal,
  [ModalTypes.ScheduleCalendar]: ScheduleCalendarModal,
  [ModalTypes.TagCreate]: SceneTagModal,
  [ModalTypes.TagCharacter]: CharacterModal,
  [ModalTypes.TagCostume]: CostumeModal,
  [ModalTypes.TagAdd]: TagAddModal,
  [ModalTypes.SceneStatistic]: SceneStatisticModal,
  [ModalTypes.ScriptUpload]: ScriptUploadModal,
  [ModalTypes.SearchList]: SearchListModal,
  [ModalTypes.PostCode]: PostCodeModal,
  [ModalTypes.MemoPopup]: MemoModal,
  [ModalTypes.CreateProject]: CreateProjectModal,
  [ModalTypes.AIScheduling]: AISchedulingModal,
  [ModalTypes.LLMScheduling]: LLMSchedulingModal,
  [ModalTypes.ScriptProcessing]: ScriptUploadProcessingModal,
  [ModalTypes.ScriptConfirm]: ScriptConfirmModal,
  [ModalTypes.TeamRegister]: TeamRegModal,
  [ModalTypes.StaffDetailInfo]: StaffDetailModal,
  [ModalTypes.StaffRegPopup]: StaffRegModal,
  [ModalTypes.CastingListPopup]: CastingListModal,
  [ModalTypes.CastingRegPopup]: CastingRegModal,
  [ModalTypes.CharacterInfo]: CharacterInfoModal,
  [ModalTypes.ActorRegPopup]: ActorRegModal,
  [ModalTypes.CharacterSchedulePopup]: CharacterScheduleModal,
  [ModalTypes.PlaceInfo]: PlaceInfoModal,
  [ModalTypes.LocationInfo]: LocationInfoModal,
  [ModalTypes.LocationRegPopup]: LocationRegModal,
  [ModalTypes.LocationSchedulePopup]: LocationScheduleModal,
  [ModalTypes.FolderRegPopup]: FolderRegModal,
  [ModalTypes.FileUploadProgress]: FileUploadProgressModal,
  [ModalTypes.DdaySetting]: DdaySettingModal,
  [ModalTypes.LocationView]: LocationViewModal,
  [ModalTypes.FileDownload]: FileDownloadModal,
};
