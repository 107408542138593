import { createReducer } from 'typesafe-actions';
import { ModalComponents, ModalTypes } from 'components/modal';

export const OPEN_MODAL = 'modal/OPEN_MODAL';
export const CLOSE_MODAL = 'modal/CLOSE_MODAL';
export const RESET_MODAL_STATE = 'modal/RESET_MODAL_STATE';

type OpenModal<T extends ModalTypes> = {
  type: typeof OPEN_MODAL;
  payload: ModalListType<T>;
};
type CloseModal = {
  type: typeof CLOSE_MODAL;
  modal?: ModalTypes;
};
type ResetModal = {
  type: typeof RESET_MODAL_STATE;
};

export const openModal = <T extends ModalTypes>(payload: ModalListType<T>): OpenModal<T> => {
  return {
    type: OPEN_MODAL,
    payload,
  };
};

export const closeModal = (modalIdx?: ModalTypes): CloseModal => {
  return {
    type: CLOSE_MODAL,
    modal: modalIdx,
  };
};

export const resetModalState = (): ResetModal => {
  return {
    type: RESET_MODAL_STATE,
  };
};

export type ModalAction = OpenModal<any> | CloseModal | ResetModal;

export type ModalListType<T extends ModalTypes> = {
  type: T;
  props?: React.ComponentProps<typeof ModalComponents[T]>;
  overlayOptions?: {
    modalPos?: { x?: number; y?: number };
    translate?: { x?: number; y?: number };
    dim?: boolean;
    hideBackdrop?: boolean;
    disableOverlayClick?: boolean;
  };
  onClose?: () => void;
};

export type ModalState = {
  modalList: ModalListType<any>[];
  isOpenAsyncStatusModal?: boolean;
  isCallAsyncFunction?: boolean;
};

const initialState: ModalState = {
  modalList: [],
  isOpenAsyncStatusModal: false,
  isCallAsyncFunction: false,
};

const modal = createReducer<ModalState, ModalAction>(initialState, {
  [OPEN_MODAL]: (state, action) => {
    if (state.isOpenAsyncStatusModal) {
      state.modalList.pop();
    }
    state.modalList.push(action.payload);
    return { ...state };
  },
  [CLOSE_MODAL]: (state, action) => {
    if (action.modal) {
      state.modalList = state.modalList.filter((m) => m.type !== action.modal);
    } else {
      state.modalList.pop();
    }

    return { ...state, modalPos: null };
  },
  [RESET_MODAL_STATE]: (state) => {
    if (state.modalList) {
      if (state.modalList[0]) {
        if (state.modalList[0].onClose) state.modalList[0].onClose();
      }
    }
    return { ...state, modalList: [], isOpenAsyncStatusModal: false, isCallAsyncFunction: false };
  },
});

export default modal;
