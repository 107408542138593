import React, { useEffect, useState } from 'react';
import styles from './SceneStatistic.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/index';
import { SceneStatistics, TextValue } from '../Scene';
import { BigNumberButton } from 'components/button';
import { InfoModal, SearchListModal } from 'components/modal';
import { getSceneStatistics } from '../../../modules/scenes/SceneReducer';
import { Col, Container, Row } from 'react-bootstrap';
import {  LoadingSpinner } from 'components';
import SceneStatisticChart from './SceneStatisticChart';

import fakeImage from 'public/fake/fake_image.svg';
import fakeAvatar from 'public/fake/fake_character.svg';
import { fileUrl } from 'util/fileHelper';

type DropdownItem = {
  title: string;
  items: TextValue[];
  showItemImage?: boolean;
};

export type SceneStatisticModalProps = {
  projectNo: number;
};

const SceneStatisticModal = ({ projectNo }: SceneStatisticModalProps) => {
  const dispatch = useAppDispatch();
  const { sceneStatistics } = useAppSelector((state) => state.scenes);
  const [activeItem, setActiveItem] = React.useState<number | undefined>(undefined);
  const [dropdownItem, setDropdownItem] = React.useState<DropdownItem>();
  const [statistics, setStatistics] = useState<SceneStatistics>();

  console.log(sceneStatistics);

  useEffect(() => {
    dispatch(getSceneStatistics({ projectNo: projectNo }));
  }, []);

  useEffect(() => {
    if (sceneStatistics.data) {
      setActiveItem(0);
      setStatistics(sceneStatistics.data);
    }
  }, [sceneStatistics]);

  React.useEffect(() => {
    if (activeItem === 0) {
      setDropdownItem({
        title: '전체 씬 검색',
        items:
          statistics?.totalScenes.map((scene) => {
            return {
              text: scene.sceneNum + '  ' + (scene.playPlaces ? scene.playPlaces.join(', ') : ''),
              value: scene.sceneNo,
            };
          }) || [],
      });
    } else if (activeItem === 1) {
      setDropdownItem({
        title: '극 중 장소 검색',
        showItemImage: true,
        items:
          statistics?.totalPlace.map((place) => {
            return {
              imgPath: place.imgPath ? fileUrl(place.imgPath) : fakeImage,
              text: place.name,
              value: place.resourceNo,
            };
          }) || [],
      });
    } else if (activeItem === 2) {
      setDropdownItem({
        title: '인물 검색',
        showItemImage: true,
        items:
          statistics?.totalCharacter.map((character) => {
            return {
              imgPath: character.imgPath ? fileUrl(character.imgPath) : fakeAvatar,
              text: character.name,
              value: character.resourceNo,
            };
          }) || [],
      });
    }
  }, [activeItem]);

  const checkActive = (index: number) => {
    return activeItem === index;
  };

  return (
    <InfoModal size="wd" title="씬 통계" bodyClass={styles.body}>
      <Container className={styles.container}>
        <Row className={styles.row_top}>
          <Col className={styles.button_group} xs={4}>
            <Row className={styles.big_button}>
              <BigNumberButton
                text="전체 씬"
                active={checkActive(0)}
                count={statistics?.totalScenes.length || 0}
                unit={'개'}
                onClick={() => setActiveItem(0)}
              />
            </Row>
            <Row className={styles.big_button}>
              <BigNumberButton
                active={checkActive(1)}
                text="극 중 장소"
                count={statistics?.totalPlace.length || 0}
                unit={'곳'}
                onClick={() => setActiveItem(1)}
              />
            </Row>
            <Row className={styles.big_button}>
              <BigNumberButton
                active={checkActive(2)}
                text="인물"
                count={statistics?.totalCharacter.length || 0}
                unit={'명'}
                onClick={() => setActiveItem(2)}
              />
            </Row>
            <Row className={styles.big_button}>
              <BigNumberButton
                active={checkActive(3)}
                text="낮 / 밤"
                showCaret={false}
                render={() => (
                  <span>
                    D:{statistics?.dayNightInfo?.dayTime || 0} <span className="text-blue-gray-300 mx-2">/</span> N:
                    {statistics?.dayNightInfo?.night || 0}
                  </span>
                )}
              />
            </Row>
          </Col>
          <Col xs={8} className={styles.search_list}>
            <SearchListModal
              size="medium"
              loading={sceneStatistics.loading}
              style={{ width: '100%', height: '100%' }}
              title={dropdownItem?.title || ''}
              items={dropdownItem?.items || []}
              showItemImage={dropdownItem?.showItemImage || false}
              hideShadow
            />
          </Col>
        </Row>
        <Row className={styles.row_bottom}>
          {sceneStatistics.loading ? (
            <LoadingSpinner />
          ) : (
            <SceneStatisticChart
              projectTitle={sceneStatistics?.data?.project?.name || '미정'}
              proceedInfo={{
                beFilmed: statistics?.proceedInfo.beFilmed || 0,
                completedFilmed: statistics?.proceedInfo.completedFilmed || 0,
                notFilmed: statistics?.proceedInfo.notFilmed || 0,
                reFilmed: statistics?.proceedInfo.reFilmed || 0,
              }}
            />
          )}
        </Row>
      </Container>
    </InfoModal>
  );
};

export default SceneStatisticModal;
