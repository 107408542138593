import React, { useEffect } from 'react';
import styles from './DaySceneList.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { ScheduleList } from '../../schedule/type';
import { useAppDispatch } from '../../../hooks/index';
import { useParams } from 'react-router-dom';
import { getScheduleScenes } from '../../../modules/callsheet/callsheetReducer';
import { StripsTable, LoadingSpinner } from 'components';

export type DaySceneListModalProps = {
  scheduleNo:number;
};
export default function DaySceneListModal({ scheduleNo }:DaySceneListModalProps) {

  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { dailyShoots, scheduleSceneList } = useSelector((state:RootState)=>state.callsheet);
  const [scenelist, setScenelist] = React.useState<ScheduleList[]>([]);
  console.log(dailyShoots, scheduleSceneList, scenelist);
  useEffect(()=>{
    if (scheduleSceneList.loading) return;
    if (scheduleSceneList.data) {
      if (scheduleSceneList.data.scheduleNo === scheduleNo) {
        return;
      }
    }
    dispatch(
      getScheduleScenes({
        projectNo: parseInt(projectId!),
        groupNo: dailyShoots.data!.groupNo,
        scheduleNo,
      })
    );
  }, []);


  React.useEffect(()=>{
    setScenelist([]);
    if (scheduleSceneList.loading) return;
    if (scheduleSceneList.data) {
      if (scheduleSceneList.data.scheduleNo === scheduleNo) {
        setScenelist(scheduleSceneList.data.scheduleList);
        return;
      } else {
        setScenelist(scheduleSceneList.data.scheduleList);
      }
    }
  }, [scheduleSceneList.loading, scheduleSceneList.data?.scheduleNo]);

  return (
      <div className={styles.scenelist_container} style={{ maxHeight:'450px' }} >
        <StripsTable
          style={{ maxHeight:'430px' }}
          headStyle={{ color:'var(--gray-50)', fontWeight:'500' }}
          rowStyle={{ background:'none' }}
          isHoverStyle={false}
          isScroll={false}
          data={scenelist || []}
          columnInfo={[
            {
              title:'EP',
              width: '10%',
              align:'center',
              style:{ border: 'none' },
              data:(t:ScheduleList)=>t.scene ? t.scene.episode : '-',
            },
            {
              title:'씬',
              width: '10%',
              align:'center',
              style:{ border: 'none' },
              data:(t:ScheduleList)=>t.scene ? t.scene.sceneNum : '-',
            },
            {
              title:'장소',
              width: '40%',
              align:'left',
              style:{ border: 'none' },
              data:(t:ScheduleList)=>t.scene ? {
                data:t.scene.playPlaces ? t.scene.playPlaces.join() : '-',
                comment:t.scene.summarize ? t.scene.summarize : '-',
              } : '-',
            },
            {
              title:'인물',
              width: '25%',
              align:'center',
              style:{ border: 'none' },
              data:(t:ScheduleList)=>t.scene ? t.scene.characters ? t.scene.characters.join() : '-' : '-',
            },
          ]}
        />
        {scheduleSceneList.loading && <LoadingSpinner />}
      </div>
  );
}
