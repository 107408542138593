import { useState, ReactNode } from 'react';
import { HorizontalLine } from '../Form/HorizontalLine';
import style from './UpperMenuGroup.module.scss';

type UpperMenuGroupProps = {
  index?: number;
  menuList: string[];
  onClick: (idx: number) => void;
  addOn?: ReactNode;
};

export const UpperMenuGroup = ({ index, menuList, onClick, addOn }: UpperMenuGroupProps) => {

  const [idx, setIdx] = useState<number>(0);

  const clk = (i: number) => {
    onClick(i);
    if (index == undefined) {
      setIdx(i);
    }
  };

  const chooseIdx = index == undefined ? idx : index;

  const renderMenu = menuList.map((val, i) => {
    if (i == chooseIdx) {
      return (
        <div className={style.menu_active} key={i} onClick={() => clk(i)}>
          {val}
          <div className={style.menu_underline} />
        </div>
      );
    } else {
      return (
        <div className={style.menu} key={i} onClick={() => clk(i)}>
          {val}
        </div>
      );
    }
  });

  return (
    <>
      <div className={style.upper_menu}>
        <div className={style.menu_group}>{renderMenu}</div>
        <div>{addOn}</div>
      </div>
      <HorizontalLine />
    </>
  );
};
