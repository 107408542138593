import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Container, Item, Menu, SearchBar, SubTitle, Title } from './DropDownComponents';
import { DropdownDirection } from './type';
import dropDownStyle from './DropDown.module.scss';
import classNames from 'classnames';

type Props = {
  direction?: DropdownDirection;
  hoverDirection?:DropdownDirection;
  isMousePos?: boolean;
  isRightClick?: boolean;
  element?: React.ReactElement;
  hoverElement?: React.ReactElement;
  style?: React.CSSProperties;
  toggleStyle?: React.CSSProperties;
  children: React.ReactNode | React.ReactNode[];
  open?: boolean;
  onOpen?: (open: boolean) => void;
  fitWidth?: boolean;
};

type DropdownContextType = {
  isOpen:boolean;
  onToggle:()=>void;
  setToggle:(isopen:boolean)=>void;
};
export const DropdownContext = React.createContext<DropdownContextType | null>(null);

export function DropDown({ direction = 'bottom-start', element, children, isMousePos, isRightClick, hoverElement, hoverDirection, style, toggleStyle, open = false, fitWidth = false, onOpen }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [isHover, setIsHover] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [hoverArrowRef, setHoverArrowRef] = useState<HTMLDivElement | null>(null);

  const [tStyle, setTStyle] = useState<React.CSSProperties>();

  const [clientPos, setClientPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [hoverClientPos, setHoverClientPos] = useState<{ x: number; y: number }>({ x: 0, y: -2 });

  useEffect(() => {
    if (onOpen) {
      onOpen(isOpen);
    }
  }, [isOpen]);

  const { styles, attributes } = usePopper(toggleRef.current, popperRef.current, {
    placement: direction,
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowRef },
      },
      {
        name: 'offset',
        options: {
          offset: [clientPos.x, clientPos.y],
        },
      },
    ],
  });
  const hoverPopper = usePopper(toggleRef.current, popperRef.current, {
    placement: hoverDirection,
    modifiers: [
      {
        name: 'arrow',
        options: { element: hoverArrowRef },
      },
      {
        name: 'offset',
        options: {
          offset: [hoverClientPos.x, hoverClientPos.y],
        },
      },
    ],
  });

  const clickFunc = (e: MouseEvent) => {
    if (popperRef.current !== null && !popperRef.current.contains(e.target as Node)) {
      if (toggleRef.current?.contains(e.target as Node)) {
        return;
      }
      setIsOpen(false);
    }
  };
  const mouseoverFunc = (e: MouseEvent) => {
    if (popperRef.current !== null && !popperRef.current.contains(e.target as Node)) {
      if (toggleRef.current?.contains(e.target as Node)) {
        return;
      }
      setIsHover(false);
      setTStyle({});
    }
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  const setToggle = (isopen:boolean)=>{
    setIsOpen(isopen);
  };

  useEffect(()=>{
    if (hoverElement) {
      window.addEventListener('mouseover', mouseoverFunc, true);
    }
    return ()=>
      window.removeEventListener('mouseover', mouseoverFunc, true);
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      window.addEventListener('contextmenu', clickFunc, true);
      window.addEventListener('click', clickFunc, true);
    }
    return () => {
      window.removeEventListener('contextmenu', clickFunc, true);
      window.removeEventListener('click', clickFunc, true);
    };
  }, [isOpen, isHover]);

  const setMouseClientPos = (x: number, y: number, type:'click' | 'hover') => {
    const clientRect = wrapperRef.current?.getBoundingClientRect();
    const rectLeft = clientRect ? clientRect.left : 0;
    const rectTop = clientRect ? clientRect.bottom : 0;
    if (type === 'click') {
      setClientPos({ x: x - rectLeft + 20, y: y - rectTop + 10 });
    } else {
      setHoverClientPos({ x: x - rectLeft + 20, y: y - rectTop + 10 });
    }
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isRightClick) return;
    e.stopPropagation();
    if (isHover) {setIsHover(false);}
    onToggle();
    if (isMousePos) setMouseClientPos(e.clientX, e.clientY, 'click');
  };


  const onClickRight = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isRightClick) return;
    e.preventDefault();
    e.stopPropagation();
    if (isHover) {setIsHover(false);}
    onToggle();
    if (isMousePos) setMouseClientPos(e.clientX, e.clientY, 'click');
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!hoverElement) return;
    e.preventDefault();
    e.stopPropagation();
    if (isRightClick) {setIsOpen(false);}
    setIsHover(true);
    setTStyle({ opacity:0.5 });
    if (isMousePos) setMouseClientPos(e.clientX, e.clientY, 'hover');
  };


  return (
    <DropdownContext.Provider value={{ isOpen, onToggle, setToggle }}>
      <div className={dropDownStyle.dropdown_wrapper} ref={wrapperRef} style={style}>
        <div ref={toggleRef} onClick={onClick} onContextMenu={onClickRight} onMouseEnter={hoverElement ? handleMouseEnter : undefined} style={{  ...tStyle, ...toggleStyle }}>
          {children}
        </div>
        {
          hoverElement && isHover ?
            (
              <div
                ref={popperRef}
                className={classNames(dropDownStyle.dropdown_element, fitWidth && dropDownStyle.fit_element)}
                style={{ ...hoverPopper.styles.popper }}
                {...hoverPopper.attributes.popper}>
                <div ref={(ref) => setHoverArrowRef(ref)} style={hoverPopper.styles.arrow} />
                <>
                  {React.cloneElement(hoverElement)}
                </>
              </div>
            ) :  isOpen ? element && (
            <div
              ref={popperRef}
              className={classNames(dropDownStyle.dropdown_element, fitWidth && dropDownStyle.fit_element)}
              style={{ ...styles.popper }}
              {...attributes.popper}>
              <div ref={(ref) => setArrowRef(ref)} style={styles.arrow} />
              <>
                {React.cloneElement(element)}
              </>
            </div>
            )
              : <></>
        }
      </div>
    </DropdownContext.Provider>
  );
}

DropDown.Container = Container;
DropDown.Menu = Menu;
DropDown.Item = Item;
DropDown.Title = Title;
DropDown.SubTitle = SubTitle;
DropDown.SearchBar = SearchBar;
