import { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource, FileButton } from 'components';

import { CharacterFormProps, CharacterProps } from 'pages/character/Character.types';
import FileService from 'modules/file/FileService';
import { CharacterRole, GenderType } from 'shared';
import { fileName } from 'util/fileHelper';
import { Modal } from 'components/modal';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';
import { createCharacter, updateCharacter } from 'modules/characters/CharacterService';
import { LabelDropdown } from 'components/dropdown';

export interface CharacterInfoModalProps {
  projectId: number;
  data?: CharacterProps;
  onDelete?: () => void;
  onChange?: (characterNo: number) => void;
}

const defaultFormData = {
  role: CharacterRole.MAIN,
  gender: GenderType.MAN,
  description: '',
};

export const CharacterInfoModal = ({ projectId, data, onDelete, onChange }: CharacterInfoModalProps) => {
  const dispath = useAppDispatch();
  const [info, setInfo] = useState<CharacterFormProps>(defaultFormData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setInfo({
        name: data?.name || '',
        role: data?.role || CharacterRole.MAIN,
        imgUrl: data?.characterImg?.[0]?.imgPath,
        gender: GenderType.MAN,
        description: data?.description || '',
      });
    } else {
      setInfo(defaultFormData);
    }
  }, [data]);

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;

    setErrorMessage(null);
    new FileService().updateImage(files[0], undefined, false).then((value: string) => {
      setInfo({ ...info, imgUrl: value });
    });
  };

  const handleDelteImage = () => {
    setInfo({ ...info, imgUrl: '' });
  };

  const handleChangeInfo = (key: string, value: string) => {
    setErrorMessage(null);
    setInfo({ ...info, [key]: value });
  };

  const handleSave = async () => {
    let error = '';
    // if (!info?.gender) error = '성별을 선택해주세요.';
    if (!info?.role) error = '포맷을 선택해주세요.';
    if (!info?.name) error = '캐릭터 이름을 입력해주세요';

    if (error) {
      setErrorMessage(error);
      return;
    }

    let result;
    if (data?.characterNo) {
      result = await updateCharacter(projectId, data.characterNo, info);
    } else {
      result = await createCharacter(projectId, info);
    }

    if (result?.characterNo) {
      dispath(closeModal());
      onChange?.(Number(result.characterNo));
    }

    // error ??
  };

  const handleDelete = () => {
    dispath(closeModal());
    onDelete?.();
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          기본 정보
        </Typography>
      </div>
      <div className="text-left">
        <InputGroup className="input-sm no-split no-border">
          <Form.Control className="bg-light" readOnly value={fileName(info?.imgUrl || '')} placeholder="이미지" />
          <InputGroup.Text className="bg-light">
            <Button
              variant="gray"
              className="d-flex align-items-center justify-content-center bs-20px p-0 rounded-circle"
              onClick={handleDelteImage}>
              <SvgIcon source={SvgIconSource.Close} size={16} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <FileButton variant="dark" className="w-100 h-8 mt-3 mb-6" accept="image/*" onFileChange={handleChangeImage}>
          <Typography variant="f12-166--48">이미지 파일 업로드</Typography>
        </FileButton>

        <div className="d-flex flex-column gap-y-4">
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              캐릭터 이름
            </Typography>
            <InputGroup className="input-sm no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="이름 입력"
                value={info?.name || ''}
                onChange={(e) => handleChangeInfo('name', e.target.value)}></Form.Control>
            </InputGroup>
          </div>
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              주/조연
            </Typography>
            <LabelDropdown
              labelPos="top"
              inputStyle={{ fontSize: 12 }}
              items={[
                { id: 'MAIN', text: '주연' },
                { id: 'SUB', text: '조연' },
              ]}
              content="주/조연 선택"
              onSelected={(val) => handleChangeInfo('role', String(val?.id || ''))}
            />
          </div>
          {/* <div>
            <Typography variant="f12-166--48" className="fw-bold">
              소속사
            </Typography>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control className="bg-light" placeholder="소속사 입력"></Form.Control>
            </InputGroup>
          </div>
          <div>
            <Typography variant="f12-166--48" className="fw-bold">
              연락처
            </Typography>
            <InputGroup className="input-sm no-split no-border mt-1">
              <Form.Control className="bg-light" placeholder="연락처 입력"></Form.Control>
            </InputGroup>
          </div> */}
        </div>
        {errorMessage && (
          <div className="mt-2">
            <Typography variant="f12-166--48" className="text-danger">
              {errorMessage}
            </Typography>
          </div>
        )}
      </div>
      <div className="items-center gap-2.5 pt-12">
        <Button variant="outline-gray" className="flex-1 h-10 m-0 fs-3.5 fw-bold" onClick={handleDelete}>
          {data?.characterNo ? '삭제' : '취소'}
        </Button>
        <Button className="flex-1 h-10 m-0 fs-3.5 fw-bold" onClick={handleSave}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
};
