import React from 'react';
import style from './Button.module.scss';
import plus_dark_gray from 'public/common/plus_dark_gray.svg';

type PlusSquareButtonProps = {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const PlusSquareButton = ({ onClick }: PlusSquareButtonProps) => {
  const clickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (onClick) onClick();
  };

  return (
    <button className={style.btn_plus} onClick={clickButton}>
      <img alt={`image-plus`} src={plus_dark_gray} />
    </button>
  );
};
