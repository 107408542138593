import { useState, useEffect, useMemo } from 'react';
import { Avatar } from '@mui/material';
import { Button } from 'react-bootstrap';
import { Typography, SvgIcon, SvgIconSource, TabBar } from 'components';
import { CompanyActorInfo } from 'shared';
import { SearchDropdown } from 'components/SearchDropdown/SearchDropdown';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'modules';
import { closeModal, openModal } from 'modules/modal/modalReducer';
import { Modal, ModalTypes } from 'components/modal';
import { fileUrl } from 'util/fileHelper';
import { sendStaffInvitation } from 'modules/staff/StaffService';
import { getInvitedStaffs } from 'modules/staff/staffReducer';
import classNames from 'classnames';
import { getCompanyUsers } from 'modules/company/CompanyReducer';
import { validateEmail } from 'util/validation';
import { CONFIRM_MESSAGES } from 'util/constant';
import { showToastMessage } from 'hooks/useToastNotification';

const TABS = [
  { label: '초대', value: 'invite' },
  { label: '초대관리', value: 'management' },
];

type StaffSelectionProps = {
  name?: string;
  thumbnail?: string;
  email: string;
  job?: string;
};

export interface StaffRegModalProps {
  teamNo?: number;
  teamName?: string;
  registered: CompanyActorInfo[];
  onConfirm?: () => void;
}

export const StaffRegModal = ({ teamNo, teamName, onConfirm }: StaffRegModalProps) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state: RootState) => state.company);
  const { project } = useAppSelector((state: RootState) => state.project);
  const { invited } = useAppSelector((state: RootState) => state.staff);
  const [excludedIds, setExcludedIds] = useState<string[]>([]);
  const [tab, setTab] = useState<string>('invite');
  const [selected, setSelected] = useState<StaffSelectionProps[]>([]);
  const [manageSearchKey, setManageSearchKey] = useState('');

  const { companyNo, projectNo } = project?.data || {};

  const fetchCompanyUsers = (key?: string) => {
    const searchTxt = key || '';
    if (!companyNo) return;
    dispatch(getCompanyUsers({ companyNo, name: searchTxt }));
  };

  const fetchInvitations = (key?: string) => {
    if (!projectNo) return;

    const searchTxt = key || '';
    setManageSearchKey(searchTxt);
    dispatch(getInvitedStaffs({ projectNo, params: { name: searchTxt, groupNo: teamNo } }));
  };

  useEffect(() => {
    fetchCompanyUsers();
  }, [project]);

  useEffect(() => {
    if (tab === 'management') fetchInvitations(manageSearchKey);
  }, [tab, projectNo]);

  const handleClose = () => {
    dispatch(closeModal(ModalTypes.StaffRegPopup));
  };

  const handleAddUser = (user: any) => {
    if (!user?.email) return;

    let errorMsg = '';
    if (!validateEmail(user.email)) errorMsg = '잘못된 이메일입니다.';

    const duplicated = selected?.find((s) => s.email === user?.email.trim());
    if (duplicated) errorMsg = '이미 등록된 이메일입니다.';

    if (errorMsg) {
      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: { text: errorMsg, confirmBtnText: '확인', autoClose: true },
        })
      );
      return;
    }

    setSelected([
      ...selected,
      {
        thumbnail: user?.thumbnail,
        email: user?.email,
        name: user?.name,
        job: user?.job,
      },
    ]);

    setExcludedIds([...excludedIds, user.email]);
  };

  const handleRemoveUser = (idx: number) => {
    const selectedList = [...selected];
    const removed = selectedList.splice(idx, 1);
    setSelected(selectedList);

    const removedId = removed[0].email;
    if (removedId) {
      const excludedIdList = [...excludedIds];
      const excludedIndex = excludedIdList?.indexOf(removedId);
      excludedIdList.splice(excludedIndex);

      setExcludedIds(excludedIdList);
    }
  };

  const handleComplete = async () => {
    if (tab === 'invite' && selected?.length > 0 && project.data?.projectNo) {
      let list = selected.map((s) => s.email).filter((s) => !!s);
      const result = await sendStaffInvitation(project.data.projectNo, { email: list, groupNo: teamNo || 0 });
      if (result?.status === 400) {
        showToastMessage('스태프 초대에 실패하였습니다.');
        return;
      }

      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: CONFIRM_MESSAGES.STAFF_INVITE_SUCCESS,
        })
      );

      onConfirm?.();
    }

    handleClose();
  };

  const availableActors = useMemo(() => {
    if (!users?.data?.length) return [];
    return users.data.filter((a) => a.email && !excludedIds.includes(a.email));
  }, [users, excludedIds]);

  const modalTitle = useMemo(() => {
    if (!teamName || teamName === '미배정') return '스태프';
    return `스태프 - ${teamName}`;
  }, [teamName]);

  return (
    <Modal.Container size="lg" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          {modalTitle}
        </Typography>
      </div>
      <div className="pt-3 text-left">
        <TabBar selected={tab} tabs={TABS} wrapperClass="px-0" onChange={(val: string) => setTab(val)} />
      </div>
      <div className={classNames('d-flex flex-column h-90 text-left py-4', tab !== 'invite' && 'd-none')}>
        <div className="mb-6">
          <SearchDropdown
            placeholder="이메일 또는 이름으로 검색해주세요."
            typeName="배우"
            list={availableActors}
            filterKeys={[]}
            activeInFocus={true}
            onSearch={(v: string) => fetchCompanyUsers(v)}
            onSelect={(item: any) => handleAddUser(item as CompanyActorInfo)}
            onCreate={(val) => handleAddUser({ email: val })}
            onChange={(val: string) => fetchCompanyUsers(val)}
            renderEmptyItem={(text: string) => (
              <>
                <SvgIcon source={SvgIconSource.Envelope} />
                <Typography variant="f12-166--48" className="fw-medium">
                  {text}
                </Typography>
              </>
            )}
            rednerItem={(item: CompanyActorInfo) => (
              <>
                <Avatar src={item.imgUrl} sx={{ width: 24, height: 24 }} />
                <div>
                  <Typography variant="f12-166--48" className="fw-medium">
                    {item.name}
                  </Typography>
                  <Typography variant="f10-166--40" className="text-blue-gray-300">
                    {item.agency || ' '}
                  </Typography>
                </div>
              </>
            )}
          />
        </div>
        <div className="flex-1 overflow-y-auto pb-6">
          {selected.map((sel, index) => (
            <div key={index} className="d-flex align-items-center bg-light rounded py-2 px-5 mb-2">
              {sel.name ? (
                <>
                  <Avatar src={fileUrl(sel.thumbnail)} className="me-4" sx={{ width: 40, height: 40 }} />
                  <Typography className="fw-bold  flex-1 ms-2">{sel.name}</Typography>
                </>
              ) : (
                <>
                  <SvgIcon source={SvgIconSource.Envelope} size={16} />
                  <Typography className="fw-bold  flex-1 ms-2">{sel.email}</Typography>
                </>
              )}
              <div>
                <span className="pointer" onClick={() => handleRemoveUser(index)}>
                  <SvgIcon source={SvgIconSource.CloseCircle} className="ms-1" color="danger" />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classNames('d-flex flex-column h-90 text-left py-4', tab === 'invite' && 'd-none')}>
        <div className="mb-6">
          <SearchDropdown
            placeholder="이메일 또는 이름으로 검색해주세요."
            filterKeys={['name', 'email']}
            onSearch={(keyword: string) => fetchInvitations(keyword)}
          />
        </div>
        <div className="flex-1 overflow-y-auto pb-6">
          {invited?.data?.map((sel, index) => (
            <div key={index} className="d-flex align-items-center bg-light rounded py-2 px-5 mb-2">
              {sel.thumbnail ? (
                <Avatar src={fileUrl(sel.thumbnail)} className="me-4" sx={{ width: 40, height: 40 }} />
              ) : (
                <SvgIcon source={SvgIconSource.Envelope} size={16} />
              )}
              <Typography className="fw-bold  flex-1 ms-2">{sel.name}</Typography>
              <div
                className={classNames('rounded-5 fs-3 px-3 py-1', {
                  'text-chip-1 bg-badge-accept': sel.state === 'ACCEPT',
                  'text-blue-gray-300 bg-badge-disable': sel.state !== 'ACCEPT',
                })}>
                {sel.state === 'ACCEPT' ? '초대수락' : '미가입'}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="items-center justify-content-end gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleComplete}>
          완료
        </Button>
      </div>
    </Modal.Container>
  );
};
