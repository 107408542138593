import _ from 'lodash';
import { CharacterProps } from 'pages/character/Character.types';
import { SearchListValue } from 'components/modal';
import { Item } from 'pages/item/Item';
import { LocationProps, PlaceProps } from 'pages/location/Location.types';
import { FilterListType, FilterSetType, Resource, SceneFilterType, SceneResource } from 'pages/scene/Scene';
import { PROCEED } from 'shared/constants';

const getProceed = (proceed: string) => {
  const proc = PROCEED.filter((item) => item.value === proceed);
  if (!_.isEmpty(proc)) {
    return proc[0].text;
  } else {
    return 'No data';
  }
};

const filtersSetToArray = (filters: FilterSetType) => {
  let filterArray: FilterListType = {
    episode: [],
    bigPlayPlaces: [],
    playPlaces: [],
    characters: [],
    site: [],
    timeslot: [],
    proceed: [],
  };
  for (let key in filters) {
    const filterKey = key as SceneFilterType;
    filterArray[filterKey] = Array.from(filters[filterKey]);
  }
  return filterArray;
};

const getEpisodes = (scenes: SceneResource[]): { episode: SearchListValue[] } => {
  const episode = new Set<string>();
  const episodeList: SearchListValue[] = [];

  scenes.forEach((scene) => {
    if (scene.episode && !episode.has(scene.episode)) {
      episode.add(scene.episode);
      episodeList.push({ text: scene.episode });
    }
  });

  return { episode: episodeList };
};

const getPlacesAndCharacters = (
  scenes: SceneResource[]
): { playPlaces: SearchListValue[]; characters: SearchListValue[] } => {
  const places = new Set<string>();
  const characters = new Set<string>();

  const placeList: SearchListValue[] = [];
  const characterList: SearchListValue[] = [];

  scenes.forEach((scene) => {
    if (scene.playPlaces)
      scene.playPlaces.forEach((place) => {
        if (!places.has(place.name)) {
          places.add(place.name);
          placeList.push({ text: place.name, id: `${place.resourceNo!}` });
        }
      });
    if (scene.characters)
      scene.characters.forEach((character) => {
        if (!characters.has(character.name)) {
          characters.add(character.name);
          characterList.push({ text: character.name, id: `${character.resourceNo!}` });
        }
        characters.add(character.name);
      });
  });

  return { playPlaces: placeList, characters: characterList };
};

const getFilteredInfos = (scenes: SceneResource[], selectedFilters: FilterListType) => {
  const bigPlayPlaces = new Set<string>();
  const places = new Set<string>();
  const characters = new Set<string>();

  let newFilteredScenes: SceneResource[] = scenes.filter((scene) => {
    for (let key in selectedFilters) {
      const filterKey = key as SceneFilterType;
      const filterList = selectedFilters[filterKey];

      if (filterList.length === 0) {
        continue;
      }

      let isPassed = false;

      switch (filterKey) {
        case 'characters':
        case 'playPlaces':
        case 'bigPlayPlaces':
          if (!scene[filterKey]) break;
          else {
            for (let listItem of scene[filterKey]) {
              if (filterList.includes(`${listItem.resourceNo}`)) {
                if (filterKey === 'playPlaces') places.add(listItem.name);
                if (filterKey === 'bigPlayPlaces') bigPlayPlaces.add(listItem.name);
                if (filterKey === 'characters') characters.add(listItem.name);
                isPassed = true;
                break;
              }
            }
            break;
          }
        default:
          if (filterList.includes(scene[filterKey])) {
            isPassed = true;
            break;
          }
          break;
      }
      if (!isPassed) return;
    }
    return true;
  });

  return {
    filteredScenes: newFilteredScenes,
    placeList: Array.from(places),
    characterList: Array.from(characters),
  };
};

const getLocationSearchList = (locations: LocationProps[]): SearchListValue[] => {
  return locations.map((item) => {
    return {
      text: item.name,
      id: item.locationNo,
      imgPath: item.locationImg ? item.locationImg[0]?.imgPath : '',
    };
  });
};

const getPlaceSearchList = (places: PlaceProps[]): SearchListValue[] => {
  return places.map((item) => {
    return {
      text: item.name,
      id: item.placeNo,
      imgPath: item.placeImg ? item.placeImg[0]?.imgPath : '',
    };
  });
};

const getCharacterSearchList = (characters: CharacterProps[]): SearchListValue[] => {
  return characters.map((item) => {
    return {
      text: item.name,
      id: item.characterNo,
      imgPath: item.characterImg ? item.characterImg[0]?.imgPath : '',
    };
  });
};

const getItemSearchList = (items: Item[]): SearchListValue[] => {
  const newItems = items.map((item) => {
    return {
      text: item.name,
      id: item.itemNo,
      imgPath: item.imgUrl,
    };
  });
  return newItems;
};

const getSearchDropdownList = (activeItems: Resource[], searchList: SearchListValue[]): SearchListValue[] => {
  if (!activeItems.length) return searchList;
  return searchList.filter((listItem) => {
    return !activeItems.find((item) => item.name === listItem.text);
  });
};

export {
  getProceed,
  filtersSetToArray,
  getPlacesAndCharacters,
  getEpisodes,
  getFilteredInfos,
  getLocationSearchList,
  getCharacterSearchList,
  getItemSearchList,
  getSearchDropdownList,
  getPlaceSearchList,
};
