import styles from './SceneStatistic.module.scss';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { STATIC_PROCEED } from '../../constants';
import { ProceedInfo } from '../Scene'; // ADD THIS
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { ChartData, ChartTypeRegistry, Plugin, TooltipItem } from 'chart.js/auto';
import { AnyObject } from 'chart.js/types/basic';
import { Col, Container, Row } from 'react-bootstrap';


type Props = {
  style?: React.CSSProperties;
  projectTitle: string;
  proceedInfo: ProceedInfo
};


export default function SceneStatisticChart({ style, projectTitle, proceedInfo }: Props) {

  const getItems = () => {
    // add all proceedInfo
    const proceedInfoList = Object.values(proceedInfo);
    const total = proceedInfoList.reduce((acc, cur) => acc + cur, 0);
    return STATIC_PROCEED.map((item, index) => {

      return (
        <div key={index}>
          {index ? <hr className={styles.line}/> : ''}
          <Row className="row flex-nowrap justify-content-start align-items-center">
            <div className="col-1">
              <div className={styles.eclipse} style={{ background: item.color }} />
            </div>
            <div className={`col-5 ${styles.item_text}`}>{item.text}</div>
            {/*@ts-ignore*/}
            <div className={`col-6 ${styles.item_text}`}>{proceedInfo[item.value]} ({Math.floor((proceedInfo[item.value] / total) * 100)}%)</div>
          </Row>
        </div>
      );

    });

  };

  const getDataSets = () => {
    return STATIC_PROCEED.map((item) => {
      return {
        // @ts-ignore
        data: proceedInfo[item.value],
        label:item.text,
        color: item.color,
      };
    });
  };

  const options = {
    plugins: {
      legend: { // 범례 사용 안 함
        display: false,
      },
      tooltips:{
        yAlign:'bottom',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
            const idx = tooltipItem.dataIndex;
            console.log(tooltipItem);
            return ` ${tooltipItem.label} : ${tooltipItem.dataset.data[idx]}`;
          },
        },
      },
      datalabels: { // datalables 플러그인 세팅
        display: true,
        color:'white',
        weight: 'bold',
        font: {
          size: 16,
        },
        formatter: function (value:any, context:any) {
          return context.dataset.data.length * 1 < value ? value : null; // 전체 데이터의 1% 미만인 경우 데이터 라벨 표시 X
        },
        
      },
    },
  };

  const chartData:ChartData<'doughnut', any[], unknown> = {
    labels:getDataSets().map((item) => item.label),
    datasets: [{
      data: getDataSets().map((item) => item.data),
      backgroundColor: getDataSets().map((item) => item.color),
    }],
  };

  return (
    <Container className={styles.chart_container} style={style}>
      <Row className={styles.project_title}>{projectTitle}</Row>
      <Row className={styles.chart_box} >
        <Col xs={3} className={styles.chart}>
          <Doughnut 
            data={chartData} 
            options={options}
            plugins={[ChartDataLabels as Plugin<'doughnut', AnyObject>]}
          />
        </Col>
        <Col xs={5} style={{ marginLeft: 112 }}>
          {getItems()}
        </Col>
      </Row>
    </Container>
  );
}
