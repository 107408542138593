export const managerNavigations = [
  {
    uid: 'alarm',
    label: '알림',
    icon: 'Bell',
    type: 'link',
    link: '/',
  },
  {
    uid: 'project',
    label: '프로젝트',
    icon: 'Folder',
    type: 'link',
    link: '/project',
  },
  {
    uid: 'staff',
    label: '직원 & 스태프',
    icon: 'UserGroup',
    type: 'link',
    link: '/staff',
  },
  {
    uid: 'actor',
    label: '배우',
    icon: 'Emoji',
    type: 'link',
    link: '/actors',
  },
  {
    uid: 'location',
    label: '촬영지',
    icon: 'Location',
    type: 'link',
    link: '/locations',
  },
  {
    uid: 'file',
    label: '파일',
    icon: 'File',
    type: 'link',
    link: '/files',
  },
  {
    uid: 'company',
    label: '조직관리',
    icon: 'Setting',
    type: 'link',
    link: '/companies',
  },
];

export const managerRouteBreadcrumbs = [
  {
    path: '/manager/companies',
    breadcrumb: [{ title: '조직 관리', url: '' }],
    accessMode: '',
    logoOnly: false,
  },
  {
    path: '/manager/company/:companyId/company',
    breadcrumb: [{ title: '조직 관리', url: '' }],
  },
  {
    path: '/manager/company/:companyId/project',
    breadcrumb: [{ title: '프로젝트', url: '' }],
  },
];
