import { SvgIcon, SvgIconSource, Typography } from 'components';
import { Modal } from 'components/modal';
import { LocationProps } from 'pages/location/Location.types';

import { locationList } from 'mock/location';
import { Avatar } from '@mui/material';

export interface LocationViewModalProps {
  location?: LocationProps;
}

export const LocationViewModal = ({}: LocationViewModalProps) => {
  const location = locationList[0];

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="items-center pb-5 text-left gap-1">
        <SvgIcon source={SvgIconSource.Location} size={18} />
        <Typography tag="h4" variant="f14-166--56" className="fw-bold">
          오늘 로케이션
        </Typography>
      </div>
      <div className="text-left">
        <Avatar src={location.locationImg?.[0].imgPath} variant="rounded" sx={{ width: '100%', height: 100 }} />
        <div className='mt-4'>
          <Typography variant="f12-166--48" className="fw-bold text-blue-gray-300">
            이름
          </Typography>
          <Typography variant="f12-166--48" className="fw-bold">
            {location.name}
          </Typography>
        </div>
        <div className='mt-6 pb-3'>
          <Typography variant="f12-166--48" className="fw-bold text-blue-gray-300">
            주소
          </Typography>
          <Typography variant="f12-166--48" className="fw-bold">
            {location.addr || '없음'}
          </Typography>
        </div>
      </div>
    </Modal.Container>
  );
};
