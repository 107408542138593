import React from 'react';

const useInput = (initialValue:string)
  :[string, ( event:React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>)=>void]=>{
  const [value, setValue] = React.useState(initialValue);
  const onChange = ( event:React.ChangeEvent<HTMLInputElement>
  | React.ChangeEvent<HTMLTextAreaElement>)=>{
    setValue(event.target.value);
  };
  return [value, onChange];
};

export default useInput;
