import { useState } from 'react';
import { Modal } from 'components/modal';
import { Typography } from 'components';
import classNames from 'classnames';

export type AISchedulingModalProps = {
  projectNo: number;
  groupNo: number;
};

const GroupMethods = [
  { label: '같은 장소 끼리', value: 'place' },
  { label: '같은 인물이 들어간 씬끼리', value: 'charater' },
  { label: '씬 넘버 순서대로', value: 'scene' },
];

export default function AISchedulingModal({ }: AISchedulingModalProps) {
  const [orders, setOrders] = useState<string[]>([]);

  const handleChangeOrder = (val: string) => {
    let orderList = [...orders];
    const index = orderList.indexOf(val);

    if (index > -1) {
      orderList.splice(index, 1);
    } else {
      orderList = [...orderList, val];
    }

    setOrders(orderList);
  };

  return (
    <Modal.Container size="sm">
      <div className="text-left py-3">
        <div className="px-3">
          <Typography variant="f12-166--48" className="fw-bold mb-3">
            AI 스케줄링
          </Typography>
        </div>

        <div className="d-flex flex-column gap-2">
          {GroupMethods.map((group, index) => {
            const order = orders.indexOf(group.value);

            return (
              <div
                key={index}
                className="items-center justify-content-between p-3 pointer"
                onClick={() => handleChangeOrder(group.value)}>
                <Typography variant="f12-166--48" className="">
                  {group.label}
                </Typography>
                <div
                  style={{ width: 18, height: 18, borderRadius: 3 }}
                  className={classNames('text-center', {
                    border: order === -1,
                    'bg-primary text-white': order > -1,
                  })}>
                  <Typography variant="f12-normal--48">{order > -1 ? order + 1 : ''}</Typography>
                </div>
              </div>
            );
          })}
        </div>
        <div className="items-center justify-content-center gap-4 pt-8">
          <Modal.Button text={'취소'} buttonType="cancel" onClick={() => {}} />
          <Modal.Button text={'확인'} buttonType="confirm" onClick={() => {}} />
        </div>
      </div>
    </Modal.Container>
  );
}
