import { PlaceProps } from 'pages/location/Location.types';
import { SCENE_FILTER_PARAMS_KEY, SCENE_PARAMS_KEY } from '../constants/sceneConfig';
import { CharacterProps } from 'pages/character/Character.types';
import { ProjectInfo } from 'shared';

export const GET_SCENE_LIST = 'getSceneList';
export const GET_SCRIPT_LIST = 'getScriptList';
export enum SiteCode {
  S = 'S',
  L = 'L',
  NA = 'NA',
}

export enum SceneSiteCode {
  E = 'E',
  I = 'I',
  NA = 'NA',
}

export enum TimeSlotCode {
  D = 'D',
  N = 'N',
  NA = 'NA',
}
export enum ProceedCode {
  BEFILMED = 'BEFILMED',
  NOTFILMED = 'NOTFILMED',
  REFILMED = 'REFILMED',
  COMPLETE = 'COMPLETE',
  OMIT = 'OMIT',
}

export const ProceedText: Readonly<{ [key: string]: string }> = {
  [ProceedCode.BEFILMED]: '예정',
  [ProceedCode.COMPLETE]: '완료',
  [ProceedCode.NOTFILMED]: '미촬영',
  [ProceedCode.REFILMED]: '재촬영',
  [ProceedCode.OMIT]: '오밋',
};

export const getProceedText = (proceed: string) => {
  try {
    return ProceedText[proceed] || 'No data';
  } catch {
    return 'No data';
  }
};

interface Scene {
  sceneNo: number;
  episode: string;
  sceneSite: SceneSiteCode;
  site: SiteCode;
  playPlaces: string[];
  bigPlayPlaces: string[];
  characters: string[];
  proceed: ProceedCode;
  note?: String;
  sceneNum: number;
  scriptNo: number;
  shootingTime: number;
  summarize: string;
  timeslot: TimeSlotCode;
  props?: string[];
  makeups?: string[];
  costumes?: string[];
  extras?: string[];
  createTime?: Date;
  updateTime?: Date;
  cutCount?: number;
}

export interface SceneResource {
  sceneNo: number;
  episode: string;
  sceneSite: SceneSiteCode;
  site: SiteCode;
  bigPlayPlaces:Resource[];
  playPlaces: Resource[];
  characters: Resource[];
  proceed: ProceedCode;
  note?: string;
  sceneNum: number;
  scriptNo: number;
  shootingTime: number;
  summarize: string;
  timeslot: TimeSlotCode;
  locations?: Resource[];
  props?: Resource[];
  makeups?: Resource[];
  costumes?: Resource[];
  extras?: Resource[];
  sfxs?: Resource[];
  etcs?: Resource[];
  cutCount?: number
}

export interface SceneSummaryInfo {
  sceneNo: number;
  scriptNo: number;
  timeslot: TimeSlotCode;
  sceneNum: number;
  sceneSite: SceneSiteCode;
  site: SiteCode;
  summarize: string;
  shootingTime: number;
  proceed: ProceedCode;
  type: 'ADDED' | 'COPIED' | 'DIVIDED';
  note?: string;
  sharedState: 'N' | 'D' | 'C';
  createTime: string;
  updatedTime: string;
}

export interface SceneParsingPlaceInfo {
  scene: SceneSummaryInfo;
  place: PlaceProps;
  selected?: PlaceProps;
  isEdited?: boolean;
}

export interface SceneParsingCharacterInfo {
  scene: SceneSummaryInfo;
  character: CharacterProps;
  selected?: CharacterProps;
  isEdited?: boolean;
}

export interface Resource {
  resourceNo?: number;
  name: string;
  imgPath?: string;
}

export interface SceneModify {
  proceed: ProceedCode;
  sceneSite: SceneSiteCode;
  shootingTime: number;
  site: SiteCode;
  summarize: string;
  cutCount: number;
  timeslot: TimeSlotCode;
}

export interface SceneCreate {
  sceneNum: string;
  scriptNo: number;
  timeslot?: TimeSlotCode;
  site?: SiteCode;
  playPlaces: string[];
  characters: string[];
  extras?: string[];
  summarize?: string;
  // 소품
  props?: string[];
  makeups?: string[];
  costumes?: string[];
  shootingTime?: number[];
}

export interface SceneCreateForm {
  episode: string;
  sceneNum: string;
  script: TextValue;
  timeslot?: TextValue;
  site?: TextValue;
  playPlaces: string;
  characters: string;
  extras?: string;
  summarize?: string;
  // 소품
  props?: string;
  makeups?: string;
  costumes?: string;
  shootingTime?: TextValue;
}

export interface ProceedInfo {
  beFilmed: number;
  completedFilmed: number;
  notFilmed: number;
  reFilmed: number;
}

export interface StatisticScene {
  proceed: ProceedCode;
  sceneNo: number;
  sceneNum: number;
  playPlaces?: string[];
}

export interface SceneStatistics {
  dayNightInfo: { dayTime?: number; night?: number };
  totalScenes: StatisticScene[];
  totalPlace: Resource[];
  totalCharacter: Resource[];
  proceedInfo: ProceedInfo;
  dayCount?: number;
  nightCount?: number;
  project?: ProjectInfo;
}

export interface TextValue {
  imgPath?: string;
  text: string;
  value?: any;
  event?: (e: any) => void;
  style?: React.CSSProperties;
}

export interface Strip {
  stripNo: number;
  sceneNo: number;
  characterNo: number;
  characterName: string;
  dialog: string;
  name: string;
}
export interface Column {
  text: string;
  value: string;
  show: boolean;
}

export interface DisplayColumn {
  [key: string]: string;
}

export interface TaggedInfo {
  resourceNo: number;
  size: number;
  starts: number[];
  highlightIndex: number;
  tagNo: number;
  taggedNo: number;
  taggedName: string;
}

export interface SceneTagged {
  strip: Strip;
  taggedPositions: TaggedInfo[];
}

export interface TaggedList {
  [index: number]: TaggedInfo[];
}

export type SceneFilterType = typeof SCENE_FILTER_PARAMS_KEY[number];
export type SceneQueryParamsType = typeof SCENE_PARAMS_KEY[number];

export type ProceedState = '예정' | '미촬영' | '재촬영' | '완료' | '오밋';

export type FilterListType = {
  [key in SceneFilterType]: string[];
};

export type FilterSetType = {
  [key in SceneFilterType]: Set<string>;
};

export default Scene;
