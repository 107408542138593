import { useEffect } from 'react';
import { LoadingSpinner } from 'components';

import { ModalTypes } from 'components/modal';
import { useAppDispatch } from 'hooks';
import { createFile } from 'modules/file/FileService';
import { closeModal } from 'modules/modal/modalReducer';
import { showToastMessage } from 'hooks/useToastNotification';

export interface FileUploadProgressModalProps {
  projectNo: number;
  companyNo: number;
  path?: string;
  files: File[];
  onConfirm?: () => void;
}

export const FileUploadProgressModal = ({
  projectNo,
  companyNo,
  path,
  files,
  onConfirm,
}: FileUploadProgressModalProps) => {
  const dispatch = useAppDispatch();

  const handleUploadFile = async (file: File) => {
    if (!projectNo || !companyNo) return;

    const formdata = new FormData();
    formdata.append('file', file);
    const result = await createFile(companyNo, projectNo, path || '', formdata);

    return !!result?.data;
  };

  const startUpload = async () => {
    let successed = [],
      failed = [];
    for (let i = 0; i < files.length; i++) {
      const result = await handleUploadFile(files[i]);
      if (result) successed.push(files[i].name);
      else failed.push(files[i].name);
    }

    let msg = '파일이 업로드 되었습니다.';
    if (!successed.length) msg = '파일업로드 실패되었습니다.';
    else if (failed.length > 0) msg = `${successed.length}개 파일이 업로드 되었습니다.`;

    showToastMessage(msg);
    onConfirm?.();
    dispatch(closeModal(ModalTypes.FileUploadProgress));
  };

  useEffect(() => {
    if (!files.length) return;

    startUpload();
  }, [files]);

  return (
    <div>
      <LoadingSpinner />
    </div>
  );
};
