import { ProceedCode } from 'pages/scene/Scene';
import SvAPI from '../../util/SvAPI';
import { SceneSiteCode, TimeSlotCode } from 'pages/scene/Scene';
import { makeQueryString } from 'util/common';

export interface DashboardScheduleResponse {
  sceneNo: number;
  unitName: string;
  filmNo: number;
  sceneNum: string;
  timeslot: TimeSlotCode;
  location: SceneSiteCode;
  place: string;
  summarize: string;
  characters: string;
  costumes: string;
  shootingTime: number;
  proceed: ProceedCode;
  scheduleType: 'REST' | 'SCHEDULE';
}

export enum ListType {
  TODAY = 'today',
  NEXT = 'next',
}

export interface GetScheduleParam {
  projectNo: number;
  type: ListType;
  params?: { [key: string]: string | number };
}

const getScheduledScenes = async (projectNo: number, type: ListType, params?: { [key: string]: string | number }) => {
  const query = makeQueryString(params);
  return SvAPI.get(SvAPI.projectSchedulesUrl(projectNo) + `/${type}${query}`).then((res) => res.data);
};

const fetchScheduleGroups = async (projectNo: number, params: { shareType: string }) => {
  return SvAPI.get(SvAPI.scheduleGroupsUrl(projectNo), params).then((res) => res.data);
};

const fetchSchedules = async (projectNo: number, groupNo: number, params?: {}) => {
  return SvAPI.get(SvAPI.schedulesUrl(projectNo, groupNo), params).then((res) => res.data);
};

const deleteSchedules = async (projectNo: number, groupNo: number, scheduleNos?: number[]) => {
  return SvAPI.delete(SvAPI.scheduleStripUrl(projectNo, groupNo), { scheduleNos: scheduleNos?.join(',') }).then(
    (res) => res.data
  );
};

const confirmWorkSchedule = async (projectNo: number, groupNo: number, params: {}) => {
  return SvAPI.put(SvAPI.confirmScheduleGroupUrl(projectNo, groupNo), params).then((res) => res.data);
};

export { fetchScheduleGroups, fetchSchedules, deleteSchedules, getScheduledScenes, confirmWorkSchedule };
