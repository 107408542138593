import React from 'react';
import style from './ScheduleModal.module.scss';
import { FormModal } from 'components/modal';
import { CustomDatePicker } from 'components';
import { Modal } from 'components/modal';
import { addHeadstrip, modifyHeadstrip, setAddStripType } from '../../../modules/schedules/scheduleReducer';
import { toDateString } from '../../../util/Times';
import { HeadstripType } from '../type';
import { useAppDispatch } from '../../../hooks';
import { showToastMessage } from 'hooks/useToastNotification';

export type AddHeadStripModalProps = {
  type: HeadstripType;
  projectNo: number;
  groupNo: number;
  date: Date;
  excludeDates?: Date[];
  modify?: {
    shootDate: string;
    scheduleNo: number;
  };
};
export default function AddHeadStripModal({
  type,
  date,
  excludeDates,
  projectNo,
  groupNo,
  modify,
}: AddHeadStripModalProps) {
  const today = new Date();

  const [shootDate, setShootDate] = React.useState<Date>();
  const [shootingDate, setShootingDate] = React.useState<string>();

  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch(setAddStripType({ type: null }));
  };

  const onConfirm = () => {
    shootDate?.setMinutes(shootDate.getMinutes() + 5);
    if (modify) {
      const body = {
        date: toDateString(shootDate!),
        groupNo: groupNo,
        projectNo,
        scheduleNo: modify.scheduleNo,
      };
      dispatch(modifyHeadstrip(body)).then(() => {
        showToastMessage(type === 'REST' ? '휴차가 수정되었습니다.' : '헤드스트립이 수정되었습니다.');
      });
    } else {
      const body = {
        date: toDateString(shootDate!),
        groupNo: groupNo,
        projectNo,
        type,
      };
      dispatch(addHeadstrip(body)).then(() => {
        showToastMessage(type === 'REST' ? '새로운 휴차가 추가되었습니다.' : '새로운 헤드스트립이 추가되었습니다.');
        dispatch(setAddStripType({ type: null }));
      });
    }
  };
  return (
    <FormModal
      title={modify ? '헤드스트립 정보' : type === 'SCHEDULE' ? '회차 추가하기' : '휴차 추가하기'}
      size="sm"
      onConfirm={onConfirm}
      onCancel={onCancel}>
      <CustomDatePicker
        minDate={today}
        selectedDate={modify ? new Date(modify.shootDate) : date ? date : new Date()}
        excludeDates={excludeDates}
        getStartDateString={(d: string) => setShootingDate(d)}
        getStartDate={(d: Date) => setShootDate(d)}
      />
      <div className={style.section}>
        <Modal.Label text={type === 'SCHEDULE' ? '촬영 일자' : '휴차 일자'} />
        <Modal.Input value={shootingDate} />
      </div>
    </FormModal>
  );
}
