import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Modal, ModalTypes } from 'components/modal';
import { DataTable, SvgIcon, SvgIconSource, Typography } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal, openModal, resetModalState } from 'modules/modal/modalReducer';
import fileCheckImage from 'public/common/file_check.png';
import {
  parseScriptCharacter,
  parseScriptPlaces,
  replaceScriptCharacters,
  replaceScriptPlaces, scriptResourceModify,
} from 'modules/scenes/SceneService';
import { SceneParsingCharacterInfo, SceneParsingPlaceInfo } from '../Scene';
import { SearchDropdown } from 'components/SearchDropdown/SearchDropdown';
import { getCharacters } from 'modules/characters/CharacterReducer';
import { RootState } from 'modules';
import { Avatar } from '@mui/material';
import { fileUrl } from 'util/fileHelper';

import { CharacterRole } from 'shared';
import { getPlaces } from 'modules/place/placeReducer';

import fakeAvatar from 'public/fake/fake_character.svg';
import fakeImage from 'public/fake/fake_marker.svg';
import { useNavigate } from 'react-router';
import { CharacterProps } from 'pages/character/Character.types';
import { PlaceProps } from 'pages/location/Location.types';
import styles from './ScriptUploadModal.module.scss';

type StepType = 'character' | 'place';

export type ScriptConfirmModalProps = {
  projectNo: number;
  scriptNo: number;
};

export default function ScriptConfirmModal({ projectNo, scriptNo }: ScriptConfirmModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { characters } = useAppSelector((state: RootState) => state.character);
  const { places } = useAppSelector((state: RootState) => state.place);
  const [step, setStep] = React.useState<StepType>('character');
  const [scriptCharacters, setScriptCharacters] = React.useState<SceneParsingCharacterInfo[]>([]);
  const [scriptPlaces, setScriptPlaces] = React.useState<SceneParsingPlaceInfo[]>([]);
  const [characterList, setCharacterList] = React.useState<SceneParsingCharacterInfo[]>([]);
  const [placeList, setPlaceList] = React.useState<SceneParsingPlaceInfo[]>([]);
  const [bigPlaceNameList, setBigPlaceNameList] = React.useState<string[]>([]);
  const [delCharacterList, setDelCharacterList] = React.useState<SceneParsingCharacterInfo[]>([]);
  const [delPlaceList, setDelPlaceList] = React.useState<SceneParsingPlaceInfo[]>([]);

  const redirectUrl = `/project/${projectNo}/scenes?scriptNo=${scriptNo}`;

  const fetchCharacters = async () => {
    if (!scriptNo || !projectNo) return;

    dispatch(getCharacters({ projectNo, params: { noPage: 'true' } }));
    const result = await parseScriptCharacter(scriptNo);
    setScriptCharacters(result || []);
  };

  const fetchPlaces = async () => {
    if (!scriptNo || !projectNo) return;

    dispatch(getPlaces({ projectNo, params: { noPage: 'true' } }));
    const result = await parseScriptPlaces(scriptNo);
    setScriptPlaces(result || []);
  };

  React.useEffect(() => {
    if (step === 'character') fetchCharacters();
    else fetchPlaces();
  }, [step, scriptNo, projectNo]);

  const handleClose = async () => {
    navigate(redirectUrl);
    dispatch(closeModal());
  };

  const handleSelectItem = (index: number, selected?: any) => {

    let list;
    if (step === 'character') {
      list = [...characterList];
      list[index] = { ...list[index], selected, isEdited: true };

      setCharacterList(list);
    } else {
      list = [...placeList];
      list[index] = { ...list[index], selected, isEdited: true };
      if (selected != undefined && selected.bigName) {
        let bigList = [...bigPlaceNameList];
        bigList[index] = selected.bigName;
        setBigPlaceNameList(bigList);
      }
      setPlaceList(list);
    }
  };

  const handleSelectPlaceItem = (index: number, selected?: any, bigPlace?: string) => {
    let list = [...placeList];
    let bigList = [...bigPlaceNameList];
    list[index] = { ...list[index], selected, isEdited: true };
    setPlaceList(list);
    if (selected != null) {
      bigList[index] = bigPlace || '';
      setBigPlaceNameList(bigList);
    }
  };

  const handleRemoveItem = (index: number) => {
    let list;
    if (step === 'character') {
      list = [...characterList];
      const delItem = list.splice(index, 1);
      setCharacterList(list);
      setDelCharacterList([...delCharacterList, ...delItem]);
    } else {
      list = [...placeList];
      let bigPlace = [...bigPlaceNameList];
      const delItem = list.splice(index, 1);
      bigPlace.splice(index, 1);
      setPlaceList(list);
      setDelPlaceList([...delPlaceList, ...delItem]);
      setBigPlaceNameList(bigPlace);
    }
  };

  const handleCreateItem = (index: number, text: string) => {
    let list;
    if (step === 'character') {
      list = [...characterList];
      list[index].selected = { characterNo: -1, name: text, role: CharacterRole.NA };
      list[index].isEdited = true;
      setCharacterList(list);
    } else {
      list = [...placeList];
      list[index].selected = { placeNo: -1, name: text, projectNo };
      list[index].isEdited = true;
      setPlaceList(list);
    }
  };

  const handleNext = async () => {
    let filteredCharacters: any = [];
    let deletedCharacters: any = [];
    let filteredPlaces: { sceneNo: number; placeNo: number; newPlaceNo: number | undefined; bigName: string; }[] = [];
    let deletedPlaces: any = [];
    if (step === 'character') {
      filteredCharacters = characterList
        .filter((sch) => !!sch.isEdited)
        .map((sch) => ({
          sceneNo: sch.scene.sceneNo,
          characterNo: sch.character.characterNo,
          newCharacterNo: sch.selected?.characterNo,
        }));

      deletedCharacters = delCharacterList.map((sch) => ({
        sceneNo: sch.scene.sceneNo,
        characterNo: sch.character.characterNo,
      }));

      setStep('place');
    } else {
      filteredPlaces = [];
      placeList
        .forEach((sp, index) => {
          if (sp.isEdited) {
            filteredPlaces.push({
              sceneNo: sp.scene.sceneNo,
              placeNo: sp.place.placeNo,
              newPlaceNo: sp.selected?.placeNo,
              bigName: bigPlaceNameList[index],
            });
          }
        });


      deletedPlaces = delPlaceList.map((sp) => ({
        sceneNo: sp.scene.sceneNo,
        placeNo: sp.place.placeNo,
      }));


      navigate(redirectUrl);
      dispatch(resetModalState());

      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: {
            text: '대본 수정을 완료 하시겠습니까?',
            confirmBtnText: '확인',
            onConfirm: async () => {
              if (filteredCharacters.length > 0 || deletedCharacters.length > 0)
                await replaceScriptCharacters(scriptNo, [...filteredCharacters, ...deletedCharacters]);
              if (filteredPlaces.length > 0 || deletedPlaces.length > 0)
                await replaceScriptPlaces(scriptNo, [...filteredPlaces, ...deletedPlaces]);
              await scriptResourceModify(scriptNo, true);
            },
            onCancel: () => {
              scriptResourceModify(scriptNo, false);
            },
          },
        })
      );
    }
  };

  React.useEffect(() => {
    if (!scriptPlaces?.length || !places?.data?.body?.length) return;

    const map: { [key: number]: PlaceProps } = places.data.body.reduce((reducer, p) => {
      return { ...reducer, [p.placeNo]: p };
    }, {});

    const list = scriptPlaces?.map((c) => ({ ...c, selected: map[c.place?.placeNo] }));
    const bigPlaceList = list.map((c) => c.selected?.bigName || '');
    setPlaceList(list);
    setBigPlaceNameList(bigPlaceList);
  }, [places, scriptPlaces]);

  React.useEffect(() => {
    if (!scriptCharacters?.length || !characters?.data?.body?.length) return;

    const map: { [key: number]: CharacterProps } = characters.data.body.reduce((reducer, p) => {
      return { ...reducer, [p.characterNo]: p };
    }, {});

    const list = scriptCharacters?.map((c) => ({ ...c, selected: map[c.character?.characterNo] }));
    setCharacterList(list);
  }, [characters, scriptCharacters]);

  const columns = [
    {
      title: <span className="fs-3.5">씬 넘버</span>,
      dataIndex: 'no',
      key: 'no',
      width: 70,
      className: 'px-2',
      render: (_: any, row: any) => {
        return (
          <Typography variant="f14-166--56" className="fw-bold">
            S# {row.scene.sceneNum}
          </Typography>
        );
      },
    },
    {
      title: <span className="fs-3.5">{step === 'character' ? '대본 정보값' : '대장소'}</span>,
      dataIndex: 'companyName',
      key: 'companyName',
      className: 'px-2',
      render: (_: any, row: any, index: number) => {
        return step === 'character' ? (
          <Typography tag="span" variant="f14-166--56" className="fw-bold">
            {row.character?.name || ''}
          </Typography>
        ) : (
          <div className={styles.deletableInput}>
            <input
              value={bigPlaceNameList[index] || ''}
              onChange={(e) => handleSelectPlaceItem(index, { ...row.selected }, e.target.value)}
              disabled={!row.selected}
            />
            {bigPlaceNameList[index] && (
              <span className="pointer" onClick={() => handleSelectPlaceItem(index, { ...row.selected })}>
                <SvgIcon source={SvgIconSource.CloseCircle} size={22} className="ms-1" color="danger" />
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'equal',
      key: 'equal',
      width: 70,
      className: 'text-center bg-white',
      render: () => {
        return <span className="fs-5 text-primary">=</span>;
      },
    },
    {
      title: <span className="fs-3.5">{step === 'character' ? 'DB 입력값' : '소장소'}</span>,
      dataIndex: 'newNo',
      key: 'newNo',
      render: (_: any, row: any, index: number) => {
        if (row.selected) {
          return (
            <div className="items-center gap-1 px-2">
              {step === 'character' ? (
                <Avatar
                  src={
                    row.selected?.characterImg?.length ? fileUrl(row.selected.characterImg?.[0].imgPath) : fakeAvatar
                  }
                  sx={{ width: 28, height: 28 }}
                />
              ) : (
                <Avatar
                  src={row.selected.placeImg?.length ? fileUrl(row.selected.placeImg?.[0].imgPath) : fakeImage}
                  sx={{ width: 28, height: 28 }}
                />
              )}
              <Typography variant="f12-166--48" className="fw-medium flex-1">
                {row.selected?.name}
                {step === 'character' && <span className="text-blue-gray-300">(배우명)</span>}
              </Typography>
              <span className="pointer" onClick={() => handleSelectItem(index)}>
                <SvgIcon source={SvgIconSource.CloseCircle} size={22} className="ms-1" color="danger" />
              </span>
            </div>
          );
        }

        return (
          <SearchDropdown
            list={step === 'character' ? characters?.data?.body || [] : places?.data?.body || []}
            filterKeys={['name']}
            hideSearchBtn
            activeInFocus
            inputClass="border-0 bg-transparent"
            onSearch={() => {}}
            onCreate={(val: string) => handleCreateItem(index, val)}
            onSelect={(item) => {
              console.log(item);
              handleSelectItem(index, item);
            }}
            renderEmptyItem={(text: string) => (
              <div className="items-center gap-1 fs-3">
                <span className="text-primary">생성</span>
                <Avatar src={step === 'character' ? fakeAvatar : fakeImage} sx={{ width: 28, height: 28 }} />
                <span>{text}</span>
              </div>
            )}
            rednerItem={(item) => (
              <>
                {step === 'character' ? (
                  <Avatar
                    src={item.characterImg?.length ? fileUrl(item.characterImg?.[0].imgPath) : fakeAvatar}
                    sx={{ width: 28, height: 28 }}
                  />
                ) : (
                  <Avatar
                    src={item.placeImg?.length ? fileUrl(item.placeImg?.[0].imgPath) : fakeImage}
                    sx={{ width: 28, height: 28 }}
                  />
                )}
                <Typography variant="f12-166--48" className="fw-medium">
                  {item.name}
                  {step === 'character' && <span className="text-blue-gray-300">(배우명)</span>}
                </Typography>
              </>
            )}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: 40,
      className: 'text-center bg-white',
      render: (_: any, row: any, index: number) => (
        <span onClick={() => handleRemoveItem(index)}>
          <SvgIcon source={SvgIconSource.Trash} color="danger" />
        </span>
      ),
    },
  ];

  return (
    <Modal.Container size="xl" style={{ borderRadius: `var(--f4)`, padding: 0 }}>
      <div className="pt-12 px-10 text-left">
        <div className="items-center mb-1">
          <img src={fileCheckImage} width={30} height={30} className="mr-2" />
          <Typography variant="f24-166--96" className="fw-bolder">
            대본의 <span className="text-primary">{step === 'character' ? '인물' : '장소'}</span>을 확인해주세요.
          </Typography>
        </div>
        <Typography variant="f16-166--64" className="fw-medium text-blue-gray-400">
          대본에서 파싱한 {step === 'character' ? '인물' : '장소'} 정보 값을 확인합니다.
        </Typography>
        <div className="d-flex flex-column gap-2.5 py-6">
          <DataTable
            columns={columns}
            scroll={{ y: 360 }}
            data={step === 'character' ? characterList || [] : placeList || []}
            isSpacing
          />
        </div>
      </div>
      <div className="items-center justify-content-end gap-2.5 border-top pt-3 pb-6 px-12">
        <Button variant="outline-secondary" className="px-8 fs-3.5" onClick={handleClose}>
          취소
        </Button>
        <Button className="px-7 fs-3.5" onClick={handleNext}>
          다음 <SvgIcon source={SvgIconSource.ArrowRight} size={16} color="white" />
        </Button>
      </div>
    </Modal.Container>
  );
}
