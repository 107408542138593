import { useState, useEffect } from 'react';
import { SvgIcon, SvgIconSource } from 'components/SvgIcon';
import { MemoInputProps } from './MemoInput.types';
import { Form } from 'react-bootstrap';
import { useAppDispatch } from 'hooks';
import { ModalTypes } from 'components/modal';
import { openModal } from 'modules/modal/modalReducer';

export const MemoInput = ({ isInline = false, disabled = false, value = '', onChange }: MemoInputProps) => {
  const dispatch = useAppDispatch();
  const [localValue, setLocalValue] = useState(value);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleEdit = () => {
    if (isInline) {
      if (editable) {
        setEditable(false);
        onChange?.(localValue);
      } else {
        setEditable(true);
        setLocalValue(value);
      }
    } else {
      dispatch(
        openModal({
          type: ModalTypes.MemoPopup,
          props: {
            defaultValue: localValue,
            onConfirm: (content: string) => {
              onChange?.(content);
            },
          },
        })
      );
    }
  };

  return (
    <div className="flex-1 border rounded-3.5 px-5 py-4" style={{ minHeight: 154 }}>
      <div className="d-flex align-items-center gap-x-1">
        <SvgIcon source={SvgIconSource.Pin} />
        <div className="fs-3.5 flex-1 fw-bold ms-1">메모</div>
        {!disabled && (
          <div>
            <button
              className="fs-3 fw-medium text-blue-gray-300 text-decoration-underline bg-white border-0"
              onClick={handleEdit}>
              {editable ? '수정완료' : '수정하기'}
            </button>
          </div>
        )}
      </div>
      <div className="mt-4 fs-3.5">
        {editable ? (
          <Form.Control
            className="input-gray border-0"
            as="textarea"
            rows={4}
            onChange={(e) => setLocalValue(e.target.value)}
          />
        ) : (
          <span>{value}</span>
        )}
      </div>
    </div>
  );
};
