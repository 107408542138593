import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Typography } from 'components';

import { Modal } from 'components/modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'modules';
import { createFolder } from 'modules/file/FileService';
import { closeModal } from 'modules/modal/modalReducer';

export interface FolderRegModalProps {
  onConfirm?: () => void;
}

export const FolderRegModal = ({ onConfirm }: FolderRegModalProps) => {
  const dispatch = useAppDispatch();
  const { project } = useAppSelector((state: RootState) => state.project);
  const [folderName, setFolderName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChangeInfo = (value: string) => {
    setErrorMessage(null);
    setFolderName(value);
  };

  const handleSave = async () => {
    const { companyNo, projectNo } = project?.data || {};
    if (!companyNo || !projectNo || !folderName) return;

    const nn = encodeURIComponent(folderName);
    const result = await createFolder(companyNo, projectNo, nn);
    if (result) {
      onConfirm?.();
    }

    handleClose();
  };

  return (
    <Modal.Container size="sm" style={{ padding: 'var(--f24)' }}>
      <div className="pb-5 text-left">
        <Typography tag="h4" variant="f24-166--96" className="fw-bold">
          폴더 추가하기
        </Typography>
      </div>
      <div className="text-left">
        <div className="d-flex flex-column gap-y-4">
          <div>
            <InputGroup className="input-sm no-border mt-1">
              <Form.Control
                className="bg-light"
                placeholder="폴더 명을 입력해주세요."
                value={folderName}
                onChange={(e) => handleChangeInfo(e.target.value)}></Form.Control>
            </InputGroup>
          </div>
        </div>
        {errorMessage && (
          <div className="mt-4">
            <Typography variant="f12-166--48" className="text-danger">
              {errorMessage}
            </Typography>
          </div>
        )}
      </div>
      <div className="items-center gap-2.5 pt-12">
        <Button variant="outline-gray" className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleClose}>
          취소
        </Button>
        <Button className="w-27 h-10 m-0 fs-3.5 fw-bold" onClick={handleSave}>
          저장
        </Button>
      </div>
    </Modal.Container>
  );
};
