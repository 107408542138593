import * as React from 'react';
import { HorizontalLine } from 'components';
import { Modal, SearchListModal } from 'components/modal';
import { createTagged } from '../../../modules/tags/tagReducer';
import { closeModal } from '../../../modules/modal/modalReducer';
import check from '../../../public/common/check_circle_green.svg';
import { useAppDispatch } from '../../../hooks';

export type TagAddProps = {
  param: { tagNo: number; stripNo: number };
  modalPos: { x: number; y: number };
};

const styles: React.CSSProperties = {
  position: 'absolute',
  right: '1.5rem',
  bottom: '1.2rem',
  cursor: 'pointer',
};

export default function TagAdd({ param }: TagAddProps) {
  const dispatch = useAppDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  const sumbitHandler = () => {
    const formData = {
      highLightIndex: -1,
      resourceName: value,
      stripNo: param.stripNo,
    };
    dispatch(closeModal());
    if (value) dispatch(createTagged({ tagNo: param.tagNo, formData: formData }));
  };

  return (
    <SearchListModal size="small" title="직접 추가하기" disabled items={[]}>
      <HorizontalLine style={{ width: '100%', margin: 0 }} />
      <div
        style={{ padding: '.5rem var(--f10) 0' }}
        onKeyPress={(e) => {
          if (e.key == 'Enter') {
            sumbitHandler();
          }
        }}>
        <Modal.Input refs={inputRef} placeholder="내용을 입력하세요." inputStyle={{}} getValue={setValue} />
        <img alt="check" src={check} width="8%" style={styles} onClick={sumbitHandler} />
      </div>
    </SearchListModal>
  );
}
