import { RefObject, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import style from './Calendar.module.scss';
import { getMonth, getYear } from 'date-fns/esm';
import { SvgIcon, SvgIconSource, Typography } from 'components';

export type TCalendarHeader = {
  calendarRef: RefObject<FullCalendar>;
  onChange?: (date?: Date) => void;
};

export const CalendarHeader = ({ calendarRef, onChange }: TCalendarHeader) => {
  const [date, setDate] = useState<Date | undefined>(calendarRef.current?.getApi().getDate());
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      setDate(calApi.getDate());
    }
  }, [calendarRef]);

  const handleDateChange = (direction: 'prev' | 'today' | 'next'): void => {
    const calApi = calendarRef.current?.getApi();

    if (calApi) {
      if (direction === 'prev') {
        calApi.prev();
      } else if (direction === 'next') {
        calApi.next();
      } else {
        calApi.today();
      }

      const d = calApi.getDate();
      setDate(d);
      onChange?.(d);
    }
  };

  return (
    <div className={style.calendar_header}>
      <div className={style.toolbarLeft} />
      <div className='d-flex align-items-center justify-content-center gap-x-3'>
        <button className={style.btn_arrow} onClick={() => handleDateChange('prev')}>
          <SvgIcon source={SvgIconSource.ArrowLeft} size={16} color='bluegray-300'/>
        </button>
        <Typography tag='span' variant='f20-166--80' className='fw-bolder'>
          {getYear(date ? date : new Date())}년 {months[getMonth(date ? date : new Date())]}월
        </Typography>
        <button className={style.btn_arrow} onClick={() => handleDateChange('next')}>
          <SvgIcon source={SvgIconSource.ArrowRight} size={16} color='bluegray-300'/>
        </button>
      </div>
      <div className={style.toolbarRight} >
        <SvgIcon source={SvgIconSource.ArrowLeft} size={16} color='bluegray-300'/>
        <button className={style.btn_arrow} onClick={() => handleDateChange('today')}>
          <Typography tag='span' variant='f16-166-64' className='fw-border'>오늘</Typography>
        </button>
        <SvgIcon source={SvgIconSource.ArrowRight} size={16} color='bluegray-300'/>
      </div>
    </div>
  );
};
