import style from './LoadingSpinner.module.scss';

export const LoadingSpinner =  () => {
  return (
    <div className={style.container}>
      <div className={style.loading_spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
