import React, { ReactElement, useState } from 'react';
import { Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { TooltipDataCell } from '../TooltipDataCell';
import { Checkbox } from '../checkbox';

import styles from './StripsTable.module.scss';

interface CommentedColumnData {
  data: string | number;
  comment: string;
}

type ColumnData = string | number | CommentedColumnData | null;
export type ColumnDataType<T> = ColumnData | ((t: T) => ColumnData | ReactElement);

interface TableColumn<T> {
  title: string;
  width?: string;
  align?: 'left' | 'center' | 'right' | 'justify';
  style?: React.CSSProperties;
  data?: ColumnDataType<T>;
  onTdClick?: (t: T, idx: number) => void;
  showInputBox?: (idx: number) => boolean;
  inputBox?: (component: ReactElement, idx: number, t: T) => ReactElement;
}

interface StripsTableProps<T> {
  data: Array<T>;
  columnInfo: Array<TableColumn<T>>;
  rowStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  headStyle?: React.CSSProperties;
  isHoverStyle?: boolean;
  isScroll?: boolean;
  checkboxConfig?: {
    show?: boolean;
    id: string;
    isShowCheckedBorder?: boolean;
    onChange?: (idx: number) => void;
    width?: string;
  };
  bordered?: boolean;
}

function isCommentedData(data: any): data is CommentedColumnData {
  return data != null && (data as CommentedColumnData).data !== undefined;
}

function CommentedDataCell({ data, comment }: CommentedColumnData) {
  return (
    <div>
      <TooltipDataCell text={`${data}`} textClassName={styles.main_title}>
        {`${data}`}
      </TooltipDataCell>
      <TooltipDataCell text={`${comment}`} textClassName={styles.sub_title}>
        {`${comment}`}
      </TooltipDataCell>
    </div>
  );
}

function peekData<T>(data: T, column: ColumnDataType<T>): string | ReactElement {
  const peek = typeof column === 'function' ? column(data) : column;

  if (isCommentedData(peek)) {
    return <CommentedDataCell data={peek.data} comment={peek.comment} />;
  }

  if (peek === null || typeof peek === 'string' || typeof peek === 'number') {
    return peek?.toString() || '';
  }

  return peek;
}

export function StripsTable<T>({
  data,
  columnInfo,
  checkboxConfig,
  rowStyle,
  style,
  headStyle,
  isHoverStyle = true,
  isScroll = true,
  bordered = true,
}: StripsTableProps<T>) {
  const [checklist, setChecklist] = useState<Set<number>>(new Set());

  const onChangeCheckbox = (idx: number) => {
    if (checklist.has(idx)) {
      const newSet = new Set(checklist);
      newSet.delete(idx);
      setChecklist(newSet);
    } else {
      setChecklist((prev) => new Set(prev).add(idx));
    }
    if (checkboxConfig?.onChange) checkboxConfig.onChange(idx);
  };

  const onChangeCheckboxAll = () => {
    if (checklist.size === data.length) {
      setChecklist(new Set());
    } else {
      const newSet = new Set<number>();
      Array(data.length)
        .fill(0)
        .map((_, i) => {
          newSet.add(i);
        });
      setChecklist(newSet);
    }
  };

  const onClickRow = (idx: number) => {
    if (checkboxConfig) {
      onChangeCheckbox(idx);
    }
  };

  const header = (
    <tr>
      {checkboxConfig && checkboxConfig.show && (
        <th style={{ width: checkboxConfig.width }}>
          <Checkbox
            id={checkboxConfig.id + 'head'}
            checked={checklist.size === data.length}
            onChange={onChangeCheckboxAll}
          />
        </th>
      )}
      {columnInfo.map((i, idx) => (
        <th key={`header-${idx}`} style={{ width: i.width || 'auto', textAlign: i.align || 'center', ...headStyle }}>
          {i.title}
        </th>
      ))}
    </tr>
  );

  const row = (s: T, idx: number) => (
    <tr
      key={`tabledata-${idx}`}
      className={`${styles.table_tbody_tr} ${checkboxConfig && checklist.has(idx) && styles.tr_checked}`}
      style={rowStyle}
      onClick={() => onClickRow(idx)}>
      {checkboxConfig && checkboxConfig.show && (
        <td style={{ width: checkboxConfig.width }}>
          <Checkbox id={checkboxConfig.id + idx} checked={checklist.has(idx)} onChange={() => onChangeCheckbox(idx)} />
        </td>
      )}
      {columnInfo.map((i, colIdx) => {
        let component: ReactElement;
        if (i.data) {
          const d = peekData(s, i.data);
          if (typeof d === 'string') {
            component = (
              <TooltipDataCell text={d} textAlign={i.align} textClassName={styles.scene_td_text}>
                {d}
              </TooltipDataCell>
            );
          } else {
            component = d;
          }
        } else {
          component = <></>;
        }
        return (
          <td
            key={`${idx}-${colIdx}`}
            style={{ width: i.width || 'auto', textAlign: i.align || 'center', overflow: 'visible', ...i.style }}
            onClick={() => {
              if (i.onTdClick) {
                i.onTdClick(s, idx);
              }
            }}>
            {i.showInputBox && i.showInputBox(idx) && i.inputBox ? i.inputBox(component, idx, s) : component}
          </td>
        );
      })}
    </tr>
  );
  return (
    <InfiniteScroll
      className={styles.body}
      dataLength={data.length}
      next={() => {}}
      hasMore={false}
      loader={<LoadingSpinner />}
      scrollableTarget="scrollableDiv">
      <div
        id="scrollableDiv"
        className={data.length > 0 ? `${styles.table_fix_head} ${isScroll === false && styles.hide_scroll}` : ''}
        style={style}>
        <Table
          className={`table ${isHoverStyle ? 'table-hover' : ''} ${
            bordered ? styles.table_bordered : 'table-borderless'
          } ${styles.table}`}
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 8px',
            marginTop: '-8px',
            tableLayout: 'fixed',
            overflowX: 'hidden',
            marginBottom: 0,
          }}>
          <thead className={styles.table_thead_tr}>{header}</thead>
          <tbody className={styles.body}>{data.map((d, i) => row(d, i))}</tbody>
        </Table>
      </div>
    </InfiniteScroll>
  );
}
