import * as React from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Frame from './Frame';
import { ModalWrapper } from 'components/modal';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from 'components';
import withLazyComponent from 'hoc/LazyComponent';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import ManagerFrame from './ManagerFrame';

// const Searching = withLazyComponent(() => import('pages/search/Searching'));
// const Staff = withLazyComponent(() => import('pages/staff/StaffRoutes'));
// const Files = withLazyComponent(() => import('pages/file/FileRoutes'));
const SceneMain = withLazyComponent(() => import('pages/scene/SceneMain'));
const Schedules = withLazyComponent(() => import('pages/schedule/Schedules'));
const CallSheet = withLazyComponent(() => import('pages/callsheet/CallSheet'));
const People = withLazyComponent(() => import('pages/character/Character'));
const LocationRoute = withLazyComponent(() => import('pages/location/LocationRoute'));
const NotFound = withLazyComponent(() => import('./NotFound'));
const Login = withLazyComponent(() => import('pages/login/Login'));
const JoinMain = withLazyComponent(() => import('pages/join/JoinMain'));
const ProjectInvite = withLazyComponent(() => import('pages/join/ProjectInvite'));
const CompanyInvite = withLazyComponent(() => import('pages/join/CompanyInvite'));
const UserDashboard = withLazyComponent(() => import('pages/dashboard/UserDashboard'));
const ProjectDashboard = withLazyComponent(() => import('pages/dashboard/ProjectDashboard'));
const ProjectRegister = withLazyComponent(() => import('pages/project/ProjectRegister'));
const CompanyRegister = withLazyComponent(() => import('pages/company/CompanyRegister'));
// const Plan = withLazyComponent(() => import('pages/plan/Plan'));
const Profile = withLazyComponent(() => import('pages/profile/Profile'));
const ManagerProject = withLazyComponent(() => import('../../managerPages/project/ManagerProject'));
const ManagerCompany = withLazyComponent(() => import('../../managerPages/company/ManagerCompany'));

const Redirect = ({ path }: { path: string }) => {
  const navigation = useNavigate();
  React.useEffect(() => {
    navigation(path);
  }, [navigation]);
  return <></>;
};

export default function MainFrame() {
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<ErrorPage />}>
        <ModalWrapper />
        <ToastContainer />
        <Routes>
          <Route path="/project/invite/:inviteKey?" element={<ProjectInvite />} />
          <Route path="/company/invite/:inviteKey?" element={<CompanyInvite />} />
          <Route element={<Frame enableLeftMenu={true} />}>
            {/* <Route path="/project/:projectId/search" element={<Searching />} /> */}
            <Route path="/project/:projectId" element={<ProjectDashboard />} />
            {/* <Route path="/project/:projectId/staff" element={<Staff />} /> */}
            {/* <Route path="/project/:projectId/files/*" element={<Files />} /> */}
            <Route path="/project/:projectId/scenes" element={<SceneMain />} />
            <Route path="/project/:projectId/scene/*" element={<SceneMain />} />
            <Route path="/project/:projectId/schedule/*" element={<Schedules />} />
            <Route path="/project/:projectId/callsheets" element={<CallSheet />} />
            <Route path="/project/:projectId/callsheet/*" element={<CallSheet />} />
            <Route path="/project/:projectId/characters/*" element={<People />} />
            <Route path="/project/:projectId/locations/*" element={<LocationRoute />} />
            <Route path="/project/:projectId/*" element={<Redirect path="" />} />
            <Route path="/profile" element={<Profile/>} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/join/*" element={<JoinMain />} />
          <Route element={<Frame enableLeftMenu={false} />}>
            <Route path="/company/register" element={<CompanyRegister />} />
            <Route path="/project/register" element={<ProjectRegister />} />
            {/* <Route path="/plan" element={<Plan />} /> */}
            <Route path="/" element={<UserDashboard />} />
            <Route path="/*" element={<NotFound />} />
          </Route>
          <Route element={<ManagerFrame enableLeftMenu={true} />}>
            <Route path="/manager/companies" element={<ManagerCompany />} />
            <Route path="/manager/company/*" element={<ManagerCompany />} />
            <Route path="/manager/company/:companyId/project" element={<ManagerProject />} />
            <Route path="/manager/*" element={<NotFound />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
