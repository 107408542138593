import { useState, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import styles from './SearchDropdown.module.scss';
import { Button, Form } from 'react-bootstrap';
import { SearchDropdownProps } from './SearchDropdown.types';
import { Typography } from 'components/Typography';

export const SearchDropdown = ({
  placeholder = '',
  typeName = '',
  list = [],
  filterKeys = [],
  activeInFocus = false,
  hideSearchBtn = false,
  inputClass,
  onSearch,
  onSelect,
  onCreate,
  rednerItem,
  renderEmptyItem,
  onChange,
}: SearchDropdownProps) => {
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setActiveIndex(-1);
  }, [open, searchText]);

  useEffect(() => {
    const handleCloseDropdown = () => {
      setOpen(false);
    };

    document.addEventListener('click', handleCloseDropdown);

    return () => document.removeEventListener('click', handleCloseDropdown);
  }, []);

  const handleSelectByIndex = (idx: number) => {
    if (onSelect) {
      onSelect(list[idx]);
    }
    setOpen(false);
  };

  const handleSelect = (item: any) => {
    if (onSelect) {
      onSelect(item);
    }
    setOpen(false);
  };

  const handleCreate = () => {
    if (onCreate) {
      onCreate(searchText);
    }
    setOpen(false);
  };

  const handleSearch = () => {
    inputRef?.current?.focus();

    if (activeIndex === 0) handleCreate();
    else if (activeIndex > 0) handleSelectByIndex(activeIndex - 1);

    if (onSearch) {
      onSearch(searchText);
    }
  };

  const handlePreventClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderEmptyDropdownItem = () => {
    if (!onCreate || !searchText) {
      return (
        <li onClick={handleCreate}>
          <Typography variant="f12-166--48" className="fw-medium text-blue-gray-300">
            결과 없음
          </Typography>
        </li>
      );
    }

    return null;
  };

  const renderNewItem = () => {
    if (!onCreate || !searchText) return null;

    if (renderEmptyItem) {
      return (
        <li className={activeIndex === 0 ? styles.itemHover : ''} onClick={handleCreate}>
          {renderEmptyItem(searchText)}
        </li>
      );
    }

    return (
      <li onClick={handleCreate} className={activeIndex === 0 ? styles.itemHover : ''}>
        <div className={styles.addIcon}>+</div>
        <Typography variant="f12-166--48" className="fw-medium">
          '{searchText}' {typeName} 신규 추가하기
        </Typography>
      </li>
    );
  };

  const filteredList = useMemo(() => {
    if (searchText && filterKeys.length) {
      return list.filter((li) => {
        return Object.keys(li).find((key) => filterKeys.includes(key) && li[key]?.includes(searchText));
      });
    } else {
      return list;
    }
  }, [list, filterKeys, searchText]);

  const handleFocusInput = () => {
    if (!activeInFocus) return;
    setOpen(true);
  };

  const handleLeaveInput = () => {
    if (!activeInFocus) return;
    setTimeout(() => setOpen(false), 200);
  };

  const handleKeyControl = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const minIndex = onCreate && searchText ? 0 : 1;
    if (!open || (!filteredList.length && minIndex === 1)) {
      setActiveIndex(-1);
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex(Math.max(activeIndex - 1, minIndex));
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex(Math.min(activeIndex + 1, filteredList.length));
      return;
    }
  };

  return (
    <div className="d-flex align-items-center gap-x-4" onClick={handlePreventClick}>
      <div className={classNames(styles.root, open && styles.open, 'flex-1')}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSearch();
          }}>
          <Form.Control
            ref={inputRef}
            placeholder={placeholder}
            className={inputClass || styles.input}
            value={searchText}
            onKeyDown={handleKeyControl}
            onFocus={handleFocusInput}
            onBlur={handleLeaveInput}
            onChange={(e) => {
              const val = e.target.value;
              onChange?.(val);
              setSearchText(val);
            }}
          />
        </form>
        <div className={styles.dropdown}>
          <ul>
            {renderNewItem()}
            {filteredList?.map((item, index) => (
              <li
                key={index}
                className={activeIndex === index + 1 ? styles.itemHover : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelect(item);
                }}>
                {rednerItem ? rednerItem(item) : item.name}
              </li>
            ))}
            {!filteredList.length && renderEmptyDropdownItem()}
          </ul>
        </div>
      </div>
      {!hideSearchBtn && (
        <Button variant="dark" className="w-27 h-10.5 m-0 fs-3.5 fw-bold" onClick={handleSearch}>
          검색
        </Button>
      )}
    </div>
  );
};
