import * as React from 'react';
import styles from './Button.module.scss';

type EpisodeButtonProps = {
  activated?: boolean;
  style?: React.CSSProperties;
  text: string;
  onClick: () => void;
  onModify?: (value: string) => void;
};

export const EpisodeButton = ({ style, text, onClick, activated, onModify }: EpisodeButtonProps) => {
  const [episode, setEpisode] = React.useState(text);
  const [isShowInput, setIsShowInput] = React.useState(false);

  const btnRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const isClickButton = (e: MouseEvent) => {
    if (btnRef.current !== null && !btnRef.current.contains(e.target as Node)) {
      const inputText = inputRef.current?.value!;
      setIsShowInput(false);
      if (onModify && inputText !== '' && inputText !== text) onModify(inputText);
    }
  };

  const detectTabMove = () => {
    setIsShowInput(false);
  };

  React.useEffect(() => {
    setEpisode(text);
  }, [isShowInput]);

  React.useEffect(() => {
    if (isShowInput) {
      window.addEventListener('mousedown', isClickButton);
      window.addEventListener('blur', detectTabMove, false);
    }
    return () => {
      window.removeEventListener('mousedown', isClickButton);
      window.removeEventListener('blur', detectTabMove, false);
    };
  }, [isShowInput]);

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEpisode(e.currentTarget.value);
  };

  const onDoubleClick = () => {
    setIsShowInput(true);
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      const inputText = inputRef.current?.value!;
      if (onModify && inputText !== '' && inputText !== text) onModify(inputText);
      setIsShowInput(false);
    }
  };

  return (
    <button
      className={`${styles.episode_button} ${activated && styles.episode_button_active}`}
      ref={btnRef}
      style={style}
      onClick={onClick}
      onDoubleClick={onModify && onDoubleClick}>
      {!isShowInput ? (
        <span className={styles.episode_button_text}> {text} </span>
      ) : (
        <input
          className={styles.change_text_input}
          ref={inputRef}
          contentEditable
          value={episode}
          onChange={onChangeText}
          onKeyUp={onKeyUp}
        />
      )}
    </button>
  );
};
