import style from './Modal.module.scss';
import { CheckList, CheckedListType, CheckboxList } from 'components';
import { useAppDispatch } from 'hooks';
import { closeModal } from 'modules/modal/modalReducer';

export type CheckboxModalProps = {
  title: string;
  checkList: CheckList[];
  onChange?:(e:React.ChangeEvent<HTMLInputElement>)=>void; 
  onConfirm?: (checkedItems?: CheckedListType[]) => void;
};

export default function CheckboxModal({ title, checkList, onChange, onConfirm }: CheckboxModalProps) {
  const dispatch = useAppDispatch();

  const onConfirmCheckList = (checkedItems?:CheckedListType[]) => {
    if (onConfirm) onConfirm(checkedItems);
    dispatch(closeModal());
  };

  return (
    <div className={style.checkbox_modal}>
      <span className={style.checkbox_modal__title}>{title}</span>
      <CheckboxList checkList={checkList} onChange={onChange} onConfirm={onConfirm ? onConfirmCheckList : undefined} />
    </div>
  );
}
