import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RefObject, useRef } from 'react';
import styles from './TooltipDataCell.module.scss';

type TooltipDataCellProps = {
  style?: React.CSSProperties;
  textClassName?: string;
  text: string | React.ReactElement;
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  children?: React.ReactNode;
  alwaysOn?: boolean;
};

export const TooltipDataCell = ({
  textClassName,
  text,
  textAlign,
  style,
  children,
  alwaysOn,
}: TooltipDataCellProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const useIsOverflow = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const hasOverflow = ref.current.scrollWidth > ref.current.clientWidth;
      return hasOverflow;
    }
  };

  return (
    <div ref={parentRef} className={styles.tooltip_text}>
      {useIsOverflow(parentRef) || alwaysOn ? (
        <OverlayTrigger
          flip={false}
          placement="top"
          delay={{ show: 300, hide: 100 }}
          overlay={<Tooltip className={styles.tooltip}>{text}</Tooltip>}>
          <p
            className={`${textClassName}`}
            style={{ textAlign: textAlign ? textAlign : 'start', ...style }}>
            {children}
          </p>
        </OverlayTrigger>
      ) : (
        <p
          className={`${textClassName}`}
          style={{ textAlign: textAlign ? textAlign : 'start', ...style }}>
          {children}
        </p>
      )}
    </div>
  );
};
