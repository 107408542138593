import SvAPI from 'util/SvAPI';

export interface InviteFormData {
  email: string[];
  groupNo?: number;
}

const sendStaffInvitation = async (projectNo: number, params: InviteFormData) => {
  return SvAPI.post(SvAPI.staffSendUrl(projectNo), params);
};

const findInvitedProject = async (code: string) => {
  return SvAPI.get(SvAPI.staffInviteUrl(code), {});
};

const acceptStaffInvitation = async (code: string) => {
  return SvAPI.post(SvAPI.staffInviteUrl(code), {});
};

const createStaff = async (projectNo: number) => {
  return SvAPI.post(SvAPI.staffUrl(projectNo), {}).then((res) => res.data);
};

const udpateStaff = async (projectNo: number) => {
  return SvAPI.put(SvAPI.placesUrl(projectNo), {}).then((res) => res.data);
};

const deleteStaffs = async (projectNo: number, userNos: number[]) => {
  const query = `?userNos=${userNos.join(',')}`;
  return SvAPI.delete(SvAPI.staffsUrl(projectNo) + query, { }).then((res) => res.data);
};

export { findInvitedProject, acceptStaffInvitation, sendStaffInvitation, createStaff, udpateStaff, deleteStaffs };
