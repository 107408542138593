import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SvgIcon, SvgIconSource } from 'components/SvgIcon';
import { SearchBoxProps } from './SearchBox.types';
import classNames from 'classnames';

export const SearchBox = ({
  className = '',
  controlClass = '',
  placeholder = '',
  value,
  onChange,
  onSearch,
}: SearchBoxProps) => {
  const handleChange = (e: any) => {
    if (!onChange) return;
    onChange(e.target.value);
  };

  const handleSearch = () => {
    if (!onSearch) return;
    onSearch();
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSearch();
    }}>
      <InputGroup className={classNames('no-split', className)}>
        <Form.Control
          className={controlClass}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <InputGroup.Text className='pointer' onClick={handleSearch}>
          <SvgIcon source={SvgIconSource.Search} />
        </InputGroup.Text>
      </InputGroup>
    </form>
  );
};
