export * from './scheduleConfig';
import scissors from 'public/common/scissors.svg';
import copy from 'public/common/copy.svg';
import trash_only from 'public/common/trash_only.svg';

export enum SiteCode {
  S = 'S',
  L = 'L',
  NA = 'NA',
}

export enum CompanyType {
  STUDIO = 'STUDIO',
  MANAGEMENT = 'MANAGEMENT',
}

export enum GenderType {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  NA = 'NA',
}

export enum AgeRangeType {
  A10 = 'A_10',
  A20 = 'A_20',
  A30 = 'A_30',
  A40 = 'A_40',
  A50 = 'A_50',
  A60 = 'A_60',
  A70 = 'A_70',
}

export enum CharacterRole {
  MAIN = 'MAIN',
  SUB = 'SUB',
  NA = 'NA',
}

export enum UserRoleName {
  PRODUCER = '프로듀서',
  ACTOR = '배우',
  DIRECTOR = '감독',
  WRITER = '작가',
}

export type ItemType = 'CHARACTER_MAIN' | 'CHARACTER_SUB' | 'PROP' | 'COSTUME' | 'MAKEUP' | 'EXTRA' | 'SFX' | 'ETC';

export const DIRECTION = {
  MOVE_SKIP_BACK: 'moveSkipBack',
  MOVE_SKIP_FORWARD: 'moveSkipForward',
  MOVE_FORWARD: 'moveForward',
  MOVE_BACK: 'moveBack',
};

export const PROCEED = [
  { text: '예정', value: 'BEFILMED' },
  { text: '미촬영', value: 'NOTFILMED' },
  { text: '재촬영', value: 'REFILMED' },
  { text: '완료', value: 'COMPLETE' },
  { text: '오밋', value: 'OMIT' },
];

export const SCENE_EDIT = [
  { text: '복사하기', value: 'COPY', icon: copy },
  { text: '씬 나누기', value: 'DIVIDE', icon: scissors },
  { text: '삭제하기', value: 'DELETE', icon: trash_only },
];

export const STATIC_PROCEED = [
  { text: '촬영 예정', value: 'beFilmed', color: '#FFBE17' },
  { text: '촬영 완료', value: 'completedFilmed', color: '#3AC8B4' },
  { text: '재촬영', value: 'reFilmed', color: '#E5615D' },
  { text: '미촬영', value: 'notFilmed', color: '#93969B' },
];

export const DISPLAY_COLUMN = [
  { text: 'EP', value: 'episode', show: true },
  { text: '씬 넘버', value: 'sceneNum', show: true },
  { text: 'D/N', value: 'timeSlot', show: true },
  { text: 'S/L', value: 'site', show: true },
  { text: 'I/E', value: 'sceneSite', show: true },
  { text: '장소', value: 'playPlaces', show: true },
  { text: '촬영지', value: 'locations', show: true },
  { text: '캐릭터', value: 'characters', show: true },
  { text: '보조출연', value: 'extras', show: true },
  { text: '소품/분장/의상', value: 'items', show: true },
  { text: 'CG/특수효과', value: 'sfxs', show: false },
  { text: '예상 소요 시간', value: 'shootingTime', show: false },
  { text: '컷수', value: 'count', show: false },
  { text: '기타', value: 'etcs', show: false },
];

export const ANAL_MODEL = [
  { text: '일반', value: '1' },
  { text: 'chat GPT', value: '2' },
];