import React from 'react';
import { Modal } from './ModalComponents';
import { ModalSizeType } from './type';
import classNames from 'classnames';

export type InfoModalProps = {
  title: string;
  children?: React.ReactNode;
  size: ModalSizeType;
  bodyClass?: string;
};

export const InfoModal = ({ title, children, size, bodyClass }: InfoModalProps) => {
  return (
    <Modal.Container size={size} style={{ padding: '5px' }}>
      <div className="d-flex flex-column">
        <div className="pt-10 px-10 pb-4">
          <Modal.Title title={title} isCloseButton={true} />
        </div>
        <div className={classNames('flex-1 overflow-y-auto pt-5 px-10 pb-10', bodyClass || '')}>{children}</div>
      </div>
    </Modal.Container>
  );
};
